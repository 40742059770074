<template>
  <div class="twitter">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      class="fig-share-tools__svg"
      aria-hidden="true"
      focusable="false"
    >
      <path
        d="M22 5.6a8 8 0 0 1-2.4.7A4.2 4.2 0 0 0 21.4 4a8 8 0 0 1-2.6 1 4 4 0 0 0-3-1.3 4.2 4.2 0 0 0-4 5.1C8.4 8.7 5.4 7 3.4 4.4a4.3 4.3 0 0 0-.6 2.2c0 1.4.8 2.7 1.9 3.5a4 4 0 0 1-1.9-.6c0 2.1 1.4 3.8 3.3 4.2a4 4 0 0 1-1 .2 4 4 0 0 1-.9-.1c.6 1.6 2 2.9 3.9 2.9A8.1 8.1 0 0 1 2 18.4a11.4 11.4 0 0 0 6.3 2c7.5 0 11.7-6.5 11.7-12v-.6a8.4 8.4 0 0 0 2-2.2"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "twitter-icon",
};
</script>

<style scoped>
.twitter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.twitter svg {
  width: 20px;
  height: 20px;
}

.twitter svg path {
  fill: var(--link);
}
</style>
