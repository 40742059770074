<template>
  <div
    class="carousel"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div
      v-if="images.length"
      class="carousel-inner"
      :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
    >
      <div class="carousel-item" v-for="(image, index) in images" :key="index">
        <img
          v-if="image.length > 1"
          :src="image"
          :alt="'Image ' + (index + 1)"
          @click="openModal(index)"
        />
      </div>
    </div>
    <button v-if="images.length > 1" class="prev" @click="prev">
      <el-icon><ArrowLeft /></el-icon>
    </button>
    <button v-if="images.length > 1" class="next" @click="next">
      <el-icon><ArrowRight /></el-icon>
    </button>

    <!-- Dots de Pagination -->
    <div v-if="images.length > 1" class="dots">
      <span
        v-for="(image, index) in images"
        :key="index"
        :class="['dot', { active: index === currentIndex }]"
        @click="goTo(index)"
      ></span>
    </div>

    <!-- Modal pour visionner les images en carrousel -->
    <div
      v-if="isModalOpen"
      class="modal"
      @click.self="closeModal"
      @touchstart="handleModalTouchStart"
      @touchmove="handleModalTouchMove"
      @touchend="handleModalTouchEnd"
    >
      <div class="modal-content">
        <div
          class="modal-carousel-inner"
          :style="{ transform: `translateX(-${modalCurrentIndex * 100}%)` }"
        >
          <div
            class="modal-carousel-item"
            v-for="(image, index) in images"
            :key="index"
          >
            <img :src="image" alt="Selected Image" />
          </div>
        </div>
        <button v-if="images.length > 1" class="modal-prev" @click="modalPrev">
          ‹
        </button>
        <button v-if="images.length > 1" class="modal-next" @click="modalNext">
          ›
        </button>
      </div>
      <button class="close-modal" @click="closeModal">
        <el-icon><Close /></el-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageCarousel",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      modalCurrentIndex: 0,
      touchStartX: 0,
      touchStartY: 0,
      touchEndX: 0,
      touchEndY: 0,
      touchThreshold: 50, // Distance minimale en pixels pour reconnaître un swipe
      swipeAngleThreshold: 30, // Angle en degrés pour différencier swipe horizontal/vertical
      isModalOpen: false,
    };
  },
  methods: {
    prev() {
      this.currentIndex =
        (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    goTo(index) {
      this.currentIndex = index;
    },
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
      this.touchStartY = event.changedTouches[0].screenY;
    },
    handleTouchMove(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.touchEndY = event.changedTouches[0].screenY;
    },
    handleTouchEnd() {
      const deltaX = this.touchEndX - this.touchStartX;
      const deltaY = this.touchEndY - this.touchStartY;
      const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);

      if (distance > this.touchThreshold) {
        const angle =
          Math.atan2(Math.abs(deltaY), Math.abs(deltaX)) * (180 / Math.PI);
        if (angle < this.swipeAngleThreshold) {
          // Swipe horizontal
          if (deltaX < 0) {
            this.next();
          } else {
            this.prev();
          }
        }
        // Sinon, c'est un défilement vertical, ne rien faire
      }

      // Réinitialiser les positions après le swipe
      this.touchStartX = 0;
      this.touchStartY = 0;
      this.touchEndX = 0;
      this.touchEndY = 0;
    },
    openModal(index) {
      this.modalCurrentIndex = index;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    modalPrev() {
      this.modalCurrentIndex =
        (this.modalCurrentIndex - 1 + this.images.length) % this.images.length;
    },
    modalNext() {
      this.modalCurrentIndex =
        (this.modalCurrentIndex + 1) % this.images.length;
    },
    handleModalTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
      this.touchStartY = event.changedTouches[0].screenY;
    },
    handleModalTouchMove(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.touchEndY = event.changedTouches[0].screenY;
    },
    handleModalTouchEnd() {
      const deltaX = this.touchEndX - this.touchStartX;
      const deltaY = this.touchEndY - this.touchStartY;
      const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);

      if (distance > this.touchThreshold) {
        const angle =
          Math.atan2(Math.abs(deltaY), Math.abs(deltaX)) * (180 / Math.PI);
        if (angle < this.swipeAngleThreshold) {
          // Swipe horizontal
          if (deltaX < 0) {
            this.modalNext();
          } else {
            this.modalPrev();
          }
        }
        // Sinon, c'est un défilement vertical, ne rien faire
      }

      // Réinitialiser les positions après le swipe
      this.touchStartX = 0;
      this.touchStartY = 0;
      this.touchEndX = 0;
      this.touchEndY = 0;
    },
  },
};
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  touch-action: pan-y; /* Permet de désactiver le défilement horizontal natif si nécessaire */
}
.carousel-inner {
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 100%;
}
.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
}
.carousel-item img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  /* max-width: 440px; */
  max-height: 440px;
  cursor: pointer;
  border-radius: 6px;
}
.prev,
.next {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: var(--card);
  color: var(--color);
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 25px;
  opacity: 0.3;
  transition: 0.2s ease;
  font-size: 16px;
}
.prev {
  left: 10px;
}
.next {
  right: 10px;
}

.prev:hover,
.next:hover {
  opacity: 1;
}

/* Styles pour les Dots */
.dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}
.dot {
  width: 6px;
  height: 6px;
  background-color: var(--card);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.dot.active {
  background-color: var(--button);
}
.dot:hover {
  background-color: var(--button);
}

/* Styles pour le Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modal-content {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  overflow: hidden;
}
.modal-carousel-inner {
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 100%;
}
.modal-carousel-item {
  min-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-carousel-item img {
  object-fit: contain;
  width: 100%;
  min-width: 300px;
  height: 90%;
}
.modal-prev,
.modal-next {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: var(--card);
  color: var(--color);
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 25px;
  opacity: 0.3;
  transition: 0.2s ease;
  z-index: 1001;
}
.modal-prev {
  left: 20px;
}
.modal-next {
  right: 20px;
}

.modal-prev:hover,
.modal-next:hover {
  opacity: 1;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1002;
}

.close-modal .el-icon {
  color: white;
}
</style>
