<template>
  <div class="articles">
    <div class="posts">
      <router-link :to="`/articles/${lien}`">
        <div class="post">
          <div class="image-container">
            <img
              :src="`https://cp.doonun.com${img}`"
              :alt="{ titre }"
              class="post-img"
            />
          </div>
          <div class="cat">
            <p>{{ categorie }}</p>
          </div>
          <div class="info">
            <p class="date">{{ date }}</p>
            <h2>{{ titre }}</h2>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "post-doonun",
  props: {
    img: String,
    titre: String,
    categorie: String,
    date: String,
    lien: String,
  },
};
</script>

<style scoped>
.articles {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-family: "Poppins", "Inter", sans-serif !important;
}

.articles .posts {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
}

.articles .posts .post {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.articles .posts .post::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 350px;
  background: rgb(20, 25, 34);
  background: linear-gradient(
    180deg,
    rgba(20, 25, 34, 0) 0%,
    rgba(20, 25, 34, 0.9472163865546218) 100%
  );
  z-index: 5;
  border-radius: 0 0 6px 6px;
}

.articles .post .image-container {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 6px;
  overflow: hidden;
}

.articles .posts .post .image-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.articles .posts .post:hover .image-container img {
  transform: scale(1.02);
}

.articles .info {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
  padding: 20px 30px;
  z-index: 6;
}

.articles .info h2,
.articles .info p {
  color: #fff !important;
}

.articles .info h2 {
  font-size: 30px;
  font-weight: 800;
  /* text-transform: uppercase; */
  line-height: 1.9rem;
}

.articles .date {
  font-size: 14px;
}

.articles .cat {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
  z-index: 6;
}

.articles .cat p {
  display: inline;
  padding: 5px 10px;
  color: #fff !important;
  background: #ff6161;
  border-radius: 4px;
  line-height: 0;
  font-weight: 400;
  font-size: 12px;
}
</style>
