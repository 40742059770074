<template>
  <div class="fiiyd">
    <span v-if="fiiydsCount >= 1">{{ fiiydsCount }}</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M10.5502 3C6.69782 3.00694 4.6805 3.10152 3.39128 4.39073C2 5.78202 2 8.02125 2 12.4997C2 16.9782 2 19.2174 3.39128 20.6087C4.78257 22 7.0218 22 11.5003 22C15.9787 22 18.218 22 19.6093 20.6087C20.8985 19.3195 20.9931 17.3022 21 13.4498"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0556 13C10.3322 3.86635 16.8023 1.27554 21.9805 2.16439C22.1896 5.19136 20.7085 6.32482 17.8879 6.84825C18.4326 7.41736 19.395 8.13354 19.2912 9.02879C19.2173 9.66586 18.7846 9.97843 17.9194 10.6036C16.0231 11.9736 13.8264 12.8375 11.0556 13Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 17C11 11.5 12.9604 9.63636 15 8"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "fiiyd-icon",
  computed: {
    fiiydsCount() {
      return this.$store.state.fiiyd.fiiyds.length;
    },
  },
};
</script>

<style scoped>
.fiiyd {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fiiyd span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  padding: 10px;
  background: var(--button);
  color: #fff;
  border-radius: 50%;
  z-index: 2;
  /* margin-left: -35px; */
  margin-top: -14px;
  font-size: 10px !important;
  font-weight: 700;
}

.fiiyd svg {
  width: 22px;
  height: 22px;
}

.fiiyd svg path {
  stroke: var(--link);
}

.router-link-exact-active svg path {
  stroke: var(--button);
}
</style>
