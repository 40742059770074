<template>
  <div class="trending-tags">
    <div class="trends">
      <h2>{{ $t("trends") }}</h2>
      <Stat />
    </div>
    <ul>
      <li v-for="(tag, index) in trendingTags" :key="tag?.id">
        <router-link :to="`/fiiyd/hashtag/${tag?.name}`">
          <span class="nb-t">{{ index + 1 }} • {{ $t("trends") }}</span>
          <p class="name">{{ tag?.name }}</p>
          <p class="count">{{ formatPoints(tag?.totalCount) }} Posts</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Stat from "../icons/fiiyd/Stat.vue";
import { mapGetters } from "vuex";

export default {
  name: "TrendingTags",
  components: {
    Stat,
  },
  computed: {
    ...mapGetters("fiiyd", ["getTrendingTags"]),
    trendingTags() {
      // console.log("getTrendingTags : ", this.getTrendingTags); // Vérifiez ici
      return this.getTrendingTags;
    },
  },
  methods: {
    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return Number(points);
      }
    },
  },
  created() {
    this.$store.dispatch("fiiyd/fetchTrendingTags");
  },
};
</script>

<style scoped>
.trending-tags {
  border: 1px solid var(--card);
  border-radius: 6px;
  width: 100%;
  padding: 20px 0;
}

.trends {
  display: flex;
  align-items: center;
  gap: 10px;
}

.trends h2 {
  font-size: 1.5em;
  padding-left: 20px;
  padding-bottom: 10px;
}

.trending-tags ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.trending-tags li {
  padding: 5px 20px 5px 20px;
  border-top: 1px solid var(--card);
}

.trending-tags li:hover {
  background: var(--card);
}

.nb-t {
  font-size: 12px;
  color: #71767b;
}

.name {
  font-size: 16px;
  color: var(--color);
  font-weight: 500;
  text-transform: capitalize;
}

.count {
  font-size: 12px;
  color: #71767b;
}
</style>
