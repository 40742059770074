<template>
  <div class="leftarrow">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="#000000"
      fill="none"
    >
      <path
        d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "leftarrow-icon",
};
</script>

<style scoped>
.leftarrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftarrow svg {
  width: 16px;
  height: 16px;
}

.leftarrow svg path {
  stroke: var(--color);
}

.box-arrows .leftarrow svg {
  width: 20px;
  height: 20px;
}

.box-arrows .leftarrow svg path {
  stroke: #fff;
}
</style>
