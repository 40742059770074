<template>
  <Head>
    <!-- Titre de la page -->
    <title>Articles - Doonun</title>

    <!-- Description Meta -->
    <meta
      name="description"
      content="Découvrez nos articles sur les sciences islamiques, le développement personnel et bien plus encore. Explorez des sujets variés, enrichissez votre connaissance et renforcez votre foi avec Doonun."
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Canonical URL -->
    <link rel="canonical" href="https://www.doonun.com/articles" />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.doonun.com/articles" />
    <meta property="og:title" content="Articles - Doonun" />
    <meta
      property="og:description"
      content="Découvrez nos articles sur les sciences islamiques, le développement personnel et bien plus encore. Explorez des sujets variés, enrichissez votre connaissance et renforcez votre foi avec Doonun."
    />
    <!-- <meta property="og:image" content="https://cp.doonun.com/uploads/articles_homepage_image.png" /> -->

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.doonun.com/articles" />
    <meta name="twitter:title" content="Articles - Doonun" />
    <meta
      name="twitter:description"
      content="Découvrez nos articles sur les sciences islamiques, le développement personnel et bien plus encore. Explorez des sujets variés, enrichissez votre connaissance et renforcez votre foi avec Doonun."
    />
    <!-- <meta name="twitter:image" content="https://cp.doonun.com/uploads/articles_homepage_image.png" /> -->
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>

  <div class="container">
    <div class="box-posts">
      <div class="left">
        <div class="tags-container">
          <router-link
            v-for="(tag, index) in visibleTags"
            :key="index"
            :to="`/categories/${tag.attributes.url}`"
            class="tag-button"
          >
            {{ tag.attributes.titre }}
          </router-link>
          <button
            v-if="hiddenTags.length"
            @click="showMoreTags"
            class="tag-button show-more"
          >
            +{{ hiddenTags.length }}
          </button>
        </div>
      </div>
      <div class="middle">
        <CardPost
          :key="articleLast.id"
          :lien="`${articleLast.attributes.categories.data[0].attributes.url}/${articleLast.attributes.url}`"
          :img="getImageUrl(articleLast.attributes.Cover)"
          :titre="articleLast.attributes.titre"
          :categorie="
            articleLast.attributes.categories.data[0].attributes.titre
          "
          :date="formatDate(articleLast.attributes.publishedAt)"
        />

        <div class="last-posts">
          <router-link
            v-for="article in sortedArticles.slice(1, 4)"
            :key="article.id"
            :to="`/articles/${article.attributes.categories.data[0].attributes.url}/${article.attributes.url}`"
          >
            <article class="last-item">
              <div class="info">
                <div class="cat-date">
                  <span class="category"
                    >{{
                      article.attributes.categories.data[0].attributes.titre
                    }}
                  </span>
                  <span class="published">
                    • {{ formatDate(article.attributes.publishedAt) }}</span
                  >
                </div>
                <p class="title">
                  {{ article.attributes.titre }}
                </p>
                <hr />
                <p class="desc">{{ article.attributes.Meta }}</p>
              </div>
              <img
                :src="`https://cp.doonun.com${article.attributes.Cover.data.attributes.formats.small.url}`"
                alt=""
              />
            </article>
          </router-link>
        </div>
        <!-- Ajoute ici le système de pagination -->
      </div>
      <div class="right">
        <div class="list-best-posts">
          <h2>Les plus lus</h2>
          <ol class="item-post">
            <router-link
              v-for="article in topArticles"
              :key="article.id"
              :to="`/articles/${article.attributes.categories.data[0].attributes.url}/${article.attributes.url}`"
            >
              <li>
                <div class="infos">
                  <span class="cat-best-post">
                    {{ article.attributes.categories.data[0].attributes.titre }}
                  </span>
                  <span class="title-best-post">
                    {{ article.attributes.titre }}
                  </span>
                </div>
              </li>
            </router-link>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardPost from "../../components/articles/CardPost.vue";
import moment from "moment";
import "moment/locale/fr";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "HomePage",
  components: {
    CardPost,
    Head,
    Tabs,
  },
  data() {
    return {
      sortedArticles: [],
      visibleTagsCount: 8,
      windowWidth: window.innerWidth,
      resizeTimeout: null,
    };
  },
  computed: {
    ...mapGetters("articles", [
      "articlesList",
      "categoriesList",
      "topArticlesList",
      "articleLast",
    ]),
    topArticles() {
      return this.topArticlesList;
    },
    visibleTags() {
      return this.categoriesList.slice(0, this.visibleTagsCount);
    },
    hiddenTags() {
      return this.categoriesList.slice(this.visibleTagsCount);
    },
  },
  methods: {
    ...mapActions("articles", [
      "fetchArticles",
      "fetchCategories",
      "fetchTopArticles",
      "fetchLatestArticle",
    ]),
    getImageUrl(cover) {
      if (
        cover &&
        cover.data &&
        cover.data.attributes &&
        cover.data.attributes.url
      ) {
        return cover.data.attributes.url;
      }
      return "/default-image.jpg";
    },

    formatDate(date) {
      return moment(date).locale("fr").fromNow();
    },

    sortArticles() {
      this.sortedArticles = this.articlesList.sort(
        (a, b) =>
          new Date(b.attributes.publishedAt) -
          new Date(a.attributes.publishedAt)
      );
    },
    handleResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.windowWidth = window.innerWidth;
        this.calculateVisibleTags();
      }, 150); // Délai de 150ms, ajustez si nécessaire
    },

    calculateVisibleTags() {
      if (this.windowWidth > 1200) {
        this.visibleTagsCount = 8;
      } else if (this.windowWidth > 800) {
        this.visibleTagsCount = 7;
      } else if (this.windowWidth > 500) {
        this.visibleTagsCount = 6;
      } else if (this.windowWidth > 414) {
        this.visibleTagsCount = 4;
      } else {
        this.visibleTagsCount = 3;
      }
    },

    showMoreTags() {
      this.visibleTagsCount = this.categoriesList.length;
    },
  },
  async created() {
    this.sortArticles();
  },
  async mounted() {
    await this.fetchLatestArticle(this?.$i18n?.locale);
    await this.fetchArticles(this?.$i18n?.locale);
    await this.fetchTopArticles(this?.$i18n?.locale);
    await this.fetchCategories(this?.$i18n?.locale);
    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // Initial call
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    clearTimeout(this.resizeTimeout);
  },
  watch: {
    "$i18n.locale"() {
      this.fetchLatestArticle(this?.$i18n?.locale);
      this.fetchCategories(this?.$i18n?.locale);
      this.fetchArticles(this?.$i18n?.locale);
      this.fetchTopArticles(this?.$i18n?.locale);
    },
  },
};
</script>

<style scoped>
.box-posts {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 70px;
  min-height: 100vh;
}

.left,
.right {
  flex: 1;
}

.middle {
  width: 700px;
  max-width: 100%;
  padding: 0 20px;
  border-left: 1px dotted #ccc;
  border-right: 1px dotted #ccc;
}

.last-posts {
  margin-top: 20px;
}

.last-item {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 20px 0;
  border-top: 1px dotted #ccc;
}

.last-item .info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.last-item .info .title {
  font-weight: 800;
}

.last-item .info .desc {
  font-style: italic;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 14px;
}

.last-item .category {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--button);
}

.published {
  font-size: 12px;
}

.last-item hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px dotted #ccc;
  padding: 0;
}

.last-item img {
  width: 100%;
  max-width: 330px;
  object-fit: cover;
  object-position: top;
  border-radius: 3px;
  /* clip-path: polygon(2% 0, 100% 0%, 98% 99%, 0% 100%); */
}

/* debut tags */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 8px;
}

.tag-button {
  padding: 5px 10px;
  border-radius: 30px;
  background-color: var(--card);
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
}

.tag-button:hover {
  background-color: var(--button);
  color: #fff;
}

.show-more {
  background-color: red;
  color: white;
}

@media (max-width: 600px) {
  .tags-container {
    justify-content: center;
  }
}
/* fin new tags */

.list-best-posts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px 10px 10px;
}

ol {
  display: flex;
  flex-direction: column;
  list-style: none;
  counter-reset: item;
}
li {
  display: flex;
  counter-increment: item;
  margin-bottom: 5px;
  padding: 10px 0;
  border-bottom: 1px dotted #ccc;
}
/* li:before {
  margin-right: 10px;
  content: counter(item);
  border-radius: 100%;
  color: var(--link);
  text-align: center;
  display: inline-block;
  font-size: 80px;
  font-weight: 800;
  line-height: 3.2rem;
} */

li a {
  display: flex;
  gap: 10px;
}

.list-best-posts .item-post .infos {
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 2;
}
.list-best-posts .item-post .infos .cat-best-post {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--button);
}
.list-best-posts .item-post .infos .title-best-post {
  font-weight: 800;
}

@media (max-width: 1200px) {
  .left,
  .right {
    flex: 100%;
  }

  .left {
    order: 2;
  }

  .middle {
    order: 1;
    flex: 100%;
    padding: 0;
    border: none;
  }

  .right {
    order: 3;
  }
}

@media (max-width: 760px) {
  .last-item img {
    width: 100%;
    max-width: 100px;
    border-radius: 3px;
  }

  .list-best-posts {
    padding: 0;
  }
}
</style>
