<template>
  <div class="mail">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      class="fig-share-tools__svg"
      aria-hidden="true"
      focusable="false"
    >
      <path
        d="M18.75 4.5H5.25A2.25 2.25 0 0 0 3 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25h13.5A2.25 2.25 0 0 0 21 17.25V6.75a2.25 2.25 0 0 0-2.25-2.25zm-.23 3.6l-6 5.25a1.39 1.39 0 0 1-.52.15 1.39 1.39 0 0 1-.53-.15l-6-5.25a.74.74 0 1 1 .98-1.13l5.47 4.8 5.48-4.8a.73.73 0 0 1 1.05.08.68.68 0 0 1 .07 1.05z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "mail-icon",
};
</script>

<style scoped>
.mail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.mail svg {
  width: 20px;
  height: 20px;
}

.mail svg path {
  fill: var(--link);
}
</style>
