<template>
  <div v-if="isOpen" class="popup-overlay" @click="closePopup">
    <button @click="closePopup" class="popup-close-button">
      <el-icon><Close /></el-icon>
    </button>
    <div class="popup-container" @click.stop>
      <div class="popup-image-container">
        <img
          :src="`https://cp.doonun.com${image}`"
          :alt="title"
          class="popup-image"
        />
      </div>

      <div class="popup-content">
        <div class="popup-header">
          <div class="top">
            <div class="movie-info">
              <span class="tag">HD</span>
              <span class="rating">
                <i class="fa-solid fa-star"></i> {{ note }}.0
              </span>
            </div>
            <div class="popup-languages">
              <span v-if="lang"
                ><i class="fa-solid fa-volume-low"></i> {{ lang }}</span
              >
              <span v-if="cc"
                ><i class="fa-solid fa-closed-captioning"></i> {{ cc }}</span
              >
            </div>
          </div>
          <h2 class="popup-title">{{ title }}</h2>
          <div class="popup-categories">
            <span
              class="popup-category"
              v-for="category in categories"
              :key="category.id"
            >
              {{ category?.attributes?.titre }}
            </span>
          </div>
        </div>
        <p
          id="markdown"
          class="markdown-body"
          v-html="$md.render(String(description))"
        ></p>
      </div>
    </div>
    <div class="btn-watch" v-if="isSubscribed">
      <router-link class="popup-watch-button" :to="`/doovie/${lien}/1`">
        <i class="fa-solid fa-play"></i> {{ $t("lookMovie") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PopupMovie",
  props: {
    isOpen: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    image: {
      type: String,
    },
    lien: {
      type: String,
    },
    note: Number,
    lang: {
      type: String,
    },
    cc: {
      type: String,
    },
    categories: Array,
  },
  computed: {
    ...mapGetters("subscribe", ["isSubscribed"]),
  },
  methods: {
    closePopup() {
      this.$emit("update:isOpen", false);
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  padding: 1rem 1rem 0 1rem;
  z-index: 999;
  color: var(--color);
}

.popup-container {
  position: relative;
  background-color: var(--card);
  border-radius: 0.5rem;
  min-height: 95vh;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  max-width: 800px;
  width: 100%;
  z-index: 1000;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  scrollbar-width: none;
}

.popup-image-container {
  position: relative;
}

.popup-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.popup-close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 1001;
}
.popup-close-button i {
  color: #fff;
  font-size: 18px;
}

.popup-content {
  position: relative;
  padding: 1.5rem 2.5rem;
}

.popup-header {
  margin-bottom: 1rem;
}

.popup-header .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.movie-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
}

.movie-info .tag {
  background: var(--color);
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  color: var(--colorBtn);
}

.movie-info .rating {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.movie-info .rating i {
  color: #ffd900;
}

.popup-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.popup-languages {
  display: flex;
  gap: 20px;
}

.markdown-body {
  color: var(--color) !important;
  margin-bottom: 100px;
}

.popup-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  margin: 20px 0 50px 0;
}

.popup-category {
  background-color: var(--card);
  border: 1px solid var(--color);
  color: var(--color);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.btn-watch {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
  z-index: 1001;
}

.popup-watch-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #dc2626;
  color: white;
  padding: 0.8rem 1.8rem;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
}

.popup-watch-button:hover {
  background-color: #b91c1c;
}

@media (max-width: 700px) {
  .popup-content {
    padding: 1.5rem;
  }
  .popup-image {
    height: 200px;
  }
}
</style>
