<template>
  <div class="right">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M20 12L4 12"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 17C15 17 20 13.3176 20 12C20 10.6824 15 7 15 7"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "right-icon",
};
</script>

<style scoped>
.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right svg {
  width: 25px;
  height: 25px;
}

.right svg path {
  stroke: var(--link);
}

.btn-fixed .right svg path {
  stroke: var(--colorBtn);
}

.btn-fixed button:hover .right svg path {
  stroke: #fff;
}

.nxt .right svg path {
  stroke: var(--colorBtn);
}

.nxt:hover .right svg path {
  stroke: #fff;
}

.next-k:hover svg path {
  stroke: var(--colorBtn);
}
</style>
