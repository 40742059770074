<template>
  <!-- YouTube Video Handling -->
  <iframe
    v-if="isVideo && isYouTubeLink"
    :src="getYouTubeEmbedUrl(link)"
    frameborder="0"
    allowfullscreen
    class="video-embed"
    loading="lazy"
  ></iframe>

  <!-- Twitter Video Handling -->
  <div
    v-else-if="isVideo && isTwitterVideo"
    class="video-embed twitter-embed"
    ref="twitterContainer"
  >
    <blockquote class="twitter-tweet" data-media-max-width="560">
      <a :href="processedLink"></a>
    </blockquote>
  </div>
</template>

<script>
export default {
  name: "MediaRenderer",
  props: {
    link: {
      type: String,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  computed: {
    isYouTubeLink() {
      return /youtube\.com|youtu\.be/.test(this.domain);
    },
    isTwitterVideo() {
      return /twitter\.com|x\.com/.test(this.domain);
    },
    processedLink() {
      if (this.isTwitterVideo) {
        return this.link.replace(/x\.com/, "twitter.com");
      }
      return this.link;
    },
  },
  methods: {
    getYouTubeEmbedUrl(url) {
      try {
        const urlObj = new URL(url);
        let videoId = urlObj.searchParams.get("v");
        if (!videoId && urlObj.hostname === "youtu.be") {
          videoId = urlObj.pathname.substring(1);
        }
        return `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&modestbranding=1`;
      } catch (e) {
        console.error("Invalid YouTube URL:", url);
        return url;
      }
    },
    loadScript(src, id) {
      return new Promise((resolve, reject) => {
        if (document.getElementById(id)) {
          resolve();
          return;
        }
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error(`Failed to load script ${src}`));
        document.body.appendChild(script);
      });
    },
    loadTwitterScript() {
      return this.loadScript(
        "https://platform.twitter.com/widgets.js",
        "twitter-wjs"
      );
    },
    renderTwitterEmbed() {
      this.$nextTick(() => {
        if (
          window.twttr &&
          window.twttr.widgets &&
          this.$refs.twitterContainer
        ) {
          window.twttr.widgets.load(this.$refs.twitterContainer);
        }
      });
    },
  },
  async mounted() {
    if (this.isVideo && this.isTwitterVideo) {
      try {
        await this.loadTwitterScript();
        this.renderTwitterEmbed();
      } catch (error) {
        console.error(error);
      }
    }
  },
  watch: {
    link(newLink, oldLink) {
      if (this.isVideo && this.isTwitterVideo && newLink !== oldLink) {
        this.renderTwitterEmbed();
      }
    },
  },
};
</script>

<style scoped>
.render {
  width: 100%;
  flex-grow: 1;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  width: 100%;
  height: 100%;
  /* max-width: 440px; */
  max-height: 440px;
  cursor: pointer;
}

.video-embed {
  width: 100%;
  min-height: 250px;
  margin-top: 10px;
}
</style>
