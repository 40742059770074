<template>
  <div>
    <h2 id="scroll-target">{{ $t("surahsCompTitle") }}</h2>
    <div class="box-sourates-fav">
      <CardCoran
        v-for="sourate in userSourates"
        :key="sourate?.id"
        :Titre="sourate?.attributes?.Titre"
        :Numero="sourate?.attributes?.Numero"
        :Arabe="sourate?.attributes?.Arabe"
        :Phonetique="sourate?.attributes?.Phonetique"
        :Revelation="sourate?.attributes?.Revelation"
        :Nombre="sourate?.attributes?.Nombre"
        :like="sourate?.attributes?.like_sourate?.data?.attributes?.likes"
        :fav="isLiked(sourate?.attributes.Numero)"
        :vues="sourate?.attributes?.vues"
        :taux="
          sourate?.attributes?.versets_checks?.data[0]?.attributes?.taux || 0
        "
        @likeChanged="onLikeChanged"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardCoran from "../../components/coran/CardCoran.vue";

export default {
  name: "sourates-profil",
  components: {
    CardCoran,
  },
  computed: {
    ...mapGetters("sourate", ["userSourates"]),
    ...mapGetters("like", ["likes"]),
    ...mapGetters("login", ["isAuthenticated"]),
    likedSourates() {
      return this.isAuthenticated && Array.isArray(this.likes.data)
        ? this.likes.data.map((like) => like.attributes.id_sourate)
        : [];
    },
  },
  methods: {
    ...mapActions("sourate", ["souratesUser"]),
    ...mapActions("like", ["fetchLikes"]),
    isLiked(numero) {
      return this.likedSourates.includes(String(numero));
    },
    async onLikeChanged() {
      try {
        await this.souratesUser();
        await this.fetchLikes();
      } catch (error) {
        console.error("Error fetching user sourates:", error);
      }
    },
  },
  async mounted() {
    try {
      if (this.isAuthenticated) {
        await this.souratesUser();
        this.fetchLikes();
      }
    } catch (error) {
      console.error("Error fetching user sourates:", error);
    }
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.box-sourates-fav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(300px - 15px), 1fr));
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
}
</style>
