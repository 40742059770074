// src/utils/tagUtils.js

/**
 * Extrait les tags d'un contenu textuel.
 * Les tags sont des mots commençant par #.
 * Cette fonction ignore la casse et élimine les doublons.
 *
 * @param {string} content - Le contenu textuel à analyser.
 * @returns {Array<string>} - Un tableau de noms de tags en minuscules sans doublons.
 */
export function extractTags(content) {
  const tagRegex = /#(\w+)/g;
  const tags = [];
  let match;

  while ((match = tagRegex.exec(content)) !== null) {
    tags.push(match[1]);
  }

  // Retirer les doublons en ignorant la casse
  return [...new Set(tags.map((tag) => tag.toLowerCase()))];
}
