<!-- doovie-d.vue -->
<template>
  <Head>
    <!-- Titre de la page -->
    <title>
      Doovie - Plateforme Vidéo Islamique | Podcasts, Films, Documentaires et
      Plus
    </title>

    <!-- Description Meta -->
    <meta
      name="description"
      content="Doovie est votre plateforme vidéo islamique dédiée aux podcasts, films, documentaires et bien plus. Explorez une large gamme de contenus pour enrichir votre foi et votre connaissance."
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Canonical URL -->
    <link rel="canonical" href="https://www.doonun.com/doovie" />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.doonun.com/doovie" />
    <meta
      property="og:title"
      content="Doovie - Plateforme Vidéo Islamique | Podcasts, Films, Documentaires et Plus"
    />
    <meta
      property="og:description"
      content="Doovie est votre plateforme vidéo islamique dédiée aux podcasts, films, documentaires et bien plus. Explorez une large gamme de contenus pour enrichir votre foi et votre connaissance."
    />
    <!-- <meta property="og:image" content="https://cp.doonun.com/uploads/doovie_homepage_image.png" /> -->

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.doonun.com/doovie" />
    <meta
      name="twitter:title"
      content="Doovie - Plateforme Vidéo Islamique | Podcasts, Films, Documentaires et Plus"
    />
    <meta
      name="twitter:description"
      content="Doovie est votre plateforme vidéo islamique dédiée aux podcasts, films, documentaires et bien plus. Explorez une large gamme de contenus pour enrichir votre foi et votre connaissance."
    />
    <!-- <meta name="twitter:image" content="https://cp.doonun.com/uploads/doovie_homepage_image.png" /> -->
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>
  <div class="doovie">
    <HeroSection
      v-for="collection in latestCollections"
      :key="collection.id"
      :title="collection?.attributes?.titre"
      :description="collection?.attributes.desc"
      :lang="collection?.attributes?.voix"
      :cc="collection?.attributes?.cc"
      :note="collection?.attributes?.note"
      :categories="collection?.attributes?.doovie_categories?.data"
      :image="
        collection?.attributes.cover?.data?.attributes?.formats?.large?.url
      "
      :lien="collection.attributes.collection"
    />
    <div class="container">
      <div class="content">
        <h2>{{ $t("newsMovies") }}</h2>
        <div class="lists">
          <div class="list-new-movies">
            <div
              class="item"
              v-for="movie in latest4Collections.slice(1, 5)"
              :key="movie.id"
            >
              <MovieCard
                :id="movie?.id"
                :title="movie?.attributes.titre"
                :image="
                  movie?.attributes.cover?.data?.attributes?.formats?.medium
                    ?.url
                "
                :lien="movie?.attributes?.collection"
                :categories="movie?.attributes?.doovie_categories?.data"
                :description="movie?.attributes?.desc"
                :lang="movie?.attributes?.voix"
                :cc="movie?.attributes?.cc"
                :note="movie?.attributes?.note"
              />
            </div>
          </div>
          <Slider :cible="'list-new-movies'" />
        </div>
        <div class="lists resumes" v-if="resumeMovies.length">
          <h2>{{ $t("resumeMovies") }}</h2>
          <div class="list-resume-movies">
            <div class="item" v-for="movie in resumeMovies" :key="movie.lien">
              <MovieCard
                :id="movie?.id"
                :title="movie.title"
                :image="movie.image"
                :lien="movie.lien"
                :categories="movie.categories"
                :description="movie.description"
                :lang="movie.lang"
                :cc="movie.cc"
                :note="movie?.attributes?.note"
              />
            </div>
          </div>
          <Slider :cible="'list-resume-movies'" />
        </div>
        <CategoryFilters
          :categories="categories"
          :selected-category="selectedCategory"
          @category-change="handleCategoryChange"
        />

        <div class="movie-grid">
          <MovieCard
            v-for="movie in filteredCollections"
            :key="movie.id"
            :id="movie?.id"
            :title="movie?.attributes.titre"
            :image="
              movie?.attributes.cover?.data?.attributes?.formats?.medium?.url
            "
            :lien="movie?.attributes?.collection"
            :categories="movie?.attributes?.doovie_categories?.data"
            :description="movie?.attributes?.desc"
            :lang="movie?.attributes?.voix"
            :cc="movie?.attributes?.cc"
            :note="movie?.attributes?.note"
          />
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HeroSection from "../../components/doovie/Hero.vue";
import CategoryFilters from "../../components/doovie/Filter.vue";
import MovieCard from "../../components/doovie/Movie.vue";
import Slider from "../../components/global/Slider.vue";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "doovie-d",
  components: {
    HeroSection,
    CategoryFilters,
    MovieCard,
    Slider,
    Head,
    Tabs,
  },
  data() {
    return {
      selectedCategory: "Tous",
      resumeMovies: [],
    };
  },
  computed: {
    ...mapGetters("bunny", [
      "latestCollections",
      "latest4Collections",
      "collections",
      "categories",
    ]),
    filteredCollections() {
      if (this.selectedCategory === "Tous") {
        return this.collections;
      }
      return this.collections.filter((movie) => {
        return movie.attributes.doovie_categories.data.some(
          (cat) => cat.attributes.titre === this.selectedCategory
        );
      });
    },
  },
  methods: {
    ...mapActions("bunny", ["loadCollections", "getCategories"]),
    handleCategoryChange(category) {
      this.selectedCategory = category;
    },
    loadResumeMovies() {
      this.resumeMovies =
        JSON.parse(localStorage.getItem("resumeMovies")) || [];
    },
  },
  created() {
    this.loadCollections(this.$i18n.locale);
    this.getCategories(this.$i18n.locale);
    this.loadResumeMovies();
  },

  watch: {
    "$i18n.locale"() {
      this.loadCollections(this.$i18n.locale);
      this.getCategories(this.$i18n.locale);
    },
  },
};
</script>

<style scoped>
.doovie {
  min-height: 100vh;
  background-color: #000000;
  color: white;
  padding-bottom: 50px;
  margin-top: 60px;
}

.container {
  min-height: 100vh;
}

h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #fff;
}

.lists {
  position: relative;
}

.resumes {
  margin-top: 30px;
}

.list-new-movies,
.list-resume-movies {
  margin-top: 30px;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  display: flex;
  gap: 0.8rem;
  min-height: 200px;
  max-height: 370px;
}

.list-new-movies .item {
  min-width: 323px;
  flex: 1 1 25%;
}

.list-resume-movies .item {
  min-width: 323px;
  max-width: 380px;
  flex: 1 1 25%;
}

.movie-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

@media (max-width: 700px) {
  .content {
    margin-top: 40px;
  }

  .movie-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1rem;
    margin-top: 2rem;
  }
}
</style>
