// store/modules/faqs.js
import axios from "axios";

const state = {
  faqs: [],
  loading: false,
  error: null,
};

const getters = {
  // Retourne tous les témoignages
  allFaqs: (state) => state.faqs,

  // Indicateur de chargement
  isLoading: (state) => state.loading,

  // Retourne l'erreur actuelle
  getError: (state) => state.error,
};

const mutations = {
  // Définit les témoignages dans l'état
  SET_Faqs(state, faqs) {
    state.faqs = faqs;
  },

  // Met à jour l'indicateur de chargement
  SET_LOADING(state, status) {
    state.loading = status;
  },

  // Définit l'erreur dans l'état
  SET_ERROR(state, error) {
    state.error = error;
  },
};

const actions = {
  // Action pour récupérer les témoignages depuis l'API
  async fetchFaqs({ commit }, lang) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      const response = await axios.get("/faqs", {
        params: {
          locale: lang,
          fields: ["titre", "reponse"],
          sort: ["publishedAt:desc"],
        },
      });
      // Supposons que l'API renvoie un objet avec une propriété 'data' contenant un tableau de témoignages
      commit("SET_Faqs", response.data.data);
    } catch (error) {
      commit(
        "SET_ERROR",
        error.message || "Erreur lors de la récupération des témoignages."
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
