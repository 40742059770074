<template>
  <div class="quote-bottom">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="409.56"
      height="297.765"
      viewBox="0 0 409.56 297.765"
    >
      <g
        id="_11398399"
        data-name="11398399"
        transform="translate(-51.3 405.065)"
      >
        <path
          id="Tracé_32"
          data-name="Tracé 32"
          d="M76-335.5V-266h54v3c0,9.4-8,36.1-15.1,50.1-12.9,25.8-33.6,47.3-60.1,62.4l-3.5,2,8.4,20.6,8.4,20.6,7.2-1.3c76-13.2,140-71.5,160.2-145.9,6.8-24.8,6.7-24.3,7.2-90.3l.4-60.2H76Z"
          fill="#0c0020"
        />
        <path
          id="Tracé_33"
          data-name="Tracé 33"
          d="M293.53-405.065S293-372.7,293-334.8V-266h27.5c23.5,0,27.5.2,27.5,1.5,0,4.1-4.3,22.6-7.4,31.8-11.3,33.9-33.1,61.2-62.7,78.7-4.9,3-8.8,5.8-8.6,6.4.3.6,4,9.8,8.3,20.3,9,22.3,7.1,20.8,22.6,17.3A207.516,207.516,0,0,0,385-150.7c9.8-7.9,27-25.8,34.3-35.5A207.826,207.826,0,0,0,450.4-246c9.4-28.6,10.8-43.4,10.4-109.5l-.3-49-83.1-.3C331.7-404.9,293.53-405.065,293.53-405.065Z"
          fill="#0c0020"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "quote-bottom-icon",
};
</script>

<style scoped>
/* .quote-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
} */

.quote-bottom svg {
  width: 100%;
  height: 100%;
}

.quote-bottom svg path {
  fill: var(--body);
}
</style>
