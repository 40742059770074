<template>
  <div class="end">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M20 18L20 6"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M16 11.9995L4 11.9995"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8C12 8 16 10.946 16 12C16 13.0541 12 16 12 16"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "end-icon",
};
</script>

<style scoped>
.end {
  display: flex;
  justify-content: center;
  align-items: center;
}

.end svg {
  width: 20px;
  height: 20px;
}

.end svg path {
  stroke: var(--link);
}
</style>
