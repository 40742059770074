<template>
  <div class="rightarrow">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="#000000"
      fill="none"
    >
      <path
        d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "rightarrow-icon",
};
</script>

<style scoped>
.rightarrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightarrow svg {
  width: 16px;
  height: 16px;
}

.rightarrow svg path {
  stroke: var(--color);
}

.box-arrows .rightarrow svg {
  width: 20px;
  height: 20px;
}

.box-arrows .rightarrow svg path {
  stroke: #fff;
}
</style>
