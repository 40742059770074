<template>
  <div class="rightLeaf">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M13.0111 6.5C13.0111 6.5 11.179 6.4339 10.3382 4.9339C9.4975 3.4339 10.4889 2 10.4889 2C10.4889 2 12.321 2.0661 13.1618 3.5661C14.0025 5.0661 13.0111 6.5 13.0111 6.5Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M12.8171 6.5C14.2805 8.59896 16.1341 13.475 11.8415 16.1875C6.47567 19.5781 5.9878 20.5469 5.5 22"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M7.9679 18.5C7.9679 18.5 11.5403 17.1364 9.49877 13.5C9.49877 13.5 5.41625 14.8636 7.9679 18.5Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 19.0001C8 19.0001 10.3674 15.9252 13.5 19.105C13.5 19.105 10.9923 22.6818 8 19.0001Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5094 12.9391C14.5094 12.9391 14.1124 8.85377 18.4701 9.00405C18.4701 9.00405 19.1061 13.5764 14.5094 12.9391Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.4906 12.5001C14.4906 12.5001 14.8876 8.41481 10.5299 8.56508C10.5299 8.56508 9.89393 13.1374 14.4906 12.5001Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "rightLeaf-icon",
};
</script>

<style scoped>
.rightLeaf {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightLeaf svg {
  width: 25px;
  height: 25px;
}

.rightLeaf svg path {
  stroke: var(--link);
}
</style>
