<template>
  <div class="facebook">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      class="fig-share-tools__svg"
      aria-hidden="true"
      focusable="false"
    >
      <path
        d="M9.3 21v-8.2H6V9.5h3.3v-2c0-3 1.9-4.5 4.6-4.5l2.7.1v3.2h-1.9C13.2 6.3 13 7 13 8v1.5h4.3l-1.7 3.3H13V21H9.3z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "facebook-icon",
};
</script>

<style scoped>
.facebook {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.facebook svg {
  width: 20px;
  height: 20px;
}

.facebook svg path {
  fill: var(--link);
}
</style>
