<template>
  <div>
    <div class="avatar-container">
      <h2 id="scroll-target">Avatar</h2>
      <ul class="filtres">
        <li
          @click="setGenre('Tous')"
          :class="{ active: selectedGenre === 'Tous' }"
        >
          {{ $t("gAll") }}
        </li>
        <li
          @click="setGenre('Femmes')"
          :class="{ active: selectedGenre === 'Femmes' }"
        >
          {{ $t("gF") }}
        </li>
        <li
          @click="setGenre('Hommes')"
          :class="{ active: selectedGenre === 'Hommes' }"
        >
          {{ $t("gH") }}
        </li>
        <li
          @click="setGenre('Autres')"
          :class="{ active: selectedGenre === 'Autres' }"
        >
          {{ $t("gA") }}
        </li>
      </ul>
      <div class="lists-avatars">
        <div
          class="box-avatar"
          v-for="(image, index) in filteredImages"
          :key="image.id"
        >
          <img
            :src="`https://cp.doonun.com${image.url}`"
            :alt="`Avatar ${image.id}`"
            @click="selectImage(index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  name: "user-avatar",
  data() {
    return {
      userAvatar: null,
      avatarId: null,
      images: [],
      selectedGenre: "Tous", // Ajout de la propriété selectedGenre
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("avatar", {
      avatarUrl: (state) => state.avatar,
    }),
    selectedImage() {
      return this.userAvatar;
    },
    // Propriété calculée pour filtrer les images en fonction du genre
    filteredImages() {
      if (this.selectedGenre === "Tous") {
        return this.images;
      } else {
        return this.images.filter(
          (image) => image.genre === this.selectedGenre
        );
      }
    },
  },
  methods: {
    ...mapActions("avatar", ["fetchAvatar"]),
    ...mapActions("user", ["fetchUser"]),
    // Méthode pour définir le genre sélectionné
    setGenre(genre) {
      this.selectedGenre = genre;
    },
    async fetchUser() {
      try {
        if (this.user) {
          const avatarId = this.user.avatar?.id;
          if (avatarId) {
            await this.fetchAvatar(avatarId);
          }
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des informations de l'utilisateur:",
          error
        );
      }
    },
    async fetchAvatars() {
      try {
        const response = await axios.get("/avatars", {
          params: {
            populate: ["users_permissions_users", "Avatar"],
          },
        });

        if (response.data && response.data.data.length > 0) {
          this.images = response.data.data.map((item) => {
            return {
              url: item.attributes.Avatar.data.attributes.formats.small.url,
              id: item.id,
              genre: item.attributes.Genres, // Ajout du champ genre
            };
          });

          const userAvatarData = response.data.data.find((item) =>
            item.attributes.users_permissions_users.data.some(
              (u) => u.id === this.user.id
            )
          );

          const defaultAvatarUrl = "/uploads/plant_6a63b26a77.png";
          this.userAvatar = userAvatarData
            ? userAvatarData.attributes.Avatar.data.attributes.formats.small.url
            : defaultAvatarUrl;
        } else {
          console.error("Aucun avatar trouvé");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des avatars:", error);
      }
    },
    async selectImage(index) {
      const selectedAvatar = this.filteredImages[index]; // Utiliser filteredImages pour l'index
      this.userAvatar = selectedAvatar.url;
      this.avatarId = selectedAvatar.id;
      await this.updateUserAvatarRelation(this.avatarId);
    },

    async updateUserAvatarRelation(avatarId) {
      try {
        await axios.put(`/users/${this.user.id}?populate=*`, {
          avatar: avatarId,
        });

        // console.log("avatarId : ", avatarId);

        // Vérifiez que l'objet `user` est défini
        if (this.user) {
          // Initialisez `avatar` si nécessaire
          if (!this.user.avatar) {
            this.user.avatar = {};
          }

          // Mettez à jour l'id de l'avatar
          this.fetchAvatar(avatarId);
          // console.log("Relation avatar-utilisateur mise à jour avec succès");
        } else {
          console.error("L'objet utilisateur est null ou non défini");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour de la relation avatar-utilisateur:",
          error
        );
      }
    },
  },
  async mounted() {
    await this.fetchUser();
    await this.fetchAvatars();
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 10px 0;
}

.avatar-container {
  display: block;
  max-width: 600px;
  min-height: 100vh;
  margin: 0 auto;
}

.filtres {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;
}

.filtres li {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;
}

.lists-avatars {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 10px;
}

.lists-avatars img {
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 90px;
  max-height: 90px;
  border-radius: 10px;
  flex-grow: 1;
  outline: 0.5px solid var(--body);
  transition: 0.2s ease;
}

.lists-avatars img:hover {
  outline: 0.5px solid var(--button);
  transform: scale(1.05);
}
</style>
