<template>
  <Head>
    <title>Fiiyd - Réseau social musulman</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container">
    <div class="boites">
      <div class="content-with-sidebars">
        <div class="left">
          <div class="fiiyd-icon">
            <div class="icons">
              <ul>
                <li>
                  <router-link to="/fiiyd">
                    <HomeFii />
                    <span>{{ $t("feed") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/notifications">
                    <div class="bell">
                      <span v-if="fiiydsCount >= 1">{{ fiiydsCount }}</span>
                      <BellFii />
                    </div>
                    <span>{{ $t("notif") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/myposts">
                    <PostsFii />
                    <span>{{ $t("myposts") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="middle">
          <div class="size-box-v">
            <div class="fil">
              <div class="loading" v-if="isLoading">
                <Loading />
              </div>
              <div v-else>
                <div class="lists-comments">
                  <div class="box-comments" v-if="comment">
                    <CardFiidCommentURL
                      :key="comment?.IdComment"
                      :avatar="comment?.avatar"
                      :username="comment?.username"
                      :time="comment?.time"
                      :content="comment?.Content"
                      :images="comment?.images"
                      :liens="comment?.liens"
                      :vues="comment?.vues"
                      :likes="comment?.like"
                      :sensible="comment?.content_sensitive"
                      :enable_comment="comment?.enable_comment"
                      :enable_notif="comment?.enable_notif"
                      :favoris="getFilteredFavForComment(comment)"
                      :url="comment?.url"
                      :comments="comment?.answers.length"
                      :IdComment="comment?.IdComment"
                      :idUsername="comment?.idUsername"
                      @delete-comment="deleteComment"
                      @addFavorite="addFavorite"
                    />
                  </div>
                </div>
                <div class="comment-comment">
                  <InputAnswers
                    :commentId="comment?.IdComment"
                    :notif="comment?.enable_notif"
                    v-if="comment?.enable_comment"
                    :commentUser="comment?.idUsername"
                    @comment-added="fetchComment"
                  />
                  <div
                    v-else
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 5px;
                    "
                  >
                    <el-icon><Warning /></el-icon>
                    <p style="text-align: center">
                      <span style="font-weight: 500">{{
                        comment.username
                      }}</span>
                      a fermer les commentaires
                    </p>
                  </div>
                </div>
                <div class="box-comments">
                  <CardFiiydComment
                    v-for="reply in comment?.answers"
                    :key="reply?.IdComment"
                    :username="reply?.username"
                    :time="reply?.time"
                    :avatar="reply?.avatar"
                    :content="reply?.Content"
                    :images="reply?.images"
                    :liens="reply?.liens"
                    :vues="reply?.vues"
                    :likes="reply?.like"
                    :favoris="getFilteredFavForReply(reply)"
                    :sensible="reply?.content_sensitive"
                    :enable_comment="reply?.enable_comment"
                    :enable_notif="reply?.enable_notif"
                    :comments="reply?.answers"
                    :url="reply?.url"
                    :IdComment="reply?.IdComment"
                    :idUsername="reply?.idUsername"
                    @addFavorite="addFavorite"
                    @comment-deleted="handleCommentDeleted"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <Trends />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import InputAnswers from "../../components/fiiyd/InputAnswers.vue";
import HomeFii from "../../components/icons/fiiyd/Home.vue";
import BellFii from "../../components/icons/fiiyd/Bell.vue";
import PostsFii from "../../components/icons/fiiyd/Posts.vue";
import CardFiidCommentURL from "../../components/fiiyd/CardFiidCommentURL.vue";
import CardFiiydComment from "../../components/fiiyd/CardFiiComment.vue";
import Trends from "../../components/fiiyd/Trends.vue";
import { Head } from "@vueuse/head";

export default {
  name: "fiiyd-Comment",
  components: {
    Loading,
    InputAnswers,
    HomeFii,
    BellFii,
    Trends,
    PostsFii,
    CardFiidCommentURL,
    CardFiiydComment,
    Head,
  },
  data() {
    return {
      isLoading: true,
      slug: this.$route.params.slug,
    };
  },
  computed: {
    ...mapState({
      comment: (state) => state.fiiyd.fetchedComment,
    }),
    ...mapState("user", ["user"]),
    fiiydsCount() {
      return this.$store.state.fiiyd.fiiyds.length; // Accès direct à la longueur
    },
  },
  methods: {
    ...mapActions("fiiyd", ["fetchCommentByUrl"]),
    ...mapActions("user", ["fetchUser"]),

    // Méthode pour filtrer fav des commentaires principaux
    getFilteredFavForComment(comment) {
      if (comment && Array.isArray(comment.fav)) {
        const userFav = comment.fav.find(
          (favItem) => favItem.id === this.user.id
        );
        // console.log(
        //   `Comment ID: ${comment.IdComment}, User Fav: ${
        //     userFav ? userFav.id : null
        //   }`
        // );
        return userFav ? userFav.id : null;
      }
      // console.log(
      //   `Comment ID: ${comment.IdComment}, Fav is not an array or undefined`
      // );
      return null;
    },

    // Méthode pour filtrer fav des réponses aux commentaires
    getFilteredFavForReply(reply) {
      if (reply && Array.isArray(reply.fav)) {
        const userFav = reply.fav.find(
          (favItem) => favItem.id === this.user.id
        );
        // console.log(
        //   `Reply ID: ${reply.IdComment}, User Fav: ${
        //     userFav ? userFav.id : null
        //   }`
        // );
        return userFav ? userFav.id : null;
      }
      // console.log(
      //   `Reply ID: ${reply.IdComment}, Fav is not an array or undefined`
      // );
      return null;
    },

    async fetchComment() {
      this.isLoading = true; // Indiquer que le chargement a commencé
      await this.fetchCommentByUrl(this.slug);
      this.isLoading = false; // Le chargement est terminé
      // this.logComments();
    },

    logComments() {
      if (this.comment && Array.isArray(this.comment.answers)) {
        console.log("Answers:", this.comment.answers, "slug : ", this.slug);
        this.comment.answers.forEach((reply) => {
          console.log("Reply Time:", reply.time);
        });
      } else {
        console.log("No answers or comment is undefined");
      }
    },

    async addFavorite() {
      try {
        await this.fetchComment();
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    },

    handleCommentDeleted(deletedId) {
      this.comment.answers = this.comment.answers.filter(
        (reply) => reply.IdComment !== deletedId
      );
    },
  },
  async mounted() {
    try {
      await this.fetchComment();
    } catch (error) {
      console.error("Erreur lors de la récupération du commentaire :", error);
    }
  },
  watch: {
    "$route.params.slug": {
      handler(newSlug) {
        this.slug = newSlug; // Mettre à jour la variable slug
        this.fetchComment(); // Relancer la récupération des commentaires
      },
      immediate: true, // Exécuter immédiatement lors du premier rendu
    },
  },
};
</script>

<style scoped>
.boites {
  width: 100%;
  display: block;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  min-height: 100vh;
}

.content-with-sidebars {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.content-with-sidebars .left {
  width: 20%;
}

.content-with-sidebars .middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(60% - 40px);
  border-right: 1px solid var(--card);
  border-left: 1px solid var(--card);
}

.content-with-sidebars .right {
  width: 20%;
}

.container {
  padding-bottom: 30px;
  .container {
    min-height: 100vh;
  }
}

.icons ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icons ul li {
  padding-bottom: 20px;
  padding-right: 10px;
}

.icons ul li a {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icons ul li a span {
  font-size: 20px;
  font-weight: 500;
}

.size-box-v {
  display: flex;
  justify-content: center;
  max-width: 550px;
  width: 100%;
}

.size-box-v .fil {
  width: 100%;
}

.comment-comment {
  padding: 10px 20px;
  border-bottom: 1px solid var(--card);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bell {
  position: relative;
  z-index: 1;
}

.bell span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  padding: 10px;
  background: var(--button);
  color: var(--colorBtn);
  border-radius: 50%;
  z-index: 2;
  margin-right: -15px;
  margin-top: -14px;
  font-size: 10px !important;
  font-weight: 700;
}

@media (max-width: 998px) {
  .boites {
    margin-top: 100px;
  }

  .content-with-sidebars .left {
    width: calc(10% - 20px);
  }

  .content-with-sidebars .left span {
    display: none;
  }

  .content-with-sidebars .middle {
    width: calc(75% - 20px);
  }

  .content-with-sidebars .right {
    width: calc(15% - 20px);
    display: none;
  }
}

@media (max-width: 800px) {
  .content-with-sidebars .left {
    width: 100%;
    display: none;
  }

  .content-with-sidebars .middle {
    width: 100%;
    border: none;
  }

  .content-with-sidebars .right {
    width: 100%;
    display: none;
  }

  .size-box-v .fil {
    padding: 0;
  }

  .comment-input {
    padding: 0;
  }

  .comment-comment {
    padding: 10px 0;
  }
}
</style>
