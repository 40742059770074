<template>
  <div class="plus">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M12 4V20"
        stroke="currentColor"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 12H20"
        stroke="currentColor"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "plus-icon",
};
</script>

<style scoped>
.plus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus svg {
  width: 15px;
  height: 15px;
}

.plus svg path {
  stroke: #fff;
}
</style>
