<template>
  <div class="box-verset" :id="numero" ref="cardContainer">
    <div class="top">
      <p class="num">{{ numero }}</p>
      <div class="tooltips">
        <div class="memorize" @click="drawer = true">
          {{ $t("memo") }}
        </div>
        <div class="icon" @click="openSharePopup">
          <Share />
        </div>
        <div class="icon">
          <router-link :to="`/video/${this.$route.params.id}/${numero}`">
            <VideoQuran />
          </router-link>
        </div>
        <div class="icon" v-if="isAuthenticated" @click="toggleFavoris">
          <MarkCheck v-if="isFavoris" />
          <Mark v-else />
        </div>
      </div>
    </div>
    <p class="arabe" v-if="options.arabic && !options.tajweed">
      <span
        class="word-arabic"
        v-for="(word, index) in arabe.split(' ')"
        :key="index"
        @click="playWordAudio(index)"
      >
        {{ word }}&nbsp;
      </span>
    </p>
    <p class="tajweed" v-if="options.tajweed" v-html="tajweed"></p>
    <p class="phonetic" v-if="options.phonetic">
      {{ phonetique }}
    </p>
    <p class="trad" v-if="options.lang">
      {{ fr }}
    </p>
    <span v-if="tafsir.length > 0">
      <p class="tafsir" v-if="options.tafsir">
        <span>Tafsir</span>
        {{ tafsir }}
      </p>
      <p class="tafsir" v-if="showTafsir && !options.tafsir">
        <span>Tafsir</span>
        {{ tafsir }}
      </p>
    </span>
    <div class="bottom">
      <div class="left">
        <div class="ar" @click="$emit('playAudioSolo', numero, 'ar')">
          <Play v-if="!playAr" />
          <Pause v-else />
          <img src="https://cp.doonun.com/uploads/ar_d5a49e3344.png" alt="" />
        </div>
        <div class="fr" @click="$emit('playAudioSolo', numero, $t('lang'))">
          <Play v-if="!playFr" />
          <Pause v-else />
          <img :src="$t('flag')" alt="" />
        </div>
        <div
          v-if="tafsir.length > 0"
          class="display-tafsir"
          @click="toggleTafsir"
        >
          <span>Tafsir</span>
        </div>
        <div class="kathir">
          <router-link :to="`/ibn-kathir/${this.$route.params.id}/${numero}`">{{
            $t("seekathir")
          }}</router-link>
        </div>
      </div>
      <div class="right">
        <label class="box-check" v-if="isAuthenticated">
          <input
            type="checkbox"
            v-model="isChecked"
            :disabled="isCheckboxDisabled(numero)"
            @change="handleCheckChange"
          />
          <svg viewBox="0 0 64 64" height="18px" width="18px">
            <path
              d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
              pathLength="575.0541381835938"
              class="path"
            ></path>
          </svg>
        </label>
      </div>
    </div>
  </div>
  <el-drawer
    :direction="direction"
    v-model="drawer"
    :title="$t('memopuptitle')"
    class="popupmemo"
    :show-close="true"
  >
    <div class="box-container-memo">
      <div class="change">
        <el-switch
          v-model="text"
          :active-text="$t('memophonetique')"
          :inactive-text="$t('memoarabic')"
        />
      </div>
      <div class="step-1" v-if="currentStep == 1">
        <h2>{{ $t("step1info") }}</h2>
        <div class="validate">
          <Wrong v-if="wrong == true" />
          <Success v-if="this.validate == true" />
        </div>
        <div class="input-repetition">
          <input
            type="number"
            v-model="repetitions"
            min="0"
            max="100"
            @input="limitRepetitions"
          />
          <button @click="toggleAudio">
            <component :is="isAudioPlaying ? 'Pause' : 'Ear'" />
            {{ isAudioPlaying ? $t("pausememo") : $t("listen") }}
          </button>
        </div>
        <div class="texts">
          <p v-if="!text" class="arabic">{{ arabe }}</p>
          <p v-else class="phonetic">{{ phonetique }}</p>
        </div>
      </div>
      <div class="step-2" v-if="currentStep == 2">
        <h2>{{ $t("listenword") }}</h2>
        <div class="validate">
          <Wrong v-if="wrong == true" />
          <Success v-if="this.validate == true" />
        </div>
        <div class="texts">
          <p class="arabic as2 cc-s" v-if="!text">
            <span
              class="w"
              v-for="(word, index) in arabe.split(' ')"
              :key="index"
              @click="playWordAudio(index), listenWord()"
            >
              {{ word }}&nbsp;
            </span>
          </p>

          <p class="phonetic ps2" v-else>
            <span
              class="w ph"
              v-for="(word, index) in arabe.split(' ')"
              :key="index"
              @click="playWordAudio(index), listenWord()"
            >
              {{ transliterateWord(word) }}&nbsp;
            </span>
          </p>
        </div>
      </div>
      <div class="step-3" v-if="currentStep == 3">
        <h2>{{ $t("missingword") }}</h2>
        <div class="validate">
          <Wrong v-if="wrong == true" />
          <Success v-if="this.validate == true" />
        </div>
        <div class="texts">
          <p class="arabic as2 st-c" v-if="!text">
            <span
              v-for="(word, index) in arabe.split(' ')"
              :key="index"
              :class="assignRandomClass(index)"
            >
              <span>{{ word }}&nbsp;</span>
            </span>
          </p>
          <p class="phonetic ps2 st-c" v-else>
            <span
              v-for="(word, index) in phonetique.split(' ')"
              :key="index"
              :class="assignRandomClass(index)"
            >
              <span>{{ word }}&nbsp;</span>
            </span>
          </p>
          <div class="c-btn">
            <button class="play-btn btn-p3" @click="changeClassOnClick">
              <openEye />
              {{ $t("unvealwords") }}
            </button>
          </div>
          <p class="decount">{{ countdown }}</p>
        </div>
      </div>
      <div class="step-4" v-if="currentStep == 4">
        <h2>{{ $t("movewords") }}</h2>
        <div class="validate">
          <Wrong v-if="wrong == true" />
          <Success v-if="this.validate == true" />
        </div>
        <div class="texts">
          <div class="result">
            <p class="arabic as2 st-c step4 move" v-if="!text">
              <span
                v-for="(word, index) in orderedArabicWords"
                :key="'ordered-ar-' + index"
                :data-index="index"
                :class="{
                  'word-wrong': incorrectWords.has(index),
                  'success-word': isOrderCorrect && !incorrectWords.has(index),
                }"
                >{{ word }}</span
              >
            </p>
            <p class="phonetique ps2 st-c step4" v-else>
              <span
                v-for="(word, index) in orderedPhoneticWords"
                :key="'ordered-ph-' + index"
                :data-index="index"
                :class="{
                  'word-wrong': incorrectWords.has(index),
                  'success-word': isOrderCorrect && !incorrectWords.has(index),
                }"
                >{{ word }}&nbsp;</span
              >
            </p>
          </div>
          <p
            v-if="!text"
            class="arabic as2 st-c step4 move"
            ref="cardContainer"
          >
            <span
              class="touch-word"
              v-for="wordObj in shuffledWords"
              :key="'shuffled-ar-' + wordObj.id"
              @click="moveWordToOrder(wordObj.id)"
            >
              <span>{{ wordObj.word }}&nbsp;</span>
            </span>
          </p>
          <p v-else class="phonetic ps2 st-c step4" ref="cardContainer">
            <span
              class="touch-word"
              v-for="wordObj in shuffledWords"
              :key="'shuffled-ph-' + wordObj.id"
              @click="moveWordToOrder(wordObj.id)"
            >
              <span>{{ wordObj.word }}&nbsp;</span>
            </span>
          </p>
        </div>
      </div>
      <div class="step-5" v-if="currentStep == 5">
        <h1>{{ $t("step5Title") }}</h1>
        <p>{{ $t("step5Message") }}</p>
        <img
          class="caas-img caas-lazy caas-loaded"
          :alt="$t('step5ImageAlt')"
          loading="lazy"
          data-caas-lazy-loading-init="1"
          src="https://s.yimg.com/ny/api/res/1.2/F9HKon5iH2MbFpeAS1sM_Q--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtjZj13ZWJw/https://media.zenfs.com/en/snappa_entertainment_819/77b45c811132100153dbac1724390867"
        />
        <div class="btn-finalStep">
          <span @click="drawer = false">{{ $t("step5Continue") }}</span>
        </div>
      </div>

      <div class="navigation">
        <div class="left" @click="goToPreviousStep">
          <div class="btn" v-if="currentStep > 1">
            <Left />
          </div>
        </div>
        <div class="middle">
          <div class="prog">
            <div class="progression">
              <div class="prg" :style="{ width: progressBarWidth }"></div>
            </div>
            <span>{{ progressBarWidth }}</span>
          </div>
        </div>
        <div class="right" @click="goToNextStep">
          <div class="btn">
            <Right />
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
  <SharePopup
    :message="`${$t('surahM1')} ${this.numero} ${$t('surahM2')}  ${
      this.surah
    }.`"
    :pageUrl="computedPageUrl"
    ref="sharePopup"
  />
</template>

<script>
import confetti from "../../assets/js/confetti";
import { mapGetters, mapActions, mapState } from "vuex";
import Play from "../icons/Play.vue";
import Pause from "../icons/Pause.vue";
import Ear from "../icons/Ear.vue";
import openEye from "../icons/openEye.vue";
import Mark from "../icons/Mark.vue";
import Left from "../icons/Left.vue";
import Right from "../icons/Right.vue";
import MarkCheck from "../icons/MarkCheck.vue";
import Share from "../icons/Share.vue";
import VideoQuran from "../icons/VideoQuran.vue";
import Success from "../icons/Success.vue";
import LeftLeaf from "../icons/LeftLeaf.vue";
import RightLeaf from "../icons/RightLeaf.vue";
import Wrong from "../icons/Wrong.vue";
import SharePopup from "../global/Share.vue";
import { ref } from "vue";

export default {
  name: "verset-doonun",
  emits: ["playAudioSolo"],
  components: {
    Play,
    LeftLeaf,
    RightLeaf,
    Mark,
    MarkCheck,
    Ear,
    Left,
    Right,
    openEye,
    Share,
    Pause,
    Success,
    Wrong,
    SharePopup,
    VideoQuran,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    surah: String,
    id: Number,
    index: Number,
    idVerse: Number,
    numero: Number,
    arabe: String,
    fr: String,
    tajweed: String,
    tafsir: String,
    phonetique: String,
    checks: String,
    favoris: Boolean,
    totalverses: String,
    idsourate: Number,
    playAr: Boolean,
    playFr: Boolean,
    reciter: {
      type: String,
      default: "Hussary",
    },
  },
  data() {
    return {
      isChecked:
        this.checks?.split("|").includes(this.numero.toString()) || false,
      repetitions: 1,
      isAudioPlaying: false,
      audioPlayCount: 0,
      currentStep: 1,
      countdown: 10,
      shuffledWords: [],
      orderedArabicWords: [],
      orderedPhoneticWords: [],
      audioFiles: [],
      isOrderCorrect: null,
      incorrectWords: new Set(),
      showPhonetic: true,
      attemptedToMoveToNextStep: false,
      currentAudioElement: null,
      hasRevealedWords: false,
      wordListen: 0,
      wrong: false,
      validate: false,
      showTafsir: false,
    };
  },
  setup() {
    const drawer = ref(false);
    const direction = ref("btt");
    const text = ref(false);

    return {
      drawer,
      direction,
      text,
    };
  },

  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapState("user", ["user"]),
    checkedVersets() {
      return this.checks ? this.checks.split("|") : [];
    },
    progressBarWidth() {
      return `${(this.currentStep / 5) * 100}%`;
    },
    isFavoris() {
      return (
        this.user &&
        this.user.ayahs &&
        this.user.ayahs.some((v) => v.id === this.idVerse)
      );
    },
    computedPageUrl() {
      return `sourate/${this.$route.params.id}#${this.index}`;
    },
  },
  methods: {
    ...mapActions("check", ["updateOrCreateCheck"]),
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("sourate", ["fetchSourate"]),
    ...mapActions("user", ["addVerseToFavorites", "removeVerseFromFavorites"]),
    ...mapActions("points", ["updateUserPoints", "updateUserStatSourates"]),

    openSharePopup() {
      this.$refs.sharePopup.togglePopup();
    },
    toggleTafsir() {
      this.showTafsir = !this.showTafsir; // bascule l'état
    },
    async toggleFavoris() {
      if (this.isAuthenticated) {
        if (this.isFavoris) {
          await this.removeVerseFromFavorites(this.idVerse);
        } else {
          await this.addVerseToFavorites(this.idVerse);
        }
      } else {
        alert("Veuillez vous connecter pour ajouter des favoris.");
      }
    },

    triggerConfetti() {
      const cardRect = this.$refs.cardContainer.getBoundingClientRect();
      confetti({
        particleCount: 150,
        spread: 60,
        origin: {
          x: (cardRect.left + cardRect.right) / 2 / window.innerWidth,
          y: (cardRect.top + cardRect.bottom) / 2 / window.innerHeight,
        },
      });
    },
    async handleCheckChange() {
      let list_verset = [];
      this.iduser = this.user.id;

      // Vérifier si this.checks est défini avant d'utiliser split()
      if (this.checks) {
        list_verset = this.checks.split("|");
      }

      // Calculer le taux avant la modification
      const tauxBefore = (list_verset.length / this.totalverses) * 100;

      // console.log("Avant modification:");
      // console.log("isChecked:", this.isChecked);
      // console.log("list_verset.length:", list_verset.length);
      // console.log("totalverses:", this.totalverses);
      // console.log("tauxBefore:", tauxBefore);

      if (this.isChecked) {
        // Ajouter le verset coché
        list_verset.push(this.numero.toString());
        let pointsToAdd = 10;
        let currentPoints =
          Number(this.user.hassanate.points) + Number(pointsToAdd);
        this.updateUserPoints(currentPoints);
        this.user.hassanate.points = currentPoints;
      } else {
        // Supprimer le verset décoché
        list_verset = list_verset.filter((v) => v !== this.numero.toString());
        let pointsToAdd = -10;
        let currentPoints =
          Number(this.user.hassanate.points) + Number(pointsToAdd);
        this.updateUserPoints(currentPoints);
        this.user.hassanate.points = currentPoints;
      }

      // Retirer les doublons
      let newlist_verset = [...new Set(list_verset)];
      const tauxAfter = Math.round(
        (newlist_verset.length / this.totalverses) * 100
      );

      // console.log("Après modification:");
      // console.log("newlist_verset.length:", newlist_verset.length);
      // console.log("tauxAfter:", tauxAfter);

      // Vérifier si tous les versets sont cochés après le changement
      if (tauxAfter === 100) {
        this.triggerConfetti();
        let sourateStat = (this.user.hassanate.sourates += 1);
        this.updateUserStatSourates(sourateStat);
        // console.log("Good !");
      }

      // Vérifier si le taux passe de 100% à moins de 100%
      if (tauxBefore === 100 && tauxAfter < 100) {
        let sourateStat = (this.user.hassanate.sourates -= 1);
        this.updateUserStatSourates(sourateStat);
        // console.log("Bad !");
      }

      // Ajouter +114 à this.idsourate si la langue est l'anglais
      let sourateId = this.idsourate;
      if (this.$i18n.locale === "en") {
        sourateId += 114;
      }

      try {
        await this.updateOrCreateCheck({
          id: this.id,
          list_verset,
          sourate: sourateId,
          iduser: String(this.user.id),
          taux: Number(tauxAfter),
          lang: this.$i18n.locale,
        });

        // Mettre à jour les versets après la modification
        await this.fetchSourate(sourateId.toString());
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour ou création du check :",
          error
        );
      }
    },

    isCheckboxDisabled(versetId) {
      const versetIndex = this.numero - 1; // Le numéro du verset est 1-based, l'index est 0-based
      const previousVersetId = versetIndex > 0 ? versetIndex.toString() : null;
      const nextVersetId =
        versetIndex < this.totalverses - 1
          ? (versetIndex + 2).toString()
          : null;

      // Désactiver si le verset précédent n'est pas coché
      if (previousVersetId && !this.checkedVersets.includes(previousVersetId)) {
        return true;
      }

      // Désactiver si ce verset est coché et que le verset suivant est également coché
      if (
        this.checkedVersets.includes(versetId.toString()) &&
        nextVersetId &&
        this.checkedVersets.includes(nextVersetId)
      ) {
        return true;
      }

      return false;
    },

    transliterateWord(word) {
      const transliterationMap = {
        ء: "ʾ",
        ا: "a",
        ب: "b",
        ت: "t",
        ث: "th",
        ج: "j",
        ح: "h",
        خ: "kh",
        د: "d",
        ذ: "dh",
        ر: "r",
        ز: "z",
        س: "s",
        ش: "sh",
        ص: "s",
        ض: "d",
        ط: "t",
        ظ: "z",
        ع: "ʿ",
        غ: "gh",
        ف: "f",
        ق: "q",
        ك: "k",
        ل: "l",
        م: "m",
        ن: "n",
        ه: "h",
        و: "w",
        ي: "y",
        ى: "a",
        ة: "h",
        ٱ: "A",
        إ: "i",
        "َ": "a",
        "ِ": "i",
        "ُ": "u",
        "ً": "an",
        "ٍ": "in",
        "ٌ": "un",
        "ْ": "",
        "ّ": "",
      };

      return word
        .split("")
        .map((char) => transliterationMap[char] || char)
        .join("");
    },
    playWordAudio(index) {
      const audioPath = this.getWordAudioPath(this.numero, index);
      if (audioPath) {
        // Si un audio est déjà en cours de lecture, l'arrêter
        if (this.currentAudio) {
          this.currentAudio.pause();
          this.currentAudio = null;
        }

        // Créer et jouer le nouvel audio
        this.currentAudio = new Audio(audioPath);
        this.currentAudio.play();

        // Écouter l'événement de fin de lecture pour libérer currentAudio
        this.currentAudio.addEventListener("ended", () => {
          this.currentAudio = null;
        });
      } else {
        console.error("Audio path is null or invalid.");
      }
    },

    getWordAudioPath(verset, wordIndex) {
      if (!verset) {
        console.error("Verset invalide:", verset);
        return null;
      }

      const sourateDossier = String(this.idsourate);
      const versetFile = String(this.numero).padStart(3, "0");
      const sourateNumero = String(this.idsourate).padStart(3, "0");
      const versetNumero = String(this.numero).padStart(3, "0");
      const wordNumber = String(wordIndex + 1).padStart(3, "0");

      const audioPath = `https://audio.doonun.com/mots/${sourateDossier}/${versetFile}/${sourateNumero}_${versetNumero}_${wordNumber}.mp3`;

      return audioPath;
    },

    // Memorisation

    toggleAudio() {
      this.isAudioPlaying ? this.pauseAudio() : this.playAudio();
    },

    limitRepetitions() {
      this.repetitions = Math.min(this.repetitions, 100);
    },

    playAudio() {
      const url = `https://audio.doonun.com/sourates/ar/${
        this.reciter || "Hussary"
      }/${this.idsourate}/${this.numero}.mp3`;
      if (!this.repetitions || !url) return;

      if (!this.isAudioPlaying) {
        this.audioPlayCount = this.repetitions;
        this.isAudioPlaying = true;
      }

      this.audioElement = this.audioElement || new Audio(url);
      this.audioElement.addEventListener("ended", this.handleAudioEnd);
      this.audioElement.play();
    },

    handleAudioEnd() {
      if (this.audioPlayCount > 1) {
        this.audioPlayCount--;
        this.repetitions--;
        this.audioElement.play();
        this.validate = false;
      } else {
        this.isAudioPlaying = false;
        this.audioPlayCount = Math.max(this.audioPlayCount - 1, 0);
        this.repetitions--;
        this.validate = true;
        this.wrong = false;
      }
    },

    pauseAudio() {
      if (this.audioElement && this.isAudioPlaying) {
        this.isAudioPlaying = false;
        this.audioElement.pause();
      }
    },

    listenWord() {
      this.wordListen = this.wordListen + 1;
      // console.log("word listen : ", this.wordListen);
      this.validate = true;
      this.wrong = false;
    },

    assignRandomClass(index) {
      return index % 2 === 0 ? "msqtxt" : "";
    },

    changeClassOnClick() {
      const spans = document.querySelectorAll(".msqtxt");
      spans.forEach((span) => {
        span.classList.replace("msqtxt", "msqtxtVisible");
      });

      let countdown = 10;
      const timer = setInterval(() => {
        this.countdown = --countdown || 10;
        if (countdown === 0) {
          clearInterval(timer);
          spans.forEach((span) => {
            span.classList.replace("msqtxtVisible", "msqtxt");
          });
        }
      }, 1000);

      this.hasRevealedWords = true; // Ajout de cette ligne
      this.validate = true;
      this.wrong = false;
    },
    shuffleWords() {
      const words = (this.text ? this.phonetique : this.arabe)
        .split(/\s+/)
        .filter(Boolean);
      for (let i = words.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [words[i], words[j]] = [words[j], words[i]];
      }
      this.shuffledWords = words.map((word, index) => ({
        id: index + Math.random(),
        word,
      }));
      this.remainingWordsToMove = words.length;
    },

    moveWordToOrder(wordId) {
      const wordObj = this.shuffledWords.find((w) => w.id === wordId);
      if (!wordObj) return;

      this.text
        ? this.orderedPhoneticWords.push(wordObj.word)
        : this.orderedArabicWords.push(wordObj.word);
      this.shuffledWords = this.shuffledWords.filter((w) => w.id !== wordId);

      if (!--this.remainingWordsToMove) {
        this.checkOrder();
      }
    },

    resetOrder() {
      this.orderedArabicWords = [];
      this.orderedPhoneticWords = [];
      this.isOrderCorrect = null;
      this.incorrectWords.clear();
      this.resetWordsVisualState();
      this.shuffleWords();
    },

    resetWordsVisualState() {
      document.querySelectorAll(".word-wrong").forEach((element) => {
        element.classList.remove("word-wrong");
      });
    },

    playSound(audioPath) {
      new Audio(audioPath).play().catch(console.error);
    },

    checkOrder() {
      const originalWords = (this.text ? this.phonetique : this.arabe).split(
        " "
      );
      const orderedWords = this.text
        ? this.orderedPhoneticWords
        : this.orderedArabicWords;

      this.incorrectWords.clear();
      let allCorrect = true;

      orderedWords.forEach((word, index) => {
        const wordElement = document.querySelector(
          `span[data-index="${index}"]`
        );
        if (originalWords[index] !== word) {
          this.incorrectWords.add(index);
          if (wordElement) {
            wordElement.classList.add("word-wrong");
            wordElement.classList.remove("success-word");
          }
          allCorrect = false;
        } else {
          if (wordElement) {
            wordElement.classList.remove("word-wrong");
            wordElement.classList.add("success-word");
          }
        }
      });

      this.isOrderCorrect = allCorrect;

      this.playSound(
        require(`@/assets/audios/quiz/${
          this.isOrderCorrect ? "correct" : "error"
        }.${this.isOrderCorrect ? "wav" : "mp3"}`)
      );

      if (!this.isOrderCorrect) {
        setTimeout(this.resetOrder, 2000);
      }

      if (this.isOrderCorrect) {
        this.validate = true;
        this.wrong = false;
        this.triggerConfetti();

        if (this.currentStep === 4) {
          this.currentStep = 5;
        }
      }
    },

    resetState() {
      this.pauseAudio();
      this.isAudioPlaying = false;
      this.audioPlayCount = 0;
      this.countdown = 10;
      this.shuffleWords();

      if (this.currentStep === 4) {
        // Correction: utiliser this.currentStep
        this.resetOrder();
      }

      this.currentStep = 1; // Ajout pour réinitialiser la progression
    },

    goToNextStep() {
      if (this.currentStep === 1 && this.repetitions >= 1) {
        this.wrong = true;
        return;
      }
      if (this.currentStep === 2 && this.wordListen <= 0) {
        this.wrong = true;
        return;
      }
      if (this.currentStep === 3 && !this.hasRevealedWords) {
        this.wrong = true;
        return;
      }
      if (this.currentStep === 4 && !this.isOrderCorrect) {
        this.wrong = true;
        return;
      }
      if (this.currentStep < 5) {
        this.shuffleWords();
        this.wrong = false;
        this.validate = false;
        this.currentStep++;
      }
    },
    goToPreviousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
        this.resetOrder();
      }
    },
  },
  watch: {
    step(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetState();
      }
    },
    text() {
      if (this.currentStep === 4) {
        this.shuffleWords();
      }
    },
    drawer(newVal) {
      // Ajout du watcher pour drawer
      if (!newVal) {
        this.resetState();
      }
    },
  },
};
</script>

<style scoped>
.box-verset {
  display: block;
  margin: 10px auto 80px auto;
  max-width: 800px;
  /* padding: 20px; */
  /* border: 1px solid var(--body); */
  border-radius: 6px;
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.top p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  background: var(--body);
  border-radius: 50%;
}

.tooltips {
  display: flex;
  align-items: center;
  gap: 20px;
}

.tooltips .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: var(--body);
  border-radius: 50%;
  cursor: pointer;
}

.tooltips .memorize {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 12px;
  height: 30px;
  min-width: 90px;
  padding: 0 10px;
  border: 1px solid var(--body);
  color: var(--color);
  border-radius: 6px;
}

.tooltips .memorize:hover {
  background: var(--button);
  border: 1px solid var(--button);
  color: #fff;
}

.arabe,
.tajweed,
.arabic {
  font-family: "uthmanic";
  text-align: end;
  font-size: clamp(20px, 8vw, 30px);
}

.arabe {
  background: var(--bgV);
  padding: 15px 10px;
  border-radius: 6px;
  border: 2px solid var(--borderV);
}

.trad,
.phonetic {
  font-size: 17px;
  margin-top: 20px;
  text-align: justify;
}

.tafsir {
  margin-top: 20px;
  font-size: 17px;
  text-align: justify;
}

.tafsir span {
  background: #ff6370;
  padding: 3px 10px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
}

.arabe .word-arabic:hover {
  cursor: pointer;
  color: #ff6370;
}

.bottom {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.bottom .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bottom .left .ar,
.bottom .left .fr,
.bottom .left .kathir,
.bottom .left .display-tafsir {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 5px 6px;
  border: 1px solid var(--body);
  background: var(--body);
  border-radius: 3px;
}

.bottom .left .ar:hover,
.bottom .left .fr:hover,
.bottom .left .kathir:hover,
.bottom .left .display-tafsir:hover {
  border: 1px solid var(--button);
}

.bottom .left .display-tafsir span {
  font-size: 13px;
}

.bottom .left .kathir a {
  display: inline;
  line-height: 0;
  font-size: 13px;
  padding: 10px 7px;
}

.btn-finalStep {
  display: flex;
  justify-content: center;
}

.btn-finalStep span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--body);
  border-radius: 50px;
  padding: 10px 30px;
  width: auto;
  transition: 0.2s;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.btn-finalStep span:hover {
  outline: 1px solid var(--button);
}

@media (max-width: 700px) {
  .trad,
  .phonetic {
    text-align: start;
  }

  .tafsir {
    text-align: start;
  }
}

@media (max-width: 380px) {
  .bottom .left .kathir a,
  .bottom .left .display-tafsir span {
    font-size: 11px;
  }
}

.bottom .left img {
  width: 15px;
  height: 15px;
}

.box-check {
  cursor: pointer;
}

.box-check input {
  display: none;
}

.box-check svg {
  overflow: visible;
}

.path {
  fill: none;
  stroke: var(--color);
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  stroke-dasharray: 241 9999999;
  stroke-dashoffset: 0;
}

.box-check input:checked ~ svg .path {
  stroke-dasharray: 70.5096664428711 9999999;
  stroke-dashoffset: -262.2723388671875;
  stroke: var(--button);
}

.btn-step {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* MEMORISATION */

.box-container-memo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.change {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

h2 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}

.input-repetition {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.input-repetition input {
  display: block;
  width: 150px;
  height: 150px;
  background: var(--button);
  color: #fff;
  border-radius: 50%;
  outline: none;
  border: none;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
}

.input-repetition input::-webkit-outer-spin-button,
.input-repetition input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-repetition input[type="number"] {
  -moz-appearance: textfield;
}

.input-repetition button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px 8px 5px;
  gap: 3px;
  border: 1px solid var(--card);
  transition: 0.2s ease-in-out;
  background: var(--body);
  color: var(--color);
  border-radius: 30px;
  width: 120px;
  font-size: 14px;
  font-weight: 500;
}

.input-repetition button:hover {
  width: 130px;
  border: 1px solid var(--button);
}

.texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 20px auto 0 auto;
}

.texts .arabic {
  font-size: 1.7rem;
  font-family: "uthmanic";
  text-align: center;
}

.texts .phonetic {
  text-align: center;
  font-size: 1.1rem;
}

.step-2 .as2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.7rem;
  font-family: "Uthmanic", Courier, monospace;
}

.step-2 .ps2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2px;
  max-width: 800px;
  margin-top: 30px;
  user-select: none;
  margin-bottom: 20px;
}

.step-2 .st-c {
  user-select: none;
}

.step-2 .cc-s .w,
.step-2 .ps2 .w {
  padding: 3px 5px;
  background: var(--body);
  border-radius: 6px;
}

.step-2 .cc-s .w:hover,
.step-2 .ps2 .w:hover {
  background: var(--button);
  color: #fff;
  cursor: pointer;
}

.step-3 .st-c {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  justify-content: center;
  user-select: none;
}

.step-3 .as2 {
  flex-direction: row-reverse;
}

.step-5 h1,
.step-5 p {
  text-align: center;
}

.step-5 img {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
}

.msqtxt {
  border: 1px dashed var(--color);
  padding: 1px 5px;
  border-radius: 6px;
  color: var(--card);
}

.msqtxtVisible {
  color: var(--button);
  padding: 1px 5px;
  border-radius: 6px;
  background-color: var(--card);
}

.c-btn {
  display: flex;
  justify-content: center;
}

.play-btn {
  margin-top: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: var(--body);
  color: var(--color);
  border-radius: 50px;
  padding: 8px 15px;
  font-weight: 500;
  font-size: 14px;
  transition: 0.2s;
  border: none;
  outline: 1px solid var(--body);
}

.play-btn:hover {
  outline: 1px solid var(--button);
}

.decount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-size: 1.5rem;
  border-radius: 50%;
  font-weight: 700;
  width: 100px;
  height: 100px;
  background: var(--body);
}

.step4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px;
}

.result {
  margin-bottom: 20px;
}

.move {
  flex-direction: row-reverse;
}

.touch-word {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--body);
  padding: 3px;
  border-radius: 6px;
  cursor: pointer;
}

.touch-word:hover {
  background: var(--button);
  color: #fff;
}

.word-wrong {
  color: red !important;
}

.success-word {
  color: #67c23a;
}

.validate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 20px 50px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  background: var(--truncated);
  height: 50px;
  margin: 0 auto;
}

.navigation .left,
.navigation .right {
  width: 40px;
  height: 40px;
}

.navigation .left .btn,
.navigation .right .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background: var(--body);
  border-radius: 6px;
  max-width: 40px;
  max-height: 40px;
  width: 100px;
  height: 100%;
}

.navigation .middle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.prog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.prog span {
  margin-top: -3px;
  font-weight: 500;
  font-size: 14px;
}

.progression {
  background-color: var(--body);
  border-radius: 5px;
  width: 70%;
  height: 6px;
}

.prg {
  background-color: var(--button);
  /* box-shadow: rgba(0, 0, 255, 0.311) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px; */
  height: 100%;
  border-radius: 5px;
  transition: 0.2s;
}

@media (max-width: 500px) {
  .box-verset {
    padding: 10px 0 20px 0;
    margin: 10px auto 40px auto;
    border: 0;
    /* border-bottom: 1px solid var(--body); */
  }
}
</style>
