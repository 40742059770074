import axios from "axios";

const state = {
  tafsir: null,
  kathirs: [],
};

const getters = {
  tafsir: (state) => state.tafsir,
  kathirs: (state) => state.kathirs,
};

const mutations = {
  SET_TAFSIR(state, tafsir) {
    state.tafsir = tafsir;
  },
  SET_KATHIRS(state, kathirs) {
    state.kathirs = kathirs;
  },
};

const actions = {
  async fetchTafsirs({ commit }) {
    try {
      const response = await axios.get("/kathirs", {
        params: {
          fields: ["id", "Titre", "nombre"],
        },
      });

      //   console.log("Kathirs : ", response.data.data);
      commit("SET_KATHIRS", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchTafsir({ commit }, payload) {
    // Accepte un objet payload
    const { id, num, lang } = payload;
    if (state.tafsir) {
      return;
    }
    try {
      const response = await axios.get(`/kathirs/${id}`, {
        params: {
          locale: lang,
          fields: ["id", "Titre", "nombre"],
          populate: {
            kathir_verses: {
              locale: lang,
              fields: ["id", "numero", "contenu"],
              filters: {
                numero: {
                  $eq: num,
                },
              },
            },
          },
        },
      });

      //   console.log("response tafsir : ", response.data.data);

      const tafsir = response.data.data;
      commit("SET_TAFSIR", tafsir);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
