import axios from "axios";

const actions = {
  async register(context, userData) {
    try {
      // Vérifiez si l'email est déjà utilisé
      const emailExists = await context.dispatch("checkEmail", userData.email);
      if (emailExists) {
        return {
          success: false,
          errors: [{ path: ["email"], message: "Cet email est déjà utilisé." }],
        };
      }

      const response = await axios.post("/auth/local/register", userData);
      // Retourner le token et les données utilisateur
      return { success: true, data: response.data };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        errors: error.response?.data?.error?.details?.errors || [],
      };
    }
  },

  async checkEmail(_, email) {
    try {
      const response = await axios.get(`/users?filters[email][$eq]=${email}`);
      return response.data.length > 0;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default {
  namespaced: true,
  actions,
};
