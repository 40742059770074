<template>
  <div class="poput-tarifs">
    <div class="container">
      <div class="mySub" v-if="hasSubscription">
        <h2 id="scroll-target">{{ $t("mySub") }}</h2>
        <div class="container-subscribe">
          <div class="box-mySub">
            <h3>{{ offerType }}</h3>
            <p>
              <i class="fa-regular fa-calendar-days"></i> {{ $t("actif") }}
              {{ date }}
            </p>
          </div>
        </div>
      </div>
      <div class="box-tarif" v-else>
        <h2>{{ $t("titletarif") }}</h2>
        <div class="c-y-m">
          <el-switch
            v-model="value"
            size="large"
            :active-text="$t('year')"
            :inactive-text="$t('month')"
            style="--el-switch-on-color: #13ce66"
            @change="toggleAnnual"
          />
          <div class="promo">
            <span v-if="isAnnual" :class="{ 'actif-f': isAnnual }">-36%</span>
          </div>
        </div>
        <div class="tarifs" v-for="tarif in tarifs" :key="tarif.id">
          <div class="offer" :class="tarif.class">
            <h3>
              {{ tarif.title }}
            </h3>
            <p>{{ tarif.description }}</p>
            <p class="prix">{{ tarif.price }}</p>
            <div
              class="btn-t SS_ProductCheckout"
              type="button"
              :data-id="tarif.id"
              :data-email="userEmail"
              @click="handleStripeCheckout"
              :disabled="loading[tarif.id]"
            >
              <div class="go-by" v-if="loading[tarif.id]">
                <Loading />
              </div>
              <div class="go-by" v-else>{{ tarif.btn }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../components/navigation/Loading.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "user-subs",
  components: {
    Loading,
  },
  data() {
    return {
      userEmail: "",
      loading: {},
      isAnnual: false,
      token: localStorage.getItem("token"),
      tarifs: [
        {
          id: 8, // Vérifiez que cet ID est valide sur votre serveur
          class: "premium",
          title: this.$t("student"),
          description: this.$t("citationplanstuden"),
          price: `${this.$t("priceplanmonth")}/${this.$t("monthplan")}`,
          btn: this.$t("btnSub1"),
        },
        {
          id: 3, // Vérifiez que cet ID est valide sur votre serveur
          class: "life",
          title: this.$t("life"),
          description: this.$t("citationplanlife"),
          price: this.$t("priceplanlife"),
          btn: this.$t("btnSub2"),
        },
        {
          id: 0, // Offre gratuite
          class: "basic",
          title: this.$t("basic"),
          description: this.$t("citationplan"),
          price: this.$t("free"),
          btn: this.$t("start"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters("subscribe", ["subscriptionDetails", "isSubscribed"]),
    ...mapState("user", ["user"]),
    hasSubscription() {
      return this.isSubscribed;
    },
    offerType() {
      return this.subscriptionDetails.offerType;
    },
    date() {
      return this.subscriptionDetails.date;
    },
    formattedDate() {
      return this.convertTimestampToDate(this.date);
    },
    oneTime() {
      return this.subscriptionDetails.isOneTime;
    },
  },
  async created() {
    try {
      await this.fetchUser();
    } catch {
      // console.log(
      //   "Erreur lors de la récupération des informations de l'utilisateur"
      // );
    }
  },
  async mounted() {
    this.loadStripeScript();
    await this.fetchUserEmail();
    if (this.userEmail) {
      await this.checkSubscriptionStatus();
    }
  },
  methods: {
    ...mapActions("subscribe", ["checkSubscriptionStatus"]),
    ...mapActions("user", ["fetchUser"]),
    loadStripeScript() {
      if (
        !document.querySelector(
          'script[src="https://cp.doonun.com/plugins/strapi-stripe/static/stripe.js"]'
        )
      ) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://cp.doonun.com/plugins/strapi-stripe/static/stripe.js";
        script.onload = this.initializeStripeButton;
        document.head.appendChild(script);
      }
    },
    handleStripeCheckout(event) {
      const button = event.currentTarget;
      const planId = parseInt(button.getAttribute("data-id"), 10);
      const userEmail = button.getAttribute("data-email");

      // Vérifiez que userEmail est défini
      if (!userEmail) {
        // console.error("L'email de l'utilisateur n'est pas défini.");
        return;
      }

      this.loading[planId] = true;

      if (planId === 0) {
        // Gestion de l'offre gratuite
        this.handleFreeOfferSelection();
        this.loading[planId] = false;
        this.$router.push("/");
        event.preventDefault();
        return;
      }

      // Logique existante pour la redirection vers Stripe
      axios
        .get(
          `https://cp.doonun.com/strapi-stripe/getRedirectUrl/${planId}/${encodeURIComponent(
            userEmail
          )}`
        )
        .then((response) => {
          if (response.data && response.data.url) {
            window.location.href = response.data.url;
          } else {
            // console.error("URL de redirection non fournie par le serveur.");
            this.loading[planId] = false;
          }
        })
        .catch((error) => {
          error;
          // console.error("Erreur lors de la redirection vers Stripe:", error);
          this.loading[planId] = false;
        });
    },
    async fetchUserEmail() {
      if (this.user && this.user.email) {
        try {
          this.userEmail = this.user.email;
          // Mettre à jour l'e-mail de l'utilisateur dans le store
          this.$store.commit("subscribe/SET_USER_EMAIL", this.userEmail);
        } catch (error) {
          error;
          // console.error(
          //   "Erreur lors de la récupération des informations de l'utilisateur:",
          //   error
          // );
        }
      } else {
        // console.error("Utilisateur ou email non défini.");
      }
    },
    toggleAnnual() {
      this.isAnnual = !this.isAnnual;
      this.tarifs = this.tarifs.map((tarif) => {
        if (tarif.id === 8 || tarif.id === 9) {
          // Si vous utilisez un ID 9 valide, sinon retirez cette condition
          const newId = this.isAnnual ? 9 : 8;
          return {
            ...tarif,
            id: newId,
            price: this.isAnnual
              ? `${this.$t("priceplanyear")}/${this.$t("yearplan")}`
              : `${this.$t("priceplanmonth")}/${this.$t("monthplan")}`,
            btn: this.isAnnual ? this.$t("btnSub1") : this.$t("btnSub1"),
          };
        }
        return tarif;
      });
    },
    handleFreeOfferSelection() {
      // Enregistrez la date de sélection dans le localStorage avec une date d'expiration dans 7 jours
      const expirationDate = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // 7 jours en millisecondes
      localStorage.setItem(
        "freeOfferSelected",
        JSON.stringify({
          selected: true,
          expiration: expirationDate,
        })
      );
    },
    checkFreeOfferSelection() {
      const offerData = JSON.parse(localStorage.getItem("freeOfferSelected"));
      if (offerData && offerData.selected) {
        const currentTime = new Date().getTime();
        if (currentTime > offerData.expiration) {
          // Si la date a expiré, supprimer les données
          localStorage.removeItem("freeOfferSelected");
          return false; // L'offre gratuite a expiré
        }
        return true; // L'offre gratuite est encore valide
      }
      return false; // L'offre gratuite n'a pas été sélectionnée ou expirée
    },
    convertTimestampToDate(timestamp) {
      let date = new Date(timestamp);
      return `${date.getDate()} ${date.toLocaleString("default", {
        month: "long",
      })} ${date.getFullYear()}`;
    },
  },
};
</script>

<style scoped>
.poput-tarifs {
  position: fixed;
  top: 0;
  z-index: 1006;
  width: 100%;
  height: 100vh;
  background-color: var(--body);
  padding-top: 50px;
  overflow: auto;
}

.poput-tarifs::-webkit-scrollbar {
  width: 10px !important;
  display: none !important;
}

.poput-tarifs::-webkit-scrollbar-thumb {
  background: var(--card) !important;
}

.mySub {
  display: block;
  margin: 0 auto;
  max-width: 600px;
}

.mySub h2 {
  text-align: center;
}

.box-mySub {
  margin-top: 10px;
  position: relative;
  padding: 20px;
  background: rgb(253, 196, 72);
  background: linear-gradient(
    45deg,
    rgba(253, 196, 72, 1) 0%,
    rgba(255, 212, 117, 1) 100%
  );
  border-radius: 8px;
  color: #000;
}

.box-mySub h3 {
  color: #000 !important;
}

.box-mySub p {
  font-weight: 600;
}
.box-tarif {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-width: 1000px;
  width: 100%;
  overflow: auto;
}

.box-tarif h2 {
  display: block;
  margin: 0 auto;
  text-align: center;
  max-width: 250px;
}

.tarifs {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 18px;
}

.offer {
  position: relative;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  font-size: 14px;
  max-width: 350px;
  width: 100%;
}

.offer.premium {
  background: #7ee0d6;
}

.offer.life {
  background: #ffbc01;
}

.offer.basic {
  background: #f2b2d7;
}

.offer h3 {
  font-size: 20px;
  font-weight: 600;
}

.offer .prix {
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.offer .go-by {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #ffffff;
  padding: 10px 30px;
  font-size: 12px;
  line-height: 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 130px;
  height: 40px;
  margin-top: 20px;
  margin-left: auto;
}

.promo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo span {
  padding: 3px 10px;
  color: #000;
  background: var(--header);
  font-size: 12px;
  border-radius: 3px;
  font-weight: 600;
}

.promo .actif-f {
  background: #ff3358;
  color: #fff;
}

.c-y-m {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 34px;
  border-radius: 17px;
  background-color: #e5e7eb;
  cursor: pointer;
  transition: background-color 0.4s ease;
}

.toggle-label:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease;
}

.toggle-input:checked + .toggle-label {
  background-color: #57d798;
}

.toggle-input:checked + .toggle-label:before {
  transform: translateX(26px);
}
</style>
