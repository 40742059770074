<template>
  <Head>
    <!-- Charset et Viewport -->
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <!-- Titre de la page -->
    <title>{{ article.attributes?.titre || "Article - Doonun" }}</title>

    <!-- Description Meta -->
    <meta name="description" :content="article.attributes?.Meta" />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Canonical URL -->
    <link
      rel="canonical"
      :href="`https://www.doonun.com/articles/${this.$route.params.slug}/${article.attributes?.url}`"
    />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="article" />
    <meta
      property="og:url"
      :content="`https://www.doonun.com/articles/${this.$route.params.slug}/${article.attributes?.url}`"
    />
    <meta
      property="og:title"
      :content="article.attributes?.titre || 'Article - Doonun'"
    />
    <meta property="og:description" :content="article.attributes?.Meta" />
    <meta
      property="og:image"
      :content="`https://cp.doonun.com${article.attributes?.Cover.data.attributes.url}`"
    />

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:url"
      :content="`https://www.doonun.com/articles/${this.$route.params.slug}/${article.attributes?.url}`"
    />
    <meta
      name="twitter:title"
      :content="article.attributes?.titre || 'Article - Doonun'"
    />
    <meta name="twitter:description" :content="article.attributes?.Meta" />
    <meta
      name="twitter:image"
      :content="`https://cp.doonun.com${article.attributes?.Cover.data.attributes.url}`"
    />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />

    <!-- Préchargement des images critiques -->
    <link
      rel="preload"
      as="image"
      :href="`https://cp.doonun.com${article.attributes?.Cover.data.attributes.url}`"
    />
  </Head>
  <div class="container">
    <div class="box-post">
      <div class="left"></div>
      <div class="middle">
        <div class="breadcrumb">
          <router-link :to="`/articles`">Articles</router-link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            color="#000000"
            fill="none"
          >
            <path
              d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
              stroke="currentColor"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <router-link
            :to="`/categories/${this.$route.params.slug}`"
            style="text-transform: capitalize"
            >{{ this.$route.params.slug }}</router-link
          >
        </div>

        <h1>{{ article.attributes?.titre }}</h1>
        <p class="date">
          Publié {{ formatDate(article.attributes?.publishedAt) }}
        </p>
        <div class="links">
          <button @click="copyLink">Copier le lien</button>
          <span @click="shareOnLinkedIn"><Linkdin /></span>
          <span @click="shareOnFacebook"><Facebook /></span>
          <span @click="shareOnTwitter"><Twitter /></span>
          <span @click="shareByEmail"><Mail /></span>
        </div>
        <img
          :src="`https://cp.doonun.com${article.attributes.Cover.data.attributes.url}`"
          :alt="article.attributes?.titre"
        />
        <div
          id="markdown"
          class="markdown-body"
          v-html="$md.render(article.attributes?.text || '')"
        ></div>

        <div
          class="more-posts"
          v-if="articlesByCategoryList[0]?.attributes.articles.data.length > 1"
        >
          <h2>À lire aussi</h2>
          <div class="list-more-posts">
            <router-link
              v-for="article in articlesByCategoryList[0]?.attributes.articles.data
                .filter((a) => a.id !== this.article.id)
                .sort(
                  (a, b) =>
                    new Date(b.attributes.publishedAt) -
                    new Date(a.attributes.publishedAt)
                )
                .slice(0, 4)"
              :key="article.id"
              :to="`/articles/${this.$route.params.slug}/${article.attributes.url}`"
            >
              <article class="last-item">
                <div class="info">
                  <div class="cat-date">
                    <span class="category">{{
                      articlesByCategoryList[0]?.attributes.titre
                    }}</span>
                    <span class="published">
                      • {{ formatDate(article.attributes.publishedAt) }}
                    </span>
                  </div>
                  <p class="title">{{ article.attributes.titre }}</p>
                  <hr />
                  <p class="desc">{{ article.attributes.Meta }}</p>
                </div>
                <img
                  :src="`https://cp.doonun.com${article.attributes.Cover.data.attributes.formats.small.url}`"
                  alt=""
                />
              </article>
            </router-link>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import "moment/locale/fr";
import Facebook from "../../components/icons/social/Facebook.vue";
import Twitter from "../../components/icons/social/Twitter.vue";
import Mail from "../../components/icons/social/Mail.vue";
import Linkdin from "../../components/icons/social/Linkdin.vue";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";
export default {
  name: "ArticleDetails",
  components: { Facebook, Twitter, Mail, Linkdin, Head, Tabs },
  data() {
    return {
      article: null,
      more: null,
    };
  },
  watch: {
    "$route.params": {
      handler(newParams) {
        this.fetchArticleBySlug(newParams.subslug, this?.$i18n?.locale);
        this.fetchArticlesByCategory(newParams.slug);
      },
      immediate: true, // Pour exécuter immédiatement lors de la création
    },
  },
  computed: {
    ...mapGetters("articles", ["articlesList", "articlesByCategoryList"]),
  },
  created() {
    const slug = this.$route.params.subslug;
    this.fetchArticleBySlug(slug, this?.$i18n?.locale);

    const categorySlug = this.$route.params.slug;
    this.fetchArticlesByCategory(categorySlug, this?.$i18n?.locale);
  },
  async mounted() {
    await this.$store.dispatch("articles/fetchArticles");
    this.fetchArticleBySlug(this.$route.params.subslug, this?.$i18n?.locale);
  },
  methods: {
    ...mapActions("articles", [
      "fetchArticlesByCategory",
      // Enlevez "fetchArticleBySlug" et "updateArticleViews"
    ]),
    async fetchArticleBySlug(slug) {
      const article = this.articlesList.find((a) => a.attributes.url === slug); // Cherche l'article par slug
      if (article) {
        // console.log("Article trouvé :", article);
        this.article = article; // Si trouvé, met à jour l'article
        const currentViews = article.attributes.vues || 0; // Récupère le nombre de vues actuel

        // Adaptez l'accès à l'ID en fonction de la structure
        const articleId =
          article.id !== undefined ? article.id : article.attributes.id;

        if (articleId !== undefined) {
          // console.log("ID de l'article :", articleId);
          // Utilisez dispatch directement sans mapper l'action
          this.$store.dispatch("articles/updateArticleViews", {
            articleId,
            currentViews,
          });
        } else {
          console.error("ID de l'article est undefined");
        }
      } else {
        console.error("Article non trouvé");
      }
    },

    formatDate(date) {
      return moment(date).locale("fr").fromNow();
    },
    copyLink() {
      const link = window.location.href; // Obtient l'URL actuelle
      navigator.clipboard
        .writeText(link)
        .then(() => {
          alert("Le lien a été copié dans le presse-papiers !");
        })
        .catch((err) => {
          console.error("Erreur lors de la copie du lien: ", err);
        });
    },
    shareOnLinkedIn() {
      const url = encodeURIComponent(window.location.href);
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
        "_blank"
      );
    },
    shareOnFacebook() {
      const url = encodeURIComponent(window.location.href);
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        "_blank"
      );
    },
    shareOnTwitter() {
      const url = encodeURIComponent(window.location.href);
      const text = encodeURIComponent(this.article.attributes?.titre);
      window.open(
        `https://twitter.com/intent/tweet?url=${url}&text=${text}`,
        "_blank"
      );
    },
    shareByEmail() {
      const url = encodeURIComponent(window.location.href);
      const subject = encodeURIComponent(
        `Article: ${this.article.attributes?.titre}`
      );
      const body = encodeURIComponent(
        `Je voulais partager cet article avec vous: ${url}`
      );
      window.location.href = `mailto:?subject=${subject}&body=${body}`;
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 70px;
  background: var(--card);
}
.box-post {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 100vh;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
}

.breadcrumb svg {
  width: 15px;
  height: 15px;
}

.breadcrumb svg path {
  stroke: var(--color);
}

.box-post h1 {
  font-weight: 800;
  line-height: 2.3rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.date {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
}

.links {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.links button {
  display: flex;
  align-items: center;
  gap: 3px;
  background: var(--body);
  padding: 10px 15px;
  border-radius: 30px;
  font-weight: 700;
  cursor: pointer;
  color: var(--color);
}

.links button svg path {
  fill: var(--color);
}

.box-post img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.left,
.right {
  flex: 1;
}

.middle {
  width: 700px;
  max-width: 100%;
  padding: 0 20px;
  border-left: 1px dotted #ccc;
  border-right: 1px dotted #ccc;
}

.more-posts h2 {
  margin-top: 30px;
}

.list-more-posts {
  margin-top: 10px;
  width: 100%;
}

.last-item {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 20px 0;
  border-top: 1px dotted #ccc;
}

.last-item .info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.last-item .info .title {
  font-weight: 800;
}

.last-item .info .desc {
  font-style: italic;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 14px;
}

.last-item .category {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: rgb(0, 91, 0);
}

.published {
  font-size: 12px;
}

.last-item hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px dotted #ccc;
  padding: 0;
}

.last-item img {
  width: 100%;
  max-width: 330px;
  object-fit: cover;
  object-position: top;
  border-radius: 3px;
  /* clip-path: polygon(2% 0, 100% 0%, 98% 99%, 0% 100%); */
}

@media (max-width: 760px) {
  .container {
    margin-top: 60px;
  }
  .last-item img {
    width: 100%;
    max-width: 100px;
    border-radius: 3px;
  }
}

@media (max-width: 1024px) {
  .left,
  .right {
    flex: 100%;
  }

  .left {
    order: 2;
  }

  .middle {
    order: 1;
    flex: 100%;
    padding: 0;
    border: none;
  }

  .right {
    order: 3;
  }
}
</style>
