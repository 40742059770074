<template>
  <div class="images-icon-fii">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <circle
        cx="16.5"
        cy="7.5"
        r="1.5"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M16 22C15.3805 19.7749 13.9345 17.7821 11.8765 16.3342C9.65761 14.7729 6.87163 13.9466 4.01569 14.0027C3.67658 14.0019 3.33776 14.0127 3 14.0351"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M13 18C14.7015 16.6733 16.5345 15.9928 18.3862 16.0001C19.4362 15.999 20.4812 16.2216 21.5 16.6617"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "images-fii",
};
</script>

<style s>
.images-icon-fii {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: 0.2s ease;
  cursor: pointer;
  margin-bottom: -10px;
}

.images-icon-fii:hover {
  background: var(--bgPoints);
}

.images-icon-fii svg {
  width: 20px;
  height: 20px;
  /* color: var(--color); */
}

.images-icon-fii:hover svg {
  color: var(--button) !important;
}

.images-icon-fii:hover svg path {
  stroke: var(--button) !important;
}

.images-icon-fii svg path {
  stroke: var(--color) !important;
}
</style>
