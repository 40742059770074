<template>
  <div class="linkdin">
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      class="fig-share-tools__svg"
      aria-hidden="true"
      focusable="false"
    >
      <path
        d="M17.5 16.67h-3.34v-4.7c0-1.23-.53-2.07-1.68-2.07-.88 0-1.37.57-1.6 1.11-.08.2-.07.47-.07.74v4.92H7.5s.04-8.33 0-9.08h3.31V9c.2-.62 1.26-1.51 2.95-1.51 2.1 0 3.74 1.3 3.74 4.12v5.05Zm-13.34-10h-.02c-1 0-1.64-.74-1.64-1.67 0-.95.66-1.67 1.68-1.67 1.01 0 1.63.72 1.65 1.67a1.6 1.6 0 0 1-1.67 1.67ZM2.5 7.5h3.33v9.17H2.5V7.5Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "linkdin-icon",
};
</script>

<style scoped>
.linkdin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.linkdin svg {
  width: 20px;
  height: 20px;
}

.linkdin svg path {
  fill: var(--link);
}
</style>
