<template>
  <div class="container-sub">
    <div class="block-login">
      <div class="card-login">
        <router-link to="/">
          <h1>Doonun</h1>
        </router-link>
        <div class="log-r">
          <div class="google" @click="authenticateWithGoogleBtn">
            <img
              src="https://cp.doonun.com/uploads/google_efae55871e.svg"
              alt="google"
            />
          </div>
        </div>
        <div class="a-input">
          <span></span>
          <p>{{ $t("or") }}</p>
          <span></span>
        </div>
        <div class="form-log">
          <form id="signup" @submit.prevent="handleRegister">
            <div class="label-input">
              <div class="form-field">
                <input
                  name="name"
                  type="text"
                  :placeholder="$t('usernameform')"
                  v-model="username"
                  autocomplete="off"
                  @input="clearError('username')"
                />
              </div>
              <small>{{ errors.username }}</small>
              <div class="form-field">
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  v-model="email"
                  autocomplete="off"
                  @input="clearError('email')"
                />
              </div>
              <small>{{ errors.email }}</small>
              <div class="form-field p">
                <input
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="$t('password')"
                  v-model="password"
                  autocomplete="off"
                  @input="clearError('password')"
                />
                <span class="toggle-password" @click="togglePasswordVisibility">
                  <openEye v-if="showPassword" />
                  <CloseEye v-if="!showPassword" />
                </span>
              </div>
              <div class="form-field p">
                <input
                  name="c-password"
                  :type="showCPassword ? 'text' : 'password'"
                  :placeholder="$t('confirmpsw')"
                  v-model="confirmPassword"
                  autocomplete="off"
                  @input="clearError('password')"
                />
                <span
                  class="toggle-password"
                  @click="toggleCPasswordVisibility"
                >
                  <openEye v-if="showCPassword" />
                  <CloseEye v-if="!showCPassword" />
                </span>
              </div>
              <small>{{ errors.password }}</small>
              <small>{{ errors.confirmPassword }}</small>
            </div>
            <small class="success">{{ success }}</small>
            <button type="submit">{{ $t("registerbtn") }}</button>
          </form>
          <router-link class="sub-c" to="/connexion">
            <span>{{ $t("connectctn") }}</span></router-link
          >
        </div>
      </div>
      <div class="card-img-log"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import openEye from "../../components/icons/openEye.vue";
import CloseEye from "../../components/icons/closeEye.vue";

export default {
  name: "inscription-d",
  components: {
    openEye,
    CloseEye,
  },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      errors: {},
      success: "",
      showPassword: false,
      showCPassword: false,
    };
  },
  methods: {
    ...mapActions("register", ["register"]),
    ...mapActions("login", ["login"]),
    ...mapActions("google", ["authenticateWithGoogle"]),

    async handleRegister() {
      this.errors = {};

      // Vérification du nom d'utilisateur
      if (!this.username) {
        this.errors.username = "Ce champ doit être rempli.";
      } else if (this.username.length < 3) {
        this.errors.username =
          "Le nom d'utilisateur doit contenir au moins 3 caractères.";
      } else if (this.username.length > 20) {
        this.errors.username =
          "Le nom d'utilisateur ne doit pas dépasser 20 caractères.";
      }

      // Vérification de l'email
      if (!this.email) {
        this.errors.email = "Ce champ doit être rempli.";
      } else if (!this.validateEmail(this.email)) {
        this.errors.email = "Le format de l'email est invalide.";
      }

      // Vérification des mots de passe
      if (!this.password || !this.confirmPassword) {
        this.errors.password =
          "Les champs de mot de passe ne doivent pas être vides.";
      } else if (this.password.length < 8) {
        this.errors.password =
          "Le mot de passe doit faire minimum 8 caractères.";
      } else if (this.password !== this.confirmPassword) {
        this.errors.password = "Les mots de passe ne correspondent pas.";
      }

      // Si il y a des erreurs, arrête l'exécution de la fonction
      if (Object.keys(this.errors).length > 0) {
        return;
      }

      // Si tout est bon, continue avec l'inscription
      const response = await this.register({
        username: this.username,
        email: this.email,
        password: this.password,
      });

      if (response.success) {
        this.success = "Inscription réussie. Connexion en cours...";

        // Connecte l'utilisateur automatiquement
        const loginResponse = await this.login({
          identifier: this.email,
          password: this.password,
        });

        if (loginResponse) {
          // Redirection vers la page d'accueil
          window.location.href = "/";
        } else {
          this.errors.generic =
            "Une erreur s'est produite lors de la connexion automatique.";
        }
      } else {
        response.errors.forEach((err) => {
          if (err.path && err.path.length > 0) {
            this.errors[err.path[0]] = err.message;
          }
        });
      }
    },

    authenticateWithGoogleBtn() {
      this.authenticateWithGoogle();
    },

    validateEmail(email) {
      // Expression régulière pour la validation de l'email
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return re.test(email);
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    toggleCPasswordVisibility() {
      this.showCPassword = !this.showCPassword;
    },

    clearError(fieldName) {
      this.errors[fieldName] = "";
    },
  },
};
</script>

<style scoped>
.container-sub {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--body);
  z-index: 999;
}

.block-login {
  display: flex;
  justify-content: center;
  align-items: stretch;
  min-height: 100%;
}

.card-img-log {
  width: 480px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-radius: 10px;
  min-height: 570px;
  height: auto;
  background-image: url("https://cp.doonun.com/uploads/sub_flower_89d9ce8e37.png");
}

.card-login {
  width: 100%;
  max-width: 400px;
  height: auto;
  /* background-color: var(--card); */
  padding: 30px;
  border-radius: 10px;
  /* border: 1px solid var(--color); */
  border-right: 0;
}

.card-login h1 {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 28px;
}

.log-r {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.log-r .google {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 5px;
  border: 1px solid var(--color);
  border-radius: 6px;
  font-size: 13px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.log-r img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.a-input {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.a-input p {
  width: auto;
  font-weight: 300;
  text-align: center;
}

.a-input span {
  height: 0.5px;
  background: var(--color);
  width: 100%;
  max-width: 25%;
}

.label-input {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.label-input input {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  border: 1px solid var(--color);
  background: var(--card);
  color: var(--color);
  border-radius: 6px;
  margin: 10px 0 0 0;
  font-weight: 500;
  font-size: 15px;
}

.label-input input:focus {
  outline: none;
}

.form-log form button {
  margin-top: 10px;
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 6px;
  color: var(--colorBtn);
  font-weight: 600;
  background: var(--color);
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.form-log form button:hover {
  background: var(--button);
  color: #fff;
}

.sub-c {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.form-field.error input {
  border-color: var(--error-color);
}

.form-field.success input {
  border-color: var(--success-color);
}

.p input {
  border: none;
  margin: 0;
}

.p {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid var(--color);
  padding: 0 15px 0 0;
  border-radius: 6px;
  margin-top: 10px;
  background: var(--card);
}

.p .toggle-password {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

small {
  color: rgb(255, 35, 35);
}

.success {
  color: rgb(0, 170, 51);
  font-weight: 600;
}

@media (max-width: 768px) {
  .container-sub {
    display: block !important;
    padding: 0 !important;
  }

  .block-login {
    margin-top: -40px !important;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 100vh !important;
    height: auto;
    align-items: flex-start;
  }

  .card-img-log {
    display: none;
  }

  .card-login {
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .card-login {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>
