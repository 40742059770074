<template>
  <div class="container">
    <div class="page-404">
      <h1>{{ $t("titleOops") }}</h1>
      <h2>{{ $t("title404") }}</h2>
      <Head>
        <title>{{ $t("title404") }}</title>
        <meta name="robots" content="Noindex, nofollow" />
        <link
          rel="icon"
          href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
        />
      </Head>
      <img src="https://cp.doonun.com/uploads/404_bc54f4529f.svg" alt="" />
      <p>
        {{ $t("description404") }}
      </p>
      <ul>
        <li class="link-home">
          <router-link to="/">{{ $t("linkHome") }}</router-link>
        </li>
        <!-- <li v-html="$t('contactSuggestion')"></li> -->
      </ul>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { Head } from "@vueuse/head";
import Tabs from "../components/global/Tabs.vue";
export default {
  name: "Page-404",
  components: {
    Tabs,
    Head,
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  padding-bottom: 100px;
  padding-top: 100px;
}
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
  text-align: center;
  height: auto;
  object-fit: contain;
}

.page-404 {
  text-align: center;
  padding: 2rem;
}

.page-404 h1 {
  font-size: 2.5rem;
}

.page-404 h2 {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.page-404 p {
  margin: 1rem 0;
}

.page-404 ul {
  list-style-type: none;
  padding: 0;
}

.page-404 li {
  margin: 0.5rem 0;
}

.link-home a {
  color: #fff;
  background: var(--button);
  padding: 10px 30px;
  border-radius: 30px;
  transition: 0.2s ease;
}

.link-home a:hover {
  background: var(--color);
  color: var(--colorBtn);
}
</style>
