<template>
  <div>
    <h2 id="scroll-target">{{ $t("booksCompTitle") }}</h2>
    <div class="books-container">
      <div class="container-book">
        <div class="item" v-for="livre in livreUser" :key="livre?.id">
          <cardBook
            :id="livre?.id"
            :titre="livre?.attributes?.titre"
            :lien="livre?.attributes?.url"
            :cover="livre?.attributes?.cover.data.attributes.formats.small.url"
            :favoris="Boolean(livre?.attributes?.users?.data[0]?.id)"
            @addFavorite="addFavorite"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import cardBook from "../livres/cardBook.vue";
export default {
  name: "livres-profil",
  components: {
    cardBook,
  },
  computed: {
    ...mapGetters("livres", ["livreUser"]),
    ...mapGetters("login", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("livres", ["fetchUserLivre"]),
    async addFavorite() {
      try {
        await this.fetchUserLivre();
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
  },
  async mounted() {
    try {
      if (this.isAuthenticated) {
        this.fetchUserLivre();
      }
    } catch (error) {
      console.error("Error fetching user books:", error);
    }
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.books-container {
  display: block;
  max-width: 600px;
  margin: 0 auto;
}

.container-book {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  align-items: stretch;
  justify-content: left;
  gap: 5px;
  margin-top: 20px;
}

.item {
  width: 100%;
}

@media (max-width: 600px) {
  .container-book {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
</style>
