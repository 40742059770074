<template>
  <div>
    <h2 id="scroll-target">{{ $t("notesCompTitle") }}</h2>
    <div class="notes-container">
      <ul>
        <li v-for="note in userNotes" :key="note.id" @click="openDrawer(note)">
          {{ note.attributes.titre }}
        </li>
      </ul>
    </div>
    <el-drawer
      :direction="direction"
      v-model="drawer"
      :title="`Lecture de la note`"
      class="popupmemo"
      :show-close="true"
    >
      <div class="box-container-notes">
        <h3>{{ selectedNote.attributes.titre }}</h3>
        <p v-for="dossier in selectedNote.attributes.Dossier" :key="dossier.id">
          {{ dossier.Titre }}: {{ dossier.note }}
        </p>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "note-profil",
  components: {},
  setup() {
    const drawer = ref(false);
    const direction = ref("btt");
    const selectedNote = ref(null);

    return {
      drawer,
      direction,
      selectedNote,
    };
  },
  computed: {
    ...mapGetters("notes", ["userNotes"]),
  },
  methods: {
    ...mapActions("notes", ["fetchUserNotes"]),

    openDrawer(note) {
      this.selectedNote = note;
      this.drawer = true;
    },
  },
  async mounted() {
    await this.fetchUserNotes();
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.notes-container {
  max-width: 600px;
  margin: 0 auto;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 4px;
}

li:hover {
  background-color: #f5f5f5;
}
</style>
