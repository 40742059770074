// src/themes.js
export const themes = [
  {
    name: "Rose",
    body: "#FEFAF9",
    color: "#0c0020",
    card: "#FEF3F6",
    link: "#500724",
    button: "#FE4879",
    colorBtn: "#ffffff",
    borderV: "#FE4879",
    bgV: "#ffe9ef",
    bgPoints: "#fecdd3",
    placeholder: "rgba(254,205,211, 0.8)",
    truncated:
      "linear-gradient(0deg, rgba(254,243,246,1) 0%, rgba(254,243,246,0) 100%)",
    optionsBackground: "rgba(254,205,211, 0.6)",
  },
  {
    name: "Jaune Doux",
    body: "#FFFBEC",
    color: "#0c0020",
    card: "#FFF8DC",
    link: "#451a03",
    button: "#facc15",
    colorBtn: "#ffffff",
    borderV: "#facc15",
    bgV: "#fef9c3",
    bgPoints: "#fef9c3",
    placeholder: "rgba(250,204,21, 0.8)",
    truncated:
      "linear-gradient(to bottom, rgba(255, 248, 220, 0) 0%, rgba(255, 248, 220, 1) 100%)",
    optionsBackground: "rgba(255, 248, 220, 0.6)",
  },
  {
    name: "Bleu Clair",
    body: "#F1F9FF",
    color: "#0c0020",
    card: "#E9F5FF",
    link: "#1e3a8a",
    button: "#50AEFF",
    colorBtn: "#ffffff",
    borderV: "#50AEFF",
    bgV: "#e0f2fe",
    bgPoints: "#e0f2fe",
    placeholder: "rgba(80,174,255, 0.8)",
    truncated:
      "linear-gradient(to bottom, rgba(233, 245, 255, 0) 0%, rgba(233, 245, 255, 1) 100%)",
    optionsBackground: "rgba(233, 245, 255, 0.6)",
  },
  {
    name: "Vert Nature",
    body: "#F5F8EF",
    color: "#0c0020",
    card: "#EBF3DE",
    link: "#1a2e05",
    button: "#6C9834",
    colorBtn: "#ffffff",
    borderV: "#6C9834",
    bgV: "#ecfccb",
    bgPoints: "#ecfccb",
    placeholder: "rgba(108,152,52, 0.8)",
    truncated:
      "linear-gradient(to bottom, rgba(235, 243, 222, 0) 0%, rgba(235, 243, 222, 1) 100%)",
    optionsBackground: "rgba(235, 243, 222, 0.6)",
  },
  {
    name: "Violet Éclatant",
    body: "#EDE7F9",
    color: "#0c0020",
    card: "#E5DAFB",
    link: "#2e1065",
    button: "#9100FF",
    colorBtn: "#ffffff",
    borderV: "#9100FF",
    bgV: "#ddd6fe",
    bgPoints: "#ddd6fe",
    placeholder: "rgba(145,0,255, 0.8)",
    truncated:
      "linear-gradient(to bottom, rgba(229, 218, 251, 0) 0%, rgba(229, 218, 251, 1) 100%)",
    optionsBackground: "rgba(229, 218, 251, 0.6)",
  },
  {
    name: "Brun Terreux",
    body: "#EBE2DD",
    color: "#0c0020",
    card: "#E5D7D0",
    link: "#451a03",
    button: "#806255",
    colorBtn: "#ffffff",
    borderV: "#806255",
    bgV: "#f1e9df",
    bgPoints: "#f1e9df",
    placeholder: "rgba(229, 215, 208, 0.8)",
    truncated:
      "linear-gradient(to bottom, rgba(229, 215, 208, 0) 0%, rgba(229, 215, 208, 1) 100%)",
    optionsBackground: "rgba(229, 215, 208, 0.6)",
    bglog: "#88706B",
    shadow: "rgb(128, 98, 85, 0.56) 0px 22px 70px 4px",
  },
];
