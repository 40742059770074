<template>
  <div class="open-eye">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_linear_eye"
        data-name="vuesax/linear/eye"
        transform="translate(-108 -188)"
      >
        <g id="eye">
          <path
            id="Vector"
            d="M7.16,3.58A3.58,3.58,0,1,1,3.58,0,3.576,3.576,0,0,1,7.16,3.58Z"
            transform="translate(116.42 196.42)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M9.785,16.55c3.53,0,6.82-2.08,9.11-5.68a5.326,5.326,0,0,0,0-5.19C16.6,2.08,13.315,0,9.785,0S2.965,2.08.675,5.68a5.326,5.326,0,0,0,0,5.19C2.965,14.47,6.255,16.55,9.785,16.55Z"
            transform="translate(110.215 191.72)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(132 212) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "open-eye-d",
};
</script>

<style>
.open-eye {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.open-eye svg {
  width: 15px;
  height: 15px;
}

.open-eye svg path {
  stroke: var(--color) !important;
}

.view .open-eye svg path {
  stroke: #fff !important;
}
</style>
