<template>
  <router-link :to="`/duaas/${slug}/${titreSlug}`">
    <div class="card-dua">
      <div class="top">
        <div class="cat">
          <img :src="`https://cp.doonun.com/${img}`" :alt="category" />
          <p class="category">{{ category }}</p>
        </div>
        <div class="icon" v-if="isAuthenticated" @click.prevent="toggleFavoris">
          <MarkCheck v-if="isFavoris" />
          <Mark v-else />
        </div>
      </div>
      <div class="titre" v-if="titre">
        <p>{{ titre }}</p>
      </div>
      <p class="arabe" v-if="arab">
        {{ arab }}
      </p>
      <p class="transcript" v-if="transcript">
        {{ transcript }}
      </p>
      <p class="translite" v-if="translite">
        {{ translite }}
      </p>
      <div class="source" v-if="source">
        <p class="ref">{{ source }}</p>
      </div>

      <div class="c-player" v-if="url_mp3">
        <Player :file="url_mp3" />
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Player from "../global/Player.vue";
import Mark from "../icons/Mark.vue";
import MarkCheck from "../icons/MarkCheck.vue";

export default {
  name: "card-duaa",
  components: {
    Player,
    Mark,
    MarkCheck,
  },
  props: {
    id: Number,
    slug: String,
    titreSlug: String,
    titre: String,
    arab: String,
    transcript: String,
    translite: String,
    source: String,
    url_mp3: String,
    favoris: Boolean,
    category: String,
    img: String,
  },
  data() {
    return {};
  },
  watch: {
    favoris(newVal) {
      // Met à jour isFavoris si favoris change
      this.isFavoris = newVal;
    },
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapState("user", ["user"]),
    isFavoris() {
      return (
        this.user &&
        this.user.douas &&
        this.user.douas.some((d) => d.id === this.id)
      );
    },
  },
  methods: {
    ...mapActions("user", ["addDouaToFavorites", "removeDouaFromFavorites"]),
    toggleFavoris() {
      if (this.isFavoris) {
        this.removeDouaFromFavorites(this.id);
      } else {
        this.addDouaToFavorites(this.id);
      }
    },
  },
};
</script>

<style scoped>
.card-dua {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 600px;
  width: 100%;
  background: var(--card);
  border-radius: 6px;
  padding: 30px;
}

.top {
  display: flex;
  justify-content: space-between;
}

.top .cat {
  display: flex;
  align-items: center;
  gap: 5px;
}

.top .cat img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.titre p {
  font-weight: 500;
  font-style: italic;
}

.arabe {
  font-family: "uthmanic";
  text-align: justify;
  text-align-last: right;
  font-size: clamp(20px, 8vw, 28px);
}

.transcript {
  text-align: justify;
}

.translite {
  text-align: justify;
}

.ref {
  color: #02be68;
  font-size: 14px;
  margin-bottom: 10px;
}
</style>
