<template>
  <div class="up" v-show="visible">
    <button class="Btn" @click="scrollToTop">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="20"
        height="20"
        color="#000000"
        fill="none"
      >
        <path
          d="M7.29469 17C3.53045 7.25 8.86313 2.9375 12 2C15.1369 2.9375 20.4696 7.25 16.7053 17C16.1369 16.6875 14.4 16.0625 12 16.0625C9.6 16.0625 7.86313 16.6875 7.29469 17Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 9C14 7.89543 13.1046 7 12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9Z"
          stroke="currentColor"
          stroke-width="1.5"
        />
        <path
          d="M17.5 15.5576C18.9421 15.6908 20.7078 16.0822 21.9814 17C21.9814 17 22.5044 12.0642 18 11"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.5 15.5576C5.05794 15.6908 3.29216 16.0822 2.01858 17C2.01858 17 1.49555 12.0642 6 11"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.5 19C9.5 19 9.91667 21.5 12 22C14.0833 21.5 14.5 19 14.5 19"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "UpButton",
  data() {
    return {
      visible: false,
      lastScrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // Call handleScroll initially to determine initial visibility
    this.handleScroll();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      let scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > this.lastScrollTop) {
        // Scrolling down
        this.visible = false;
      } else {
        // Scrolling up
        this.visible = scrollTop > 0; // Only show when scrollTop is greater than 0
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    },
  },
};
</script>

<style scoped>
.up {
  display: flex;
  justify-content: center;
  padding: 20px;
  position: fixed;
  bottom: 65px;
  right: 30px;
  z-index: 998;
}

.up svg {
  width: 25px;
  height: 25px;
}

.up svg path {
  stroke: var(--color);
}

.up svg:hover path {
  stroke: var(--button);
}

.Btn {
  width: 45px;
  height: 45px;
  background: var(--optionsBackground);
  backdrop-filter: var(--options-v-blur);
  -webkit-backdrop-filter: var(--options-v-blur);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: none;
}

.arrow path {
  fill: var(--color);
}

.Btn:hover .text {
  opacity: 1;
  transition-duration: 0.7s;
}

.Btn:hover .arrow {
  animation: slide-in-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .up {
    bottom: 110px;
  }
}
</style>
