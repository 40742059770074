<template>
  <div>
    <h2 id="scroll-target">{{ $t("testiComp") }}</h2>
    <div class="testi-container">
      <div class="box-avis" v-if="myTestimonials && isEditing === false">
        <CardAvis
          :avis="myTestimonials?.attributes?.Avis"
          :username="
            myTestimonials?.attributes?.users_permissions_user?.data?.attributes
              ?.username
          "
          :img="
            myTestimonials?.attributes?.users_permissions_user?.data?.attributes
              ?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats
              ?.small?.url || '/uploads/plant_6a63b26a77.png'
          "
          :note="myTestimonials?.attributes?.Note"
        />
        <div class="choice">
          <el-button type="danger" round @click="handleDeleteReview">
            {{ $t("deletePost") }}
          </el-button>
          <el-button type="primary" round @click="editReview">{{
            $t("updateReview")
          }}</el-button>
        </div>
      </div>
      <div class="box-review" v-else>
        <div class="top">
          <img :src="`https://cp.doonun.com${avatarUrl}`" alt="Avatar" />
          <textarea
            ref="contentTextarea"
            v-model="newReviewContent"
            :placeholder="$t('holderReview')"
            @input="autosize"
            :maxlength="maxCharacters"
          ></textarea>
        </div>
        <div class="right-btn">
          <el-rate v-model="value" />
          <button @click="saveReview" :disabled="!canPost">
            {{ isEditing ? $t("updateReview") : $t("sendReview") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import CardAvis from "../logout/CardAvis.vue";

export default {
  name: "testimonials-profil",
  data() {
    return {
      newReviewContent: "",
      maxCharacters: 350,
      value: 0,
      isEditing: false,
    };
  },
  components: { CardAvis },
  computed: {
    ...mapGetters("testimonials", [
      "allTestimonials",
      "myTestimonials",
      "isLoading",
      "getError",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("avatar", {
      avatarUrl: (state) => state.avatar,
    }),
    canPost() {
      return this.newReviewContent.trim() !== "" && this.value > 0;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.contentTextarea) {
        this.autosize({ target: this.$refs.contentTextarea });
      }
    });
  },
  async created() {
    try {
      await this.fetchUser();
      if (this.user && this.user.avatar && this.user.avatar.id) {
        const avatarId = this.user.avatar.id;
        await this.fetchAvatar(avatarId);
      } else {
        console.error("User or user avatar ID not found");
      }
    } catch (error) {
      console.error("Error fetching user or avatar:", error);
    }
    this.fetchTestimonialById();
  },
  methods: {
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("avatar", ["fetchAvatar"]),
    ...mapActions("testimonials", [
      "fetchTestimonialById",
      "createReview",
      "updateReview",
      "deleteReview",
    ]),

    async saveReview() {
      const reviewData = {
        Avis: this.newReviewContent,
        Note: this.value,
        users_permissions_user: this.user.id,
      };

      try {
        if (this.isEditing) {
          await this.updateReview({
            id: this.myTestimonials.id,
            data: reviewData,
          });
          this.fetchTestimonialById();
        } else {
          await this.createReview(reviewData);
          this.fetchTestimonialById();
        }
        this.resetForm();
      } catch (error) {
        console.error("Erreur lors de la sauvegarde du review:", error);
      }
    },

    editReview() {
      this.isEditing = true;
      this.newReviewContent = this.myTestimonials.attributes.Avis;
      this.value = this.myTestimonials.attributes.Note;
      this.$nextTick(() => {
        this.autosize({ target: this.$refs.contentTextarea });
      });
    },

    async handleDeleteReview() {
      // Utilisez une méthode renommée pour éviter la récursion
      try {
        await this.deleteReview(this.myTestimonials.id);
        this.resetForm();
      } catch (error) {
        console.error("Erreur lors de la suppression du review:", error);
      }
    },

    resetForm() {
      this.isEditing = false;
      this.newReviewContent = "";
      this.value = 0;
    },

    autosize(event) {
      const el = event.target;
      if (el) {
        el.style.height = "auto";
        el.style.height = `${el.scrollHeight}px`;
      }
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.testi-container {
  max-width: 600px;
  max-height: 100vh;
  margin: 0 auto;
}

.box-avis {
  display: block;
  margin: 0 auto;
}

.box-review {
  padding: 20px;
  background: var(--card);
  border-radius: 10px;
  outline: 1px solid var(--bgPoints);
}

.box-review .top {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.box-review .top img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

textarea {
  width: 100%;
  border: 0;
  background: var(--card);
  min-height: 50px;
  max-height: 1400px;
  resize: none;
  font-size: 16px;
  font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, Helvetica;
  font-weight: 500 !important;
  color: var(--color);
  overflow: hidden;
  outline: 1px solid var(--bgPoints);
  padding: 10px;
  border-radius: 10px;
}

textarea::placeholder {
  color: var(--placeholder);
}

textarea:focus {
  border: none;
}

.right-btn {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.right-btn button {
  display: block;
  cursor: pointer;
  padding: 18px 25px;
  background: var(--color);
  color: var(--colorBtn);
  font-weight: 600;
  font-size: 16px;
  line-height: 0;
  border-radius: 30px;
  transition: 0.2s ease;
  border: none;
}

.right-btn button:hover {
  opacity: 0.9;
}

.choice {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
</style>
