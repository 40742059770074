<template>
  <div class="hassanate">
    <span>{{ formatPoints(user?.hassanate?.points) || 0 }} </span>
    <Hass />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Hass from "../icons/Hass.vue";
export default {
  name: "hassanate-doonun",
  components: {
    Hass,
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("user", ["fetchUser"]),
    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return Number(points);
      }
    },
  },
  created() {
    this.fetchUser();
  },
};
</script>

<style scoped>
.hassanate {
  display: flex;
  align-items: center;
}

.hassanate span {
  font-size: 14px;
  color: var(--link);
}
</style>
