<template>
  <router-link to="/connexion">
    <el-button type="warning" plain>{{ $t("login") }}</el-button>
  </router-link>
</template>

<script>
export default {
  name: "login-btn",
};
</script>

<style scoped></style>
