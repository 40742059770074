<template>
  <Head>
    <title>Fiiyd - Réseau social musulman</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div>
    <div class="trending-tags">
      <ul>
        <li v-for="(tag, index) in trendingTags" :key="tag?.id">
          <router-link :to="`/fiiyd/hashtag/${tag?.name}`">
            <span class="nb-t">{{ index + 1 }} • {{ $t("trends") }}</span>
            <p class="name">{{ tag?.name }}</p>
            <p class="count">{{ formatPoints(tag?.totalCount) }} Posts</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="tabs">
    <TabsFiiyd />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TabsFiiyd from "../../components/fiiyd/TabsFiiyd.vue";
import { Head } from "@vueuse/head";
export default {
  name: "trends-fii",
  components: {
    TabsFiiyd,
    Head,
  },
  computed: {
    ...mapGetters("fiiyd", ["getTrendingTags"]),
    trendingTags() {
      // console.log("getTrendingTags : ", this.getTrendingTags); // Vérifiez ici
      return this.getTrendingTags;
    },
  },
  methods: {
    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return Number(points);
      }
    },
  },
  created() {
    this.$store.dispatch("fiiyd/fetchTrendingTags");
  },
};
</script>

<style scoped>
.trending-tags {
  margin-top: 100px;
  padding: 0 20px 20px 20px;
}
.trending-tags ul {
  list-style: none;
  padding: 0;
}

.trending-tags li {
  padding: 8px 0 8px 0;
  border-bottom: 1px solid var(--card);
}

.nb-t {
  font-size: 12px;
  color: #71767b;
}

.name {
  font-size: 18px;
  color: var(--color);
  font-weight: 700;
  text-transform: capitalize;
}

.count {
  font-size: 12px;
  color: #71767b;
}

.tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  background: var(--card);
  width: 100%;
  z-index: 10;
}
</style>
