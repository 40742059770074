<template>
  <div class="comments-icon-fii">
    <svg
      id="Layer_3"
      height="512"
      viewBox="0 0 22 22"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 3"
    >
      <path
        d="m21.08 15.4.91 5.43a1.0217 1.0217 0 0 1 -.28.88 1.0068 1.0068 0 0 1 -.71.29 1.0284 1.0284 0 0 1 -.17-.01l-5.43-.91a10.8118 10.8118 0 0 1 -4.4.92 11 11 0 1 1 11-11 10.8118 10.8118 0 0 1 -.92 4.4z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "comments-fii",
};
</script>

<style scoped>
.comments-icon-fii {
  display: flex;
  align-items: center;
}

.comments-icon-fii svg {
  width: 20px;
  height: 20px;
}

.comments-icon-fii svg path {
  fill: #1d9bf0;
}
</style>
