<template>
  <div>
    <div class="box-ranking">
      <div
        class="item-rank"
        v-for="(user, index) in topUsers.slice(0, 5)"
        :key="index"
      >
        <div class="num-rank">
          <p>#{{ user?.attributes?.rang }}</p>
        </div>
        <div class="rank">
          <div class="rank-info">
            <img
              class="profil-user"
              :src="`https://cp.doonun.com${
                user?.attributes?.users_permissions_user?.data?.attributes
                  ?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats
                  ?.small?.url || '/uploads/small_plant_f9f00dabd7.png'
              }`"
              :alt="
                user?.attributes?.users_permissions_user?.data?.attributes
                  ?.username
              "
            />
            <p>
              {{
                user?.attributes?.users_permissions_user?.data?.attributes
                  ?.username
              }}
            </p>
          </div>
          <div class="hassanate-rank">
            <p>
              {{ formatPoints(user.attributes?.points) }}
              <Blitz />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ranking-card",
  computed: {
    ...mapGetters("ranking", ["topUsers", "isLoading", "error"]),
  },
  methods: {
    ...mapActions("ranking", ["fetchTopUsers"]),
    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return Number(points);
      }
    },
  },
  mounted() {
    this.fetchTopUsers();
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 20px;
}

.title-c-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.box-ranking {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 10px;
}

.item-rank {
  width: 100%;
  padding: 10px;
  background-color: var(--card);
  height: 60px;
  border-radius: 6px;
}

.num-rank {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20;
  left: 20;
  width: 30px;
  height: 30px;
  padding: 15px;
  background: var(--body);
  border-radius: 10px;
}

.num-rank p {
  color: var(--text);
  font-size: 12px;
  font-weight: 600;
  line-height: 0;
}

.rank {
  padding: 0 0 0 20px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.rank p {
  font-size: 14px;
}

.rank-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rank-info p {
  word-break: break-all;
  font-weight: 600;
}

.rank-info img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.hassanate-rank p {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}
</style>
