<template>
  <div>
    <div class="themes-container">
      <h2>Thèmes</h2>
      <div class="box-themes">
        <!-- Thème par défaut -->
        <div
          class="theme-item"
          :style="{ backgroundColor: '#ffffff' }"
          @click="changeTheme(-1)"
        >
          <span v-if="currentTheme === -1" class="selected-indicator"></span>
        </div>

        <!-- Thèmes existants -->
        <div
          v-for="(theme, index) in themes"
          :key="index"
          class="theme-item"
          :style="{ backgroundColor: theme.card }"
          @click="changeTheme(index)"
        >
          <span v-if="currentTheme === index" class="selected-indicator"
            >⬤</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { themes } from "../../assets/js/themes";
import { applyTheme } from "../../assets/js/themeUtils";

export default {
  name: "UserThemes",
  data() {
    return {
      themes,
      currentTheme: null, // Index du thème actuel
    };
  },

  methods: {
    changeTheme(index) {
      if (index === -1) {
        // Si on clique sur le thème par défaut (index -1), supprimer le localStorage
        localStorage.removeItem("selectedTheme");
        this.currentTheme = null;
        applyTheme(index);
        window.location.reload();
      } else if (themes[index]) {
        // Appliquer un thème spécifique et le stocker dans le localStorage
        applyTheme(index);
        this.currentTheme = index;
        localStorage.setItem("selectedTheme", index);
      }
    },
  },

  mounted() {
    // Charger le thème stocké dans le localStorage, si présent
    const storedTheme = localStorage.getItem("selectedTheme");
    if (storedTheme !== null) {
      const index = parseInt(storedTheme, 10);
      if (index >= 0 && index < themes.length) {
        this.changeTheme(index);
      }
    }
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.themes-container {
  display: block;
  max-width: 600px;
  margin: 0 auto;
}

.theme-item {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 1px solid #000;
}

.box-themes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.box-themes div {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s ease;
}

.box-themes div:hover {
  outline: 1px solid var(--button);
  transform: scale(1.05);
}

.theme-error {
  background: #ffef89;
  border: 2px solid #ffd900;
  padding: 10px;
  border-radius: 10px;
}

.theme-error p {
  color: #000;
}

.theme-error p a {
  font-weight: 700;
  color: blue;
}

.selected-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 50%;
  background: var(--button);
  color: var(--button);
  margin: 0;
}
</style>
