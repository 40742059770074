<template>
  <Head>
    <title>
      Tafsir Ibn Kathir verset {{ this.$route.params.subId }} de la sourate
      {{ tafsir?.attributes?.Titre }}
    </title>
    <meta
      name="description"
      :content="`Découvrez le tafisr complet d'Ibn Kathir pour le verset ${this.$route.params.subId} de la sourate ${tafsir?.attributes?.Titre}.`"
    />
    <meta
      property="og:title"
      :content="`Tafsir Ibn Kathir verset ${this.$route.params.subId} de la sourate ${tafsir?.attributes?.Titre}.`"
    />
    <meta
      property="og:url"
      :content="`https://www.doonun.com/ibn-kathir/${this.$route.params.id}/${this.$route.params.subId}`"
    />
    <meta
      property="og:description"
      :content="`Découvrez le tafisr complet d'Ibn Kathir pour le verset ${this.$route.params.subId} de la sourate ${tafsir?.attributes?.Titre}.`"
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/kathir_cb3b4e6bac.png"
    />
    <meta name="robots" content="index, follow" />

    <!-- Twitter -->
    <meta
      name="twitter:title"
      :content="`Tafsir Ibn Kathir verset ${this.$route.params.subId} de la sourate ${tafsir?.attributes?.Titre}.`"
    />
    <meta
      name="twitter:description"
      :content="`Découvrez le tafisr complet d'Ibn Kathir pour le verset ${this.$route.params.subId} de la sourate ${tafsir?.attributes?.Titre}.`"
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/kathir_cb3b4e6bac.png"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container">
    <div class="sourate">
      <h1>
        Tafsir Ibn Kathir <br />
        {{ $t("versekathir") }}
        {{ tafsir?.attributes?.kathir_verses?.data[0]?.attributes?.numero }}
        {{ $t("ofkathir") }}<br />{{ tafsir?.attributes?.Titre }}
      </h1>
      <div class="selected">
        <el-select
          v-model="selectedKathirId"
          placeholder="Select"
          style="width: 240px"
          @change="onKathirChange"
        >
          <el-option
            v-for="kathir in kathirs"
            :key="kathir.id"
            :label="kathir.attributes.Titre"
            :value="kathir.id"
          />
        </el-select>

        <el-input-number
          v-model="numero"
          :min="1"
          :max="tafsir?.attributes?.nombre"
          style="width: 120px"
          class="mx-4"
          @change="onNumeroChange"
        />
      </div>
      <div class="sharing">
        <div class="icon" @click="openSharePopup">
          <Share />
        </div>
      </div>
      <div class="context c-down">
        <div
          ref="markdownBody"
          :id="tafsir?.attributes?.kathir_verses?.data[0]?.attributes?.numero"
          class="markdown-body"
          v-html="
            $md.render(
              String(
                tafsir?.attributes?.kathir_verses?.data[0]?.attributes?.contenu
              )
            )
          "
        ></div>
      </div>
      <div class="options-kathir sticky">
        <div class="box">
          <div class="left" @click="goPrev">
            <button class="back-k">
              <Left />
            </button>
          </div>
          <div class="middle">
            <router-link
              :to="`/sourate/${Number(currentId)}/#${Number(currentSubId)}`"
            >
              {{ $t("backverse") }}
            </router-link>
          </div>
          <div class="right" @click="goNext">
            <button class="next-k">
              <Right />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SharePopup
    :message="`${$t('kathirM1')} ${currentId} ${$t('kathirM2')} ${
      tafsir?.attributes?.Titre
    } ${$t('kathirM3')}.`"
    :pageUrl="`ibn-kathir/${currentId}/${currentSubId}`"
    ref="sharePopup"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Left from "../../components/icons/Left.vue";
import Right from "../../components/icons/Right.vue";
import Share from "../../components/icons/Share.vue";
import SharePopup from "../../components/global/Share.vue";
import { Head } from "@vueuse/head";

export default {
  name: "IbnKathir",
  components: { Left, Right, Share, SharePopup, Head },
  data() {
    return {
      numero: null,
      selectedKathirId: null,
    };
  },
  computed: {
    ...mapGetters("kathirs", ["tafsir", "kathirs"]),
    currentId() {
      return parseInt(this.$route.params.id, 10);
    },
    currentSubId() {
      return parseInt(this.$route.params.subId, 10) || 1;
    },
    computedPageUrl() {
      return `ibn-kathir/${this.$route.params.id}/${this.$route.params.subId}`;
    },
  },
  methods: {
    ...mapActions("kathirs", ["fetchTafsir", "fetchTafsirs"]),
    scrollToHash(hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    async updateTafsir() {
      let tafsirId = this.$route.params.id;
      const subId = this.$route.params.subId || this.numero;
      if (this.$i18n.locale === "en") {
        tafsirId = parseInt(tafsirId) + 114;
      }
      await this.fetchTafsir({
        id: tafsirId.toString(),
        num: subId,
        lang: this.$i18n.locale,
      });

      // Détection du texte en arabe dans les balises <p>
      this.$nextTick(() => {
        const markdownBody = this.$refs.markdownBody;
        if (markdownBody) {
          // Vérifiez si la référence est définie
          const paragraphs = markdownBody.querySelectorAll("p");
          paragraphs.forEach((paragraph) => {
            const htmlContent = paragraph.innerHTML;
            const newHtmlContent = htmlContent.replace(
              /([\u0600-\u06FF]+)/g,
              '<span class="arabic-text">$1</span>'
            );
            paragraph.innerHTML = newHtmlContent;
          });
        } else {
          console.error("Référence 'markdownBody' non trouvée.");
        }
      });
    },
    containsArabic(text) {
      const arabicRegex = /[\u0600-\u06FF]/; // Plage de caractères arabes
      return arabicRegex.test(text);
    },
    async goNext() {
      const currentId = this.currentId;
      const currentSubId = this.currentSubId;
      const maxNum = this.tafsir?.attributes?.nombre;

      if (currentSubId < maxNum) {
        this.$router.push(`/ibn-kathir/${currentId}/${currentSubId + 1}`);
      } else if (currentId !== 114) {
        this.$router.push(`/ibn-kathir/${currentId + 1}/1`);
      }
    },
    async goPrev() {
      const currentId = this.currentId;
      let currentSubId = this.currentSubId;

      if (currentSubId > 1) {
        this.$router.push(`/ibn-kathir/${currentId}/${currentSubId - 1}`);
      } else if (currentId > 1) {
        const newId = currentId - 1;
        await this.fetchTafsir({
          id: newId.toString(),
          num: null,
          lang: this.$i18n.locale,
        });
        const newMaxNum = this.tafsir?.attributes?.nombre;
        this.$router.push(`/ibn-kathir/${newId}/${newMaxNum}`);
      }
    },
    onKathirChange(newId) {
      // Navigue vers la nouvelle route lorsque l'utilisateur change la sélection
      this.$router.push(`/ibn-kathir/${newId}/1`);
    },
    onNumeroChange(newNumero) {
      // Navigue vers la nouvelle route lorsque l'utilisateur change le numéro
      this.$router.push(`/ibn-kathir/${this.currentId}/${newNumero}`);
    },
    openSharePopup() {
      // Utilisation de la référence pour appeler la méthode togglePopup du SharePopup
      if (this.$refs.sharePopup) {
        this.$refs.sharePopup.togglePopup();
      } else {
        console.error("Référence 'sharePopup' non trouvée.");
      }
    },
  },
  async created() {
    await this.fetchTafsirs(); // Assurez-vous que les kathirs sont chargés
    const idExists = this.kathirs.some(
      (kathir) => kathir.id === this.currentId
    );
    this.selectedKathirId = idExists ? this.currentId : null; // Initialise avec l'id de la route
    this.updateTafsir();
    this.numero = this.currentSubId; // Utilisez currentSubId pour s'assurer que 'numero' est bien synchronisé
  },
  watch: {
    "$i18n.locale"() {
      this.updateTafsir();
    },
    "$route.params.id"(newId) {
      this.selectedKathirId = parseInt(newId, 10);
      this.updateTafsir();
    },
    "$route.params.subId"(newSubId) {
      this.numero = parseInt(newSubId, 10) || 1;
      this.updateTafsir();
    },
    $route: {
      immediate: true,
      handler(to, from) {
        if (to.hash) {
          this.scrollToHash(to.hash);
        } else if (from && to.path !== from.path) {
          window.scrollTo(0, 0);
        }
      },
    },
  },
};
</script>

<style scoped>
.sourate {
  position: relative;
  padding: 60px 0 100px 0;
  display: block;
  margin: 70px auto 0px auto;
  width: 100%;
  min-height: 100vh;
  background: var(--card);
  border-radius: 6px;
  z-index: 1;
}

h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: var(--link);
}

.selected {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 20px 0;
  /* padding: 0 20px; */
}

.sharing {
  display: flex;
  justify-content: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--body);
  border-radius: 50%;
  cursor: pointer;
}

.c-down {
  max-width: 700px;
  min-height: 100vh;
  margin: 40px auto 0px auto;
}

.options-kathir {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: 0 auto 0 auto;
  min-height: 80px;
  max-width: 1400px;
  width: 100%;
  padding-top: 30px;
  background: var(--truncated);
}

.options-kathir .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  max-width: 600px;
  width: 100%;
  gap: 10px;
  padding: 0 20px;
}

.options-kathir .box .left,
.options-kathir .box .middle,
.options-kathir .box .right {
  display: flex;
  align-items: center;
}

.options-kathir .box .left button,
.options-kathir .box .right button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--card);
  transition: 0.2s ease;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.options-kathir .box .left button:hover,
.options-kathir .box .right button:hover {
  background: var(--button);
}

.options-kathir .box .middle a {
  display: flex;
  align-items: center;
  background: var(--color);
  color: var(--colorBtn);
  padding: 8px 20px;
  border-radius: 20px;
  transition: 0.2s ease;
  font-size: 15px;
  line-height: 0;
  height: 36px;
}

.options-kathir .box .middle a:hover {
  background: var(--button);
  color: #fff;
}

@media (max-width: 1300px) {
  .sourate {
    padding: 4rem;
  }

  .options-kathir {
    padding: 0 120px;
  }
}

@media (max-width: 900px) {
  .container {
    background: var(--card);
  }

  .sourate {
    padding: 50px 0 100px 0;
    margin-top: 0;
  }

  .options-kathir {
    padding: 0 0 5vh 0;
  }
}
</style>
