<template>
  <div>
    <div class="box-Comment">
      <div class="loading" v-if="isLoading">
        <Loading />
      </div>
      <div v-else>
        <div class="top">
          <img
            :src="`https://cp.doonun.com${
              avatarUrl || '/uploads/small_plant_f9f00dabd7.png'
            }`"
            alt="Avatar"
          />
          <textarea
            ref="contentTextarea"
            v-model="newCommentContent"
            type="text"
            :placeholder="$t('inputComment')"
            @input="autosize"
            :maxlength="maxCharacters"
          ></textarea>
        </div>

        <!-- Rendu de tous les médias via MediaRenderer, LinkPreview et ImageCarousel -->
        <div
          v-if="parsedLinks.length > 0 || newCommentImages.length > 0"
          class="medias"
        >
          <!-- Affichage du carousel si des images sont présentes -->
          <ImageCarousel
            v-if="newCommentImages.length > 0"
            :images="newCommentImages"
          />
          <LinkPreview
            v-for="(link, index) in previewLinks"
            :key="`preview-${index}`"
            :link="link.url"
          />
          <MediaRenderer
            v-for="(link, index) in mediaLinks"
            :key="`media-${index}`"
            :link="link.url"
            :domain="link.domain"
            :isVideo="link.isVideo"
            @open-modal="showModal"
            @click.prevent
          />
        </div>

        <div v-if="errorMessage" class="error">{{ errorMessage }}</div>

        <div class="submit">
          <div class="progress-container">
            <div class="uploads">
              <!-- Popover pour les Images -->
              <el-popover placement="bottom" :width="340" trigger="click">
                <template #reference>
                  <div class="images"><Images /></div>
                </template>
                <template #default>
                  <div
                    class="demo-rich-conent"
                    style="display: flex; gap: 16px; flex-direction: column"
                  >
                    <div class="input-list">
                      <div
                        class="input-item"
                        v-for="(image, index) in newCommentImages"
                        :key="index"
                      >
                        <input
                          type="text"
                          v-model="newCommentImages[index]"
                          :placeholder="$t('addImagesFII')"
                          @input="handleInput('images')"
                          :maxlength="maxCharactersImages"
                        />
                        <span
                          type="button"
                          class="add-btn"
                          @click="addImageLine(index)"
                          v-if="
                            index === newCommentImages.length - 1 &&
                            totalCharactersImages < maxCharactersImages
                          "
                        >
                          +
                        </span>
                        <span
                          type="button"
                          class="remove-btn"
                          @click="removeImageLine(index)"
                          v-if="newCommentImages.length > 1"
                        >
                          ×
                        </span>
                      </div>
                    </div>
                    <div
                      class="progress-circle"
                      v-if="newCommentImages.length > 0"
                    >
                      <svg viewBox="0 0 36 36">
                        <path
                          class="circle-bg"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path
                          class="circle"
                          :stroke-dasharray="progressImages + ', 100'"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                      </svg>
                    </div>
                  </div>
                </template>
              </el-popover>

              <!-- Bouton GIF -->
              <div class="gif" @click="openGifPopup"><Gif /></div>

              <!-- Popover pour les Liens -->
              <el-popover placement="bottom" :width="340" trigger="click">
                <template #reference>
                  <div class="liens"><Liens /></div>
                </template>
                <template #default>
                  <div
                    class="demo-rich-conent"
                    style="display: flex; gap: 16px; flex-direction: column"
                  >
                    <div class="input-list">
                      <div
                        class="input-item"
                        v-for="(link, index) in newCommentLinks"
                        :key="index"
                      >
                        <input
                          type="text"
                          v-model="newCommentLinks[index]"
                          :placeholder="$t('addLinksFii')"
                          @input="handleInput('links')"
                          :maxlength="maxCharactersLinks"
                        />
                        <span
                          type="button"
                          class="add-btn"
                          @click="addLinkLine(index)"
                          v-if="
                            index === newCommentLinks.length - 1 &&
                            totalCharactersLinks < maxCharactersLinks
                          "
                        >
                          +
                        </span>
                        <span
                          type="button"
                          class="remove-btn"
                          @click="removeLinkLine(index)"
                          v-if="newCommentLinks.length > 1"
                        >
                          ×
                        </span>
                      </div>
                    </div>
                    <div
                      class="progress-circle"
                      v-if="newCommentLinks.length > 0"
                    >
                      <svg viewBox="0 0 36 36">
                        <path
                          class="circle-bg"
                          d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path
                          class="circle"
                          :stroke-dasharray="progressLinks + ', 100'"
                          d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                      </svg>
                    </div>
                  </div>
                </template>
              </el-popover>

              <!-- Popover pour les Emojis -->
              <el-popover placement="bottom" :width="340" trigger="click">
                <template #reference>
                  <div class="emoji">
                    <Emoji />
                  </div>
                </template>
                <template #default>
                  <div
                    class="demo-rich-conent"
                    style="display: flex; gap: 16px; flex-direction: column"
                  >
                    <input
                      type="text"
                      v-model="searchQuery"
                      placeholder="Rechercher un emoji..."
                      class="emoji-search-input"
                    />
                    <!-- Liste filtrée des emojis -->
                    <div class="emoji-tooltip">
                      <span
                        v-for="(emoji, index) in filteredEmojis"
                        :key="index"
                        class="emoji-item"
                        @click="addEmoji(emoji.emoji)"
                      >
                        {{ emoji.emoji }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-popover>
            </div>
            <div class="right-btn">
              <div class="progress-circle" v-if="newCommentContent.length > 0">
                <svg viewBox="0 0 36 36">
                  <path
                    class="circle-bg"
                    d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path
                    class="circle"
                    :stroke-dasharray="progressContent + ', 100'"
                    d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                </svg>
              </div>
              <button @click="createNewComment" :disabled="!canComment">
                {{ $t("comment") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GifPopup ref="gifPopup" @gifSelected="addGifToComment" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MediaRenderer from "./MediaRenderer.vue";
import GifPopup from "./GifPopup.vue";
import ImageCarousel from "./ImageCarousel.vue";
import Gif from "../icons/fiiyd/Gif.vue";
import Emoji from "../icons/fiiyd/Emoji.vue";
import emojiData from "@/assets/js/emoji.json";
import Loading from "../navigation/Loading.vue";
import LinkPreview from "./LinkPreview.vue";
import Images from "../icons/fiiyd/Images.vue";
import Liens from "../icons/fiiyd/Liens.vue";
import { ref } from "vue";

export default {
  name: "InputComments",
  setup() {
    const visible = ref(false);
    return { visible };
  },
  components: {
    MediaRenderer,
    ImageCarousel,
    GifPopup,
    Gif,
    Emoji,
    Loading,
    Images,
    Liens,
    LinkPreview,
  },
  props: {
    postId: {
      type: Number,
      required: true,
    },
    postUser: {
      type: Number,
    },
    notif: Boolean,
  },
  data() {
    return {
      newCommentContent: "",
      newCommentLinks: [""], // Tableau de liens
      newCommentImages: [""],
      errorMessage: null,
      parsedLinks: [],
      maxCharacters: 320,
      maxCharactersImages: 400,
      maxCharactersLinks: 200,
      searchQuery: "",
      emojiList: emojiData,
      videoDomains: ["youtube.com", "youtu.be", "x.com"],
      isLoading: false,
      isModalOpen: false, // Pour le modal
      selectedImage: "",
    };
  },
  computed: {
    ...mapState("avatar", {
      avatarUrl: (state) => state.avatar,
    }),
    ...mapState("user", ["user"]),
    progressContent() {
      return Math.min(
        (this.newCommentContent.length / this.maxCharacters) * 100,
        100
      );
    },
    progressLinks() {
      return Math.min(
        (this.totalCharactersLinks / this.maxCharactersLinks) * 100,
        100
      );
    },
    progressImages() {
      return Math.min(
        (this.totalCharactersImages / this.maxCharactersImages) * 100,
        100
      );
    },
    filteredEmojis() {
      if (!this.searchQuery) {
        return this.emojiList;
      }
      const query = this.searchQuery.toLowerCase();
      return this.emojiList.filter(
        (emoji) =>
          emoji.emoji.includes(query) ||
          emoji.description.toLowerCase().includes(query) ||
          emoji.tags.some((tag) => tag.toLowerCase().includes(query)) ||
          emoji.aliases.some((alias) => alias.toLowerCase().includes(query))
      );
    },
    totalCharactersImages() {
      return this.newCommentImages.reduce((acc, curr) => acc + curr.length, 0);
    },
    totalCharactersLinks() {
      return this.newCommentLinks.reduce((acc, curr) => acc + curr.length, 0);
    },
    mediaLinks() {
      return this.parsedLinks.filter((link) => !link.isPreview);
    },
    previewLinks() {
      return this.parsedLinks.filter((link) => link.isPreview);
    },
    // Nouvelle propriété canPost qui vérifie si au moins un des champs est rempli
    canComment() {
      return (
        this.newCommentContent.trim().length > 0 ||
        this.newCommentLinks.some((link) => link.trim().length > 0) ||
        this.newCommentImages.some((image) => image.trim().length > 0)
      );
    },
  },
  watch: {
    newCommentLinks: "parseLinks",
    newCommentImages: "parseLinks",
    newCommentContent: "validateComment",
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);

    // Ajuster la taille initiale
    this.$nextTick(() => {
      if (this.$refs.contentTextarea) {
        this.autosize({ target: this.$refs.contentTextarea });
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    ...mapActions("fiiyd", ["createComment"]),
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("avatar", ["fetchAvatar"]),
    async createNewComment() {
      const commentData = {
        Content: this.newCommentContent,
        liens: this.newCommentLinks
          .filter((link) => link.trim() !== "")
          .join(" "),
        images: this.newCommentImages
          .filter((image) => image.trim() !== "")
          .join(" "),
        Like: 0,
        users_permissions_user: this.user.id,
        username: this.user.username,
        url: this.generateRandomUrl(),
        post: this.postId,
        owner: this.postUser,
        notif: this.notif,
      };

      try {
        await this.createComment(commentData);
        // console.log("Comment successfully created");
        this.newCommentContent = "";
        this.newCommentLinks = [""];
        this.newCommentImages = [""];
        this.errorMessage = null;
        this.parsedLinks = [];
        // Réinitialiser la hauteur du textarea
        this.$nextTick(() => {
          if (this.$refs.contentTextarea) {
            this.autosize({ target: this.$refs.contentTextarea });
          }
        });
        this.$emit("comment-added");
        // console.log("Comment added event emitted");
      } catch (error) {
        console.error("Erreur lors de la création d'un Comment:", error);
        this.errorMessage =
          error.response?.data?.error?.message ||
          "Erreur lors de la création d'un Comment.";
        // console.log("Error Message:", this.errorMessage);
      }
    },
    generateRandomUrl() {
      const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
      let url = "";
      for (let i = 0; i < 10; i++) {
        url += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return url;
    },
    parseLinks() {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".svg",
      ];
      const isImageLink = (url) =>
        imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));

      // Traiter newCommentLinks
      const links = this.newCommentLinks
        .flatMap((link) => link.match(/https?:\/\/[^\s]+/g) || [])
        .filter((link) => link.trim() !== "");

      // Traiter newCommentImages
      const imageLinks = this.newCommentImages
        .flatMap((image) => image.match(/https?:\/\/[^\s]+/g) || [])
        .filter((image) => image.trim() !== "");

      // Validation des liens
      for (const link of links) {
        if (isImageLink(link)) {
          this.errorMessage =
            'Les liens dans les "liens" ne doivent pas être des liens d\'images.';
          return;
        }
      }

      // Vérifiez que tous les liens d'images sont valides
      for (const link of imageLinks) {
        if (!isImageLink(link)) {
          this.errorMessage =
            'Tous les liens dans "images" doivent être des liens d\'images.';
          return;
        }
      }

      // Définir parsedLinks si tout est correct
      this.parsedLinks = [
        ...links.map((link) => {
          const match = link.match(/https?:\/\/(?:www\.)?([^/]+)/);
          const domain = match ? match[1] : "";
          const isVideo = this.videoDomains.some((videoDomain) =>
            domain.includes(videoDomain)
          );
          return { url: link, domain, isVideo, isPreview: false };
        }),
        ...imageLinks.map((link) => {
          const match = link.match(/https?:\/\/(?:www\.)?([^/]+)/);
          const domain = match ? match[1] : "";
          return { url: link, domain, isVideo: false, isPreview: false };
        }),
      ];

      // Ajouter un flag isPreview pour déterminer si le lien doit avoir une prévisualisation
      // Par exemple, vous pouvez décider de prévisualiser uniquement les liens non vidéo
      this.parsedLinks = this.parsedLinks.map((link) => ({
        ...link,
        isPreview: !link.isVideo && !this.isImageLink(link.url),
      }));

      // Réinitialiser le message d'erreur si tout est correct
      this.errorMessage = null;
    },
    isImageLink(url) {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".svg",
      ];
      return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));
    },

    validateContent() {
      const urlRegex = /https?:\/\/[^\s]+/g;
      const urls = this.newCommentContent.match(urlRegex);
      if (urls && urls.length > 0) {
        // Supprimer les URLs du contenu
        this.newCommentContent = this.newCommentContent.replace(urlRegex, "");
        this.errorMessage =
          "Les URLs ne sont pas autorisées dans le contenu du post et ont été supprimées.";
      } else {
        this.errorMessage = null;
      }
    },

    autosize(event) {
      const el = event.target;
      if (el) {
        el.style.height = "auto";
        el.style.height = `${el.scrollHeight}px`;
      }
    },

    openGifPopup() {
      this.$refs.gifPopup.openPopup();
    },
    addGifToComment(gifUrl) {
      // Rechercher un champ vide dans newCommentImages
      const emptyIndex = this.newCommentImages.findIndex(
        (image) => image.trim() === ""
      );

      if (emptyIndex !== -1) {
        // Si un champ vide est trouvé, y insérer l'URL du GIF
        // Utilisez splice pour remplacer l'élément à emptyIndex
        this.newCommentImages.splice(emptyIndex, 1, gifUrl);
      } else {
        // Sinon, ajouter une nouvelle entrée avec l'URL du GIF
        this.newCommentImages.push(gifUrl);
      }

      this.parseLinks(); // Mettre à jour les liens affichés
    },
    // Ajoute l'emoji sélectionné dans le contenu du post
    addEmoji(emoji) {
      // Insère l'emoji à la position actuelle du curseur
      const textarea = this.$refs.contentTextarea;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = this.newCommentContent;
      this.newCommentContent =
        text.substring(0, start) + emoji + text.substring(end);

      // Met à jour la position du curseur après l'insertion de l'emoji
      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = start + emoji.length;
        textarea.focus();
      });

      // Ferme le tooltip après la sélection d'un emoji
      this.showEmojiTooltip = false;
    },

    handleInput(type) {
      if (type === "images") {
        this.updateTotalCharactersImages();
        this.parseLinks(); // Appel explicite à parseLinks
      } else if (type === "links") {
        this.updateTotalCharactersLinks();
        this.parseLinks(); // Appel explicite à parseLinks
      }
    },

    addImageLine(index) {
      if (this.totalCharactersImages < this.maxCharactersImages) {
        this.newCommentImages.splice(index + 1, 0, "");
        this.parseLinks(); // Appel à parseLinks après ajout
      }
    },

    removeImageLine(index) {
      if (this.newCommentImages.length > 1) {
        this.newCommentImages.splice(index, 1);
        this.updateTotalCharactersImages();
        this.parseLinks(); // Appel à parseLinks après suppression
      }
    },

    // Méthodes pour les Liens
    addLinkLine(index) {
      if (this.totalCharactersLinks < this.maxCharactersLinks) {
        this.newCommentLinks.splice(index + 1, 0, "");
        this.parseLinks(); // Appel explicite à parseLinks après ajout
      }
    },

    removeLinkLine(index) {
      if (this.newCommentLinks.length > 1) {
        this.newCommentLinks.splice(index, 1);
        this.updateTotalCharactersLinks();
        this.parseLinks(); // Appel à parseLinks après suppression
      }
    },

    // Méthodes pour Mettre à Jour les Caractères Totaux
    updateTotalCharactersImages() {
      if (this.totalCharactersImages > this.maxCharactersImages) {
        this.errorMessage = `Le nombre total de caractères pour les images ne doit pas dépasser ${this.maxCharactersImages}.`;
      } else {
        this.errorMessage = null;
      }
    },

    updateTotalCharactersLinks() {
      if (this.totalCharactersLinks > this.maxCharactersLinks) {
        this.errorMessage = `Le nombre total de caractères pour les liens ne doit pas dépasser ${this.maxCharactersLinks}.`;
      } else {
        this.errorMessage = null;
      }
    },
  },
};
</script>

<style scoped>
.box-Comment {
  padding: 20px;
  background: var(--card);
  border-radius: 10px;
  outline: 1px solid var(--bgPoints);
}

.box-Comment .top {
  display: flex;
  gap: 10px;
}

.box-Comment .top img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

textarea {
  margin-top: 5px;
  width: 100%;
  border: 0;
  background: var(--card);
  min-height: 50px;
  max-height: 1400px;
  resize: none;
  font-size: 20px;
  font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, Helvetica;
  font-weight: 500;
  color: var(--text);
  overflow: hidden;
}

textarea::placeholder {
  color: var(--placeholder);
}

textarea:focus {
  outline: none;
  border: none;
}

.progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}

.input-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-item {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.input-item input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid var(--body);
  background: var(--body);
  color: var(--color);
  border-radius: 4px;
  width: 100%;
}

.input-item input::placeholder {
  color: var(--placeholder);
}

.add-btn,
.remove-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 2px 10px;
}

.add-btn {
  color: var(--button);
}

.remove-btn {
  color: #ff2222;
}

.add-btn:hover,
.remove-btn:hover {
  color: var(--color);
}

.uploads {
  display: flex;
  gap: 10px;
}

.right-btn {
  display: flex;
  gap: 20px;
  align-items: center;
}

.git {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-circle {
  width: 25px;
  height: 25px;
}

.progress-circle svg {
  width: 100%;
  height: 100%;
}

.circle-bg {
  fill: none;
  stroke: var(--bgPoints);
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 4.8;
  stroke: var(--button);
  stroke-linecap: round;
  transition: stroke-dasharray 0.35s;
  transform: rotate(-90deg);
  transform-origin: center;
}

.percentage {
  fill: #666;
  font-size: 0.5em;
  text-anchor: middle;
  dominant-baseline: middle;
}

button {
  display: block;
  cursor: pointer;
  padding: 18px 25px;
  background: var(--color);
  color: var(--colorBtn);
  font-weight: 600;
  font-size: 16px;
  line-height: 0;
  border-radius: 30px;
  transition: 0.2s ease;
  border: none;
}

button:hover {
  opacity: 0.9;
}

.error {
  color: red;
  margin-top: 10px;
}
.medias {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.medias img.img-Comment {
  object-fit: cover;
  object-position: center;
  width: calc(50% - 5px);
  max-width: 100%;
  /* max-height: 300px; */
  flex-grow: 1;
}

.medias img.img-Comment:nth-last-child(1):only-child {
  width: calc(100% - 3px);
}

.emoji-search-input {
  padding: 12px 20px;
  border: none;
  background: var(--body);
  border-radius: 10px;
  width: 90%;
  margin: 10px auto 0 auto;
}

.emoji-tooltip {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 10px 10px 10px;
  max-height: 300px;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
}

.emoji-item {
  font-size: 25px;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.2s;
}

.emoji-item:hover {
  transform: scale(1.2);
}
</style>
