// store/modules/google.js
import axios from "axios";

const state = {
  userInfo: null,
};

const getters = {
  getUserInfo: (state) => state.userInfo,
};

const mutations = {
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
};

const actions = {
  authenticateWithGoogle() {
    const googleAuthUrl = "https://cp.doonun.com/api/connect/google";
    window.location.href = googleAuthUrl;
  },

  async processGoogleAuthCallback({ commit }, searchParams) {
    try {
      const envToken = process.env.VUE_APP_API_STRAPI;
      // console.log("searchParams", searchParams);

      const response = await axios.get(
        `https://cp.doonun.com/api/auth/google/callback${searchParams}`,
        {
          headers: { Authorization: `Bearer ${envToken}` },
        }
      );

      // console.log("Réponse de Google:", response.data);

      if (response.data.jwt) {
        const token = response.data.jwt;
        localStorage.setItem("token", token);

        // Utiliser le module 'login' pour définir le token
        commit("login/SET_TOKEN", token, { root: true });

        // Définir le token par défaut pour les futures requêtes Axios
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        // Récupérer les informations utilisateur
        const userInfoResponse = await axios.get("/users/me", {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ["id", "username", "email"],
            populate: ["hassanate", "avatar", "ayahs", "livres", "douas"],
          },
        });

        // console.log("Informations utilisateur:", userInfoResponse.data);

        // Mettre à jour le state local de 'google.js'
        commit("SET_USER_INFO", userInfoResponse.data);

        // Transmettre les données utilisateur au module 'user.js'
        commit("user/SET_USER", userInfoResponse.data, { root: true });
      } else {
        // console.error("Token manquant dans la réponse:", response.data);
        throw new Error("Token manquant");
      }
    } catch (error) {
      console.error(
        "Erreur lors du traitement de l'authentification Google:",
        error
      );
      // Optionnel : Vous pouvez gérer l'état d'erreur ici ou dans le composant
      // Par exemple, émettre un événement ou utiliser un autre mécanisme de gestion d'erreurs
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
