<template>
  <div class="collapse">
    <div class="collapse-header" @click="toggle">
      <slot name="header">
        {{ titre }}
      </slot>
      <span class="toggle-icon">
        {{ isOpen ? "-" : "+" }}
      </span>
    </div>
    <transition name="collapse-transition">
      <div v-if="isOpen" class="collapse-body">
        <slot>
          {{ reponse }}
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "collapse-doonun",
  props: {
    titre: String,
    reponse: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.collapse {
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: var(--card);
  border-radius: 6px;
}

.collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 20px;
  cursor: pointer;
  font-weight: 500;
  gap: 10px;
}

.collapse-body {
  padding: 0 20px 20px 20px;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Transition pour l'ouverture et la fermeture */
.collapse-transition {
  overflow: hidden;
}

.collapse-transition-enter-active,
.collapse-transition-leave-active {
  transition: max-height 0.1s ease;
}

.collapse-transition-enter-from,
.collapse-transition-leave-to {
  max-height: 0;
}

.collapse-transition-enter-to,
.collapse-transition-leave-from {
  max-height: 500px;
}
</style>
