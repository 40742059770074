<template>
  <div>
    <div class="subs-container">
      <h2 id="scroll-target">{{ $t("titleAb") }}</h2>
      <div class="container-subscribe">
        <div class="box-mySub" v-if="hasSubscription">
          <h3 v-if="hasSubscription">{{ offerType }}</h3>
          <p v-if="hasSubscription">
            <i class="fa-regular fa-calendar-days"></i> {{ $t("actif") }}
            {{ date }}
          </p>
          <a
            href="https://billing.stripe.com/p/login/fZecOc8jS3ao4HS7ss"
            target="_blank"
            v-if="hasSubscription && oneTime == false"
          >
            <div class="manag-sub">
              <span>{{ $t("gereSub") }}</span>
            </div>
          </a>
        </div>

        <div class="list-billings" v-if="!hasSubscription">
          <div class="tarif month">
            <p>
              <span>{{ $t("priceplanmonth") }}</span> 🍂 {{ $t("offerM") }}
            </p>
            <button
              type="button"
              class="SS_ProductCheckout"
              :data-id="8"
              :data-email="userEmail"
              @click="handleStripeCheckout"
            >
              <span v-if="loading[8]">
                <Loading />
              </span>
              <span v-else>{{ $t("btnSub1") }}</span>
            </button>
          </div>
          <div class="tarif year">
            <p>
              <span>{{ $t("priceplanyear") }}</span>
              <span class="annuelle">-36%</span> 🍂 {{ $t("offerY") }}
            </p>
            <button
              type="button"
              class="SS_ProductCheckout"
              :data-id="9"
              :data-email="userEmail"
              @click="handleStripeCheckout"
            >
              <span v-if="loading[9]">
                <Loading />
              </span>
              <span v-else>{{ $t("btnSub1") }}</span>
            </button>
          </div>
          <div class="tarif life">
            <p>
              <span>{{ $t("priceplanlife") }}</span> 🍂 {{ $t("offerL") }}
            </p>
            <button
              type="button"
              class="SS_ProductCheckout"
              :data-id="3"
              :data-email="userEmail"
              @click="handleStripeCheckout"
            >
              <span v-if="loading[3]">
                <Loading />
              </span>
              <span v-else>{{ $t("btnSub2") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../navigation/Loading.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "user-subs",
  components: {
    Loading,
  },
  data() {
    return {
      userEmail: "",
      loading: {},
      token: localStorage.getItem("token"),
    };
  },
  computed: {
    ...mapGetters("subscribe", ["subscriptionDetails", "isSubscribed"]),
    ...mapState("user", ["user"]),
    hasSubscription() {
      return this.isSubscribed;
    },
    offerType() {
      return this.subscriptionDetails.offerType;
    },
    date() {
      return this.subscriptionDetails.date;
    },
    oneTime() {
      return this.subscriptionDetails.isOneTime;
    },
  },
  async created() {
    try {
      await this.fetchUser();
    } catch {
      console.log("error info user");
    }
  },
  async mounted() {
    this.loadStripeScript();
    this.fetchUserEmail();
    if (this.userEmail) {
      await this.checkSubscriptionStatus();
    }
  },
  methods: {
    ...mapActions("subscribe", ["checkSubscriptionStatus"]),
    ...mapActions("user", ["fetchUser"]),
    loadStripeScript() {
      if (
        !document.querySelector(
          'script[src="https://cp.doonun.com/plugins/strapi-stripe/static/stripe.js"]'
        )
      ) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://cp.doonun.com/plugins/strapi-stripe/static/stripe.js";
        script.onload = this.initializeStripeButton;
        document.head.appendChild(script);
      }
    },

    handleStripeCheckout(event) {
      const button = event.currentTarget;
      const planId = button.getAttribute("data-id");
      const userEmail = button.getAttribute("data-email");
      this.loading[planId] = true;

      axios
        .get(
          `https://cp.doonun.com/strapi-stripe/getRedirectUrl/${planId}/${userEmail}`
        )
        .then((response) => {
          if (response.data && response.data.url) {
            window.location.href = response.data.url;
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la redirection vers Stripe:", error);
          this.loading[planId] = false;
        });
    },

    async fetchUserEmail() {
      if (this.user) {
        try {
          this.userEmail = this.user.email;
          // Mettre à jour l'e-mail de l'utilisateur dans le store
          this.$store.commit("subscribe/SET_USER_EMAIL", this.userEmail);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des informations de l'utilisateur:",
            error
          );
        }
      }
    },

    convertTimestampToDate(timestamp) {
      let date = new Date(timestamp);
      return `${date.getDate()} ${date.toLocaleString("default", {
        month: "long",
      })} ${date.getFullYear()}`;
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.subs-container {
  display: block;
  max-width: 600px;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .container-subscribe {
    margin-top: 30px;
  }
}

.container-subscribe .btn-g {
  margin: 20px 0;
}

.sugg {
  margin-bottom: 20px;
}

.list-billings {
  margin-top: 20px;
}

.list-billings .tarif {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--card);
  border-radius: 6px;
  padding: 10px 10px 10px 20px;
  margin-bottom: 10px;
  color: var(--text);
  gap: 20px;
  outline: 1px solid var(--text);
}

.list-billings .tarif p {
  font-size: 14px;
}

.list-billings .tarif span {
  font-size: 14px;
  font-weight: 600;
}

.list-billings .tarif p span {
  font-size: 14px;
  font-weight: 700;
}

.list-billings .tarif button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: var(--colorBtn);
  background: var(--color);
  padding: 10px 10px;
  border-radius: 6px;
  min-width: 90px;
  max-height: 34px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;
}

.list-billings .tarif button span {
  font-size: 12px;
}

.list-billings .tarif button:hover {
  background: var(--button);
  color: #fff;
}

.annuelle {
  background: #ff3358;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
}

.box-mySub {
  margin-top: 10px;
  position: relative;
  padding: 20px;
  background: rgb(253, 196, 72);
  background: linear-gradient(
    45deg,
    rgba(253, 196, 72, 1) 0%,
    rgba(255, 212, 117, 1) 100%
  );
  border-radius: 8px;
  color: #000;
}

.box-mySub h3 {
  color: #000 !important;
}

.box-mySub p {
  font-weight: 600;
}

.manag-sub {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  transition: 0.2s ease;
  font-size: 14px;
}

.manag-sub:hover {
  background: var(--button);
}
</style>
