<template>
  <div class="lists">
    <div class="category-filters">
      <!-- Bouton "Tous" -->
      <button
        v-if="categories"
        :key="'all'"
        :class="['category-btn', { active: selectedCategory === 'Tous' }]"
        @click="$emit('category-change', 'Tous')"
      >
        {{ $t("allMovies") }}
      </button>

      <button
        v-for="category in categories"
        :key="category.id"
        :class="[
          'category-btn',
          { active: selectedCategory === category?.attributes?.titre },
        ]"
        @click="$emit('category-change', category?.attributes?.titre)"
      >
        {{ category?.attributes?.titre }}
      </button>
    </div>
    <Slider :cible="'category-filters'" />
  </div>
</template>

<script>
import Slider from "../global/Slider";

export default {
  name: "CategoryFilters",
  components: { Slider },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    selectedCategory: {
      type: String,
      default: "Tous",
    },
  },
};
</script>

<style scoped>
.lists {
  margin: 30px 0;
  position: relative;
}
.category-filters {
  display: flex;
  justify-content: start;
  gap: 10px;
  overflow-x: auto;
  scrollbar-width: none;
}

.category-btn {
  padding: 0.5rem 1.1rem;
  border-radius: 20px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.category-btn.active {
  background: white;
  color: black;
}

.category-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.category-btn.active:hover {
  background: white;
}

@media (max-width: 1000px) {
  .category-filters {
    justify-content: start;
  }
}
</style>
