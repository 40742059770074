<template>
  <div class="settings-icon-fii">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="#000000"
      fill="none"
    >
      <path
        d="M11.9959 12H12.0049"
        stroke="currentColor"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.9998 12H18.0088"
        stroke="currentColor"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99981 12H6.00879"
        stroke="currentColor"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "settings-fii",
};
</script>

<style scoped>
.settings-icon-fii {
  display: flex;
  align-items: center;
  transition: 0.2s ease;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.settings-icon-fii svg {
  width: 20px;
  height: 20px;
}

.settings-icon-fii:hover {
  background: var(--card);
}

.settings-icon-fii svg path {
  stroke: var(--color) !important;
}

.settings-icon-fii:hover svg path {
  stroke: var(--color) !important;
}
</style>
