import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
import { extractTags } from "@/utils/tagUtils";

const state = {
  posts: [],
  comments: [],
  fetchedPost: null,
  tags: [],
  trendingTags: [],
  taggedContent: [],
  fiiyds: [],
  postsUser: [],
};

const getters = {
  getPosts: (state) => state.posts,
  getPostsUser: (state) => state.postsUser,
  getComments: (state) => state.comments,
  getTrendingTags: (state) => state.trendingTags,
  getFiiydsCount: (state) => state.fiiyds.length,
};

const mutations = {
  SET_POSTS(state, posts) {
    posts.sort(
      (a, b) =>
        new Date(b.attributes.publishedAt) - new Date(a.attributes.publishedAt)
    );

    state.posts = posts.map((post) => {
      const user = post.attributes.users_permissions_user?.data;
      const avatarUrl =
        user?.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes
          ?.formats?.small?.url || null;

      const likesCount = post.attributes.users_posts_favs?.data?.length || 0;

      // Calcul du nombre total de commentaires et de réponses
      const totalComments = post.attributes.comments.data.reduce(
        (sum, comment) =>
          sum + 1 + (comment.attributes.answers?.data?.length || 0),
        0
      );

      return {
        IdPost: post.id,
        Content: post.attributes.Content,
        images: post.attributes.images,
        liens: post.attributes.liens,
        enable_notif: post.attributes.enable_notif,
        content_sensitive: post.attributes.sensible,
        enable_comment: post.attributes.enable_comment,
        like: likesCount,
        fav: post.attributes.users_posts_favs?.data,
        vues: Number(post.attributes.vues) || 0,
        url: post.attributes.url,
        time: moment(post.attributes.publishedAt).fromNow(),
        comments: post.attributes.comments,
        totalComments: totalComments,
        avatar: avatarUrl,
        idUsername: user ? user.id : null,
        username: user ? user.attributes.username : "Anonymous",
      };
    });
  },
  SET_POSTS_USER(state, postsUser) {
    postsUser.sort(
      (a, b) =>
        new Date(b.attributes.publishedAt) - new Date(a.attributes.publishedAt)
    );

    state.postsUser = postsUser.map((post) => {
      const user = post.attributes.users_permissions_user?.data;
      const avatarUrl =
        user?.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes
          ?.formats?.small?.url || null;

      const likesCount = post.attributes.users_posts_favs?.data?.length || 0;

      // Calcul du nombre total de commentaires et de réponses
      const totalComments = post.attributes.comments.data.reduce(
        (sum, comment) =>
          sum + 1 + (comment.attributes.answers?.data?.length || 0),
        0
      );

      return {
        IdPost: post.id,
        Content: post.attributes.Content,
        images: post.attributes.images,
        liens: post.attributes.liens,
        enable_notif: post.attributes.enable_notif,
        content_sensitive: post.attributes.sensible,
        enable_comment: post.attributes.enable_comment,
        like: likesCount,
        fav: post.attributes.users_posts_favs?.data,
        vues: Number(post.attributes.vues) || 0,
        url: post.attributes.url,
        time: moment(post.attributes.publishedAt).fromNow(),
        comments: post.attributes.comments,
        totalComments: totalComments,
        avatar: avatarUrl,
        idUsername: user ? user.id : null,
        username: user ? user.attributes.username : "Anonymous",
      };
    });
  },

  SET_POST(state, { post, comments }) {
    const user = post.attributes.users_permissions_user?.data;
    const avatarUrl =
      user?.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes
        ?.formats?.small?.url;

    const likesCount = post.attributes.users_posts_favs?.data?.length || 0;

    // Fonction pour formater les commentaires
    const formatComment = (comment) => {
      const commentLikesCount =
        comment.attributes.users_comments_favs?.data?.length || 0;

      return {
        IdComment: comment.id,
        Content: comment.attributes.Content,
        images: comment.attributes.images,
        liens: comment.attributes.liens,
        enable_notif: comment.attributes.enable_notif,
        content_sensitive: comment.attributes.sensible,
        enable_comment: comment.attributes.enable_comment,
        vues: Number(comment.attributes.vues),
        like: commentLikesCount,
        fav: comment.attributes.users_comments_favs?.data,
        url: comment.attributes.url,
        avatar:
          comment.attributes.users_permissions_user.data.attributes?.avatar
            ?.data?.attributes?.Avatar?.data?.attributes?.formats?.small?.url,
        answers: comment.attributes.answers.data.length,
        username:
          comment.attributes.users_permissions_user.data.attributes?.username,
        time: comment.attributes.publishedAt
          ? moment(comment.attributes.publishedAt).fromNow()
          : "Temps inconnu", // Gestion du cas où publishedAt est indéfini
      };
    };

    // Assurez-vous que comments.data est défini avant de le mapper
    const formattedComments =
      comments && comments.data
        ? comments.data.map(formatComment).reverse()
        : [];

    // Calcul du nombre total de commentaires et de réponses
    const totalComments = formattedComments.reduce(
      (sum, comment) => sum + 1 + (comment.answers || 0),
      0
    );

    state.fetchedPost = {
      IdPost: post.id,
      Content: post.attributes.Content,
      images: post.attributes.images,
      liens: post.attributes.liens,
      like: likesCount,
      fav: post.attributes.users_posts_favs?.data,
      enable_notif: post.attributes.enable_notif,
      content_sensitive: post.attributes.sensible,
      enable_comment: post.attributes.enable_comment,
      vues: Number(post.attributes.vues) || 0,
      url: post.attributes.url,
      time: moment(post.attributes.publishedAt).fromNow(),
      avatar: avatarUrl,
      idUsername: user ? user?.id : null,
      username: user ? user.attributes.username : "Anonymous",
      comments: formattedComments, // Utilisation des commentaires formatés
      totalComments: totalComments,
    };
  },

  SET_COMMENT(state, { comment, answers }) {
    const user = comment.attributes.users_permissions_user?.data;

    const formatAnswers = (answers) => {
      const answersLikesCount =
        answers.attributes.users_comments_favs?.data?.length || 0;
      return {
        IdComment: answers.id,
        Content: answers.attributes.Content,
        images: answers.attributes.images,
        liens: answers.attributes.liens,
        vues: Number(answers.attributes.vues),
        like: answersLikesCount,
        fav: answers.attributes.users_comments_favs?.data,
        url: answers.attributes.url,
        enable_notif: answers.attributes.enable_notif,
        content_sensitive: answers.attributes.sensible,
        enable_comment: answers.attributes.enable_comment,
        avatar:
          answers.attributes.users_permissions_user.data.attributes?.avatar
            ?.data?.attributes?.Avatar?.data?.attributes?.formats?.small?.url,
        username:
          answers.attributes.users_permissions_user.data.attributes?.username,
        time: answers.attributes.publishedAt
          ? moment(answers.attributes.publishedAt).fromNow()
          : "Temps inconnu",
      };
    };

    const formattedAnswers =
      answers && answers.data ? answers.data.map(formatAnswers).reverse() : [];

    state.fetchedComment = {
      IdComment: comment.id,
      Content: comment.attributes.Content,
      images: comment.attributes.images,
      liens: comment.attributes.liens,
      enable_notif: comment.attributes.enable_notif,
      content_sensitive: comment.attributes.sensible,
      enable_comment: comment.attributes.enable_comment,
      vues: Number(comment.attributes.vues),
      like: Number(comment.attributes.users_comments_favs?.data?.length),
      fav: comment.attributes.users_comments_favs?.data,
      avatar:
        comment.attributes.users_permissions_user.data.attributes?.avatar?.data
          ?.attributes?.Avatar?.data?.attributes?.formats?.small?.url,
      url: comment.attributes.url,
      time: moment(comment.attributes.publishedAt).fromNow(),
      username: user ? user.attributes.username : "Anonymous",
      idUsername: user ? user?.id : null,
      answers: formattedAnswers,
    };
  },

  SET_COMMENTS(state, comments) {
    state.comments = comments.map((comment) => {
      const attributes = comment.attributes || {};
      const likesCount = attributes.users_comments_favs?.data?.length || 0;

      return {
        IdComment: comment.id,
        Content: attributes.Content || "",
        liens: attributes.liens,
        images: attributes.images,
        enable_notif: attributes.enable_notif,
        content_sensitive: attributes.sensible,
        enable_comment: attributes.enable_comment,
        avatar:
          attributes.users_permissions_user.data.attributes?.avatar?.data
            ?.attributes?.Avatar?.data?.attributes?.formats?.small?.url,
        vues: Number(attributes.vues),
        like: likesCount,
        fav: attributes.users_comments_favs?.data,
        url: attributes.url || "",
        username:
          attributes.users_permissions_user.data.attributes?.username ||
          "John Doe",
        time: attributes.publishedAt
          ? moment(attributes.publishedAt).fromNow()
          : "Temps inconnu", // Vérifiez ici
        created_by_id: attributes.users_permissions_user?.data?.id || null,
      };
    });
  },

  ADD_POST(state, post) {
    const user = post.attributes?.users_permissions_user?.data;

    state.posts.push({
      IdPost: post.id,
      Content: post.attributes.Content,
      vues: post.attributes.vues,
      url: post.attributes.url,
      liens: post.attributes.liens,
      images: post.attributes.images,
      time: moment(post.attributes.publishedAt).fromNow(),
      idUsername: user ? user.id : null,
      username: user ? user.attributes.username : "Anonymous",
      comments: post.attributes.comments || { data: [] },
      created_by_id: user ? user.id : null,
    });
  },

  ADD_COMMENT(state, comment) {
    const attributes = comment.attributes || {};

    state.comments.push({
      IdComment: comment.id,
      Content: attributes.Content || "",
      liens: attributes.liens,
      images: attributes.images,
      url: attributes.url || "",
      username: attributes.username || "John Doe",
      publishedAt: moment(attributes.publishedAt).fromNow(),
      created_by_id: attributes.users_permissions_user?.data?.id || null,
    });
  },

  ADD_ANSWER(state, comment) {
    const attributes = comment.attributes || {};

    state.comments.push({
      IdComment: comment.id,
      Content: attributes.Content || "",
      liens: attributes.liens,
      images: attributes.images,
      url: attributes.url || "",
      username: attributes.username || "John Doe",
      publishedAt: moment(attributes.publishedAt).fromNow(),
      created_by_id: attributes.users_permissions_user?.data?.id || null,
    });
  },

  ADD_TAG(state, tag) {
    state.tags = state.tags || [];
    state.tags.push({
      id: tag.id,
      name: tag.attributes.name,
    });
  },

  UPDATE_POST_VIEWS(state, { postId, newViews }) {
    const post = state.posts.find((p) => p.IdPost === postId);
    if (post) {
      post.vues = newViews;
    }
    if (state.fetchedPost && state.fetchedPost.IdPost === postId) {
      state.fetchedPost.vues = newViews;
    }
  },
  UPDATE_COMMENT_VIEWS(state, { commentId, newViews }) {
    const comment = state.comments.find((p) => p.IdComment === commentId);
    if (comment) {
      comment.vues = newViews;
    }
    if (state.fetchedComment && state.fetchedComment.IdComment === commentId) {
      state.fetchedComment.vues = newViews;
    }
  },
  SET_TRENDING_TAGS(state, trendingTags) {
    state.trendingTags = trendingTags; // Mettez à jour l'état avec les tags tendance
  },
  SET_TAGGED_CONTENT(state, taggedContent) {
    state.taggedContent = taggedContent; // Stocke le contenu taggé
  },
  SET_FIIYDS(state, fiiyds) {
    state.fiiyds = fiiyds;
  },
  SET_FIIYDS_READ(state, fiiyds) {
    state.fiiyds = state.fiiyds.map((fiiyd) => {
      const updatedFiiyd = fiiyds.find((f) => f.id === fiiyd.id);
      if (updatedFiiyd) {
        return { ...fiiyd, read: true }; // Mettez à jour le champ read à true
      }
      return fiiyd;
    });
  },
  UPDATE_POST_SETTINGS(state, { postId, updatedFields }) {
    const post = state.posts.find((p) => p.IdPost === postId);
    if (post) {
      Object.assign(post, updatedFields);
    }
    if (state.fetchedPost && state.fetchedPost.IdPost === postId) {
      Object.assign(state.fetchedPost, updatedFields);
    }
  },

  UPDATE_COMMENT_SETTINGS(state, { commentId, updatedFields }) {
    const comment = state.comments.find((c) => c.IdComment === commentId);
    if (comment) {
      Object.assign(comment, updatedFields);
    }
    if (state.fetchedComment && state.fetchedComment.IdComment === commentId) {
      Object.assign(state.fetchedComment, updatedFields);
    }
  },

  // Mutation pour supprimer un poste
  DELETE_POST(state, postId) {
    state.posts = state.posts.filter((post) => post.IdPost !== postId);
    if (state.fetchedPost && state.fetchedPost.IdPost === postId) {
      state.fetchedPost = null;
    }
  },

  // Mutation pour supprimer un commentaire
  DELETE_COMMENT(state, commentId) {
    state.comments = state.comments.filter(
      (comment) => comment.IdComment !== commentId
    );
    if (state.fetchedComment && state.fetchedComment.IdComment === commentId) {
      state.fetchedComment = null;
    }
  },

  // Mutation pour supprimer plusieurs commentaires
  DELETE_COMMENTS(state, commentIds) {
    state.comments = state.comments.filter(
      (comment) => !commentIds.includes(comment.IdComment)
    );
  },

  // Mutation pour supprimer plusieurs réponses
  DELETE_ANSWERS(state, answerIds) {
    state.comments = state.comments.map((comment) => {
      if (comment.answers && comment.answers.length) {
        return {
          ...comment,
          answers: comment.answers.filter(
            (answer) => !answerIds.includes(answer.IdComment)
          ),
        };
      }
      return comment;
    });
  },

  DELETE_FIIYDS(state, fiiydIds) {
    state.fiiyds = state.fiiyds.filter((fiiyd) => !fiiydIds.includes(fiiyd.id));
  },
};

const actions = {
  async fetchPostsUser({ commit, dispatch }, userId) {
    try {
      const response = await axios.get("/posts", {
        params: {
          fields: [
            "id",
            "Content",
            "vues",
            "liens",
            "url",
            "images",
            "publishedAt",
            "enable_comment",
            "enable_notif",
            "sensible",
          ],
          filters: {
            users_permissions_user: {
              id: {
                $eq: userId, // Filtrer les posts par ID de l'utilisateur
              },
            },
          },
          populate: {
            comments: {
              fields: ["id"],
              populate: {
                answers: {
                  fields: ["id"],
                },
                users_permissions_user: {
                  fields: ["id", "username"],
                  populate: {
                    avatar: {
                      fields: ["id", "Avatar"],
                      populate: {
                        Avatar: {
                          fields: ["formats"],
                        },
                      },
                    },
                  },
                },
              },
            },
            users_posts_favs: {
              fields: ["id"],
            },
            users_permissions_user: {
              fields: ["id", "username"],
              populate: {
                avatar: {
                  fields: ["id", "Avatar"],
                  populate: {
                    Avatar: {
                      fields: ["formats"],
                    },
                  },
                },
              },
            },
          },
        },
      });
      // console.log("posts user : ", response.data.data);

      commit("SET_POSTS_USER", response.data.data);
      dispatch("fetchTrendingTags");
    } catch (error) {
      console.error("Erreur lors de la récupération des posts:", error);
    }
  },
  async fetchPosts({ commit, dispatch }) {
    try {
      const response = await axios.get("/posts", {
        params: {
          fields: [
            "id",
            "Content",
            "vues",
            "liens",
            "url",
            "images",
            "publishedAt",
            "enable_comment",
            "enable_notif",
            "sensible",
          ],
          populate: {
            comments: {
              fields: ["id"],
              populate: {
                answers: {
                  fields: ["id"],
                },
                users_permissions_user: {
                  fields: ["id", "username"],
                  populate: {
                    avatar: {
                      fields: ["id", "Avatar"],
                      populate: {
                        Avatar: {
                          fields: ["formats"],
                        },
                      },
                    },
                  },
                },
              },
            },
            users_posts_favs: {
              fields: ["id"],
            },
            users_permissions_user: {
              fields: ["id", "username"],
              populate: {
                avatar: {
                  fields: ["id", "Avatar"],
                  populate: {
                    Avatar: {
                      fields: ["formats"],
                    },
                  },
                },
              },
            },
          },
        },
      });
      commit("SET_POSTS", response.data.data);
      dispatch("fetchTrendingTags");
    } catch (error) {
      console.error("Erreur lors de la récupération des posts:", error);
    }
  },

  async fetchTrendingTags({ commit }) {
    try {
      // Récupérer tous les tags avec les informations de posts et de commentaires
      const response = await axios.get("/tags?populate=*");

      // Compter les posts et commentaires pour chaque tag
      const tagCounts = response.data.data.map((tag) => {
        const postsCount = tag.attributes.posts.data.length;
        const commentsCount = tag.attributes.comments.data.length;
        return {
          id: tag.id,
          name: tag.attributes.tags,
          totalCount: postsCount + commentsCount, // Somme des posts et commentaires
        };
      });

      // Trier les tags par totalCount décroissant et prendre les 8 premiers
      const trendingTags = tagCounts
        .sort((a, b) => b.totalCount - a.totalCount)
        .slice(0, 8);
      // console.log("trendingTags : ", trendingTags);

      // Engager la mutation pour mettre à jour l'état avec les tags tendance
      commit("SET_TRENDING_TAGS", trendingTags);
    } catch (error) {
      console.error("Erreur lors de la récupération des tags tendance:", error);
    }
  },

  async fetchTaggedContent({ commit }, tagSlug) {
    try {
      const response = await axios.get("/tags", {
        params: {
          filters: {
            tags: {
              $eq: tagSlug,
            },
          },
          fields: ["tags", "id"],
          populate: {
            posts: {
              fields: [
                "id",
                "Content",
                "images",
                "vues",
                "liens",
                "enable_comment",
                "enable_notif",
                "sensible",
                "url",
                "publishedAt",
              ],
              populate: {
                users_permissions_user: {
                  fields: ["id", "username"],
                  populate: {
                    avatar: {
                      fields: ["id", "Avatar"],
                      populate: {
                        Avatar: {
                          fields: ["formats"],
                        },
                      },
                    },
                  },
                },
                users_posts_favs: {
                  fields: ["id"],
                },
                comments: {
                  fields: ["id"],
                  populate: {
                    answers: {
                      fields: ["id"],
                    },
                  },
                },
              },
            },
            comments: {
              fields: [
                "id",
                "Content",
                "images",
                "vues",
                "liens",
                "enable_comment",
                "enable_notif",
                "sensible",
                "url",
                "publishedAt",
              ],
              populate: {
                users_permissions_user: {
                  fields: ["id", "username"],
                  populate: {
                    avatar: {
                      fields: ["id", "Avatar"],
                      populate: {
                        Avatar: {
                          fields: ["formats"],
                        },
                      },
                    },
                  },
                },
                users_comments_favs: {
                  fields: ["id"],
                },
                answers: {
                  fields: ["id"],
                },
              },
            },
          },
        },
      });

      const posts = response.data.data[0].attributes.posts;
      const comments = response.data.data[0].attributes.comments;

      const formattedPosts = posts.data.map((post) => {
        const comments = post.attributes.comments || { data: [] };
        const totalComments =
          comments.data.length +
          comments.data.reduce((sum, comment) => {
            return sum + (comment.answers?.data.length || 0);
          }, 0);

        return {
          IdPost: post.id,
          Content: post.attributes.Content,
          images: post.attributes.images,
          liens: post.attributes.liens,
          avatar:
            post.attributes.users_permissions_user?.data?.attributes?.avatar
              ?.data?.attributes?.Avatar?.data?.attributes?.formats?.small
              ?.url || null,
          username:
            post.attributes.users_permissions_user?.data?.attributes?.username,
          fav: post.attributes.users_posts_favs?.data,
          likes: post.attributes.users_posts_favs?.data?.length || 0,
          vues: Number(post.attributes.vues) || 0,
          url: post.attributes.url,
          time: moment(post.attributes.publishedAt).locale("fr").fromNow(),
          publishedAt: post.attributes.publishedAt,
          comments: totalComments,
          isComment: false,
        };
      });

      const formattedComments = comments.data.map((comment) => ({
        IdComment: comment.id,
        Content: comment.attributes.Content,
        images: comment.attributes.images,
        liens: comment.attributes.liens,
        username:
          comment.attributes.users_permissions_user?.data?.attributes?.username,
        avatar:
          comment.attributes.users_permissions_user?.data?.attributes?.avatar
            ?.data?.attributes?.Avatar?.data?.attributes?.formats?.small?.url ||
          null,
        likes: comment.attributes.users_comments_favs?.data?.length,
        fav: comment.attributes.users_comments_favs?.data,
        comments: comment.attributes.answers?.data.length,
        vues: Number(comment.attributes.vues) || 0,
        url: comment.attributes.url,
        publishedAt: comment.attributes.publishedAt,
        time: moment(comment.attributes.publishedAt).locale("fr").fromNow(),
        isComment: true,
      }));

      const taggedContent = [...formattedPosts, ...formattedComments];
      // console.log("taggedContent :", taggedContent);

      commit("SET_TAGGED_CONTENT", taggedContent);
    } catch (error) {
      console.error("Erreur lors de la récupération du contenu taggé:", error);
    }
  },

  async fetchPostByUrl({ commit, dispatch }, slug) {
    try {
      const response = await axios.get("/posts", {
        params: {
          filters: {
            url: {
              $eq: slug,
            },
          },
          fields: [
            "id",
            "Content",
            "vues",
            "images",
            "liens",
            "url",
            "enable_comment",
            "enable_notif",
            "sensible",
            "publishedAt",
            "url",
          ],
          populate: {
            users_posts_favs: {
              fields: ["id"],
            },
            users_permissions_user: {
              fields: ["id", "username"],
              populate: {
                avatar: {
                  fields: ["id", "Avatar"],
                  populate: {
                    Avatar: {
                      fields: ["formats"],
                    },
                  },
                },
              },
            },
            comments: {
              fields: [
                "id",
                "username",
                "Content",
                "images",
                "vues",
                "liens",
                "enable_comment",
                "enable_notif",
                "sensible",
                "url",
                "publishedAt",
              ],
              populate: {
                users_permissions_user: {
                  fields: ["id", "username"],
                  populate: {
                    avatar: {
                      fields: ["id", "Avatar"],
                      populate: {
                        Avatar: {
                          fields: ["formats"],
                        },
                      },
                    },
                  },
                },
                users_comments_favs: {
                  fields: ["id"],
                },
                answers: {
                  fields: ["id"],
                  populate: {
                    users_permissions_user: {
                      fields: ["id", "username"],
                      populate: {
                        avatar: {
                          fields: ["id", "Avatar"],
                          populate: {
                            Avatar: {
                              fields: ["formats"],
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });
      if (response.data.data.length) {
        const post = response.data.data[0];
        const comments = post.attributes.comments;
        commit("SET_POST", { post, comments });

        // Récupérer l'ID du post et les vues actuelles
        const postId = post.id;
        const currentViews = Number(post.attributes.vues) || 0;

        if (postId !== undefined) {
          // Incrémenter les vues
          dispatch("updatePostViews", { postId, currentViews });
        } else {
          console.error("ID du post est undefined");
        }
      } else {
        // console.log("Aucun post trouvé avec l'URL fournie");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du post:", error);
    }
  },

  async createPost({ commit, dispatch }, postData) {
    try {
      // Extraire les tags du contenu
      const tags = extractTags(postData.Content);
      const tagIds = [];

      // Pour chaque tag, fetch ou créez-le et récupérez son ID
      for (const tag of tags) {
        const tagId = await dispatch("fetchOrCreateTag", tag);
        tagIds.push(tagId);
      }

      // Inclure les IDs des tags dans les données du post
      postData.tags = tagIds; // Assurez-vous que votre API accepte ce champ
      postData.created_by_id = postData.userId;

      // Récupérer le token de l'utilisateur depuis le localStorage
      const token = localStorage.getItem("token");

      const response = await axios.post(
        "/posts",
        { data: postData }, // Wrapping postData in a data object
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("ADD_POST", response.data.data);
      dispatch("fetchTrendingTags");
    } catch (error) {
      console.error(
        "Erreur lors de la création d'un post:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  async fetchComments({ commit, dispatch }) {
    try {
      const response = await axios.get("/comments", {
        params: {
          fields: [
            "id",
            "Content",
            "vues",
            "liens",
            "enable_comment",
            "enable_notif",
            "sensible",
            "images",
            "publishedAt",
            "url",
          ],
          populate: {
            answers: {
              fields: [
                "id",
                "username",
                "Content",
                "images",
                "liens",
                "vues",
                "url",
                "enable_comment",
                "enable_notif",
                "sensible",
                "publishedAt",
              ],
              populate: {
                users_permissions_user: {
                  fields: ["id", "username"],
                  populate: {
                    avatar: {
                      fields: ["id", "Avatar"],
                      populate: {
                        Avatar: {
                          fields: ["formats"],
                        },
                      },
                    },
                  },
                },
              },
            },
            users_comments_favs: {
              fields: ["id"],
            },
            users_permissions_user: {
              fields: ["id", "username"],
              populate: {
                avatar: {
                  fields: ["id", "Avatar"],
                  populate: {
                    Avatar: {
                      fields: ["formats"],
                    },
                  },
                },
              },
            },
          },
        },
      });
      commit("SET_COMMENTS", response.data);
      dispatch("fetchTrendingTags");
    } catch (error) {
      console.error("Erreur lors de la récupération des commentaires:", error);
    }
  },

  async fetchCommentByUrl({ commit, dispatch }, slug) {
    try {
      const response = await axios.get("/comments", {
        params: {
          filters: {
            url: {
              $eq: slug,
            },
          },
          fields: [
            "id",
            "Content",
            "images",
            "vues",
            "liens",
            "enable_comment",
            "enable_notif",
            "sensible",
            "url",
            "publishedAt",
          ],
          populate: {
            users_permissions_user: {
              fields: ["id", "username"],
              populate: {
                avatar: {
                  fields: ["id", "Avatar"],
                  populate: {
                    Avatar: {
                      fields: ["formats"],
                    },
                  },
                },
              },
            },
            users_comments_favs: {
              fields: ["id"],
            },
            answers: {
              fields: [
                "id",
                "Content",
                "images",
                "vues",
                "liens",
                "enable_comment",
                "enable_notif",
                "sensible",
                "url",
                "publishedAt",
              ],
              populate: {
                users_comments_favs: {
                  fields: ["id"],
                },
                users_permissions_user: {
                  fields: ["id", "username"],
                  populate: {
                    avatar: {
                      fields: ["id", "Avatar"],
                      populate: {
                        Avatar: {
                          fields: ["formats"],
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });
      if (response.data.data.length) {
        const comment = response.data.data[0];
        const answers = comment.attributes.answers;
        commit("SET_COMMENT", { comment, answers });
        // Récupérer l'ID du commentaire et les vues actuelles
        const commentId = comment.id;
        const currentViews = Number(comment.attributes.vues) || 0;

        if (commentId !== undefined) {
          // Incrémenter les vues
          // console.log("comment ID : ", commentId, "currentViews : ", comment);

          dispatch("updateCommentViews", { commentId, currentViews });
        } else {
          console.error("ID du commentaire est undefined");
        }
        dispatch("fetchTrendingTags");
      } else {
        // console.log("Aucun commentaire trouvé avec l'URL fournie");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du commentaire:", error);
    }
  },

  async createComment({ commit, dispatch, rootGetters }, commentData) {
    const username = rootGetters["user/username"];
    const userId = rootGetters["user/userId"];
    try {
      // Extraire les tags du contenu
      const tags = extractTags(commentData.Content);
      const tagIds = [];

      for (const tag of tags) {
        const tagId = await dispatch("fetchOrCreateTag", tag);
        tagIds.push(tagId);
      }

      commentData.tags = tagIds;
      commentData.created_by_id = commentData.userId;

      const token = localStorage.getItem("token");

      const response = await axios.post(
        "/comments",
        { data: commentData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("Etat notif : ", commentData.notif);

      commit("ADD_COMMENT", response.data.data);
      if (commentData.owner !== userId && commentData.notif === true) {
        // Créer une notification Fiiyd
        await axios.post("/fiiyds", {
          data: {
            commentaire: true,
            name_user: username,
            owner: commentData.owner,
            posts: commentData.post,
          },
        });
      }

      dispatch("fetchTrendingTags");
    } catch (error) {
      console.error("Erreur lors de la création d'un commentaire:", error);
      throw error;
    }
  },

  async createAnswer({ commit, dispatch, rootGetters }, commentData) {
    const username = rootGetters["user/username"];
    const userId = rootGetters["user/userId"];
    try {
      const tags = extractTags(commentData.Content);
      const tagIds = [];

      for (const tag of tags) {
        const tagId = await dispatch("fetchOrCreateTag", tag);
        tagIds.push(tagId);
      }

      commentData.tags = tagIds;
      commentData.created_by_id = commentData.userId;

      const token = localStorage.getItem("token");

      const response = await axios.post(
        "/comments",
        { data: commentData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      commit("ADD_ANSWER", response.data.data);
      if (commentData.owner !== userId && commentData.notif === true) {
        // Créer une notification Fiiyd
        await axios.post("/fiiyds", {
          data: {
            commentaire: true,
            name_user: username,
            owner: commentData.owner,
            comment: commentData.comment,
          },
        });
      }

      dispatch("fetchTrendingTags", "fetchCommentByUrl");
    } catch (error) {
      console.error("Erreur lors de la création d'un commentaire:", error);
      throw error;
    }
  },
  async updatePostSettings({ commit }, { postId, updatedFields }) {
    try {
      await axios.put(`/posts/${postId}`, {
        data: updatedFields,
      });

      // console.log("mise à jour boolean : ", response);

      commit("UPDATE_POST_SETTINGS", { postId, updatedFields });
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des paramètres du post:",
        error
      );
    }
  },

  async updateCommentSettings({ commit }, { commentId, updatedFields }) {
    try {
      await axios.put(`/comments/${commentId}`, {
        data: updatedFields,
      });

      // console.log("update comments : ", response);

      commit("UPDATE_COMMENT_SETTINGS", { commentId, updatedFields });
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des paramètres du commentaire:",
        error
      );
    }
  },
  async fetchOrCreateTag({ commit }, tagName) {
    try {
      // Rechercher le tag en ignorant la casse
      const response = await axios.get("/tags", {
        params: {
          filters: {
            tags: {
              $eqi: tagName, // Utilisez $eqi pour une comparaison insensible à la casse
            },
          },
        },
      });

      if (response.data.data.length > 0) {
        // Tag existe déjà
        return response.data.data[0].id;
      } else {
        // Tag n'existe pas, le créer
        const createResponse = await axios.post("/tags", {
          data: {
            tags: tagName,
          },
        });
        commit("ADD_TAG", createResponse.data.data); // Optionnel : si vous gérez les tags dans le state
        return createResponse.data.data.id;
      }
    } catch (error) {
      console.error(`Erreur lors de la gestion du tag "${tagName}":`, error);
      throw error;
    }
  },

  async updatePostViews({ commit }, { postId, currentViews }) {
    try {
      await axios.put(`/posts/${postId}`, {
        data: {
          vues: Number(currentViews) + 1, // Incrémenter les vues
        },
      });
      // Mettre à jour l'état local avec le nouveau nombre de vues
      commit("UPDATE_POST_VIEWS", { postId, newViews: currentViews + 1 });
      // console.log("response vues : ", response);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des vues du post:", error);
    }
  },
  async updateCommentViews({ commit }, { commentId, currentViews }) {
    try {
      await axios.put(`/comments/${commentId}`, {
        data: {
          vues: Number(currentViews) + 1, // Incrémenter les vues
        },
      });
      // Mettre à jour l'état local avec le nouveau nombre de vues
      commit("UPDATE_COMMENT_VIEWS", { commentId, newViews: currentViews + 1 });
    } catch (error) {
      console.error("Erreur lors de la mise à jour des vues du post:", error);
    }
  },
  async fetchFiiyds({ commit, rootGetters }) {
    const userId = rootGetters["user/userId"];
    try {
      const response = await axios.get("/fiiyds", {
        params: {
          filters: {
            owner: {
              $eq: userId,
            },
            read: {
              $eq: false,
            },
          },
          populate: {
            owner: {
              fields: ["id"],
              populate: {
                fiiyds: {
                  fields: ["id"],
                },
              },
            },
            posts: {
              fields: [
                "id",
                "Content",
                "vues",
                "liens",
                "images",
                "publishedAt",
                "url",
              ],
              populate: {
                users_permissions_user: {
                  fields: ["id", "username"],
                  populate: {
                    avatar: {
                      fields: ["id", "Avatar"],
                      populate: {
                        Avatar: {
                          fields: ["formats"],
                        },
                      },
                    },
                  },
                },
                comments: {
                  fields: ["id"],
                },
              },
            },
            comment: {
              fields: [
                "id",
                "Content",
                "vues",
                "liens",
                "images",
                "publishedAt",
                "url",
              ],
              populate: {
                users_permissions_user: {
                  fields: ["id", "username"],
                  populate: {
                    avatar: {
                      fields: ["id", "Avatar"],
                      populate: {
                        Avatar: {
                          fields: ["formats"],
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });
      // console.log("response fiiyds : ", response.data.data); // Vérifiez ici
      commit("SET_FIIYDS", response.data.data.reverse());
      // console.log(
      //   "fiiydsCount après mise à jour : ",
      //   response.data.data.length
      // );
    } catch (error) {
      console.error("Erreur lors de la récupération des Fiiyds:", error);
    }
  },
  async markFiiydsAsRead({ commit, rootGetters }) {
    const userId = rootGetters["user/userId"];
    try {
      // Récupérer les fiiyds de l'utilisateur
      const response = await axios.get("/fiiyds", {
        params: {
          filters: {
            owner: {
              $eq: userId,
            },
            read: {
              $eq: false,
            },
          },
        },
      });

      // Mettre à jour les fiiyds pour marquer comme lus
      const fiiyds = response.data.data;

      // Appel API pour mettre à jour le champ read à true
      await Promise.all(
        fiiyds.map((fiiyd) =>
          axios.put(`/fiiyds/${fiiyd.id}`, {
            data: {
              read: true,
            },
          })
        )
      );

      // Commit la mutation pour mettre à jour le state
      // console.log("fiiyds update : ", fiiyds);

      commit("SET_FIIYDS_READ", fiiyds);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des Fiiyds:", error);
    }
  },
  async deletePost({ commit, dispatch }, postId) {
    try {
      const token = localStorage.getItem("token");

      // Récupérer le post avec ses commentaires
      const postResponse = await axios.get(`/posts/${postId}`, {
        params: {
          populate: {
            comments: {
              fields: ["id"],
              populate: {
                answers: {
                  fields: ["id"],
                },
              },
            },
          },
        },
      });

      const post = postResponse.data.data;

      if (post) {
        // Récupérer les IDs des commentaires
        const commentIds = post.attributes.comments.data.map(
          (comment) => comment.id
        );

        // Récupérer les Fiiyds associés au post
        const fiiydResponse = await axios.get(`/fiiyds`, {
          params: {
            filters: {
              posts: {
                id: {
                  $eq: postId,
                },
              },
            },
          },
        });
        const fiiydIds = fiiydResponse.data.data.map((fiiyd) => fiiyd.id);

        // Supprimer les réponses
        const answerIds = post.attributes.comments.data.flatMap((comment) =>
          comment.attributes.answers.data.map((answer) => answer.id)
        );
        if (answerIds.length > 0) {
          await Promise.all(
            answerIds.map((answerId) =>
              axios.delete(`/comments/${answerId}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
            )
          );
          commit("DELETE_ANSWERS", answerIds);
        }

        // Supprimer les commentaires
        if (commentIds.length > 0) {
          await Promise.all(
            commentIds.map((commentId) =>
              axios.delete(`/comments/${commentId}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
            )
          );
          commit("DELETE_COMMENTS", commentIds);
        }

        // Supprimer les Fiiyds associés
        if (fiiydIds.length > 0) {
          await Promise.all(
            fiiydIds.map((fiiydId) =>
              axios.delete(`/fiiyds/${fiiydId}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
            )
          );
          commit("DELETE_FIIYDS", fiiydIds);
        }

        // Supprimer le post lui-même
        await axios.delete(`/posts/${postId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        commit("DELETE_POST", postId);

        // Actualiser les tags tendance
        dispatch("fetchTrendingTags", "fetchPostByUrl");
      } else {
        console.warn(`Post avec l'ID ${postId} non trouvé.`);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la suppression du post et de ses commentaires/réponses:",
        error
      );
    }
  },

  async deleteComment({ commit, dispatch }, commentId) {
    try {
      const token = localStorage.getItem("token");

      // Récupérer le commentaire avec ses réponses
      const commentResponse = await axios.get(`/comments/${commentId}`, {
        params: {
          populate: {
            answers: {
              fields: ["id"],
            },
          },
        },
      });

      const comment = commentResponse.data.data;

      if (comment) {
        // Récupérer les IDs des réponses
        const answerIds = comment.attributes.answers.data.map(
          (answer) => answer.id
        );

        // Récupérer les Fiiyds associés au commentaire
        const fiiydResponse = await axios.get(`/fiiyds`, {
          params: {
            filters: {
              comment: {
                id: {
                  $eq: commentId,
                },
              },
            },
          },
        });
        const fiiydIds = fiiydResponse.data.data.map((fiiyd) => fiiyd.id);

        // Supprimer les réponses
        if (answerIds.length > 0) {
          await Promise.all(
            answerIds.map((answerId) =>
              axios.delete(`/comments/${answerId}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
            )
          );
          commit("DELETE_ANSWERS", answerIds);
        }

        // Supprimer le commentaire lui-même
        await axios.delete(`/comments/${commentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        commit("DELETE_COMMENT", commentId);

        // Supprimer les Fiiyds associés
        if (fiiydIds.length > 0) {
          await Promise.all(
            fiiydIds.map((fiiydId) =>
              axios.delete(`/fiiyds/${fiiydId}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
            )
          );
          commit("DELETE_FIIYDS", fiiydIds);
        }

        // Actualiser les tags tendance
        dispatch("fetchTrendingTags", "fetchCommentByUrl");
      } else {
        console.warn(`Commentaire avec l'ID ${commentId} non trouvé.`);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la suppression du commentaire et de ses réponses:",
        error
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
