// store/modules/resume.js

import axios from "axios";

const state = {
  checks: [],
  lastVersetDetails: null, // Stocker les détails du dernier verset
};

const getters = {
  resume: (state) => state.checks,
  lastVersetDetails: (state) => state.lastVersetDetails,
};

const mutations = {
  SET_CHECKS(state, checks) {
    state.checks = checks;
  },
  SET_LAST_VERSET_DETAILS(state, verset) {
    state.lastVersetDetails = verset;
  },
};

const actions = {
  /**
   * Récupère les vérifications des versets pour l'utilisateur actuel,
   * détermine le dernier verset lu et récupère ses détails.
   *
   * @param {Object} context - Contexte de l'action Vuex
   * @param {String} lang - Langue pour la locale
   */
  async fetchChecks({ commit, rootGetters }, lang) {
    try {
      const userId = rootGetters["user/userId"];
      // console.log("User ID:", userId);

      // Première requête : Récupérer les vérifications des versets
      const response = await axios.get("/versets-checks", {
        params: {
          locale: lang,
          fields: ["id", "id_user", "id_sourate", "list_verset", "taux"],
          filters: { id_user: { $eq: userId } },
          sort: ["updatedAt:desc"],
          pagination: { limit: 1 },
          populate: {
            sourate: {
              fields: [
                "Arabe",
                "Numero",
                "Nombre",
                "Phonetique",
                "Revelation",
                "Titre",
              ],
              locale: lang,
            },
          },
        },
      });

      // Log de la réponse complète
      // console.log("Réponse serveur (checks) :", response);
      // Log des données récupérées
      // console.log("Données récupérées (checks) :", response.data.data);

      const data = response.data.data;
      if (Array.isArray(data) && data.length > 0) {
        const check = data[0].attributes;
        const idSourate = check.id_sourate;
        const listVerset = check.list_verset
          .split("|")
          .map(Number)
          .sort((a, b) => a - b);
        const lastVersetNumber = listVerset[listVerset.length - 1];
        check.lastVersetNumber = lastVersetNumber;

        // Deuxième requête : Récupérer les détails du dernier verset basé sur id_sourate et numero
        const versetResponse = await axios.get("/ayahs", {
          params: {
            filters: {
              sourate: { $eq: idSourate },
              numero: { $eq: lastVersetNumber },
            },
            fields: ["id", "fr", "numero"],
            locale: lang,
            pagination: { limit: 1 },
          },
        });

        // Log des détails du dernier verset
        // console.log("Détails du dernier verset :", versetResponse.data.data);
        const versetData = versetResponse.data.data;

        if (Array.isArray(versetData) && versetData.length > 0) {
          const lastVerset = versetData[0].id;
          const lastVersetFr = versetData[0].attributes.fr;

          check.lastVerset = lastVerset;
          check.lastVersetFr = lastVersetFr;

          // Commit des détails du dernier verset
          commit("SET_LAST_VERSET_DETAILS", versetData[0]);
        } else {
          // Aucun verset trouvé pour les critères spécifiés
          check.lastVerset = null;
          check.lastVersetFr = null;
          commit("SET_LAST_VERSET_DETAILS", null);
        }
      } else {
        // Aucun check trouvé
        commit("SET_LAST_VERSET_DETAILS", null);
      }

      commit("SET_CHECKS", data || []);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des vérifications :",
        error
      );
      commit("SET_CHECKS", []);
      commit("SET_LAST_VERSET_DETAILS", null);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
