<template>
  <Head>
    <title>Accueil - Doonun</title>

    <!-- Meta Description -->
    <meta
      name="description"
      content="Découvrez le Coran en ligne gratuitement sur notre plateforme. Apprenez le Tafsir des sourates, suivez des cours de sciences islamiques et explorez le Coran en arabe et français avec audio. Renforcez votre foi avec les invocations islamiques et la Citadelle du musulman. Rejoignez-nous pour approfondir vos connaissances islamiques."
    />

    <!-- Robots -->
    <meta name="robots" content="index, follow" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.doonun.com/" />
    <meta property="og:title" content="Accueil - Doonun" />
    <meta
      property="og:description"
      content="Découvrez le Coran en ligne gratuitement sur notre plateforme. Apprenez le Tafsir des sourates, suivez des cours de sciences islamiques et explorez le Coran en arabe et français avec audio. Renforcez votre foi avec les invocations islamiques et la Citadelle du musulman. Rejoignez-nous pour approfondir vos connaissances islamiques."
    />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/doonun_00407a689c.png"
    />

    <!-- Twitter -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.doonun.com/" />
    <meta name="twitter:title" content="Accueil - Doonun" />
    <meta
      name="twitter:description"
      content="Découvrez le Coran en ligne gratuitement sur notre plateforme. Apprenez le Tafsir des sourates, suivez des cours de sciences islamiques et explorez le Coran en arabe et français avec audio. Renforcez votre foi avec les invocations islamiques et la Citadelle du musulman. Rejoignez-nous pour approfondir vos connaissances islamiques."
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/doonun_00407a689c.png"
    />

    <!-- Canonical URL -->
    <link rel="canonical" href="https://www.doonun.com/" />

    <!-- Additional SEO Tags -->
    <meta
      name="keywords"
      content="Coran en ligne, Cours islamique, Tafsir Ibn Kathir, Cours en ligne, Communauté, Classements, Témoignages, FAQ, Doonun"
    />

    <!-- Language -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>
  <div class="logout" v-if="!isAuthenticated">
    <Header />
    <div class="container">
      <div class="top-title">
        <h2>{{ $t("titlerank") }}</h2>
        <router-link to="/classement" class="link-arrow">
          <Arrow />
        </router-link>
      </div>

      <div class="ranks">
        <CardRankingTop />
      </div>
    </div>

    <div class="why">
      <Why />
    </div>

    <div class="tarifs">
      <div class="container">
        <Tarifs />
      </div>
    </div>

    <div class="container avis">
      <h2>{{ $t("titleavis") }}</h2>
      <div class="lists">
        <div class="lists-avis">
          <div class="item" v-for="testi in allTestimonials" :key="testi.id">
            <CardAvis
              :avis="testi?.attributes?.Avis"
              :username="
                testi?.attributes?.users_permissions_user?.data?.attributes
                  ?.username
              "
              :img="
                testi?.attributes?.users_permissions_user?.data?.attributes
                  ?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats
                  ?.small?.url || '/uploads/small_plant_f9f00dabd7.png'
              "
              :note="testi?.attributes?.Note"
            />
          </div>
        </div>
        <Slider :cible="'lists-avis'" />
      </div>
    </div>
    <div>
      <div class="container faq">
        <h2>{{ $t("faq") }}</h2>
        <div class="lists-faq" v-for="faq in allFaqs" :key="faq.id">
          <Collapse
            :titre="faq.attributes.titre"
            :reponse="faq.attributes.reponse"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container login" v-if="isAuthenticated">
    <div class="container-app">
      <div class="left">
        <div class="news__articles" v-if="articleLast">
          <div class="box-articles">
            <div class="post">
              <CardPost
                :key="articleLast.id"
                :lien="`${articleLast.attributes.categories.data[0].attributes.url}/${articleLast.attributes.url}`"
                :img="getImageUrl(articleLast.attributes.Cover)"
                :titre="articleLast.attributes.titre"
                :categorie="
                  articleLast.attributes.categories.data[0].attributes.titre
                "
                :date="formatDate(articleLast.attributes.publishedAt)"
              />
            </div>
          </div>
        </div>

        <div class="courses">
          <div class="top-course">
            <h2>{{ $t("titlecours") }}</h2>
            <router-link to="/cours">
              <Arrow />
            </router-link>
          </div>
          <div class="lists">
            <div class="list-courses">
              <div class="item" v-for="cours in lastCourses" :key="cours.id">
                <CardCourse
                  :id="cours?.id"
                  :titre="cours?.attributes?.titre"
                  :points="cours?.attributes?.points"
                  :gain="cours?.attributes?.gain"
                  :level="cours?.attributes?.niveau"
                  :url="cours?.attributes?.url"
                  :category="cours?.attributes?.Category"
                  :percent="
                    cours?.attributes?.suivis?.data[0]?.attributes?.pourcentage
                  "
                  :publishedAt="cours?.attributes?.publishedAt"
                  :cover="
                    cours?.attributes?.Couverture?.data?.attributes?.formats
                      .small.url
                  "
                  @unlock="unlock"
                />
              </div>
            </div>
            <Slider :cible="'list-courses'" />
          </div>
        </div>
        <div class="sourates">
          <div class="top-coran">
            <h2>{{ $t("titlecoran") }}</h2>
            <router-link to="/coran">
              <Arrow />
            </router-link>
          </div>
          <div class="lists">
            <div class="list-sourate">
              <div
                class="item"
                v-for="sourate in mostViewedSourates"
                :key="sourate?.id"
              >
                <CardCoran
                  :Titre="sourate?.attributes?.Titre"
                  :Numero="sourate?.attributes?.Numero"
                  :Arabe="sourate?.attributes?.Arabe"
                  :Phonetique="sourate?.attributes?.Phonetique"
                  :Revelation="sourate?.attributes?.Revelation"
                  :Nombre="sourate?.attributes?.Nombre"
                  :like="
                    sourate?.attributes?.like_sourate?.data?.attributes?.likes
                  "
                  :vues="sourate?.attributes?.vues || 0"
                  :fav="isLiked(sourate?.attributes.Numero)"
                  :taux="
                    sourate?.attributes?.versets_checks?.data[0]?.attributes
                      ?.taux || 0
                  "
                />
              </div>
            </div>
            <Slider :cible="'list-sourate'" />
          </div>
        </div>
      </div>
      <div class="right">
        <Ranklist />
        <Charity />
        <div class="calendar">
          <div>
            <Hijri />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { Head } from "@vueuse/head";
import Arrow from "../components/icons/Arrow.vue";
import Header from "../components/logout/Header.vue";
import Why from "../components/logout/Why.vue";
import Tarifs from "../components/logout/Tarifs.vue";
import CardAvis from "../components/logout/CardAvis.vue";
import Collapse from "../components/logout/Collapse.vue";
import Slider from "../components/global/Slider";
import Tabs from "../components/global/Tabs.vue";
import CardPost from "../components/articles/CardPost.vue";
import CardCourse from "../components/cours/CardCourse.vue";
import CardCoran from "../components/coran/CardCoran.vue";
import Ranklist from "../components/ranking/Ranklist.vue";
import Charity from "../components/global/Charity.vue";
import CardRankingTop from "../components/ranking/CardRankingTop.vue";
import Hijri from "../components/hours/Hijri.vue";
// import Loading from "../navigation/Loading.vue";
import moment from "moment";
import "moment/locale/fr";

export default {
  name: "home-doonun",
  components: {
    Header,
    Ranklist,
    Arrow,
    Why,
    Tarifs,
    CardAvis,
    Collapse,
    Slider,
    Tabs,
    CardRankingTop,
    CardPost,
    CardCourse,
    CardCoran,
    Hijri,
    Charity,
    Head,
    // Loading,
  },
  data() {
    return {
      userEmail: "",
      loadingSubscription: {}, // Renommé pour éviter les conflits
      token: localStorage.getItem("token"),
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("articles", ["articleLast"]),
    ...mapGetters("cours", ["lastCourses"]),
    ...mapGetters("sourate", ["mostViewedSourates"]),
    ...mapGetters("testimonials", ["allTestimonials"]),
    ...mapGetters("faq", ["allFaqs"]),
    ...mapGetters("like", ["likes"]),
    likedSourates() {
      return this.isAuthenticated && Array.isArray(this.likes.data)
        ? this.likes.data.map((like) => like.attributes.id_sourate)
        : [];
    },
  },
  methods: {
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("points", ["updateUserPoints"]),
    ...mapActions("articles", ["fetchLatestArticle"]),
    ...mapActions("cours", ["fetchLastCourses"]),
    ...mapActions("sourate", ["fetchMostViewedSourates"]),
    ...mapActions("like", ["fetchLikes"]),
    ...mapActions("testimonials", ["fetchTestimonials"]),
    ...mapActions("faq", ["fetchFaqs"]),

    getImageUrl(cover) {
      if (
        cover &&
        cover.data &&
        cover.data.attributes &&
        cover.data.attributes.url
      ) {
        return cover.data.attributes.url;
      }
      return "/default-image.jpg";
    },

    formatDate(date) {
      return moment(date).locale("fr").fromNow();
    },

    async unlock() {
      try {
        await this.fetchLastCourses(this?.$i18n?.locale);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    },
    isLiked(numero) {
      return this.likedSourates.includes(String(numero));
    },
  },
  async created() {
    this.fetchTestimonials();
    this.fetchFaqs(this?.$i18n?.locale);
    try {
      await this.fetchUser();
      if (this.isAuthenticated) {
        if (!this.user.hassanate) {
          this.updateUserPoints(0);
          // console.log("hassanate crée");
        }
        await this.fetchLatestArticle(this?.$i18n?.locale);
        await this.fetchLastCourses(this?.$i18n?.locale);
        this.fetchMostViewedSourates(this?.$i18n?.locale);
        await this.fetchLikes();
      } else {
        // console.log("vous avez déjà des hassanates");
      }
    } catch (error) {
      console.error("Erreur lors de la création du composant home:", error);
    }
  },

  watch: {
    "$i18n.locale"() {
      this.fetchLatestArticle(this?.$i18n?.locale);
      this.fetchLastCourses(this?.$i18n?.locale);
      this.fetchMostViewedSourates(this?.$i18n?.locale);
      this.fetchFaqs(this?.$i18n?.locale);
    },
  },
};
</script>

<style scoped>
.login,
.logout {
  padding-top: 40px;
}
.container {
  display: block;
  margin: 0 auto;
  padding-bottom: 30px;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 20px 0;
}

.top-title h2 {
  font-weight: 500;
}

.top-title .link-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--card);
  border-radius: 50%;
}

.ranks {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 10px;
}

.why {
  margin-top: 40px;
}

.tarifs {
  margin-top: 80px;
}

.avis {
  margin-top: 80px;
}

.avis h2 {
  text-wrap: balance;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  font-size: clamp(20px, 8vw, 30px);
  white-space: break-spaces;
  text-align: center;
}

.slider-course,
.slider-sourate {
  display: none;
}

@media (max-width: 1540px) {
  .slider-course,
  .slider-sourate {
    margin-top: 20px;
    display: block;
  }
}

@media (max-width: 960px) {
  .slider,
  .slider-course,
  .slider-sourate {
    display: none !important;
  }
}

.lists-avis {
  margin-top: 50px;
  /* width: 100%; */
  overflow-x: auto;
  scrollbar-width: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: column;
  align-items: stretch;
  gap: 1rem;
}

.lists-avis::-webkit-scrollbar {
  display: none !important;
}

.lists-avis .item {
  flex: 0 0 30%;
  height: 100%;
}

.faq h2 {
  margin-top: 80px;
  margin-bottom: 50px;
  text-wrap: balance;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  font-size: clamp(20px, 8vw, 30px);
  white-space: break-spaces;
  text-align: center;
}

.lists-faq {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.container-app {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-bottom: 100px;
}

.container-app .left {
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 65%;
  flex: 1 1 70%;
}

.container-app .left h2 {
  text-wrap: balance;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  font-size: clamp(20px, 8vw, 23px);
  white-space: break-spaces;
  margin-bottom: 30px;
}

.container-app .left .top-course,
.container-app .left .top-coran {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-app .left .top-course a,
.container-app .left .top-coran a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s ease;
  border: 1px solid transparent;
}

.container-app .left .top-course a:hover,
.container-app .left .top-coran a:hover {
  background: var(--card);
}

.container-app .right {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  gap: 20px;
  width: 30%;
}

@media (max-width: 1200px) {
  .container-app .left {
    width: 55%;
  }
  .container-app .right {
    width: 35%;
  }
}

@media (max-width: 992px) {
  .container-app .left,
  .container-app .right {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .container-app {
    flex-direction: column;
  }
}

.box-articles {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  display: flex;
  align-items: stretch;
  gap: 1rem;
}

.box-articles .post {
  min-width: 250px;
  flex: 1 1 25%;
}

.post-slider {
  padding-top: 15px;
}

.lists {
  position: relative;
}

.courses,
.sourates {
  display: flex;
  flex-direction: column;
}

.list-courses,
.list-sourate {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  display: flex;
  gap: 0.8rem;
  min-height: 200px;
  max-height: 370px;
}

.list-courses .item,
.list-sourate .item {
  min-width: 240px;
  flex: 1 1 25%;
}

.calendar {
  width: 100%;
  padding: 0 20px 20px 20px;
  background: var(--card);
  border-radius: 6px;
}

@media (max-width: 800px) {
  .list-courses .item,
  .list-sourate .item {
    min-width: 270px;
  }
}
</style>
