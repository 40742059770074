// store/modules/note.js
import axios from "axios";

const state = {
  userNotes: [], // Notes spécifiques à l'utilisateur
};

const getters = {
  userNotes: (state) => state.userNotes,
  userNoteById: (state) => (id) =>
    state.userNotes.find((note) => note.id === id),
};

const mutations = {
  SET_USER_NOTES(state, notes) {
    state.userNotes = notes;
  },
  ADD_USER_NOTE(state, note) {
    state.userNotes.push(note);
  },
  UPDATE_USER_NOTE(state, updatedNote) {
    const index = state.userNotes.findIndex(
      (note) => note.id === updatedNote.id
    );
    if (index !== -1) {
      state.userNotes.splice(index, 1, updatedNote);
    }
  },
  DELETE_USER_NOTE(state, noteId) {
    state.userNotes = state.userNotes.filter((note) => note.id !== noteId);
  },
};

const actions = {
  async fetchUserNotes({ commit, rootGetters }) {
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get("/notes", {
        params: {
          filters: {
            user: {
              id: userId,
            },
          },
          populate: {
            fields: ["id"],
            user: {
              fields: ["id"],
            },
            Dossier: {
              fields: ["id", "Titre", "note", "categories", "Created"],
            },
          },
        },
      });

      // console.log("response : ", response.data.data);
      commit("SET_USER_NOTES", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des notes de l'utilisateur :",
        error
      );
    }
  },

  async addUserNote({ commit, rootGetters }, noteData) {
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.post("/notes", {
        data: {
          ...noteData,
          user: userId,
        },
      });
      commit("ADD_USER_NOTE", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de l'ajout de la note de l'utilisateur :",
        error
      );
    }
  },

  async updateUserNote({ commit }, { id, noteData }) {
    try {
      const response = await axios.put(`/notes/${id}`, { data: noteData });
      commit("UPDATE_USER_NOTE", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour de la note de l'utilisateur :",
        error
      );
    }
  },

  async deleteUserNote({ commit }, noteId) {
    try {
      await axios.delete(`/notes/${noteId}`);
      commit("DELETE_USER_NOTE", noteId);
    } catch (error) {
      console.error(
        "Erreur lors de la suppression de la note de l'utilisateur :",
        error
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
