<template>
  <div class="left">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M4 12L20 12"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.99996 17C8.99996 17 4.00001 13.3176 4 12C3.99999 10.6824 9 7 9 7"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "left-icon",
};
</script>

<style scoped>
.left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left svg {
  width: 25px;
  height: 25px;
}

.left svg path {
  stroke: var(--link);
}

.finished .back-f .left svg path {
  stroke-width: 2;
}

.finished .back-f .left svg path {
  stroke: var(--colorBtn);
}

.back-k:hover svg path {
  stroke: var(--colorBtn);
}

.finished .back-f:hover .left svg path {
  stroke: #fff;
}
</style>
