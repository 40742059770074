<template>
  <div class="audio-player">
    <button @click.prevent="togglePlayPause" class="play-pause-button">
      <Play v-if="!isPlaying" />
      <Pause v-else />
    </button>
    <div
      class="progress-container"
      @click="seekAudio"
      @mousedown="startSeeking"
      @mousemove="seekOnDrag"
      @mouseup="stopSeeking"
      @mouseleave="stopSeeking"
      @touchstart="startSeeking"
      @touchmove="seekOnDrag"
      @touchend="stopSeeking"
    >
      <el-slider input-size="small" v-model="progressPercentage" />
    </div>
    <div class="time-display">
      {{ formattedCurrentTime }} / {{ formattedDuration }}
    </div>
  </div>
</template>

<script>
import Play from "../icons/audios/Play.vue";
import Pause from "../icons/audios/Pause.vue";
export default {
  name: "PlayerD",
  components: {
    Play,
    Pause,
  },
  props: {
    file: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      audio: null,
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      isSeeking: false,
    };
  },
  computed: {
    formattedDuration() {
      return this.formatTime(this.duration);
    },
    formattedCurrentTime() {
      return this.formatTime(this.currentTime);
    },
    progressPercentage() {
      return this.duration
        ? Math.floor((this.currentTime / this.duration) * 100)
        : 0;
    },
  },
  methods: {
    togglePlayPause() {
      if (this.isPlaying) {
        this.audio.pause();
      } else {
        this.audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    updateTime() {
      if (!this.isSeeking) {
        this.currentTime = this.audio.currentTime;
      }
    },
    resetPlayer() {
      this.isPlaying = false;
      this.currentTime = 0;
      this.audio.currentTime = 0;
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const sec = Math.floor(seconds % 60);
      return `${minutes}:${sec < 10 ? "0" : ""}${sec}`;
    },
    seekAudio(event) {
      const progressContainer = event.currentTarget;
      let clientX;

      if (event.type.startsWith("touch")) {
        clientX = event.touches[0].clientX;
      } else {
        clientX = event.clientX;
      }

      const rect = progressContainer.getBoundingClientRect();
      const clickX = clientX - rect.left;
      const clickPercentage = clickX / rect.width;
      this.audio.currentTime = clickPercentage * this.duration;
      this.currentTime = this.audio.currentTime;
    },
    startSeeking() {
      this.isSeeking = true;
    },
    seekOnDrag(event) {
      if (this.isSeeking) {
        this.seekAudio(event);
      }
    },
    stopSeeking() {
      this.isSeeking = false;
    },
  },
  mounted() {
    this.audio = new Audio(this.file);
    this.audio.addEventListener("timeupdate", this.updateTime);
    this.audio.addEventListener("ended", this.resetPlayer);
    this.audio.addEventListener("loadedmetadata", () => {
      this.duration = this.audio.duration;
    });
    this.audio.volume = 1; // Volume par défaut
  },
  beforeUnmount() {
    if (this.audio) {
      this.audio.removeEventListener("timeupdate", this.updateTime);
      this.audio.removeEventListener("ended", this.resetPlayer);
      this.audio.pause();
    }
  },
};
</script>

<style scoped>
.audio-player {
  display: flex;
  align-items: center;
  background: var(--body);
  padding: 10px 15px;
  max-width: 600px;
  width: 100%;
  margin: auto;
  gap: 15px;
  height: 40px;
  /* clip-path: polygon(2% 0, 98% 0, 100% 50%, 98% 100%, 2% 100%, 0% 50%);
  transform: rotate(-0.2deg); */
  border-radius: 20px;
  /* outline: 1px solid var(--color); */
}

.play-pause-button {
  border: none;
  background: transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.progress-container {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.progress-bar {
  width: 100%;
  height: 3px;
  background: var(--color);
  overflow: hidden;
  position: relative;
  border-radius: 30px;
}

.progress-filled {
  height: 100%;
  background: var(--button);
  transition: width 0.05s linear; /* Transition plus fluide et linéaire */
  border-radius: 30px;
}

.time-display {
  color: var(--color);
  font-size: 14px;
  text-align: left;
  min-width: 80px;
}
</style>
