<template>
  <Head>
    <title>Horaires de prières - Ville - Doonun</title>
    <meta
      name="description"
      :content="`Retrouvez facilement les horaires de prières de votre ville sur Doonun.`"
    />
    <meta property="og:title" :content="`Horaires de prières - Ville`" />
    <meta property="og:url" :content="`https://www.doonun.com/priere`" />
    <meta
      property="og:description"
      :content="`Retrouvez facilement les horaires de prières de votre ville sur Doonun.`"
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/2148590247_f23daa8ddc.jpg"
    />
    <meta name="robots" content="index, follow" />

    <!-- Twitter -->
    <meta
      name="twitter:title"
      :content="`Horaires de prières - Ville - Doonun`"
    />
    <meta
      name="twitter:description"
      :content="`Retrouvez facilement les horaires de prières de votre ville sur Doonun.`"
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/2148590247_f23daa8ddc.jpg"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div>
    <!-- <div
      class="img-bg-pray"
      :style="{ backgroundImage: `url(${prays[currentPrayerName]})` }"
    ></div> -->
    <div class="container">
      <div class="box-time">
        <div v-if="currentPrayerName">
          <p class="name-pr">{{ currentPrayerName }} {{ $t("a") }}</p>
          <!-- Display the city by default if city is not empty -->
          <div
            v-if="city && country && show == false"
            class="city"
            @click="showInput"
          >
            {{ city }}, {{ country }}
          </div>

          <!-- Display the input field when city is clicked -->
          <div v-else class="flex gap-4">
            <div>
              <el-autocomplete
                v-model="state"
                :fetch-suggestions="querySearch"
                clearable
                class="inline-input w-50"
                :placeholder="$t('searchCity')"
                @select="handleSelect"
              />
            </div>
          </div>
          <p class="hour-pray">{{ currentPrayerTime }}</p>
          <div class="countdown" :style="{ color: countdownBgColor }">
            {{ countdown }}
          </div>
        </div>
        <div class="prays">
          <div class="item" v-for="(time, name) in prayerTimes" :key="name">
            <p class="name">{{ name }}</p>
            <p class="time">{{ time }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="box-day">
        <!-- <ul>
        <li>
          <div class="components" @click="changeComponent('Hijri')">
            <span :class="{ activeComp: currentComponent === 'Hijri' }">{{
              $t("hijriT")
            }}</span>
          </div>
        </li>
        <li>
          <div class="components" @click="changeComponent('Gregorian')">
            <span :class="{ activeComp: currentComponent === 'Gregorian' }">{{
              $t("gregoT")
            }}</span>
          </div>
        </li>
        <li>
          <div class="components" @click="changeComponent('Prays')">
            <span :class="{ activeComp: currentComponent === 'Prays' }">{{
              $t("monthP")
            }}</span>
          </div>
        </li>
      </ul> -->
        <div class="box-component">
          <component
            :is="currentComponent"
            :timings="prayerTimesMonth"
          ></component>
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import Gregorian from "../../components/hours/Gregorian.vue";
import Hijri from "../../components/hours/Hijri.vue";
import Prays from "../../components/hours/Prays.vue";
import { Head } from "@vueuse/head";
import Tabs from "../../components/global/Tabs.vue";
export default {
  name: "horaires-doonun",
  components: {
    Hijri,
    Gregorian,
    Prays,
    Head,
    Tabs,
  },
  data() {
    return {
      prays: {
        Fajr: "https://cp.doonun.com/uploads/fajr1_7841ed957d.png",
        Shuruq: "https://cp.doonun.com/uploads/Shuruk1_dbae7fd409.png",
        Dhuhr: "https://cp.doonun.com/uploads/Duhr1_1aab0af2c4.png",
        Asr: "https://cp.doonun.com/uploads/Asr1_18708096b6.png",
        Maghrib: "https://cp.doonun.com/uploads/Maghrib1_c894eaa5bf.png",
        Isha: "https://cp.doonun.com/uploads/Icha1_1868dcad29.png",
      },
      currentComponent: "Hijri",
      prayerTimes: {},
      prayerTimesMonth: {},
      city: "",
      country: "",
      currentPrayerName: "",
      currentPrayerTime: "",
      state: "",
      cities: [],
      show: false,
      countdown: "00:00:00", // Initial countdown
      countdownBgColor: "#4FD777", // Initial background color
    };
  },

  methods: {
    async querySearch(queryString, cb) {
      if (queryString) {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&city=${queryString}&addressdetails=1`
          );
          const data = await response.json();
          console.log("Data from Nominatim:", data);
          this.cities = data.map((item) => {
            const city =
              item.address?.city ||
              item.address?.town ||
              item.address?.village ||
              item.address?.county ||
              item.address?.state ||
              item.address?.country ||
              "Unknown City";
            const country = item.address?.country || "Unknown Country";
            return {
              value: item.display_name,
              city: city,
              country: country,
            };
          });
          cb(this.cities);
        } catch (error) {
          console.error("Erreur lors de la récupération des villes:", error);
        }
      } else {
        cb([]);
      }
    },

    handleSelect(item) {
      if (item && item.city && item.country) {
        this.city = item.city;
        this.country = item.country;
        console.log(
          "Selected city:",
          this.city,
          "Selected country:",
          this.country
        );
        // Update localStorage when city or country changes
        localStorage.setItem("selectedCity", this.city);
        localStorage.setItem("selectedCountry", this.country);
        this.getPrayerTimes(this.city, this.country);
        this.getPrayerTimesMonth(this.city, this.country);
        this.getNextPrayerTime();

        // Hide input and show city after selecting a city
        this.state = `${this.city}, ${this.country}`; // Update input state
        this.show = false;
      } else {
        console.error("Item sélectionné invalide:", item);
      }
    },

    async getPrayerTimes(city, country) {
      try {
        const response = await fetch(
          `http://api.aladhan.com/v1/timingsByCity?city=${city}&country=${country}&method=8`
        );
        const data = await response.json();
        // console.log("Data from Aladhan:", data);

        if (data.data && data.data.timings) {
          const timings = data.data.timings;

          // Création d'un objet de mappage pour renommer certaines prières
          const prayerRenameMap = {
            Sunrise: "Shuruq",
            Firstthird: "1/3 de la nuit",
            Lastthird: "3/3 de la nuit",
          };

          // Initialisation de l'objet prayerTimes
          this.prayerTimes = {};

          // Filtrer et renommer les prières d'intérêt
          const prayersOfInterest = [
            "Fajr",
            "Sunrise",
            "Dhuhr",
            "Asr",
            "Maghrib",
            "Isha",
            "Firstthird",
            "Lastthird",
          ];

          prayersOfInterest.forEach((prayer) => {
            if (timings[prayer]) {
              // Utiliser le nom renommé si applicable, sinon utiliser le nom original
              const prayerName = prayerRenameMap[prayer] || prayer;
              // Ajouter la prière et son heure dans l'objet prayerTimes
              this.prayerTimes[prayerName] = timings[prayer];
            }
          });

          // Appeler la méthode pour déterminer la prière actuelle
          this.getCurrentPrayer();
        } else {
          console.error("Pas de données de temps de prière disponibles.");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des temps de prière:",
          error
        );
      }
    },

    async getPrayerTimesMonth(city, country) {
      const date = new Date();
      const response = await fetch(
        `https://api.aladhan.com/v1/calendarByCity/${date.getFullYear()}/${
          date.getMonth() + 1
        }?city=${city}&country=${country}&method=8`
      );

      console.log("response : ", response);

      // Vérification de l'absence d'erreurs HTTP
      if (!response.ok) {
        console.error(
          "Erreur lors de la récupération des données :",
          response.statusText
        );
        return;
      }

      const data = await response.json();
      console.log("prays months:", data);

      // Vérification de la structure des données
      if (data.data && Array.isArray(data.data)) {
        // Initialisation de l'objet prayerTimesMonth
        this.prayerTimesMonth = {};

        // Filtrer et renommer les prières d'intérêt
        const prayersOfInterest = [
          "Fajr",
          "Sunrise",
          "Dhuhr",
          "Asr",
          "Maghrib",
          "Isha",
        ];

        // Parcourir chaque jour du mois pour récupérer les horaires des prières
        data.data.forEach((day, index) => {
          if (day.timings) {
            this.prayerTimesMonth[index + 1] = {}; // Utiliser l'index pour représenter le jour du mois (1 à 31)
            prayersOfInterest.forEach((prayer) => {
              if (day.timings[prayer]) {
                // Ajouter la prière et son heure dans l'objet prayerTimes
                this.prayerTimesMonth[index + 1][prayer] = day.timings[prayer]
                  .replace("(EAT)", "")
                  .replace("(CEST)", "")
                  .replace("(EDT)", "");
              }
            });
          }
        });

        console.log("Heures de prière du mois :", this.prayerTimesMonth);
      } else {
        console.error("Pas de données de temps de prière disponibles.");
      }
    },

    getCurrentPrayer() {
      if (!this.prayerTimes) {
        console.error("Les temps de prière ne sont pas disponibles.");
        return;
      }

      const now = new Date();
      const prayerNames = [
        "Fajr",
        "Shuruq",
        "Dhuhr",
        "Asr",
        "Maghrib",
        "Isha",
        "Firstthird",
      ];
      const times = prayerNames.map((name) => ({
        name,
        time: new Date(
          `${now.toISOString().split("T")[0]}T${this.prayerTimes[name]}`
        ),
      }));

      // Ajouter le temps de Fajr pour le lendemain pour boucler sur les prières
      times.push({
        name: "Fajr",
        time: new Date(
          `${now.toISOString().split("T")[0]}T${this.prayerTimes.Fajr}`
        ),
      });
      times[times.length - 1].time.setDate(
        times[times.length - 1].time.getDate() + 1
      );

      // Chercher la prochaine prière
      for (let i = 0; i < times.length - 1; i++) {
        if (now < times[i].time) {
          this.currentPrayerName = times[i].name;
          this.currentPrayerTime = times[i].time.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          break;
        }
      }
    },

    changeComponent(componentName) {
      this.currentComponent = componentName;
    },

    // Method to show input and hide city
    showInput() {
      this.show = true;
      console.log("click");
    },

    updateCountdown() {
      const nextPrayerTime = this.getNextPrayerTime();
      if (nextPrayerTime) {
        const now = new Date().getTime();
        const timeDifference = nextPrayerTime - now; // in milliseconds

        let hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        // Add leading zeros if necessary
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        this.countdown = `${hours}:${minutes}:${seconds}`;

        // Determine the background color based on the remaining time
        if (hours >= 2) {
          this.countdownBgColor = "#4FD777"; // More than 2 hours
        } else if (hours < 2 && minutes > 20) {
          this.countdownBgColor = "#FFA600"; // Between 20 minutes and 2 hours
        } else if (hours < 1 && minutes < 20) {
          this.countdownBgColor = "red"; // Less than 20 minutes
        }

        // No direct DOM manipulation needed
      }
    },

    getNextPrayerTime() {
      const now = new Date();
      const prayerNames = [
        "Fajr",
        "Shuruq",
        "Dhuhr",
        "Asr",
        "Maghrib",
        "Isha",
        "Firstthird",
      ];
      const times = prayerNames.map((name) => ({
        name,
        time: new Date(
          `${now.toISOString().split("T")[0]}T${this.prayerTimes[name]}`
        ),
      }));

      // Ajouter le temps de Fajr pour le lendemain pour boucler sur les prières
      times.push({
        name: "Fajr",
        time: new Date(
          `${now.toISOString().split("T")[0]}T${this.prayerTimes.Fajr}`
        ),
      });
      times[times.length - 1].time.setDate(
        times[times.length - 1].time.getDate() + 1
      );

      for (let i = 0; i < times.length; i++) {
        if (now < times[i].time) {
          return times[i].time.getTime();
        }
      }
      return null;
    },
  },

  mounted() {
    // Check localStorage for city and country
    const storedCity = localStorage.getItem("selectedCity");
    const storedCountry = localStorage.getItem("selectedCountry");

    if (storedCity && storedCountry) {
      this.city = storedCity;
      this.country = storedCountry;
      console.log("Loaded from localStorage:", this.city, this.country);
      this.getPrayerTimes(this.city, this.country);
      this.getPrayerTimesMonth(this.city, this.country);
    } else {
      // Default to Paris, France if no data is stored
      this.city = "Paris";
      this.country = "France";
      console.log("Default city set to Paris, France");
      this.getPrayerTimes(this.city, this.country);
      this.getPrayerTimesMonth(this.city, this.country);
    }

    // Set interval to update countdown every second
    setInterval(this.updateCountdown, 1000);
  },
};
</script>

<style scoped>
body {
  overflow: none !important;
}
.noise {
  background: linear-gradient(to right, blue, transparent),
    url(https://grainy-gradients.vercel.app/noise.svg);
}
.img-bg-pray {
  position: absolute;
  margin-top: 40px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  max-height: 80vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* filter: blur(300px); */
  /* opacity: 0.9; */
  z-index: -1 !important;
}

.img-bg-pray::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 80vh;
  background: rgb(25, 0, 255);
  z-index: 1;
  opacity: 0.3;
}

.box-time {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  max-width: 600px;
  max-height: 100%;
  /* min-height: 800px; */
  margin: 80px auto 0 auto;
  padding-top: 0px;
}

.angle {
  text-align: center;
  font-size: clamp(18px, 8vw, 18px);
  margin-bottom: 0px;
  color: var(--color);
  font-weight: 600;
}

.city {
  text-align: center;
  font-size: clamp(16px, 8vw, 16px);
  margin-top: 5px;
  cursor: pointer;
  color: var(--color);
  font-weight: 200;
  z-index: 10;
}

.name-pr {
  text-align: center;
  font-size: clamp(30px, 8vw, 30px);
  font-weight: 800;
  color: var(--color);
}

.hour-pray {
  text-align: center;
  font-size: clamp(90px, 8vw, 150px);
  font-weight: 800;
  color: var(--color);
  margin-top: -20px;
  margin-bottom: -30px;
}

.countdown {
  margin-top: 20px;
  margin-bottom: 10px;
  /* background: rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px) !important; */
  background: var(--card);
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 100px;
  text-align: center;
  font-weight: 600;
}

.prays {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(100px - 15px), 1fr));
  gap: 10px;
  width: 100%;
  max-width: 450px;
  margin-top: 20px;
}

.prays .item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 10px;
  /* background: rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px) !important; */
  background: var(--card);
  border-radius: 6px;
}

.prays .item p {
  color: var(--color);
}

.prays .item .name {
  font-weight: 300;
  font-size: 12px;
}

.prays .item .time {
  text-align: end;
  font-weight: 600;
  color: var(--color);
}

.box-day {
  display: block;
  margin: 10px auto 30px auto;
  max-width: 450px;
  width: 100%;
  height: 100%;
  background: var(--card);
  border-radius: 6px;
  padding: 20px;
}

.box-day ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.box-day ul li {
  cursor: pointer;
}

.box-day ul li span {
  font-size: 14px;
  font-weight: 300;
  color: var(--color);
}

.box-day ul li span:hover {
  color: var(--button);
}

.box-day ul li .activeComp {
  color: var(--color);
  font-weight: 700;
}
</style>
