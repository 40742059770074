<template>
  <div class="suivant">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M6 20L18 20"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M12 16V4"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 12C16 12 13.054 16 12 16C10.9459 16 8 12 8 12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "suivant-icon",
};
</script>

<style scoped>
.suivant {
  display: flex;
  justify-content: center;
  align-items: center;
}

.suivant svg {
  width: 20px;
  height: 20px;
}

.suivant svg path {
  stroke: var(--link);
}
</style>
