<template>
  <div class="bell-icon-fii">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="#000000"
      fill="none"
    >
      <path
        d="M5.15837 11.491C5.08489 12.887 5.16936 14.373 3.92213 15.3084C3.34164 15.7438 3 16.427 3 17.1527C3 18.1508 3.7818 19 4.8 19H19.2C20.2182 19 21 18.1508 21 17.1527C21 16.427 20.6584 15.7438 20.0779 15.3084C18.8306 14.373 18.9151 12.887 18.8416 11.491C18.6501 7.85223 15.6438 5 12 5C8.35617 5 5.34988 7.85222 5.15837 11.491Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 3.125C10.5 3.95343 11.1716 5 12 5C12.8284 5 13.5 3.95343 13.5 3.125C13.5 2.29657 12.8284 2 12 2C11.1716 2 10.5 2.29657 10.5 3.125Z"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M15 19C15 20.6569 13.6569 22 12 22C10.3431 22 9 20.6569 9 19"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "bell-fii",
};
</script>

<style s>
.bell-icon-fii {
  display: flex;
  align-items: center;
}

.bell-icon-fii svg {
  width: 23px;
  height: 23px;
}

.router-link-exact-active .bell-icon-fii svg path {
  stroke: var(--button) !important;
}

.bell-icon-fii svg path {
  stroke: var(--color) !important;
}
</style>
