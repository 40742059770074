<template>
  <div class="link-preview" v-if="!isLoading && !error">
    <a :href="canonicalUrl || link" target="_blank" rel="noopener noreferrer">
      <div class="card">
        <img
          v-if="image"
          :src="image"
          alt="Preview Image"
          class="preview-image"
          @error="handleImageError"
        />
        <img
          v-else
          :src="defaultImage"
          alt="Default Preview Image"
          class="preview-image"
        />
        <div class="content">
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
          <span>
            {{ domain }}
          </span>
        </div>
      </div>
    </a>
  </div>

  <div v-else-if="isLoading" class="loading">
    <Loading />
    <!-- Assurez-vous d'avoir un composant Loading -->
  </div>

  <div v-else-if="error" class="error-card">
    <div class="card error-content">
      <img :src="errorImage" alt="Error Image" class="preview-image" />
      <div class="content">
        <h3>Erreur lors de la récupération du lien</h3>
        <p>{{ error }}</p>
        <span>Veuillez vérifier l'URL et réessayer.</span>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../navigation/Loading.vue";

export default {
  name: "LinkPreview",
  components: { Loading },
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: "",
      description: "",
      image: "",
      canonicalUrl: "",
      domain: "",
      isLoading: false,
      error: null,
      defaultImage:
        "https://media.architecturaldigest.com/photos/5ba551bb44966b64d8d5fc2b/16:9/w_2560%2Cc_limit/hyper-blue-4.jpg", // Chemin vers votre image par défaut
      errorImage:
        "https://cp.doonun.com/uploads/29395910_3663_modern_abstract_background_with_retro_memphis_elements_and_pastel_red_gray_color_10_min_6652d5f9fa.jpg", // Chemin vers votre image d'erreur
    };
  },
  watch: {
    link: {
      immediate: true,
      handler(newLink) {
        if (newLink) {
          this.fetchLinkPreview(newLink);
        }
      },
    },
  },
  methods: {
    async fetchLinkPreview(url) {
      this.isLoading = true;
      this.error = null;
      this.title = "";
      this.description = "";
      this.image = "";
      this.canonicalUrl = "";
      this.domain = ""; // Réinitialisation de la propriété

      try {
        // Utilisez un proxy CORS gratuit pour contourner les restrictions CORS
        const proxyUrl = "https://corsproxy.io/?"; // Remplacez par votre propre proxy si possible
        const response = await fetch(proxyUrl + url);
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération de la page.");
        }
        const html = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Extraire le titre
        const titleTag =
          doc.querySelector('meta[property="og:title"]') ||
          doc.querySelector("title");
        this.title = titleTag
          ? titleTag.content || titleTag.innerText
          : "Titre non disponible";

        // Extraire la description
        const descriptionTag =
          doc.querySelector('meta[property="og:description"]') ||
          doc.querySelector('meta[name="description"]');
        this.description = descriptionTag
          ? descriptionTag.content
          : "Description non disponible";

        // Extraire l'image
        const imageTag = doc.querySelector('meta[property="og:image"]');
        this.image = imageTag ? imageTag.content : "";

        // Extraire l'URL canonique
        const urlTag = doc.querySelector('meta[property="og:url"]');
        this.canonicalUrl = urlTag ? urlTag.content : url;

        // Extraire le domaine
        const parsedUrl = new URL(this.canonicalUrl || url);
        this.domain = parsedUrl.hostname; // Récupérer le nom de domaine
      } catch (err) {
        console.error(err);
        this.error = "Impossible de récupérer la prévisualisation du lien.";
      } finally {
        this.isLoading = false;
      }
    },

    handleImageError(event) {
      event.target.src = this.defaultImage;
    },
  },
};
</script>

<style scoped>
.link-preview {
  margin-top: 16px;
  width: 100%;
  background: var(--card);
  border-radius: 6px;
  outline: 2px dotted var(--body);
}
.card {
  display: grid;
  grid-template-columns: 100px 1fr;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
}
.preview-image {
  width: 100px;
  height: 100%;
  object-fit: cover;
}
.content {
  padding: 16px 14px;
}
.content h3 {
  margin: 0 0 8px 0;
  font-size: 1.2em;
  line-height: 1.4rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.content p {
  margin: 0 0 8px 0;
  color: var(--color);
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.content span {
  color: #1e90ff;
  text-decoration: none;
  word-break: break-all;
  text-align: end;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.loading {
  margin-top: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-card {
  margin-top: 16px;
  width: 100%;
  background: #ffe6e6;
  border-radius: 6px;
}
.error-content {
  display: grid;
  grid-template-columns: 100px 1fr;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
}
.error {
  color: #ff0000;
}
</style>
