<template>
  <router-link class="my-button" to="/inscription">
    <span class="btn_lg">
      <span class="btn_sl"></span>
      <span class="btn_text">{{ $t("cta") }}</span>
    </span>
  </router-link>
</template>

<script>
export default {
  name: "cta-doonun",
};
</script>

<style scoped>
.my-button {
  background-color: #d2b161;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.my-button:after {
  content: "";
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.my-button:hover:after {
  animation: ripple_401 1s ease-out;
}

@keyframes ripple_401 {
  0% {
    width: 5px;
    height: 5px;
    opacity: 1;
  }

  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}
</style>
