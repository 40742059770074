<template>
  <div class="box-charity">
    <a href="https://buy.stripe.com/fZe6rz57v24S2FqcMM" target="_blank">
      <p>{{ $t("giveDonation") }}</p>
    </a>
  </div>
</template>
<script>
export default {
  name: "charity-doonun",
};
</script>

<style scoped>
.box-charity {
  display: flex;
  align-items: end;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-image: url("https://cp.doonun.com/uploads/charity_f8df8cb917.png");
  background-size: cover;
  background-position: center center;
  border-radius: 6px;
}

a p {
  padding: 10px 40px;
  text-align: center;
  background: #ffbb73;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  border-radius: 30px;
  transition: 0.2s ease-in;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

a:hover p {
  background: #000;
}
</style>
