<template>
  <div>
    <Head>
      <title>Redirect</title>
      <meta name="robots" content="Noindex, nofollow" />
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head>
    <div class="container">
      <h1 v-if="error">La connexion a échoué !</h1>
      <p v-else>Redirection en cours...</p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { Head } from "@vueuse/head";

export default {
  name: "redirect-d",
  components: {
    Head,
  },
  data() {
    return {
      error: false,
    };
  },
  methods: {
    ...mapMutations("google", ["SET_USER_INFO"]),
    ...mapActions("google", ["processGoogleAuthCallback"]),

    async processCallback() {
      const searchParams = window.location.search;
      try {
        await this.processGoogleAuthCallback(searchParams);

        this.$router.push("/");
      } catch (err) {
        this.error = true;
      }
    },
  },
  mounted() {
    this.processCallback().catch(() => {
      console.error("Erreur lors de l'authentification Google");
    });
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
