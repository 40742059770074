<template>
  <!-- Div de recherche et champ pour l'année -->
  <div class="controls">
    <h1>{{ $t("titleHi") }}</h1>
    <div class="inputs">
      <input
        type="number"
        v-model="yearInput"
        :min="500"
        :max="currentYear"
        @input="onYearInput"
        :placeholder="$t('holderYear')"
      />
      <input
        type="text"
        v-model="searchQuery"
        :placeholder="$t('holderWords')"
        @input="onSearchInput"
      />
      <div class="share">
        <button @click="openSharePopup" class="icon">
          <Share />
        </button>
      </div>
    </div>
    <div v-if="highlightedEvents.length > 0" class="arrow-buttons">
      <button @click="navigateEvent(-1)">
        <i class="fa-solid fa-arrow-left-long"></i>
      </button>
      <span
        >{{ currentHighlightedIndex + 1 }} /
        {{ highlightedEvents.length }}</span
      >
      <button @click="navigateEvent(1)">
        <i class="fa-solid fa-arrow-right-long"></i>
      </button>
    </div>
  </div>

  <div ref="timeline" class="timeline">
    <!-- Barre horizontale -->
    <div ref="line" class="line"></div>

    <div v-for="(year, index) in events" :key="index" class="year">
      <!-- Grand cercle pour l'année -->
      <div class="circle year-circle" :data-year="year.year">
        {{ year.year }}
      </div>
      <!-- Petits cercles pour les événements -->
      <div class="events">
        <div
          v-for="(event, eventIndex) in year.events"
          :key="eventIndex"
          class="circle event-circle"
          :class="{ highlight: highlightedEvents.includes(event) }"
          :data-event-id="`${event.date}-${event.description}`"
          :data-event-year="event.date"
          @click="showTooltip(event)"
        >
          <span v-if="event.emoji" class="emoji">{{ event.emoji }}</span>
          <span v-if="event.month" class="month">{{ event.month }}</span>
          {{ event.date }}
          <!-- Tooltip -->
          <div
            v-if="tooltip && selectedEvent === event"
            class="tooltip-container"
          >
            <div
              class="tooltip"
              :class="{ highlight: highlightedEvents.includes(event) }"
            >
              <p>
                <strong>{{ $t("textY") }} {{ event.date }}</strong>
              </p>
              <p>{{ event.description }}</p>
              <a :href="event.link" target="_blank" v-if="event.link">{{
                $t("moreD")
              }}</a>
            </div>
            <div
              class="tooltip-line"
              :class="{ highlight: highlightedEvents.includes(event) }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Tabs />
  <SharePopup
    :pageUrl="sharePageUrl"
    :message="$t('messageTimeline')"
    ref="sharePopup"
  />
</template>

<script>
import Tabs from "../components/global/Tabs.vue";
import SharePopup from "../components/global/Share.vue";
import Share from "../components/icons/Share.vue";
const SCROLL_OFFSET = 150; // Définir le décalage en pixels

export default {
  name: "HistoryTimeline",
  components: {
    Tabs,
    SharePopup,
    Share,
  },
  data() {
    return {
      tooltip: false,
      selectedEvent: null,
      yearInput: "", // Input pour l'année
      searchQuery: "", // Champ de recherche
      highlightedEvents: [], // Événements à surligner
      currentHighlightedIndex: 0, // Indice de l'événement actuel surligné
      events: [], // Initialiser vide, les données seront chargées dynamiquement
      currentYear: new Date().getFullYear(), // Année en cours
      isLoading: false, // Indicateur de chargement
      error: null, // Stocker les erreurs potentielles
    };
  },
  computed: {
    sharePageUrl() {
      // Vérifie si l'objet query contient des clés
      if (this.$route.query && Object.keys(this.$route.query).length > 0) {
        // Sérialise les paramètres de requête en une chaîne de requête valide
        const queryString = new URLSearchParams(this.$route.query).toString();
        return `history?${queryString}`;
      }
      // Si aucun paramètre de requête, retourne simplement '/history'
      return "history";
    },
  },
  methods: {
    // Fonction pour afficher le tooltip
    showTooltip(event) {
      if (this.selectedEvent === event) {
        this.tooltip = false;
        this.selectedEvent = null;
      } else {
        this.tooltip = true;
        this.selectedEvent = event;
      }
    },

    // Fonction de défilement vers l'année (défilement horizontal uniquement)
    scrollToYear() {
      if (!this.yearInput) return;
      const selector = `.year-circle[data-year="${this.yearInput}"], .event-circle[data-event-year="${this.yearInput}"]`;
      const yearElement = this.$refs.timeline.querySelector(selector);
      if (yearElement) {
        const scrollPosition = Math.max(
          yearElement.offsetLeft - SCROLL_OFFSET,
          0
        );
        this.$refs.timeline.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    },

    // Mettre en surbrillance les événements qui correspondent à la recherche
    highlightSearchResults() {
      if (!this.searchQuery) {
        this.highlightedEvents = [];
        return;
      }

      this.highlightedEvents = [];
      this.currentHighlightedIndex = 0; // Réinitialiser l'index de l'événement sélectionné

      this.events.forEach((year) => {
        year.events.forEach((event) => {
          if (
            event.description
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          ) {
            this.highlightedEvents.push(event);
          }
        });
      });
      this.scrollToHighlightedEvent();
    },

    // Scroller vers le premier événement surligné
    scrollToHighlightedEvent() {
      if (this.highlightedEvents.length > 0) {
        const firstHighlightedEvent = this.highlightedEvents[0];
        const eventElement = this.$refs.timeline.querySelector(
          `.event-circle[data-event-id="${firstHighlightedEvent.date}-${firstHighlightedEvent.description}"]`
        );
        if (eventElement) {
          const scrollPosition = Math.max(
            eventElement.offsetLeft - SCROLL_OFFSET,
            0
          );
          this.$refs.timeline.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
        }
      }
    },

    // Naviguer entre les événements mis en surbrillance
    navigateEvent(direction) {
      const newIndex = this.currentHighlightedIndex + direction;

      if (newIndex >= 0 && newIndex < this.highlightedEvents.length) {
        this.currentHighlightedIndex = newIndex;
        this.selectedEvent =
          this.highlightedEvents[this.currentHighlightedIndex];
        this.scrollToEvent(this.selectedEvent);
      }
    },

    // Scroller automatiquement vers un événement sélectionné
    scrollToEvent(event) {
      const eventElement = this.$refs.timeline.querySelector(
        `.event-circle[data-event-id="${event.date}-${event.description}"]`
      );
      if (eventElement) {
        const scrollPosition = Math.max(
          eventElement.offsetLeft - SCROLL_OFFSET,
          0
        );
        this.$refs.timeline.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    },

    handleWheelScroll(event) {
      event.preventDefault();
      const timeline = this.$refs.timeline;
      timeline.scrollLeft += event.deltaY;
    },

    adjustLineWidth() {
      const timeline = this.$refs.timeline;
      const line = this.$refs.line;

      // Trouver le dernier événement dans la timeline
      const lastYear = timeline.querySelector(".year:last-child");
      if (lastYear) {
        const lastEvent = lastYear.querySelector(
          ".events .event-circle:last-child"
        );
        if (lastEvent) {
          // Calculer la largeur de la ligne
          const lineStart = timeline.offsetLeft; // Position de départ de la ligne
          const lineEnd = lastEvent.offsetLeft + lastEvent.offsetWidth; // Fin du dernier événement

          line.style.width = `${lineEnd - lineStart}px`; // Ajuster la largeur de la ligne
        }
      }
    },

    // Nouvelle méthode pour récupérer les données depuis l'URL externe
    async fetchTimelineData() {
      const lang = `${this.$t("linkTimeline")}`;
      const DATA_URL = `https://cp.doonun.com/uploads/${lang}.json`; // Votre URL externe

      this.isLoading = true;
      this.error = null;

      try {
        const response = await fetch(DATA_URL);
        if (!response.ok) {
          throw new Error(`Erreur réseau: ${response.status}`);
        }
        const data = await response.json();
        this.events = data.events; // Assurez-vous que la structure correspond
        this.adjustLineWidth(); // Ajuster la ligne après avoir chargé les données

        // Après avoir chargé les données et mis à jour le DOM, appliquer les paramètres de l'URL
        this.$nextTick(() => {
          this.applyURLParameters();
        });
      } catch (err) {
        console.error(
          "Erreur lors de la récupération des données de la timeline:",
          err
        );
        this.error = "Impossible de charger les données de la timeline.";
      } finally {
        this.isLoading = false;
      }
    },

    // Méthode pour mettre à jour l'URL avec les paramètres actuels
    updateURLParams() {
      const query = {};

      if (this.searchQuery) {
        // Si search est présent, ne stocke pas year
        query.search = this.searchQuery;
      } else if (this.yearInput) {
        // Si search n'est pas présent, stocke year
        query.year = this.yearInput;
      }

      this.$router.replace({ query });
    },

    // Méthode appelée lors de la saisie de l'année
    onYearInput() {
      if (this.searchQuery) {
        // Si search est actif, ignore la saisie de l'année
        return;
      }
      this.scrollToYear();
      this.updateURLParams();
    },

    // Méthode appelée lors de la saisie dans le champ de recherche
    onSearchInput() {
      if (this.searchQuery) {
        // Si search est actif, réinitialisez yearInput
        this.yearInput = "";
      }
      this.highlightSearchResults();
      this.updateURLParams();
    },

    // Appliquer les paramètres de l'URL au composant
    applyURLParameters() {
      const { search, year } = this.$route.query;

      if (search) {
        this.searchQuery = search;
        this.highlightSearchResults();
      } else if (year) {
        this.yearInput = year;
        this.scrollToYear();
      }
    },
    openSharePopup() {
      // Utilisation de la référence pour appeler la méthode togglePopup du SharePopup
      this.$refs.sharePopup.togglePopup();
    },
  },
  mounted() {
    const timeline = this.$refs.timeline;
    timeline.addEventListener("wheel", this.handleWheelScroll);
    this.fetchTimelineData(); // Charger les données depuis l'URL externe
    window.addEventListener("resize", this.adjustLineWidth); // Réajuster en cas de redimensionnement
  },
  beforeUnmount() {
    const timeline = this.$refs.timeline;
    timeline.removeEventListener("wheel", this.handleWheelScroll);
    window.removeEventListener("resize", this.adjustLineWidth);
  },
  watch: {
    "$i18n.locale"() {
      this.fetchTimelineData();
    },
    // Surveiller les changements de route pour appliquer les nouveaux paramètres
    "$route.query.search"(newSearch) {
      if (newSearch !== this.searchQuery) {
        this.searchQuery = newSearch;
        this.highlightSearchResults();
      }
    },
    "$route.query.year"(newYear) {
      if (newYear !== this.yearInput && !this.searchQuery) {
        this.yearInput = newYear;
        this.scrollToYear();
      }
    },
  },
};
</script>

<style scoped>
/* Div des contrôles */
.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 80px;
  right: 20px;
  z-index: 1;
  gap: 20px;
}

h1 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: end;
  font-family: "Lobster", sans-serif;
  color: #000;
  border-radius: 5px;
  max-width: 80%;
}

.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 10px;
  max-width: 300px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}

/* Style du champ de l'année */
.controls input[type="number"] {
  padding: 10px;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  color: #000;
}

/* Style du champ de recherche */
.controls input[type="text"] {
  padding: 10px;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  color: #000;
}

.controls input::placeholder {
  color: gray !important;
}

/* Flèches pour naviguer entre les événements */
.arrow-buttons button {
  padding: 10px;
  font-size: 18px;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.arrow-buttons button i {
  color: #000;
}

/* Flèches au survol */
.arrow-buttons button:hover {
  background-color: dark#95836B;
}

/* Nombre d'événements trouvés */
.arrow-buttons span {
  margin: 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

/* Événements surlignés */
.highlight {
  border-color: yellow !important;
}

/* Le reste du style reste inchangé */
.line {
  position: absolute;
  top: 50%;
  left: 0;
  height: 5px;
  background-color: #96866e;
  transform: translateY(-50%);
  z-index: 0;
}

.timeline {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  height: 100vh;
  width: 100%;
  scroll-behavior: smooth;
  background: white;
  background-image: url("https://cp.doonun.com/uploads/801_min_ead3341b18.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.year {
  display: flex;
  gap: 20px;
  align-items: center;
  z-index: 1;
}

.year-circle {
  width: 130px;
  height: 130px;
  border: 5px solid #96866e;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #96866e;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.events {
  display: flex;
  gap: 15px;
}

.event-circle {
  width: 100px;
  height: 100px;
  border: 5px solid #96866e;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #96866e;
  position: relative;
  cursor: pointer;
  z-index: 2;
  transition: 0.2s ease-in;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.event-circle .month {
  position: absolute;
  bottom: 0;
  left: auto;
  right: auto;
  margin-bottom: 20px;
  font-size: 12px;
}

.event-circle .emoji {
  position: absolute;
  top: 0;
  left: auto;
  right: auto;
  margin-top: 10px;
}

.event-circle:hover {
  background-color: #96866e;
  color: #fff;
}

.tooltip-container {
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000;
}

.tooltip {
  background: white;
  color: black;
  border: 2px solid #96866e;
  padding: 15px;
  border-radius: 5px;
  width: 310px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  text-align: left;
}

.tooltip p {
  margin: 10px 0;
  font-size: 16px;
  text-wrap: wrap;
}

.tooltip a {
  color: #96866e;
  text-decoration: underline;
  font-weight: bold;
}

.tooltip-line {
  width: 2px;
  height: 40px;
  background-color: #96866e;
  z-index: 9;
}

.highlight.tooltip-line {
  border: none;
  background-color: yellow;
}
</style>
