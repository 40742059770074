<template>
  <Head>
    <title>Profil - Doonun</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <Top @componentChange="handleComponentChange" />
  <div class="container">
    <div class="navigation">
      <ul>
        <li>
          <div class="components" @click="changeComponent('Cours')">
            <Courses /> <span>{{ $t("courseComp") }}</span>
          </div>
        </li>
        <li>
          <div class="components" @click="changeComponent('Sourates')">
            <Favoris /> <span>{{ $t("surahsComp") }}</span>
          </div>
        </li>
        <li>
          <div class="components" @click="changeComponent('VersetsComponents')">
            <Versets />
            <span>{{ $t("versesComp") }}</span>
          </div>
        </li>
        <li>
          <div class="components" @click="changeComponent('Livres')">
            <Book />
            <span>{{ $t("booksComp") }}</span>
          </div>
        </li>
        <li>
          <div class="components" @click="changeComponent('DuaasComponents')">
            <Duaas />
            <span>{{ $t("duaasComp") }}</span>
          </div>
        </li>
        <!-- <li>
          <div class="components" @click="changeComponent('NotesComponents')">
            <Note />
            <span>{{ $t("notesComp") }}</span>
          </div>
        </li> -->
      </ul>
    </div>
    <div class="box-component">
      <component :is="currentComponent"></component>
    </div>
  </div>
</template>

<script>
import Top from "../../components/Profil/Top.vue";
import Courses from "../../components/icons/Courses.vue";
import Favoris from "../../components/icons/Favoris.vue";
import Versets from "../../components/icons/Versets.vue";
import Book from "../../components/icons/Book.vue";
import Duaas from "../../components/icons/Duaas.vue";
// import Note from "../../components/icons/Note.vue";
import Cours from "../../components/Profil/Cours.vue";
import DuaasComponents from "../../components/Profil/Duaas.vue";
import NotesComponents from "../../components/Profil/Notes.vue";
import Sourates from "../../components/Profil/Sourates.vue";
import Livres from "../../components/Profil/Livres.vue";
import VersetsComponents from "../../components/Profil/Versets.vue";
import Parametres from "../../components/Profil/Parametres.vue";
import Edit from "../../components/Profil/Edit.vue";
import Themes from "../../components/Profil/Themes.vue";
import Reseaux from "../../components/Profil/Reseaux.vue";
import Abonnement from "../../components/Profil/Abonnement.vue";
import Testimonials from "../../components/Profil/Testimonials.vue";
import Avatar from "../../components/Profil/Avatar.vue";

import { Head } from "@vueuse/head";

export default {
  name: "profil-user",
  components: {
    Top,
    Courses,
    Favoris,
    Versets,
    Book,
    Duaas,
    DuaasComponents,
    Sourates,
    Livres,
    VersetsComponents,
    Cours,
    // Note,
    NotesComponents,
    Parametres,
    Edit,
    Themes,
    Reseaux,
    Abonnement,
    Avatar,
    Testimonials,
    Head,
  },
  props: {
    initialComponent: {
      type: String,
      default: "Cours",
    },
  },
  data() {
    return {
      currentComponent: this.initialComponent,
    };
  },
  methods: {
    changeComponent(componentName) {
      this.currentComponent = componentName;
      this.scrollToH3();
    },
    scrollToH3() {
      const target = document.getElementById("scroll-target");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleComponentChange(newComponent) {
      this.changeComponent(newComponent);
    },
  },
};
</script>

<style scoped>
.container {
  padding-bottom: 100px;
  min-height: 100vh;
}
.navigation ul {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
}

.navigation ul li .components {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.2s ease-in;
}
.navigation ul li .components span {
  font-size: 14px;
  line-height: 0;
}

.components:hover span {
  font-weight: 400;
  color: var(--button);
}

@media (max-width: 800px) {
  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--card);
    min-height: 50px;
    width: 100%;
    z-index: 4;
    padding: 20px 30px 5vh 30px;
  }

  .navigation ul {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .navigation ul li .components span {
    display: none;
  }
}
</style>
