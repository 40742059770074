<template>
  <Head>
    <title>{{ cours?.attributes?.titre }}</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div>
    <div
      v-if="cours && cours.attributes && cours.attributes.Couverture"
      class="after-head"
      :style="{
        backgroundImage: `url('https://cp.doonun.com${cours.attributes.Couverture.data.attributes.formats.large.url}')`,
      }"
    ></div>
    <div class="container">
      <div class="box-infos" v-if="cours && cours.attributes">
        <div class="infos">
          <img
            v-if="cours.attributes.Couverture"
            :src="`https://cp.doonun.com${cours.attributes.Couverture.data.attributes.formats.large.url}`"
            alt=""
            class="cover"
          />

          <div class="stat-start">
            <div class="demo-progress pr">
              <el-progress
                v-if="
                  cours.attributes.suivis &&
                  cours.attributes.suivis.data.length > 0
                "
                type="circle"
                :percentage="
                  cours.attributes.suivis.data[0].attributes.pourcentage
                "
                :width="45"
                :stroke-width="4"
                :color="customColors"
              />
            </div>
          </div>
          <div class="texts">
            <ul>
              <li v-if="cours.attributes.Category">
                <p>{{ cours.attributes.Category }}</p>
              </li>
              <li v-if="cours.attributes.niveau">
                <p>{{ cours.attributes.niveau }}</p>
              </li>
              <li v-if="cours.attributes.gain">
                <p class="gain">+{{ cours.attributes.gain }}</p>
              </li>
            </ul>
            <h1 v-if="cours.attributes.titre">
              {{ cours.attributes.titre }}
            </h1>
            <p class="desc" v-if="cours.attributes.description">
              {{ cours.attributes.description }}
            </p>
            <hr v-if="cours.attributes.chapitres" />
            <h2 v-if="cours.attributes.chapitres">Liste des chapitres</h2>
            <ul class="chapters" v-if="cours.attributes.chapitres">
              <li
                v-for="chapitre in cours.attributes.chapitres.data"
                :key="chapitre.id"
              >
                <div class="left">
                  <div class="num">{{ chapitre.attributes.numero }}</div>
                  <p class="title-chap">{{ chapitre.attributes.titre }}</p>
                </div>
                <div class="check">
                  <Valide
                    v-if="
                      (cours.attributes.suivis &&
                        cours.attributes.suivis.data[0].attributes.id_chapitre >
                          chapitre.attributes.numero) ||
                      cours.attributes.suivis.data[0].attributes.pourcentage ==
                        '100'
                    "
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="btn-fixed" v-if="cours">
        <button @click="handleStartClick()">
          <span>
            {{
              cours.attributes.suivis.data[0].attributes.pourcentage == 100
                ? $t("restartCourse")
                : cours.attributes.suivis.data[0].attributes.pourcentage > 0
                ? $t("resumeCourse")
                : $t("startCourse")
            }}
          </span>
          <Right />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import Right from "../../components/icons/Right.vue";
import Valide from "../../components/icons/Valide.vue";
import { Head } from "@vueuse/head";

export default {
  name: "cours-slug",
  components: { Right, Valide, Head },
  data() {
    return {
      slug: this.$route.params.slug,
    };
  },
  setup() {
    const customColors = [
      { color: "#FEC733", percentage: 20 },
      { color: "#FC68EB", percentage: 40 },
      { color: "#F88055", percentage: 60 },
      { color: "#0738FD", percentage: 80 },
      { color: "#2BC971", percentage: 100 },
    ];
    const format = (percentage) =>
      percentage === 100 ? "✔" : `${percentage}%`;
    const percentage = ref(50);

    return { percentage, format, customColors };
  },
  computed: {
    ...mapGetters("cours", ["cours", "idSuivi"]),
    ...mapGetters("login", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("cours", ["fetchUniqueCours", "restartCourseProgress"]),
    async handleStartClick() {
      if (this.cours && this.cours.attributes.suivis.data.length > 0) {
        const suivi = this.cours.attributes.suivis.data[0];
        const idSuivi = suivi.id;
        if (suivi.attributes.pourcentage == 100) {
          await this.restartCourseProgress(Number(idSuivi));
          this.$router.push(`/cours/${this.cours.attributes.url}/1`);
        } else {
          this.$router.push(
            `/cours/${this.cours.attributes.url}/${suivi.attributes.id_chapitre}`
          );
        }
      }
    },
  },
  async mounted() {
    try {
      if (this.isAuthenticated) {
        await this.fetchUniqueCours({
          slug: this.slug,
          lang: this.$i18n.locale,
        });
      }
    } catch (error) {
      console.error("Error fetching cours:", error);
    }
  },
  watch: {
    "$i18n.locale"() {
      this.fetchUniqueCours({
        slug: this.slug,
        lang: this.$i18n.locale,
      });
    },
  },
};
</script>

<style scoped>
.after-head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  filter: blur(50px);
  opacity: 0.5;
  z-index: -1;
}

.container {
  min-height: 100vh;
}

.box-infos {
  display: block;
  margin: 100px auto 0 auto;
  max-width: 700px;
}

.top {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 1;
}

.stat-start {
  margin-top: -25px;
  padding: 0 20px 0 30px;
  width: 100%;
}

.btn-fixed {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: 0 auto 0 auto;
  min-height: 80px;
  max-width: 700px;
  padding-top: 20px;
  width: 100%;
  padding-bottom: 5vh;
  background: var(--truncated);
  border-radius: 0 0 6px 6px;
}

.btn-fixed button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: var(--color);
  color: var(--colorBtn);
  border: none;
  min-width: 150px;
  max-width: 210px;
  width: 100%;
  border-radius: 30px;
  padding: 8px 20px;
  font-weight: 700;
  transition: 0.2s ease-in;
}

.btn-fixed button:hover {
  background: var(--button);
  padding: 8px 16px 8px 20px;
  color: #fff;
}

.btn-fixed button span {
  margin-top: -1px;
}

.cover {
  text-align: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 300px;
  border-radius: 10px 10px 0 0;
}

.infos {
  display: block;
  width: 100%;
  margin: 0 auto 0 auto;
  background: var(--card);
  border-radius: 10px 10px 0 0;
}

.texts {
  padding: 0 30px 30px 30px;
}

.infos .texts ul {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
}

.gain {
  background: #f0f9eb !important;
  color: #9bd77d !important;
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 10px !important;
}

h1 {
  font-size: clamp(20px, 8vw, 20px);
  text-align: left;
}

.desc {
  margin: 20px 0;
  text-align: justify;
}

hr {
  border: 1px dotted var(--body);
}

h2 {
  margin-top: 30px;
  font-size: 18px;
  text-align: left;
}

.chapters {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chapters li {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.chapters li .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chapters li .left .num {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  padding: 10px;
  background: var(--body);
  color: var(--color);
  border-radius: 6px;
  font-weight: 600;
}

.chapters li .left .title-chap {
  font-size: 15px;
}
</style>
