<template>
  <div class="header">
    <div class="container">
      <div class="left">
        <h1 class="large-title" v-html="$t('titre')"></h1>
        <p size="large">{{ $t("citation") }}</p>
        <Cta />
      </div>
      <div class="right">
        <img
          src="https://cp.doonun.com/uploads/Fichier_1d_4x_1f14a0e0ee.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import Cta from "./Cta.vue";
export default {
  name: "header-doonun",
  components: {
    Cta,
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  min-height: 500px;
  background: var(--bgHeader);
}

.container {
  padding-top: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.container .left,
.container .right {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.container .left {
  padding: 35px 0;
  justify-content: center;
  align-items: start;
}

.container .right {
  padding-top: 40px;
  justify-content: end;
  align-items: center;
}

h1 {
  text-wrap: balance;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  font-size: clamp(20px, 8vw, 45px);
  white-space: break-spaces;
  width: 90%;
  font-family: "Roboto";
  color: var(--color);
}

.container .left p {
  font-size: 18px;
  text-wrap: balance;
}

.container img {
  max-width: 100%;
  max-height: 450px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

@media (max-width: 533px) {
  .header {
    height: 100%;
  }

  .container .left {
    padding-bottom: 40px;
  }

  .container .right {
    padding-top: 0px;
  }

  .container img {
    max-width: 100%;
    max-height: 350px;
  }
}
</style>
