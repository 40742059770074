<template>
  <div :class="['navbar', { 'navbar--hidden': !showNavbar }]">
    <div class="container">
      <nav>
        <div class="left-nav">
          <router-link to="/">
            <div class="logo">
              <Logo />
            </div>
          </router-link>
          <ul>
            <li>
              <router-link to="/">{{ $t("home") }}</router-link>
            </li>
            <li>
              <router-link to="/articles">{{ $t("news") }}</router-link>
            </li>
            <li>
              <router-link to="/histoire">{{ $t("timeline") }}</router-link>
            </li>
            <li>
              <router-link to="/fiiyd">Fiiyd</router-link>
            </li>
            <li>
              <router-link to="/doovie">Doovie</router-link>
            </li>
            <li>
              <router-link to="/coran">{{ $t("quran") }}</router-link>
            </li>
            <li>
              <router-link to="/cours">{{ $t("course") }}</router-link>
            </li>
            <li>
              <router-link to="/livres">{{ $t("books") }}</router-link>
            </li>
            <li>
              <router-link to="/duaas">{{ $t("duaa") }}</router-link>
            </li>
            <li>
              <router-link to="/priere">{{ $t("pray") }}</router-link>
            </li>
          </ul>
        </div>
        <div class="right-nav">
          <span class="search-link">
            <Search />
          </span>
          <Mode />
          <Langue />
          <Hassanate v-if="isAuthenticated" />
          <Avatar v-if="isAuthenticated" />
          <Login v-if="!isAuthenticated" />
          <span class="Menu">
            <Menu />
          </span>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "../icons/Logo.vue";
import Search from "../icons/Search.vue";
import Mode from "./Mode.vue";
import Avatar from "./Avatar.vue";
import Langue from "./Langue.vue";
import Hassanate from "./Hassanate.vue";
import Menu from "./Menu.vue";
import Login from "./Login.vue";

export default {
  name: "navbar-doonun",
  components: {
    Logo,
    Langue,
    Menu,
    Avatar,
    Hassanate,
    Mode,
    Search,
    Login,
  },
  data() {
    return {
      showNavbar: true, // Contrôle la visibilité de la navbar
      lastScrollY: 0, // Stocke la dernière position de défilement
      ticking: false, // Pour optimiser les appels de défilement
    };
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
  },
  methods: {
    handleScroll() {
      const currentScrollY = window.scrollY;

      if (currentScrollY > this.lastScrollY && currentScrollY > 100) {
        // Défilement vers le bas
        this.showNavbar = false;
      } else {
        // Défilement vers le haut
        this.showNavbar = true;
      }

      this.lastScrollY = currentScrollY;
    },
    onScroll() {
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this.handleScroll();
          this.ticking = false;
        });
        this.ticking = true;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    // Utilisez 'beforeDestroy' si vous êtes sur Vue 2
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background: var(--card);
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}

.navbar--hidden {
  transform: translateY(
    -100%
  ); /* Cache la navbar en la déplaçant vers le haut */
}

.container nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--body);
  padding: 10px;
  border-radius: 6px;
}

.left-nav {
  display: flex;
  align-items: center;
  gap: 15px;
}

.left-nav ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.left-nav ul a {
  color: var(--link);
}

.left-nav ul li {
  font-size: 14px;
}

.left-nav ul li .router-link-exact-active {
  font-weight: 500;
  color: var(--color);
}

.right-nav {
  display: flex;
  gap: 30px;
  align-items: center;
}

.Menu {
  display: none;
}

@media (max-width: 1150px) {
  .left-nav ul {
    display: none;
  }
  .Menu {
    display: block;
  }
}

@media (max-width: 600px) {
  .right-nav {
    gap: 25px;
  }

  .Menu {
    display: block !important;
  }
}

@media (max-width: 361px) {
  .search-link {
    display: none;
  }
}
</style>
