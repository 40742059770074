<template>
  <div class="container">
    <div v-if="hasSubmitted">
      <div class="success-submit">
        <p class="emoji">👋😊</p>
        <p>
          {{ $t("successMessage") }}
        </p>
        <img
          class="wave"
          src="https://cp.doonun.com/uploads/Fichier_1_2x_26b8f81fef.png"
          alt=""
        />
        <router-link to="/"
          ><button>{{ $t("backToHome") }}</button></router-link
        >
      </div>
    </div>
    <div v-else class="box-contact">
      <div>
        <Head>
          <title>{{ $t("contactFormTitle") }}</title>
          <meta name="robots" content="Noindex, nofollow" />
          <link
            rel="icon"
            href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
          />
        </Head>
        <div class="contact-container">
          <h1 class="title" v-html="$t('contactFormTitle')"></h1>

          <form @submit.prevent="submitForm" ref="form" class="contact-form">
            <div class="form-group">
              <label for="name">{{ $t("labelName") }}</label>
              <input
                type="text"
                id="name"
                v-model="form.name"
                :placeholder="$t('placeholderName')"
                required
              />
            </div>

            <div class="form-group">
              <label for="email">{{ $t("labelEmail") }}</label>
              <input
                type="email"
                id="email"
                v-model="form.email"
                :placeholder="$t('placeholderEmail')"
                required
              />
            </div>

            <div class="form-row">
              <div class="form-group">
                <label for="interest">{{ $t("labelInterest") }}</label>
                <select id="subject" v-model="form.subject" required>
                  <option style="display: none" value="">
                    {{ $t("selectSubject") }}
                  </option>
                  <option value="Abonnement">
                    {{ $t("optionSubscription") }}
                  </option>
                  <option value="Bug">{{ $t("optionBugReport") }}</option>
                  <option value="Partenariat">
                    {{ $t("optionPartnership") }}
                  </option>
                  <option value="Question">{{ $t("optionQuestion") }}</option>
                  <option value="Suggestion">
                    {{ $t("optionFeatureSuggestion") }}
                  </option>
                  <option value="Contribution">
                    {{ $t("optionContribution") }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label for="message">{{ $t("labelMessage") }}</label>
              <textarea
                id="message"
                :placeholder="$t('placeholderMessage')"
                v-model="form.message"
                required
              ></textarea>
            </div>

            <button type="submit" class="submit-button">
              {{ $t("submitButtonC") }}
              <span class="arrow">→</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import axios from "axios";
import { Head } from "@vueuse/head";
import Tabs from "../components/global/Tabs.vue";
export default {
  name: "contact-d",
  components: {
    Head,
    Tabs,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      hasSubmitted: false,
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.checkValidity()) {
        try {
          await axios.post("/contacts", {
            data: {
              Sujet: this.form.subject,
              Nom: this.form.name,
              Email: this.form.email,
              Message: this.form.message,
            },
          });
          this.hasSubmitted = true;
        } catch (error) {
          console.error("Erreur lors de la soumission de l'avis", error);
        }
      } else {
        console.error("Le formulaire n'est pas valide");
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  background: var(--card);
  margin-top: 70px;
  margin-bottom: 200px;
  min-height: 100vh;
  border-radius: 6px;
}

.box-contact {
  margin-top: 10px;
  max-width: 900px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  align-items: center;
}

form {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

form input,
form textarea,
form select {
  border: 1px solid var(--color);
  background: var(--card);
  color: var(--color);
  resize: none;
  padding: 15px;
  width: 100%;
  font-size: 16px;
}

form textarea {
  padding: 20px;
  min-height: 180px;
  font-weight: 400;
  font-size: 18px;
}

form button {
  background: var(--color);
  color: var(--colorBtn);
  border: none;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s;
}

form button:hover {
  background: var(--button);
  color: #fff;
}

.success-submit {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  min-height: 80vh;
}

.success-submit p {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.success-submit .emoji {
  font-size: 30px;
}

.success-submit img {
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 300px;
}

.success-submit a {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.success-submit button {
  border: none;
  padding: 15px 30px;
  background: var(--text);
  color: var(--color);
  border-radius: 6px;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  transition: 0.2s;
}

.success-submit button:hover {
  background: var(--button);
  color: #fff;
}

/*  */
.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 40px;
  line-height: 1.2;
}

.wave {
  display: inline-block;
  animation: wave 1s infinite ease-in;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

label {
  font-size: 0.9rem;
  font-weight: 500;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: #000;
}

textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  background: var(--color);
  color: var(--colorBtn);
  border: none;
  padding: 16px 32px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background: #333;
}

.arrow {
  font-size: 1.2rem;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 640px) {
  .form-row {
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 2rem;
  }
}
/*  */

@media (max-width: 1280px) {
  .container {
    margin-top: 0;
    border-radius: 0;
  }
}

@media (max-width: 480px) {
  .img {
    display: flex;
    justify-content: center;
  }
}
</style>
