<template>
  <Head>
    <title>Fiiyd - Réseau social musulman</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container">
    <div class="boites">
      <div class="content-with-sidebars">
        <div class="left">
          <div class="fiiyd-icon">
            <div class="icons">
              <ul>
                <li>
                  <router-link to="/fiiyd">
                    <HomeFii />
                    <span>{{ $t("feed") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/notifications">
                    <div class="bell">
                      <span v-if="fiiydsCount >= 1">{{ fiiydsCount }}</span>
                      <BellFii />
                    </div>
                    <span>{{ $t("notif") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/myposts">
                    <PostsFii />
                    <span>{{ $t("myposts") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="middle">
          <h1>Fiiyd</h1>
          <div class="size-box-v">
            <div class="fil">
              <div class="loading" v-if="isLoading">
                <Loading />
              </div>

              <div v-else>
                <div class="post-input">
                  <!-- Écouteur d'événement supprimé ici -->
                  <InputPost />
                </div>
                <div class="lists-posts">
                  <div class="box-posts">
                    <CardFiiyd
                      v-for="post in visiblePosts"
                      :key="post.IdPost"
                      :avatar="post.avatar"
                      :username="post.username"
                      :time="post.time"
                      :content="post.Content"
                      :vues="post.vues"
                      :likes="post.like"
                      :images="post.images"
                      :liens="post.liens"
                      :url="post.url"
                      :IdPost="post.IdPost"
                      :favoris="getFilteredFav(post)"
                      :comments="post.totalComments"
                      :idUsername="post.idUsername"
                      :enable_comment="post.enable_comment"
                      :enable_notif="post.enable_notif"
                      :content_sensitive="post.content_sensitive"
                      @addFavorite="addFavorite"
                      @delete-post="deletePost"
                    />
                  </div>
                  <!-- Sentinelle pour l'IntersectionObserver -->
                  <div ref="sentinel"></div>
                  <!-- Afficher un loader lors du chargement de plus de posts -->
                  <div class="loading" v-if="isLoadingMore">
                    <Loading />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <Trends />
        </div>
      </div>
    </div>
  </div>
  <div class="tabs">
    <TabsFiiyd />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import InputPost from "../../components/fiiyd/InputPost.vue";
import HomeFii from "../../components/icons/fiiyd/Home.vue";
import BellFii from "../../components/icons/fiiyd/Bell.vue";
import PostsFii from "../../components/icons/fiiyd/Posts.vue";
import CardFiiyd from "../../components/fiiyd/CardFiiyd.vue";
import Trends from "../../components/fiiyd/Trends.vue";
import TabsFiiyd from "../../components/fiiyd/TabsFiiyd.vue";
import { Head } from "@vueuse/head";

export default {
  name: "fiiyd-d",
  components: {
    Loading,
    Head,
    InputPost,
    HomeFii,
    BellFii,
    PostsFii,
    CardFiiyd,
    Trends,
    TabsFiiyd,
  },
  data() {
    return {
      isLoading: true,
      visiblePosts: [],
      batchSize: 3,
      currentBatch: 1,
      hasMorePosts: true,
      isLoadingMore: false,
      observer: null,
    };
  },
  computed: {
    ...mapState({
      posts: (state) => state.fiiyd.posts,
    }),
    ...mapState("user", ["user"]),
    fiiydsCount() {
      return this.$store.state.fiiyd.fiiyds.length;
    },
  },
  methods: {
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("fiiyd", ["fetchPosts", "fetchFiiyds"]),

    getFilteredFav(post) {
      if (post && Array.isArray(post.fav)) {
        const userFav = post.fav.find((favItem) => favItem.id === this.user.id);
        return userFav ? userFav.id : null;
      }
      return null;
    },

    async loadInitialPosts() {
      try {
        await this.fetchPosts();
        this.loadMorePosts();
      } catch (error) {
        console.error("Erreur lors de la récupération des posts:", error);
      } finally {
        this.isLoading = false;
      }
    },
    loadMorePosts() {
      if (!this.hasMorePosts) return;

      this.isLoadingMore = true;
      const start = (this.currentBatch - 1) * this.batchSize;
      const end = this.currentBatch * this.batchSize;
      const nextBatch = this.posts.slice(start, end);

      if (nextBatch.length > 0) {
        this.visiblePosts = [...this.visiblePosts, ...nextBatch];
        this.currentBatch += 1;
        if (this.visiblePosts.length >= this.posts.length) {
          this.hasMorePosts = false;
          if (this.observer) {
            this.observer.disconnect();
          }
        }
      } else {
        this.hasMorePosts = false;
        if (this.observer) {
          this.observer.disconnect();
        }
      }
      this.isLoadingMore = false;
    },
    setupObserver() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };

      this.observer = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          this.hasMorePosts &&
          !this.isLoadingMore
        ) {
          this.loadMorePosts();
        }
      }, options);

      if (this.$refs.sentinel) {
        this.observer.observe(this.$refs.sentinel);
      }
    },
    async addFavorite() {
      try {
        await this.fetchPosts();
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    },
    async deletePost(postId) {
      if (!postId) {
        console.error("Invalid postId:", postId);
        return;
      }
      try {
        await this.$store.dispatch("fiiyd/deletePost", postId);
        // Retirer le post de visiblePosts sans recharger tous les posts
        this.visiblePosts = this.visiblePosts.filter(
          (post) => post.IdPost !== postId
        );

        // Optionnel : Si vous avez besoin de mettre à jour d'autres états ou effectuer d'autres actions
        // par exemple, mettre à jour fiiydsCount si nécessaire
      } catch (error) {
        console.error("Erreur lors de la suppression d'un post:", error);
      }
    },
  },
  watch: {
    posts() {
      // Si vous souhaitez que visiblePosts reflète automatiquement l'état global
      this.visiblePosts = [];
      this.currentBatch = 1;
      this.hasMorePosts = true;

      this.loadMorePosts();

      if (this.observer) {
        this.observer.disconnect();
        this.setupObserver();
      }
    },
  },
  async mounted() {
    await this.loadInitialPosts();
    await this.fetchFiiyds();
    this.setupObserver();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
}
.boites {
  /* max-width: 1100px; */
  width: 100%;
  display: block;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  min-height: 100vh;
}

.loading {
  display: flex;
  justify-content: center;
}

.content-with-sidebars {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.content-with-sidebars .left {
  width: 20%;
}

.content-with-sidebars .middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(60% - 40px);
  border-right: 1px solid var(--card);
  border-left: 1px solid var(--card);
}

.content-with-sidebars .right {
  width: 20%;
}

.container {
  padding-bottom: 30px;
  min-height: 100vh;
}

.icons ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icons ul li {
  padding-bottom: 20px;
  padding-right: 10px;
}

.icons ul li a {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icons ul li a span {
  font-size: 20px;
  font-weight: 500;
}

.size-box-v {
  display: flex;
  justify-content: center;
  max-width: 550px;
  width: 100%;
}

.size-box-v .fil {
  width: 100%;
}

.post-input {
  padding: 0 20px;
}

.bell {
  position: relative;
  z-index: 1;
}

.bell span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  padding: 10px;
  background: var(--button);
  color: #fff;
  border-radius: 50%;
  z-index: 2;
  margin-right: -15px;
  margin-top: -14px;
  font-size: 10px !important;
  font-weight: 700;
}

.tabs {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  background: var(--card);
  width: 100%;
  z-index: 10;
}

@media (max-width: 998px) {
  .boites {
  }

  .content-with-sidebars .left {
    width: calc(10% - 20px);
  }

  .content-with-sidebars .left span {
    display: none;
  }

  .content-with-sidebars .middle {
    width: calc(75% - 20px);
  }

  .content-with-sidebars .right {
    width: calc(15% - 20px);
    display: none;
  }
}

@media (max-width: 800px) {
  .boites {
    margin-top: 100px;
  }
  .content-with-sidebars .left {
    width: 100%;
    display: none;
  }

  .content-with-sidebars .middle {
    width: 100%;
    border: none;
  }

  .content-with-sidebars .right {
    width: 100%;
    display: none;
  }

  .size-box-v .fil {
    padding: 0;
  }

  .post-input {
    padding: 0;
  }

  .tabs {
    display: block;
  }
}
</style>
