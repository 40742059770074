<template>
  <div class="box-arrows">
    <div class="arrow left" @click="scrollLeft"><Leftslide /></div>
    <div class="arrow right" @click="scrollRight">
      <Rightslide />
    </div>
  </div>
</template>

<script>
import Leftslide from "../icons/Leftslider.vue";
import Rightslide from "../icons/Rightslider.vue";
export default {
  name: "slider-doonun",
  props: {
    cible: String,
  },
  components: { Leftslide, Rightslide },

  methods: {
    scrollLeft() {
      const container = document.querySelector(`.${this.cible}`);
      if (container) {
        container.scrollTo({
          left: container.scrollLeft - 320,
          behavior: "smooth",
        });
      }
    },
    scrollRight() {
      const container = document.querySelector(`.${this.cible}`);
      if (container) {
        container.scrollTo({
          left: container.scrollLeft + 320,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped>
.box-arrows {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.arrow {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  background: #c3dbff;
  filter: blur(50px);
  opacity: 0.8;
  z-index: 1;
  width: 65px;
  height: 100%;
  opacity: 0;
  transition: 0.3s ease-in;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.arrow:hover {
  opacity: 1;
}

@media (max-width: 800px) {
  .box-arrows {
    display: none;
  }
}
</style>
