<template>
  <svg
    class="hamburger"
    @click.prevent.stop="toggleSidebar"
    xmlns="http://www.w3.org/2000/svg"
    width="20.64"
    height="24"
    viewBox="0 0 20.64 24"
  >
    <g
      id="vuesax_linear_menu"
      data-name="vuesax/linear/menu"
      transform="translate(-684 -380)"
    >
      <g id="menu" transform="translate(684 380)">
        <path
          id="Vector"
          d="M0,0H7.32"
          transform="translate(10.32 7)"
          fill="none"
          stroke="#292d32"
          stroke-linecap="round"
          stroke-width="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H14.64"
          transform="translate(3 12)"
          fill="none"
          stroke="#292d32"
          stroke-linecap="round"
          stroke-width="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H7.32"
          transform="translate(3 17)"
          fill="none"
          stroke="#292d32"
          stroke-linecap="round"
          stroke-width="1"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H20.64V24H0Z"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "hamber-doonun",
};
</script>

<style scoped>
.hamburger {
  height: 30px;
  width: 30px;
  pointer-events: none;
}

label svg path {
  stroke: var(--link);
}
</style>
