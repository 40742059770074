<template>
  <Head>
    <title>Bibliothèque - Doonun</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container">
    <h1>{{ $t("titlebook") }}</h1>
    <div class="search">
      <el-input
        v-model="input1"
        @input="handleSearch"
        style="max-width: 640px; width: 100%"
        size="large"
        :placeholder="$t('searchbooks')"
        :suffix-icon="Search"
        class="book-search"
      />
    </div>
    <div class="pag" v-if="totalLivres > itemsPerPage">
      <el-pagination
        :pager-count="5"
        layout="prev, pager, next"
        v-model:current-page="currentPage"
        :page-size="itemsPerPage"
        :total="totalLivres"
        @current-change="handlePageChange"
      />
    </div>
    <div class="loading" v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <div class="container-book">
        <div class="item" v-for="livre in paginatedLivres" :key="livre?.id">
          <cardBook
            :id="livre?.id"
            :titre="livre?.attributes?.titre"
            :lien="livre?.attributes?.url"
            :cover="livre?.attributes?.cover.data.attributes.formats.small.url"
            :favoris="Boolean(livre?.attributes?.users?.data[0]?.id)"
            @addFavorite="addFavorite"
          />
        </div>
      </div>
    </div>
    <div class="pag" v-if="totalLivres > itemsPerPage">
      <el-pagination
        :pager-count="5"
        layout="prev, pager, next"
        v-model:current-page="currentPage"
        :page-size="itemsPerPage"
        :total="totalLivres"
        @current-change="handlePageChange"
      />
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import cardBook from "../../components/livres/cardBook.vue";
import Loading from "../../components/navigation/Loading.vue";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "livres-doonun",
  components: { cardBook, Loading, Head, Tabs },
  data() {
    return {
      loading: true,
    };
  },
  setup() {
    const input1 = ref("");
    const currentPage = ref(1);
    const itemsPerPage = 20;

    const handleSearch = () => {
      currentPage.value = 1;
    };

    return {
      input1,
      Search,
      currentPage,
      itemsPerPage,
      handleSearch,
    };
  },
  computed: {
    ...mapGetters("livres", ["livres"]),
    filteredLivres() {
      if (!this.input1) {
        return this.livres;
      }
      const searchTerm = this.input1.toLowerCase();
      return this.livres.filter((livre) =>
        livre.attributes.titre.toLowerCase().includes(searchTerm)
      );
    },
    paginatedLivres() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredLivres.slice(start, end);
    },
    totalLivres() {
      return this.filteredLivres.length;
    },
  },
  methods: {
    ...mapActions("livres", ["fetchLivres"]),
    async listBooks() {
      this.loading = true;
      try {
        await this.fetchLivres(this?.$i18n?.locale);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async addFavorite() {
      try {
        await this.fetchLivres(this?.$i18n?.locale);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.listBooks();
  },
  watch: {
    "$i18n.locale"() {
      this.listBooks();
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  min-height: 100vh;
  z-index: 2;
}

h1 {
  font-size: clamp(20px, 8vw, 30px);
  text-align: center;
  margin-bottom: 20px;
}

.container-book {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: stretch;
  justify-content: left;
  gap: 10px;
  margin-top: 20px;
}

.item {
  width: 100%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
}

.pag {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.el-pagination {
  background: transparent !important;
}

@media (max-width: 600px) {
  .container-book {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
</style>
