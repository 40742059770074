<template>
  <router-link :to="`/sourate/${Numero}`">
    <div class="box-card">
      <div class="top">
        <div class="left">
          <p class="num">{{ Numero }}</p>
          <p class="title">{{ Phonetique }}</p>
        </div>
        <div class="like">
          <div class="con-like" @click.stop="toggleLike">
            <input
              class="like"
              type="checkbox"
              title="like"
              :checked="isLiked"
            />
            <div class="checkmark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="outline"
                viewBox="0 0 24 24"
              >
                <path
                  d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="filled"
                viewBox="0 0 24 24"
              >
                <path
                  d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"
                ></path>
              </svg>
            </div>
          </div>
          <p>{{ localLike }}</p>
        </div>
      </div>
      <div class="middle">
        <p>
          <span>{{ Arabe }}</span> : {{ Titre }} {{ $t("reveal") }}
          {{ Revelation }}, {{ $t("contain") }} {{ Nombre }} {{ $t("nbverse") }}
        </p>
      </div>
      <div class="bottom">
        <div class="demo-progress" v-if="isAuthenticated">
          <el-progress
            class="progression"
            :percentage="taux"
            :format="format"
            :color="customColors"
          />
        </div>
        <p class="vues">{{ vues }} {{ $t("vues") }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ref, computed } from "vue";

export default {
  name: "card-coran",
  components: {},
  props: {
    Titre: String,
    Numero: Number,
    Arabe: String,
    Phonetique: String,
    Revelation: String,
    Nombre: String,
    like: Number,
    vues: Number,
    taux: Number,
    fav: Boolean,
  },
  data() {
    return {
      customColors: [
        { color: "#FEC733", percentage: 20 },
        { color: "#FC68EB", percentage: 40 },
        { color: "#F88055", percentage: 60 },
        { color: "#0738FD", percentage: 80 },
        { color: "red", percentage: 99 },
        { color: "#2BC971", percentage: 100 },
      ],
      localLike: this.like, // Initialiser le nombre de likes localement
    };
  },
  setup(props) {
    const format = (percentage) =>
      percentage === 100 ? "100%" : `${percentage}%`;
    const percentage = ref(50);

    const isLiked = computed(() => props.fav);

    return { percentage, format, isLiked };
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("like", ["updateLike"]),
    toggleLike() {
      if (!this.isAuthenticated) {
        // Afficher un message d'erreur ou rediriger vers la page de connexion
        return;
      }

      const liked = !this.isLiked;
      const id = this.Numero;
      const like = this.localLike + (liked ? 1 : -1); // Calculer le nouveau nombre de likes
      this.updateLike({ id, liked, like })
        .then(() => {
          // Mettre à jour l'état local après la réussite de la mise à jour du backend
          this.localLike = like;
          this.$emit("likeChanged");
        })
        .catch((error) => {
          console.error("Error toggling like:", error);
        });
    },
  },
};
</script>

<style scoped>
.box-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 240px;
  max-width: 640px;
  width: 100%;
  height: 100%;
  background: var(--card);
  border-radius: 10px;
  padding: 1.3rem;
  /* transition: 0.2s ease; */
}

.box-card:hover {
  /* transform: scale(0.99); */
}

.top {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
}

.top .left {
  font-size: 16px;
}

.ornement {
  position: absolute;
  margin-top: 5px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.top .left .num {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  background: var(--body);
  border-radius: 6px;
  font-size: 13px;
  font-weight: 600 !important;
  z-index: 3;
  margin-bottom: 5px;
}

.top .left .title {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.like {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 13px;
}

.like p {
  font-size: 12px;
}

.middle {
  margin: 20px 0;
}

.middle p {
  font-size: 14px;
}

.middle p span {
  font-weight: 600;
  font-family: "Noto Sans Arabic", sans-serif;
}

.vues {
  margin-top: 10px;
  font-size: 12px;
}

.el-progress__text span {
  font-size: 13px;
}

.con-like {
  --red: rgb(255, 91, 137);
  position: relative;
  width: 20px;
  height: 20px;
}

.con-like .like {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.con-like .checkmark {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-sourates-fav .con-like .outline,
.box-sourates-fav .con-like .filled {
  fill: var(--red) !important;
  transition: 0.2s ease-in;
}

.box-sourates-fav .con-like:hover .outline,
.box-sourates-fav .con-like:hover .filled {
  fill: var(--color) !important;
}

.con-like .outline,
.con-like .filled {
  fill: var(--color);
  position: absolute;
}

.con-like .filled {
  animation: kfr-filled 0.5s;
  display: none;
}

.con-like .poly {
  stroke: var(--red);
  fill: var(--red);
}

.con-like .like:checked ~ .checkmark .filled {
  display: block;
}
</style>
