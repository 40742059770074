<template>
  <router-link :to="`/profil/${this.user.id}`">
    <div class="avatar">
      <img
        :src="`https://cp.doonun.com${
          avatarUrl || '/uploads/small_plant_f9f00dabd7.png'
        }`"
        :alt="this.user.username"
      />
    </div>
  </router-link>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AvatarDoonun",
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("avatar", {
      avatarUrl: (state) => state.avatar,
    }),
  },
  async created() {
    // Ensure the user data is loaded before attempting to access the avatar ID
    try {
      await this.fetchUser();
      if (this.user && this.user.avatar && this.user.avatar.id) {
        const avatarId = this.user.avatar.id; // Access the user's avatar ID
        await this.fetchAvatar(avatarId);
      } else {
        console.error("User or user avatar ID not found");
      }
    } catch (error) {
      console.error("Error fetching user or avatar:", error);
    }
  },
  methods: {
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("avatar", ["fetchAvatar"]),
  },
};
</script>

<style scoped>
.avatar {
  max-width: 35px;
  max-height: 35px;
  background: var(--body);
  border-radius: 10px;
}

.avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
</style>
