<template>
  <div class="container-sub">
    <div class="block-login">
      <div class="card-login">
        <router-link to="/">
          <h1>Doonun</h1>
        </router-link>
        <Head>
          <title>Connexion</title>
          <meta name="robots" content="Noindex, nofollow" />
          <link
            rel="icon"
            href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
          />
        </Head>

        <div class="log-r">
          <div class="google" @click="authenticateWithGoogleBtn">
            <img
              src="https://cp.doonun.com/uploads/google_efae55871e.svg"
              alt="google"
            />
          </div>
        </div>
        <div class="a-input">
          <span></span>
          <p>{{ $t("or") }}</p>
          <span></span>
        </div>
        <div class="form-log">
          <form @submit.prevent="handleLogin">
            <div class="label-input">
              <input
                name="email"
                type="email"
                placeholder="Email"
                v-model="email"
                @input="clearError('email')"
              />
              <p class="err-mail">{{ errors.email }}</p>
              <div class="mdp-c">
                <input
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="$t('password')"
                  v-model="password"
                  @input="clearError('password')"
                />
                <span class="toggle-password" @click="togglePasswordVisibility">
                  <openEye v-if="showPassword" />
                  <CloseEye v-if="!showPassword" />
                </span>
              </div>

              <p class="err-mdp">{{ errors.password }}</p>
            </div>
            <div class="forget-rem">
              <router-link to="/oublie">
                <p>{{ $t("lost") }}</p>
              </router-link>
            </div>
            <button>{{ $t("loginbtn") }}</button>
          </form>
          <router-link class="sub-c" to="/inscription">
            <span>{{ $t("createctn") }}</span>
          </router-link>
        </div>
      </div>
      <div class="card-img-log"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import openEye from "../../components/icons/openEye.vue";
import CloseEye from "../../components/icons/closeEye.vue";

export default {
  name: "Connexion-d",
  components: {
    openEye,
    CloseEye,
  },
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      errors: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions("login", ["login", "checkEmail"]),
    ...mapActions("google", ["authenticateWithGoogle"]),

    async handleLogin() {
      this.errors.email = "";
      this.errors.password = "";

      if (!this.email || !this.password) {
        if (!this.email) {
          this.errors.email = this.$t("errors.email_required");
        }
        if (!this.password) {
          this.errors.password = this.$t("errors.password_required");
        }
        return;
      }

      const emailExists = await this.checkEmail(this.email);

      if (!emailExists) {
        this.errors.email = this.$t("errors.email_not_exist");
        return;
      }

      const isLoggedIn = await this.login({
        identifier: this.email,
        password: this.password,
      });

      if (isLoggedIn) {
        window.location.href = "/";
      } else {
        this.errors.password = this.$t("errors.incorrect_credentials");
      }
    },

    authenticateWithGoogleBtn() {
      this.authenticateWithGoogle();
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    clearError(field) {
      this.errors[field] = "";
    },
  },
};
</script>

<style scoped>
.container-sub {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--body);
  z-index: 999;
}

.block-login {
  display: flex;
  justify-content: center;
  align-items: stretch;
  min-height: 100%;
}

.card-img-log {
  width: 480px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-radius: 10px;
  /* border: 1px solid var(--colorMD); */
  min-height: 570px;
  height: auto;
  background-image: url("https://cp.doonun.com/uploads/login_flower_34191413f4.png");
}

.card-login {
  width: 100%;
  max-width: 400px;
  height: auto;
  /* background-color: var(--card); */
  padding: 30px;
  border-radius: 5px;
  /* border: 1px solid var(--colorMD); */
  border-right: 0;
}

.card-login h1 {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 28px;
}

.log-r {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.log-r .google {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 5px;
  border: 1px solid var(--color);
  border-radius: 6px;
  font-size: 13px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.log-r img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.a-input {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.a-input p {
  width: auto;
  font-weight: 300;
  text-align: center;
}

.a-input span {
  height: 0.5px;
  background: var(--color);
  width: 100%;
  max-width: 25%;
}

.label-input {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.label-input input {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  border: 1px solid var(--color);
  background: var(--card);
  border-radius: 6px;
  color: var(--color);
  font-weight: 500;
  font-size: 15px;
}

.label-input input:focus {
  outline: none;
}

.label-input label {
  margin: 0px 0 10px 0;
}

.forget-rem {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  font-weight: 300;
  font-size: 14px;
}

.remember {
  display: flex;
  align-items: center;
  gap: 5px;
}

.form-log form button {
  margin-top: 10px;
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 6px;
  color: var(--colorBtn);
  font-weight: 600;
  background: var(--color);
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.form-log form button:hover {
  background: var(--button);
  color: #fff;
}

.sub-c {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mdp-c {
  display: flex;
  align-items: center;
  gap: 5px;
  background: var(--card);
}

.mdp-c input {
  border: none;
  margin: 0 !important;
}

.mdp-c {
  border: 1px solid var(--color);
  padding: 0 15px 0 0;
  border-radius: 6px;
}

.toggle-password {
  cursor: pointer;
}

.err-mdp,
.err-mail {
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(255, 57, 57);
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .container-sub {
    display: block !important;
    padding: 0 !important;
  }

  .block-login {
    margin-top: -40px !important;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 100vh !important;
    height: auto;
    align-items: flex-start;
  }

  .card-img-log {
    display: none;
  }

  .card-login {
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .card-login {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>
