<template>
  <div class="wrong">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "wrong-icon",
};
</script>

<style scoped>
.wrong {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  background: #fef0f0;
}

.wrong svg {
  width: 25px;
  height: 25px;
}

.wrong svg path {
  stroke: #f56c6c;
}
</style>
