import axios from "axios";

const state = {
  avatar: null,
};

const mutations = {
  SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
};

const actions = {
  async fetchAvatar({ commit }, avatarId) {
    try {
      const response = await axios.get(`/avatars/${avatarId}?populate=*`);
      // console.log("avatar :", response);
      // Assuming 'small' format is always available as per the requirement
      const avatarUrl =
        response.data.data.attributes.Avatar.data.attributes.formats.small.url;
      commit("SET_AVATAR", avatarUrl);
      return avatarUrl;
    } catch (error) {
      // console.error("Error fetching avatar:", error);
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
