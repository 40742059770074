<template>
  <div class="twitter">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M3 21L10.5484 13.4516M21 3L13.4516 10.5484M13.4516 10.5484L8 3H3L10.5484 13.4516M13.4516 10.5484L21 21H16L10.5484 13.4516"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "twitter-icon",
};
</script>

<style scoped>
.twitter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.twitter svg {
  width: 20px;
  height: 20px;
}

.twitter svg path {
  stroke: var(--color);
}
</style>
