<template>
  <router-link :to="courseUrl">
    <div class="box-card" ref="cardContainer">
      <span class="new" v-if="isNewCourse">{{ $t("newCourse") }}</span>
      <img :src="`https://cp.doonun.com${cover}`" alt="" />
      <div class="bottom">
        <div>
          <div class="top">
            <p v-if="!percent" class="pts">{{ points }} pts</p>
            <p v-else class="pts gain">+{{ gain }} pts</p>
            <p class="category">{{ category }}</p>
            <p class="level">{{ level }}</p>
          </div>
          <div class="middle">
            <h3>{{ titre }}</h3>
          </div>
        </div>
        <div>
          <div class="demo-progress" v-if="isAuthenticated">
            <div class="lock" v-if="!percent">
              <!-- <div class="group">
                <Group />
                <span>10</span>
              </div> -->

              <button
                class="lock-btn"
                type="primary"
                color="#0c0020"
                @click.prevent="unlockCours()"
                v-if="!loading"
              >
                <Hass /> {{ $t("unlock") }}
              </button>
              <Loading v-else />
            </div>
            <el-progress
              v-else
              :percentage="Number(percent)"
              :format="format"
              :color="customColors"
            />
          </div>
        </div>
      </div>
    </div>
  </router-link>
  <el-dialog
    v-model="centerDialogVisible"
    :title="$t('warningTitleM')"
    width="500"
    align-center
  >
    <span>{{ $t("messagePopupC") }}</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Ok</el-button>
        <el-button type="primary" @click="goToSubscription">
          {{ $t("btnSub1") }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import confetti from "../../assets/js/confetti";
import Hass from "../icons/Hass.vue";
import Loading from "../navigation/Loading.vue";
import { ref } from "vue";

export default {
  name: "card-course",
  components: {
    Hass,
    Loading,
  },
  props: {
    id: Number,
    titre: String,
    url: String,
    percent: String,
    cover: String,
    points: String,
    gain: String,
    level: String,
    category: String,
    publishedAt: String,
  },
  data() {
    return {
      userPoints: 0,
      loading: false,
      centerDialogVisible: false,
    };
  },
  setup() {
    const customColors = [
      { color: "#FEC733", percentage: 20 },
      { color: "#FC68EB", percentage: 40 },
      { color: "#F88055", percentage: 60 },
      { color: "#0738FD", percentage: 80 },
      { color: "#2BC971", percentage: 100 },
    ];
    const format = (percentage) =>
      percentage === 100 ? "100%" : `${percentage}%`;
    const percentage = ref(50);
    return { percentage, format, customColors };
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    ...mapState("user", ["user"]),
    courseUrl() {
      if (this.isAuthenticated) {
        return this.percent ? `/cours/${this.url}` : "";
      } else {
        return "/connexion";
      }
    },
    isNewCourse() {
      const publishedDate = new Date(this.publishedAt);
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 4);
      return publishedDate > currentDate;
    },
  },
  methods: {
    ...mapActions("points", ["updateUserPoints"]),
    ...mapActions("cours", ["createCourseProgress"]),

    ...mapActions("user", ["fetchUser"]),
    triggerConfetti() {
      const cardRect = this.$refs.cardContainer.getBoundingClientRect();
      confetti({
        particleCount: 150,
        spread: 60,
        origin: {
          x: (cardRect.left + cardRect.right) / 2 / window.innerWidth,
          y: (cardRect.top + cardRect.bottom) / 2 / window.innerHeight,
        },
      });
    },
    goToSubscription() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "tarifs" });
    },
    async unlockCours() {
      this.loading = true;
      try {
        if (
          Number(this.user.hassanate.points) >= Number(this.points) ||
          this.isSubscribed
        ) {
          if (!this.isSubscribed) {
            let newPoints =
              Number(this.user.hassanate.points) - Number(this.points);
            await this.updateUserPoints(newPoints);
            this.user.hassanate.points = newPoints;
          }
          await this.createCourseProgress({ url: this.url, idCourse: this.id });
          this.triggerConfetti();
          this.$emit("unlock");
        } else {
          if (!this.user.hassanate.points) {
            await this.updateUserPoints(0);
            // console.log("hassanate crée");
          }
          // console.log("points : ", this.user.hassanate.points);
          // console.log(
          //   "Vous n'avez pas suffisamment de points pour débloquer ce cours."
          // );
          this.centerDialogVisible = true;
        }
      } catch (error) {
        console.error("Erreur lors du déblocage du cours :", error);
      } finally {
        this.loading = false; // Fin du chargement
      }
    },
  },
  async created() {
    await this.fetchUser();
  },
};
</script>

<style scoped>
.box-card {
  position: relative;
  min-width: 240px;
  height: 100%;
  transition: 0.2s ease;
  border-radius: 10px;
}

.new {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  background: #ff6370;
  color: #fff;
  border-radius: 3px;
  padding: 4px 8px;
  font-size: 12px;
}

.box-card img {
  width: 100%;
  height: 140px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--card);
  margin-top: -10px;
  padding: 1rem;
  border-radius: 8px;
  /* border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6; */
  /* border-bottom: 1px solid #dcdfe6; */
  min-height: 190px;
  max-height: 200px;
}

.bottom .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom .top p {
  font-size: 13px;
  color: var(--link);
}

.group {
  display: flex;
  align-items: center;
  gap: 3px;
}

.group span {
  font-size: 13px;
  color: var(--link);
}

.pts {
  color: #fbc33b !important;
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 4px;
  background: #fff9eb;
  font-size: 10px !important;
}

.gain {
  background: #f0f9eb !important;
  color: #9bd77d !important;
}

.middle {
  margin-top: 20px;
}

.middle h3 {
  font-size: 15px;
  font-weight: 400;
}

.demo-progress {
  margin-top: 20px;
  padding-bottom: 10px;
}

.lock {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.lock-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: var(--card);
  color: var(--color);
  padding: 8px 13px 8px 10px;
  border-radius: 4px;
  border: 1px solid var(--color);
  font-size: 12px;
  gap: 3px;
}

.lock-btn:hover {
  background: var(--button);
  border: 1px solid var(--button);
  color: #fff;
}
</style>
