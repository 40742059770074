<template>
  <router-link class="link-book" :to="livreUrl">
    <div class="card-book" @click="showSubscriptionDialog()">
      <div class="top">
        <img :src="`https://cp.doonun.com${cover}`" alt="titre" />
        <h3>{{ titre }}</h3>
      </div>
      <div class="icon" v-if="isAuthenticated" @click.prevent="toggleFavoris">
        <MarkCheck v-if="isFavoris" />
        <Mark v-else />
      </div>
    </div>
  </router-link>
  <el-dialog
    v-model="centerDialogVisible"
    :title="`${$t('warningTitleM')}`"
    width="500"
    align-center
  >
    <span>{{ $t("messageBook") }}</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Ok</el-button>
        <el-button type="primary" @click="goToSubscription">
          {{ $t("btnSub1") }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref, watch } from "vue";
import MarkCheck from "../icons/MarkCheck.vue";
import Mark from "../icons/Mark.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "card-book",
  components: {
    MarkCheck,
    Mark,
  },
  props: {
    id: Number,
    lien: String,
    titre: String,
    cover: String,
    favoris: Boolean,
  },
  data() {
    return {
      centerDialogVisible: false,
    };
  },
  setup(props) {
    const isFavoris = ref(props.favoris);

    watch(
      () => props.favoris,
      (newVal) => {
        isFavoris.value = newVal;
      }
    );

    return { isFavoris };
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    livreUrl() {
      if (this.isAuthenticated) {
        if (this.isSubscribed) {
          return `/livres/${this.lien}`;
        } else {
          return "";
        }
      } else {
        return "/connexion";
      }
    },
  },
  methods: {
    ...mapActions("user", ["addBookToFavorites", "removeBookFromFavorites"]),
    async toggleFavoris() {
      if (this.isAuthenticated) {
        if (this.isFavoris) {
          await this.removeBookFromFavorites(this.id);
        } else {
          await this.addBookToFavorites(this.id);
        }
        this.isFavoris = !this.isFavoris;
        this.$emit("addFavorite"); // Si vous avez besoin d'émettre un événement pour notifier le parent
      } else {
        alert("Veuillez vous connecter pour ajouter des favoris.");
      }
    },
    showSubscriptionDialog() {
      if (!this.isSubscribed) {
        this.centerDialogVisible = true;
      }
    },
    goToSubscription() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "tarifs" });
    },
  },
};
</script>

<style scoped>
.link-book {
  display: inline-block;
  min-width: 100%;
  max-width: 250px;
}
.card-book {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  min-height: 320px;
  max-height: 320px;
  max-width: 250px;
  gap: 5px;
  background: var(--card);
  z-index: 1;
  border-radius: 6px;
}

.top {
  width: 100%;
}

.card-book img {
  width: 100%;
  height: 100%;
  max-height: 180px;
  object-fit: cover;
  object-position: top;
  z-index: 2;
  border-radius: 6px 6px 0 0;
}

/* .card-book img:hover {
  filter: grayscale(0%);
} */

.icon {
  display: flex;
  justify-content: end;
  margin-right: 10px;
  padding-bottom: 20px;
  z-index: 3;
}

h3 {
  margin-top: 5px;
  font-size: 14px;
  padding: 0 15px;
  font-weight: 500;
}
</style>
