<template>
  <div class="logo">
    <svg
      id="D_x5F_spirale"
      xmlns="http://www.w3.org/2000/svg"
      width="278.533"
      height="258.504"
      viewBox="0 0 278.533 258.504"
    >
      <path
        id="spirale_noir"
        d="M362.225,192.414a104.947,104.947,0,0,0-45.764-44.837q-30.015-15.37-72.628-15.377H151.2v67.367a73.147,73.147,0,0,0,38.167,64.239h0V164.069h51.874c64.968,0,103.266,33.225,103.266,99.675a106.155,106.155,0,0,1-30.271,75.22c-8.977,9.247-19.552,15.642-34.234,15.642-30.107,0-37.713-18.152-42.694-28.172-.734-1.472-16.25-42.226-50.315-54.051a80.225,80.225,0,0,0-41.748-3.34c-44.162,10.2-47.207,58.486-45.46,72.922,5.01,41.377,40.788,50.035,61.112,48.592,19.378-1.38,38.124-18.963,37.12-38.6-1.815-35.455-36.073-35.6-45.663-25.6,0,0,18.094-2.915,23.2,11.467,3.919,11.052-4.267,24.306-16.979,24.122-23.287-1.018-28.669-18.924-29.5-25.7-1.105-8.963,2.968-29.76,24.214-34.885,36.647-8.234,56.9,43.6,57.236,44.22,32.689,60.933,94.617,40.561,105.563,34.885a107.083,107.083,0,0,0,45.947-45.021q15.927-29.458,15.932-71.7Q377.981,221.87,362.225,192.414Z"
        transform="translate(-99.425 -132.2)"
        fill="#fff"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "logo-d",
};
</script>

<style>
.logo svg {
  width: 20px;
  height: 20px;
}

.logo svg path {
  fill: var(--color);
}

@media (max-width: 360px) {
  .logo svg {
    width: 15px;
    height: 15px;
  }
}
</style>
