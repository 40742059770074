<template>
  <div class="options">
    <img
      v-if="!showSelect && getSelectedSinger()"
      :src="getSelectedSinger().img"
      alt=""
      class="img-s"
      @click="toggleSelect"
      style="
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
      "
    />
    <div v-else>
      <el-select
        v-model="selectedSinger"
        placeholder="Choisir un récitateur"
        style="max-width: 260px; min-width: 260px"
        @change="changeSinger"
      >
        <el-option
          v-for="singer in singers"
          :key="singer.value"
          :label="singer.name"
          :value="singer.value"
          style="height: 50px"
        >
          <template #default>
            <div class="singer">
              <img
                :src="singer.img"
                alt=""
                style="
                  width: 40px;
                  height: 40px;
                  margin-right: 8px;
                  border-radius: 50%;
                  object-fit: cover;
                  object-position: center;
                "
              />
              <span>{{ singer.name }}</span>
            </div>
          </template>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "recitateur-doonun",
  setup() {
    const selectedSinger = ref("Hussary");
    const showSelect = ref(false);

    const singers = [
      {
        value: "Abdelbaset",
        name: "Abdel Basset Abdel Samad",
        img: "https://cp.doonun.com/uploads/IMG_6960_d2b78c8bba.jpeg",
      },
      {
        value: "Alafasy",
        name: "Mishary bin Rashid Alafasy",
        img: "https://cp.doonun.com/uploads/mishary_rashid_alafasy_profile_0c2abf7ffc.jpeg",
      },
      {
        value: "Alaqimy",
        name: "Akram Al Aalakmi",
        img: "https://cp.doonun.com/uploads/akram_al_aalakmi_e0e5acafd7.png",
      },
      {
        value: "Ali_Jaber",
        name: "Ali Jaber",
        img: "https://cp.doonun.com/uploads/4357d32cc152d4e3b8f8c37fd525b806_298a108068.jpg",
      },
      {
        value: "Alqatami",
        name: "Nasser Al Qatami",
        img: "https://cp.doonun.com/uploads/unnamed_b5e7c7eaf4.jpg",
      },
      {
        value: "Bafsar",
        name: "Abdullah Basfar",
        img: "https://cp.doonun.com/uploads/unnamed_9f3d55d4a8.png",
      },
      {
        value: "Bukhatir",
        name: "Salah Bukhatir",
        img: "https://cp.doonun.com/uploads/51_A2gm5b_RQL_UX_Na_N_F_Mjpg_QL_85_c1614cc41d.jpg",
      },
      {
        value: "Dussary",
        name: "Yasser Ad-Dussary",
        img: "https://cp.doonun.com/uploads/timthumb_02527beeaf.jfif",
      },
      {
        value: "Hani_Rifai",
        name: "Hani Ar Rifai",
        img: "https://cp.doonun.com/uploads/hani_ar_rifai_1024x1024_084b3d0615.jpg",
      },
      {
        value: "Hudhaify",
        name: "Ali Abdur-Rahman al-Huthaify",
        img: "https://cp.doonun.com/uploads/ely_alhdhyfy_95605eca26.jpg",
      },
      {
        value: "Hussary",
        name: "Mahmoud Khalil Al Hussary",
        img: "https://cp.doonun.com/uploads/mahmoud_khalil_al_hussary_9c7e83ab0d.webp",
      },
      {
        value: "Hussary_Mujawad",
        name: "Al Hussary Mujawad",
        img: "https://cp.doonun.com/uploads/F_Mx_E1_KYXIA_Ywyg_B_0defbd0183.jpg",
      },
      {
        value: "Murratal",
        name: "Mohamed Siddiq al-Minshawi",
        img: "https://cp.doonun.com/uploads/58899093f5a473089d1ff272756af958_9b8926d604.jpg",
      },
      {
        value: "Salamah",
        name: "Yasser Salama",
        img: "https://cp.doonun.com/uploads/yasser_salama_5a1e72d76f.jpg",
      },
      {
        value: "Shaatree",
        name: "Abu Bakr al-Shatri",
        img: "https://cp.doonun.com/uploads/abu_bakr_al_shatri_pofile_cd981a91c9.jpeg",
      },
      {
        value: "Shuraym",
        name: "Saood bin Ibraaheem Ash-Shuray",
        img: "https://cp.doonun.com/uploads/VS_2sgs_B_Clj_Vwp_G_Detg8_B2_D_Zz3_Zo_W8_F4_Y_Onab_Ym_ZU_Ko_Y_59152294f8.webp",
      },
      {
        value: "Suesy",
        name: "Ali Hajjaj Al Suesy",
        img: "https://cp.doonun.com/uploads/ely_hjaj_alswysy_eabb3ed685.png",
      },
    ];

    const getSelectedSinger = () => {
      return singers.find((singer) => singer.value === selectedSinger.value);
    };

    const toggleSelect = () => {
      showSelect.value = !showSelect.value;
    };

    return {
      selectedSinger,
      singers,
      getSelectedSinger,
      showSelect,
      toggleSelect,
    };
  },
  methods: {
    changeSinger(singer) {
      this.$emit("singer-changed", singer); // Émet l'événement avec la valeur sélectionnée
      this.toggleSelect();
    },
  },
};
</script>

<style scoped>
.options {
  display: flex;
  align-items: center;
}

.singer {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.img-s {
  outline: 3px solid var(--card);
  transition: 0.2s ease-in-out;
}

.img-s:hover {
  outline: 4px solid var(--body);
  transform: scale(1.1);
}
</style>
