<template>
  <div>
    <transition name="fade">
      <div v-if="isOpen" class="popup-overlay" @click="togglePopup">
        <div class="popup" @click.stop>
          <div class="popup-header">
            <h2>{{ $t("share") }}</h2>
            <button @click="togglePopup" class="close-button">
              <el-icon><Close /></el-icon>
            </button>
          </div>
          <div class="share-options">
            <a
              v-for="option in shareOptions"
              :key="option.name"
              :href="option.buildUrl(message, fullUrl)"
              target="_blank"
              rel="noopener noreferrer"
              class="share-option"
            >
              <img :src="option.icon" :alt="option.name" class="share-icon" />
              <span>{{ option.name }}</span>
            </a>
          </div>
          <div class="page-link">
            <h3>{{ $t("copyLink") }}</h3>
            <div class="link-container">
              <input type="text" :value="fullUrl" readonly />
              <button @click="copyLink" class="copy-button">
                <el-icon><CopyDocument /></el-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SharePopup",
  props: {
    pageUrl: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      shareOptions: [
        {
          name: "Twitter",
          icon: "https://svgl.app/library/twitter.svg",
          type: "link",
          buildUrl(message, url) {
            return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              `${message} ${url}`
            )}`;
          },
        },
        {
          name: "Facebook",
          icon: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Facebook_colored_svg_copy-512.png",
          type: "link",
          buildUrl(message, url) {
            return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              url
            )}&quote=${encodeURIComponent(message)}`;
          },
        },
        {
          name: "Reddit",
          icon: "https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Reddit-256.png",
          type: "link",
          buildUrl(message, url) {
            return `https://www.reddit.com/submit?url=${encodeURIComponent(
              url
            )}&title=${encodeURIComponent(message)}`;
          },
        },
        {
          name: "WhatsApp",
          icon: "https://svgl.app/library/whatsapp.svg",
          type: "link",
          buildUrl(message, url) {
            return `https://api.whatsapp.com/send?text=${encodeURIComponent(
              `${message} ${url}`
            )}`;
          },
        },
        {
          name: "Messenger",
          icon: "https://svgl.app/library/messenger.svg",
          type: "link",
          buildUrl(message, url) {
            return `https://www.messenger.com/t/?link=${encodeURIComponent(
              url
            )}&quote=${encodeURIComponent(message)}`;
          },
        },
        {
          name: "Telegram",
          icon: "https://svgl.app/library/telegram.svg",
          type: "link",
          buildUrl(message, url) {
            return `https://t.me/share/url?url=${encodeURIComponent(
              url
            )}&text=${encodeURIComponent(`${message}`)}`;
          },
        },
        {
          name: "Gmail",
          icon: "https://svgl.app/library/gmail.svg",
          type: "link",
          buildUrl(message, url) {
            return `mailto:?subject=${encodeURIComponent(
              message
            )}&body=${encodeURIComponent(`${message}\n\n${url}`)}`;
          },
        },
      ],
      copyOption: {
        name: "Copier le message et le lien",
        icon: "https://svgl.app/library/copy.svg",
        type: "copy",
      },
    };
  },
  computed: {
    fullUrl() {
      return `https://doonun.com/${this.pageUrl}`;
    },
  },
  methods: {
    togglePopup() {
      this.isOpen = !this.isOpen;
    },
    async copyLink() {
      try {
        // Essayez d'abord d'utiliser le presse-papiers moderne
        await navigator.clipboard.writeText(this.fullUrl);
        this.$notify({
          title: "Succès",
          message: "Lien copié dans le presse-papiers !",
          type: "success",
        });
      } catch (err) {
        console.error("Échec de la copie du lien : ", err);

        // Si la copie échoue, utilisez un fallback avec un textarea
        const textarea = document.createElement("textarea");
        textarea.value = this.fullUrl;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);

        this.$notify({
          title: "Succès",
          message: "Lien copié dans le presse-papiers !",
          type: "success",
        });
      }
    },
    async copyMessageAndLink() {
      const textToCopy = `Invocation : ${this.message}\n\n${this.fullUrl}`;
      try {
        await navigator.clipboard.writeText(textToCopy);
        this.$notify({
          title: "Succès",
          message: "Message et lien copiés dans le presse-papiers !",
          type: "success",
        });
      } catch (err) {
        console.error("Échec de la copie du message et du lien : ", err);
        this.$notify({
          title: "Erreur",
          message: "Échec de la copie du message et du lien.",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.share-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
}

.popup {
  background-color: var(--card);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40px;
  max-height: 40px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  background: var(--body);
  padding: 10px;
  border-radius: 50%;
}

.share-options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 30px;
  justify-content: space-between;
}

.share-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.share-icon {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

a span {
  font-size: 12px;
}

.page-link {
  margin-top: 30px;
}

.link-container {
  display: flex;
  margin-top: 10px;
}

.link-container input {
  flex-grow: 1;
  padding: 10px;
  background: var(--body);
  border-radius: 6px 0 0 6px;
  color: var(--color);
  outline: none;
  border: none;
  border: 1px 1px 0 1px solid var(--color);
  font-size: 16px;
}

.copy-button {
  background-color: var(--body);
  border-left: none;
  border-radius: 0 6px 6px 0;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
