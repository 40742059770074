<template>
  <div>
    <h2 id="scroll-target">{{ $t("duaasCompTitle") }}</h2>
    <div class="duaas-container">
      <div class="container-duaa">
        <div class="item" v-for="duaa in duaasUser" :key="duaa.id">
          <CardDuaaUser
            :id="duaa.id"
            :arab="duaa?.attributes?.arab"
            :transcript="duaa?.attributes?.transcript"
            :translite="duaa?.attributes?.translite"
            :source="duaa.attributes?.source"
            :url_mp3="duaa?.attributes?.url_mp3"
            :category="
              duaa?.attributes?.situations?.data[0]?.attributes?.sujets?.data[0]
                ?.attributes?.name
            "
            :img="
              duaa?.attributes?.situations?.data[0]?.attributes?.sujets?.data[0]
                ?.attributes?.icon?.data?.attributes?.url
            "
            :slug="
              duaa?.attributes?.situations?.data[0]?.attributes?.sujets?.data[0]
                ?.attributes?.slug
            "
            :titreSlug="duaa?.attributes?.situations?.data[0]?.attributes?.slug"
            :titre="duaa?.attributes?.situations?.data[0]?.attributes?.name"
            :favoris="Boolean(duaa?.attributes?.users?.data[0]?.id)"
            @addFavorite="addFavorite"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardDuaaUser from "../duaas/CardDuaaUser";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "duaas-profil",
  components: {
    CardDuaaUser,
  },
  computed: {
    ...mapGetters("duaas", ["duaasUser"]),
    ...mapGetters("login", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("duaas", ["fetchUserDuaa"]),
    async addFavorite(duaaId) {
      try {
        await this.toggleDuaaInUser(duaaId); // Gère l'ajout/retrait des favoris
        await this.fetchUserDuaa(this.$i18n.locale); // Recharge les données après modification
      } catch (error) {
        console.error("Error fetching duaas:", error);
      }
    },
  },
  async mounted() {
    try {
      if (this.isAuthenticated) {
        await this.fetchUserDuaa(this.$i18n.locale);
      }
    } catch (error) {
      console.error("Error fetching user duaas:", error);
    }
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.container-duaa {
  display: flex;
  flex-direction: column;
  margin: 0 auto 10px auto;
  gap: 20px;
  position: relative;
  border-radius: 6px;
  max-width: 600px;
  width: 100%;
  min-height: 150px;
}
</style>
