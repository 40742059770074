<template>
  <div class="container">
    <div class="box-tarifs">
      <h2>{{ $t("titletarif") }}</h2>
      <div class="top-t">
        <el-switch
          v-model="value"
          size="large"
          :active-text="$t('year')"
          :inactive-text="$t('month')"
          style="--el-switch-on-color: #13ce66"
        />
      </div>
    </div>
    <div class="lists">
      <section class="plans__container">
        <div class="plans">
          <div class="item free">
            <div>
              <div class="top">
                <div class="titre">
                  <div class="left">
                    <p class="plan-titre">{{ $t("free") }}</p>
                    <p class="citationplan">{{ $t("citationplan") }}</p>
                  </div>
                </div>
              </div>
              <div class="price">
                <p>
                  {{ $t("priceplan") }} <span>/ {{ $t("monthplan") }}</span>
                </p>
              </div>
              <ul>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option1") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option2") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option3") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option4") }}
                </li>
                <li class="nop">
                  <el-icon><Close /></el-icon>{{ $t("option5") }}
                </li>
                <li class="nop">
                  <el-icon><Close /></el-icon>{{ $t("option6") }}
                </li>
                <!-- <li class="nop"><span><el-icon><Close /></el-icon></span>{{ $t("option7") }}</li> -->
              </ul>
              <router-link to="/inscription" class="free-start">
                {{ $t("start") }}</router-link
              >
            </div>
          </div>
          <div class="item student">
            <div>
              <div class="top">
                <div class="titre">
                  <div class="left">
                    <p class="plan-titre">
                      {{ $t("student") }}
                    </p>
                    <p class="citationplan">{{ $t("citationplanstuden") }}</p>
                  </div>
                </div>
              </div>
              <div class="price">
                <p v-if="!value">
                  {{ $t("priceplanmonth") }}
                  <span>/ {{ $t("monthplan") }}</span>
                </p>
                <p v-else>
                  {{ $t("priceplanyear") }} <span>/ {{ $t("yearplan") }}</span>
                </p>
              </div>
              <ul>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option1") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option2") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option3") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option4") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option5") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option6") }}
                </li>
                <!-- <li><span><el-icon><Check /></el-icon></span>{{ $t("option7") }}</li> -->
              </ul>
              <router-link to="/inscription" class="student-start">
                {{ $t("start") }}</router-link
              >
            </div>
          </div>
          <div class="item life">
            <div>
              <div class="top">
                <div class="titre">
                  <div class="left">
                    <p class="plan-titre">{{ $t("life") }}</p>
                    <p class="citationplan">{{ $t("citationplanlife") }}</p>
                  </div>
                </div>
              </div>
              <div class="price">
                <p>
                  {{ $t("priceplanlife") }}
                </p>
              </div>
              <ul>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option1") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option2") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option3") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option4") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option5") }}
                </li>
                <li>
                  <el-icon><Check /></el-icon>{{ $t("option6") }}
                </li>
                <!-- <li><span><el-icon><Check /></el-icon></span>{{ $t("option7") }}</li> -->
              </ul>
              <router-link to="/inscription" class="life-start">
                {{ $t("start") }}</router-link
              >
            </div>
          </div>
        </div>
      </section>
      <Slider :cible="'plans'" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Slider from "../global/Slider.vue";

export default {
  name: "tarifs-doonun",
  components: { Slider },
  setup() {
    const value = ref(false);
    return { value };
  },
};
</script>

<style scoped>
.container {
  padding: 0;
}

.lists {
  position: relative;
}

.box-tarifs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-tarifs .top-t {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.box-tarifs h2 {
  display: block;
  margin: 0 auto 10px auto;
  text-wrap: balance;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  font-size: clamp(20px, 8vw, 30px);
  white-space: break-spaces;
  text-align: center;
  width: 250px;
}

/* Plan */

.plans__container {
  position: relative;
  display: block;
  margin: 0 auto;
  overflow-x: auto !important;
  max-width: 1000px;
}

.plans__container::-webkit-scrollbar {
  height: 0px !important;
}

.plans {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  display: flex;
  align-items: stretch;
  gap: 1.5rem;
}

.plans::-webkit-scrollbar {
  display: none !important;
}

.plans .item {
  background: var(--card);
  padding: 2rem 1.5rem;
  min-width: 315px;
  flex: 1 1 25%;
  min-height: 500px;
  border-radius: 4px;
}

.plans .free {
  border: 1px solid #dcdfe6;
}

.plans .student {
  border: 1px solid var(--color);
}

.plans .life {
  background: var(--button);
}

.titre .left {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 24px;
  gap: 10px;
}

.titre .plan-titre {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 400;
  font-family: "Inter";
}

.titre .citationplan {
  color: #656c7c;
}

.price p {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 2rem;
  padding: 2rem 0;
}

.price p span {
  font-size: 1rem;
  font-weight: 400;
  color: #656c7c;
}

.plans .item ul li {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 24px;
  font-weight: 500;
}

.plans .item ul li span {
  color: #d2b161;
}

.plans .item ul .nop {
  color: #b1b8c9;
}

.plans .item ul .nop span {
  color: #b1b8c9;
}

.life .plan-titre {
  color: #fff;
}

.life .price p {
  color: #fff;
}

.life li {
  color: #fff;
}

.life .citationplan {
  color: #fff;
  opacity: 0.8;
}

.life ul li span {
  color: #fff !important;
}

.free-start,
.student-start,
.life-start {
  display: block;
  margin-top: 15px;
  padding: 15px 20px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.free-start {
  border: 1px solid #d2b161;
  color: #d2b161;
}

.student-start {
  background: #d2b161;
  color: #fff;
}

.life-start {
  background: #fff;
  color: #d2b161;
}

.slider {
  display: none !important;
}

@media (max-width: 1156px) {
  .slider {
    display: block !important;
  }
}

@media (max-width: 960px) {
  .slider {
    display: none !important;
  }
}
</style>
