<template>
  <div @click="toggleMode" style="cursor: pointer">
    <Sun v-if="isLightMode" />
    <Moon v-else />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Sun from "../icons/Sun.vue";
import Moon from "../icons/Moon.vue";

export default {
  name: "darkmode-doonun",
  components: {
    Sun,
    Moon,
  },
  setup() {
    const isLightMode = ref(true);

    // Read the initial mode from localStorage on mounted
    onMounted(() => {
      const savedMode = localStorage.getItem("isLightMode");
      if (savedMode !== null) {
        isLightMode.value = JSON.parse(savedMode);
      } else {
        // If no value is found, set it to true by default (light mode)
        localStorage.setItem("isLightMode", JSON.stringify(true));
      }
      applyMode();
    });

    const toggleMode = () => {
      isLightMode.value = !isLightMode.value;
      localStorage.setItem("isLightMode", JSON.stringify(isLightMode.value));
      applyMode();
    };

    const applyMode = () => {
      if (isLightMode.value) {
        document.body.classList.remove("dark-mode");
      } else {
        document.body.classList.add("dark-mode");
      }
    };

    return { isLightMode, toggleMode };
  },
};
</script>
