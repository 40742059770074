<template>
  <Head>
    <!-- Charset et Viewport -->
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <!-- Titre de la page -->
    <title>
      {{ selectedDuaa.value ? selectedDuaa.value.name : "Duaas - Doonun" }}
    </title>

    <!-- Description Meta -->
    <meta
      name="description"
      :content="
        selectedDuaa.value && selectedDuaa.value.duaas.length
          ? selectedDuaa.value.duaas[0].translate
          : 'Liste de toutes les invocations, Duaas importantes à connaître sur Doonun.'
      "
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Canonical URL -->
    <link
      rel="canonical"
      :href="`https://www.doonun.com/duaas/${
        selectedDuaa.value ? selectedDuaa.value.slug : ''
      }`"
    />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta
      property="og:url"
      :content="`https://www.doonun.com/duaas/${
        selectedDuaa.value ? selectedDuaa.value.slug : ''
      }`"
    />
    <meta
      property="og:title"
      :content="selectedDuaa.value ? selectedDuaa.value.name : 'Duaas - Doonun'"
    />
    <meta
      property="og:description"
      :content="
        selectedDuaa.value && selectedDuaa.value.duaas.length
          ? selectedDuaa.value.duaas[0].translate
          : 'Liste de toutes les invocations, Duaas importantes à connaître sur Doonun.'
      "
    />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/duaas_73117ce2be.png"
    />

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:url"
      :content="`https://www.doonun.com/duaas/${
        selectedDuaa.value ? selectedDuaa.value.slug : ''
      }`"
    />
    <meta
      name="twitter:title"
      :content="selectedDuaa.value ? selectedDuaa.value.name : 'Duaas - Doonun'"
    />
    <meta
      name="twitter:description"
      :content="
        selectedDuaa.value && selectedDuaa.value.duaas.length
          ? selectedDuaa.value.duaas[0].translate
          : 'Liste de toutes les invocations, Duaas importantes à connaître sur Doonun.'
      "
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/duaas_73117ce2be.png"
    />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>
  <div>
    <div class="loading" v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <div class="container">
        <div class="box-container">
          <div class="top">
            <h1>{{ duaaDetails?.attributes?.name }}</h1>
          </div>
          <div
            class="box-list-duaas"
            v-for="(duaa, index) in duaaDetails?.attributes?.douas.data"
            :key="duaa.id"
          >
            <CardDuaa
              :id="duaa.id"
              :titre="duaaDetails?.attributes?.name"
              :index="index + 1"
              :arab="duaa?.attributes?.arab"
              :transcript="duaa?.attributes?.transcript"
              :translite="duaa?.attributes?.translite"
              :source="duaa.attributes?.source"
              :url_mp3="duaa?.attributes?.url_mp3"
              :favoris="
                Boolean(duaa?.attributes?.users_permissions_users?.data[0]?.id)
              "
              @addFavorite="addFavorite"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import CardDuaa from "../../components/duaas/CardDuaa.vue";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "duaas-titre",
  components: {
    Loading,
    CardDuaa,
    Head,
    Tabs,
  },
  data() {
    return {
      loading: true,
      slug: this.$route.params.subSlug,
    };
  },
  computed: {
    ...mapGetters("duaas", ["duaaDetails"]),
  },
  methods: {
    ...mapActions("duaas", ["fetchTitreDuaaBySlug"]),
    async listDuaas() {
      this.loading = true;
      try {
        await this.fetchTitreDuaaBySlug({
          slug: this.slug,
          lang: this.$i18n.locale, // Passez la locale ici
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async addFavorite() {
      try {
        await this.fetchTitreDuaaBySlug({
          slug: this.slug,
          lang: this.$i18n.locale, // Passez la locale ici
        });
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
    scrollToHash(hash) {
      if (hash.match(/^#\d+/)) {
        const elementId = hash.substring(1);
        this.$nextTick(() => {
          this.scrollToElement(elementId);
        });
      }
    },

    scrollToElement(elementId) {
      const attempts = 10;
      let currentAttempt = 0;
      const scroll = () => {
        const element = document.getElementById(elementId);
        if (element) {
          const windowHeight = window.innerHeight;
          const elementHeight = element.clientHeight;
          const elementPosition =
            element.offsetTop - (windowHeight - elementHeight) / 2;
          window.scrollTo({ top: elementPosition, behavior: "smooth" });
        } else if (currentAttempt < attempts) {
          setTimeout(scroll, 500);
          currentAttempt++;
        }
      };

      scroll();
    },
  },
  created() {
    this.listDuaas();
  },
  watch: {
    "$i18n.locale"() {
      this.listDuaas();
    },
    "$route.params.subSlug"(newSlug) {
      // Ajoutez un watcher pour le slug si nécessaire
      this.slug = newSlug;
      this.listDuaas();
    },
    $route: {
      immediate: true,
      handler(to, from) {
        if (to.hash) {
          this.scrollToHash(to.hash);
        } else if (from && to.path !== from.path) {
          window.scrollTo(0, 0);
        }
      },
    },
  },
};
</script>

<style scoped>
.box-container {
  margin-top: 70px;
  background: var(--card);
  border-radius: 6px;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 50px;
}

.top {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
}

h1 {
  margin-top: 40px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 25px;
  max-width: 600px;
}

.box-list-duaas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
}

@media (max-width: 900px) {
  .box-container {
    margin-top: 0;
  }
  .container {
    background: var(--card);
    border-radius: 0 0 6px 6px;
  }
}
</style>
