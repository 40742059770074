<template>
  <div class="grid">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M18 8V8.00635M12 8V8.00635M6 8L6 8.00635M18 15.9937V16M12 15.9937V16M6 15.9937L6 16"
        stroke="currentColor"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "grid-icon",
};
</script>

<style scoped>
.grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid svg {
  width: 16px;
  height: 16px;
}

.grid svg path {
  stroke: var(--color);
}
</style>
