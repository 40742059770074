import axios from "axios";

const state = {
  sourates: [],
  sourate: [],
  souratesUser: [],
  myVersesFavorites: [],
  fetchedSourate: null,
  mostViewedSourates: [],
  isFetchingMostViewedSourates: false,
};

const getters = {
  sourates: (state) => state.sourates,
  sourate: (state) => state.sourate,
  userSourates: (state) => state.souratesUser,
  mostViewedSourates: (state) => state.mostViewedSourates,
};

const mutations = {
  SET_SOURATES(state, sourates) {
    state.sourates = sourates;
  },
  SET_SOURATES_USER(state, souratesUser) {
    state.souratesUser = souratesUser;
  },
  SET_SOURATE(state, sourate) {
    state.sourate = sourate;
  },
  SET_MY_VERSES_FAVORITES(state, myVersesFavorites) {
    state.myVersesFavorites = myVersesFavorites;
  },
  UPDATE_SOURATE_VIEWS(state, { sourateId }) {
    const sourate = state.sourates.find((p) => p.IdSourate === sourateId);
    if (sourate) {
      sourate.vues += 1; // Incrémente les vues
    }
    if (state.fetchedSourate && state.fetchedSourate.IdSourate === sourateId) {
      state.fetchedSourate.vues += 1; // Incrémente les vues pour fetchedSourate
    }
  },
  SET_MOST_VIEWED_SOURATES(state, mostViewedSourates) {
    // Nouvelle mutation
    state.mostViewedSourates = mostViewedSourates;
  },
};

const actions = {
  async fetchSourates({ commit, rootGetters }, lang) {
    if (state.sourates.length) {
      return;
    }
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get("/sourates", {
        params: {
          locale: lang,
          fields: [
            "id",
            "Titre",
            "Numero",
            "Arabe",
            "Phonetique",
            "Revelation",
            "vues",
            "Nombre",
          ],
          populate: {
            like_sourate: { fields: ["likes"] },
            versets_checks: {
              fields: ["id_user", "taux"],
              filters: { users_permissions_user: { $eq: userId } },
            },
          },
        },
      });

      commit("SET_SOURATES", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchSourate({ commit, rootGetters, dispatch }, id, lang) {
    if (state.sourate.length) {
      return;
    }
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get(`/sourates/${id}`, {
        params: {
          locale: lang,
          fields: [
            "id",
            "Titre",
            "Numero",
            "Arabe",
            "Phonetique",
            "Contexte",
            "Nombre",
            "vues",
          ],
          populate: {
            like_sourate: { fields: ["likes"] },
            ayahs: {
              fields: [
                "id",
                "arabe",
                "fr",
                "phonetique",
                "numero",
                "Tajweed",
                "tafsir",
              ],
              populate: {
                users: {
                  fields: ["id"],
                  filters: {
                    id: {
                      $eq: userId,
                    },
                  },
                },
              },
            },
            versets_checks: {
              fields: ["id", "list_verset", "taux", "id_user"],
              filters: {
                id_user: {
                  $eq: userId,
                },
              },
            },
          },
        },
      });

      // console.log("response sourate : ", response.data.data);

      const sourate = response.data.data;
      commit("SET_SOURATE", sourate);

      // Récupérer l'ID du post et les vues actuelles
      const sourateId = id;
      const currentViews = Number(sourate.attributes.vues);

      if (sourateId !== undefined) {
        // Incrémenter les vues
        dispatch("updateSourateViews", { sourateId, currentViews });
      } else {
        console.error("ID du sourate est undefined");
      }
    } catch (error) {
      console.error(error);
    }
  },

  async updateSourateViews({ commit }, { sourateId, currentViews }) {
    try {
      await axios.put(`/sourates/${sourateId}`, {
        data: {
          vues: Number(currentViews) + 1, // Incrémenter les vues sur le serveur
        },
      });
      // Mettre à jour l'état local avec le nouveau nombre de vues
      commit("UPDATE_SOURATE_VIEWS", { sourateId }); // Pas besoin de passer newViews
      // console.log("Mise à jour des vues réussie : ", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des vues du sourate:",
        error
      );
    }
  },
  async fetchMyVersesFavorites({ commit, rootGetters }, lang) {
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get("/ayahs", {
        params: {
          locale: lang,
          filters: {
            users: {
              id: {
                $eq: userId,
              },
            },
          },
          sort: ["updatedAt:desc"],
          populate: {
            sourate: {
              fields: ["Numero", "Phonetique"],
            },
          },
        },
      });
      commit("SET_MY_VERSES_FAVORITES", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },

  async souratesUser({ commit, rootGetters }) {
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get("/sourates", {
        params: {
          filters: {
            like_sourate: {
              users_permissions_users: {
                id: {
                  $eq: userId,
                },
              },
            },
          },
          fields: [
            "id",
            "Titre",
            "Numero",
            "Arabe",
            "Phonetique",
            "Revelation",
            "Nombre",
            "vues",
          ],
          populate: {
            vue: { fields: ["vues"] },
            like_sourate: { fields: ["likes"] },
            versets_checks: {
              fields: ["id", "list_verset", "taux", "id_user"],
              filters: {
                id_user: {
                  $eq: userId,
                },
              },
            },
          },
        },
      });

      commit("SET_SOURATES_USER", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchMostViewedSourates({ commit, rootGetters }, lang) {
    if (state.isFetchingMostViewedSourates) {
      return; // Si une requête est déjà en cours, on ne lance pas une nouvelle requête
    }

    try {
      // Marquer la requête comme étant en cours
      state.isFetchingMostViewedSourates = true;

      const response = await axios.get("/sourates", {
        params: {
          locale: lang,
          fields: [
            "id",
            "Titre",
            "Numero",
            "Arabe",
            "Phonetique",
            "Revelation",
            "vues",
            "Nombre",
          ],
          sort: ["vues:desc"],
          pagination: {
            limit: 4,
          },
          populate: {
            like_sourate: { fields: ["likes"] },
            versets_checks: {
              fields: ["id_user", "taux"],
              filters: {
                users_permissions_user: { $eq: rootGetters["user/userId"] },
              },
            },
          },
        },
      });

      commit("SET_MOST_VIEWED_SOURATES", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des sourates les plus vues:",
        error
      );
    } finally {
      // Libérer le verrou lorsque la requête est terminée
      state.isFetchingMostViewedSourates = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
