// store/modules/points.js
import axios from "axios";

const state = {};

const getters = {};

const mutations = {
  SET_USER_SOURATES(state, sourates) {
    state.userSourates = sourates;
  },
};

const actions = {
  async getHassanateId({ rootGetters }) {
    const userId = rootGetters["user/userId"];
    try {
      const existingHassanatesResponse = await axios.get("/hassanates", {
        params: {
          filters: { users_permissions_user: { $eq: userId } },
        },
      });

      if (
        existingHassanatesResponse.data &&
        existingHassanatesResponse.data.data &&
        existingHassanatesResponse.data.data.length > 0
      ) {
        return existingHassanatesResponse.data.data[0].id;
      } else {
        return null;
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération de l'ID de hassanates de l'utilisateur:",
        error
      );
      return null;
    }
  },

  async updateUserPoints({ dispatch, rootGetters }, newPoints) {
    try {
      // Assurez-vous que newPoints est un nombre et s'il est négatif, mettez-le à 0
      newPoints = Number(newPoints);
      if (newPoints < 0) {
        newPoints = 0;
      }

      const idHassanate = await dispatch("getHassanateId");

      if (idHassanate) {
        await axios.put(`/hassanates/${idHassanate}`, {
          data: { points: newPoints },
        });
      } else {
        // Aucun enregistrement de points trouvé, donc en créer un nouveau
        const userId = rootGetters["user/userId"];
        await axios.post("/hassanates", {
          data: {
            id_user: String(userId),
            points: newPoints,
            users_permissions_user: userId,
          },
        });
      }
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour ou de la création des points de l'utilisateur:",
        error
      );
    }
  },

  async updateUserStatSourates({ dispatch }, newSourateChange) {
    try {
      const idHassanate = await dispatch("getHassanateId");

      if (idHassanate) {
        await axios.get(`/hassanates/${idHassanate}`);

        const updatedSourates = newSourateChange;

        const updateData = { data: { sourates: updatedSourates } };

        await axios.put(`/hassanates/${idHassanate}`, updateData);
      } else {
        console.log(
          "Aucun enregistrement Hassanates trouvé pour l'utilisateur."
        );
      }
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des sourates de l'utilisateur:",
        error
      );
    }
  },

  async createUserHassanates({ rootGetters }) {
    const userId = rootGetters["user/userId"];
    try {
      await axios.post("/hassanates", {
        data: {
          id_user: String(userId),
          points: 0,
          users_permissions_user: userId,
        },
      });
    } catch (error) {
      console.error(
        "Erreur lors de la création des hassanates de l'utilisateur:",
        error
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
