<template>
  <div class="play-icon">
    <svg width="35" height="35" fill="currentColor" viewBox="0 0 24 24">
      <path
        d="m21.778 10.716-13.5-8.24a1.453 1.453 0 0 0-1.51-.028A1.481 1.481 0 0 0 6 3.75v16.5a1.481 1.481 0 0 0 .769 1.303 1.453 1.453 0 0 0 1.51-.028l13.5-8.24a1.5 1.5 0 0 0 0-2.57Z"
      ></path>
    </svg>
  </div>
</template>
<script>
export default {
  name: "play-d",
};
</script>

<style scoped>
.play-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play-icon svg {
  width: 15px;
  height: 15px;
}

.play-icon svg path {
  fill: var(--color);
}
</style>
