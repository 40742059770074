<template>
  <div class="list">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M4 8.5L20 8.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 15.5L20 15.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "list-icon",
};
</script>

<style scoped>
.list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list svg {
  width: 16px;
  height: 16px;
}

.list svg path {
  stroke: var(--color);
}
</style>
