<template>
  <div class="right">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "right-icon",
};
</script>

<style scoped>
.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right svg {
  width: 25px;
  height: 25px;
}

.right svg path {
  stroke: var(--color);
}
</style>
