<template>
  <Head>
    <!-- Titre de la page -->
    <title>Coran : Générer une Vidéo - Doonun</title>

    <!-- Description Meta -->
    <meta
      name="description"
      content="Créez facilement des vidéos personnalisées avec les versets du Coran et les récitations de votre récitateur préféré sur Doonun. Sélectionnez des sourates, choisissez un récitateur, et personnalisez votre vidéo pour partager ou étudier le Coran de manière interactive."
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Charset -->
    <meta charset="UTF-8" />

    <!-- Viewport pour la réactivité mobile -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <!-- Canonical URL -->
    <link rel="canonical" href="https://www.doonun.com/generer-video" />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.doonun.com/generer-video" />
    <meta property="og:title" content="Coran : Générer une Vidéo - Doonun" />
    <meta
      property="og:description"
      content="Créez facilement des vidéos personnalisées avec les versets du Coran et les récitations de votre récitateur préféré sur Doonun. Sélectionnez des sourates, choisissez un récitateur, et personnalisez votre vidéo pour partager ou étudier le Coran de manière interactive."
    />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/generer_video_doonun.png"
    />

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.doonun.com/generer-video" />
    <meta name="twitter:title" content="Coran : Générer une Vidéo - Doonun" />
    <meta
      name="twitter:description"
      content="Créez facilement des vidéos personnalisées avec les versets du Coran et les récitations de votre récitateur préféré sur Doonun. Sélectionnez des sourates, choisissez un récitateur, et personnalisez votre vidéo pour partager ou étudier le Coran de manière interactive."
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/generer_video_doonun.png"
    />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>

  <div class="ombre" v-if="prepareUpload == true || isRecording">
    <div class="demo-progress" v-if="isRecording">
      <el-progress type="circle" :percentage="recordingProgress" />
    </div>
    <p>
      {{ $t("warningMake") }}
    </p>
    <el-button type="danger" @click="stopRecording" round>{{
      $t("cancelDl")
    }}</el-button>
  </div>
  <div class="container">
    <div class="box-editing">
      <div class="box">
        <div class="left">
          <div class="recitateur">
            <Recitateur @singer-changed="handleSingerChange" />
          </div>

          <div class="ratio">
            <h3>Ratio</h3>
            <div class="buttons">
              <div
                class="ratio-button"
                :class="{ active: selectedRatio === '9:16' }"
                @click="setRatio('9:16')"
              >
                <p>9:16</p>
              </div>

              <div
                class="ratio-button"
                :class="{ active: selectedRatio === '16:9' }"
                @click="setRatio('16:9')"
              >
                <p>16:9</p>
              </div>
            </div>
          </div>

          <h3>{{ $t("choiceSurah") }}</h3>
          <div>
            <el-select
              v-model="selectedSourateId"
              placeholder="Select"
              style="width: 100%"
              @change="onSourateChange"
            >
              <el-option
                v-for="sourate in sourates"
                :key="sourate.id"
                :label="`${sourate.id} - ${sourate.attributes.Phonetique}`"
                :value="sourate.id"
              />
            </el-select>

            <div class="select-verses">
              <el-input-number
                :min="1"
                :max="selectedSourate?.attributes?.Nombre"
                v-model="numero"
                :step="1"
                step-strictly
                style="width: 50%"
                placeholder="Verset début"
                @change="onVerseChange"
              />
              <el-input-number
                :min="numero"
                :max="computedMaxFin"
                v-model="numeroFin"
                :step="1"
                step-strictly
                style="width: 50%"
                placeholder="Verset fin"
                @change="onVerseChange"
              />
            </div>
            <div class="verse-selection">
              <div class="verse-bar">
                <span
                  v-for="verse in getVersesInRange()"
                  :key="verse.numero"
                  class="verse-point"
                  :style="{
                    backgroundColor:
                      verse.numero === selectedVerseNumero
                        ? `var(--button)`
                        : `var(--body)`,
                  }"
                  @click="selectVerse(verse.numero)"
                ></span>
              </div>
            </div>
          </div>

          <h3>{{ $t("choiceVideo") }}</h3>
          <div class="box-videos">
            <button
              class="nav-button prev-button"
              @mousedown="startScrollLeft"
              @mouseup="stopScroll"
              @mouseleave="stopScroll"
            >
              ‹
            </button>
            <div class="videos-list" ref="videosListRef">
              <div
                class="video"
                v-for="video in selectedRatio === '9:16'
                  ? portraitVideos
                  : landscapeVideos"
                :key="video.id"
                @click="handleVideoClick(video)"
              >
                <img
                  :style="{
                    border:
                      this.idVideo === video.id
                        ? `2px solid var(--button)`
                        : 'none',
                  }"
                  :src="video.image"
                  :alt="'Video ' + video.id"
                />
              </div>
            </div>
            <button
              class="nav-button next-button"
              @mousedown="startScrollRight"
              @mouseup="stopScroll"
              @mouseleave="stopScroll"
            >
              ›
            </button>
          </div>

          <h3>{{ $t("changeSize") }}</h3>
          <div class="lists-size-color">
            <div class="changes-size">
              <el-input-number
                :min="6"
                v-model="arabicSize"
                :step="1"
                step-strictly
                style="width: 50%"
                placeholder="Taille arabe"
                @change="updateSizeVerses"
              />
              <el-input-number
                :min="6"
                v-model="phoneticSize"
                :step="1"
                step-strictly
                style="width: 50%"
                placeholder="Taille phonétique"
                @change="updateSizeVerses"
              />
            </div>
            <div class="display">
              <div class="markTxt">
                <el-switch
                  v-model="watermark"
                  :disabled="!isSubscribed"
                  class="ml-2"
                  style="
                    --el-switch-on-color: #13ce66;
                    --el-switch-off-color: #ff4949;
                  "
                />
                <span>{{ $t("watermark") }}</span>
              </div>
              <div class="arabeTxt">
                <el-switch
                  v-model="arabicTxt"
                  class="ml-2"
                  style="
                    --el-switch-on-color: #13ce66;
                    --el-switch-off-color: #ff4949;
                  "
                />
                <span>{{ $t("arabicMaker") }}</span>
              </div>
              <div class="frenchTxt">
                <el-switch
                  v-model="frenchTxt"
                  class="ml-2"
                  style="
                    --el-switch-on-color: #13ce66;
                    --el-switch-off-color: #ff4949;
                  "
                />
                <span>{{ $t("tradMaker") }}</span>
              </div>
            </div>
          </div>
          <div style="max-width: 600px" v-if="!isSubscribed">
            <el-alert
              :title="`${$t('genVideo')} ${points} points.`"
              type="warning"
            />
          </div>

          <button
            class="dl"
            v-if="!isRecording && (arabicTxt || frenchTxt)"
            @click="startRecording"
          >
            {{ $t("dlVideo") }}
          </button>
          <div class="message-mobile">
            <el-alert :title="$t('alertMobile')" type="error" />
          </div>
        </div>

        <div class="right">
          <div
            class="video-canvas"
            v-if="currentVideoUrl"
            :class="ratioClass"
            ref="videoCanvas"
          >
            <!-- Utilisation d'une vidéo locale pour tester les problèmes CORS -->
            <video
              ref="videoPlayer"
              :src="currentVideoUrl"
              @ended="onVideoEnded"
              :class="{ playing: isPlaying }"
              preload="metadata"
              muted
              crossorigin="anonymous"
              playsinline
              webkit-playsinline
            ></video>

            <div class="overlay">
              <div class="captureText" ref="captureText">
                <div class="watermark" v-if="watermark">
                  <p>{{ $t("makeBy") }} <br /><span>Doonun.com</span></p>
                </div>
                <div class="verse-text" v-if="currentArText || currentFrText">
                  <div class="surah">
                    <p
                      :style="{
                        fontSize: `calc(${
                          sizeVerses[selectedVerseNumero - 1]?.phoneticSize - 2
                        }px)`,
                      }"
                    >
                      {{ selectedSourate?.attributes?.Phonetique }} :
                      <span>{{ numero }}</span>
                      <span v-if="numeroFin > numero">-{{ numeroFin }}</span>
                    </p>
                  </div>
                  <div class="reciter">
                    <p
                      :style="{
                        fontSize: `calc(${
                          sizeVerses[selectedVerseNumero - 1]?.phoneticSize - 2
                        }px)`,
                      }"
                    >
                      {{ $t("reciterMake") }} : {{ selectedSinger }}
                    </p>
                  </div>
                  <p
                    v-if="arabicTxt"
                    class="arabe"
                    :style="{
                      fontSize:
                        sizeVerses[selectedVerseNumero - 1]?.arabicSize + 'px',
                    }"
                  >
                    {{ currentArText }}
                  </p>
                  <p
                    v-if="frenchTxt"
                    class="francais"
                    :style="{
                      fontSize: `calc(${
                        sizeVerses[selectedVerseNumero - 1]?.phoneticSize - 2
                      }px)`,
                    }"
                  >
                    {{ currentFrText }}
                  </p>
                </div>
              </div>
            </div>

            <button
              @click="togglePlayPause"
              class="control-button play-pause"
              aria-label="Play/Pause"
            >
              {{ isPlaying ? "▐▐" : "▶︎" }}
            </button>

            <div class="duration">
              <span>{{ formatDuration(totalAudioDuration) }}</span>
            </div>
          </div>

          <div v-else class="video-placeholder">
            <Loading />
          </div>
        </div>
      </div>
    </div>
    <canvas
      ref="recordCanvas"
      class="video-canvas"
      style="display: none"
    ></canvas>
    <audio ref="audioPlayer" crossorigin="anonymous" hidden controls></audio>
  </div>
  <el-dialog
    v-model="centerDialogVisible"
    :title="$t('warningTitleM')"
    width="500"
    align-center
  >
    <span>{{ $t("warninMessageV") }}</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Ok</el-button>
        <el-button type="primary" @click="goToSubscription">
          {{ $t("btnSub1") }}
        </el-button>
      </div>
    </template>
  </el-dialog>
  <Tabs />
</template>

<script>
import Recitateur from "../../components/coran/Recitateur.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import Tabs from "../../components/global/Tabs.vue";
import RecordRTC from "recordrtc";
import { Head } from "@vueuse/head";

export default {
  name: "video-quran",
  components: {
    Recitateur,
    Loading,
    Head,
    Tabs,
  },
  data() {
    // Initialisation des données avec des valeurs par défaut
    const subId = parseInt(this.$route.params.subId, 10) || 1;
    return {
      numero: subId,
      numeroFin: subId,
      arabicSize: 20,
      phoneticSize: 12,
      selectedSourateId: null,
      selectedSinger: "Hussary",
      videos: [],
      scrollInterval: null,
      currentVideoUrl: "",
      isPlaying: false,
      totalAudioDuration: 0,
      selectedRatio: "9:16",
      audioList: [],
      currentAudioIndex: 0,
      audioPlayer: null,
      proxiedAudioPlayer: null,
      preloadedAudios: [],
      preloadedPlaybackAudios: [],
      preloadedImages: [],
      audioBuffers: [],
      audioContext: null,
      gainNode: null,
      audioEnded: false,
      previousAudiosDuration: 0,
      audioDurations: [],
      currentArText: "",
      currentFrText: "",
      arabicTxt: true,
      frenchTxt: true,
      watermark: true,
      isRecording: false,
      originalVolume: 1,
      recordingDestination: null,
      audioPlaybackElement: null,
      recordingProgress: 0,
      prepareUpload: false,
      points: 0,
      verses: [],
      sizeVerses: Array.from({ length: 10 }, (_, index) => ({
        numero: index + 1,
        arabicSize: 20,
        phoneticSize: 12,
      })),
      selectedVerseNumero: null,
      centerDialogVisible: false,
      idVideo: null,
      audioFile: null, // pour stocker le fichier audio sélectionné
      imageFiles: [], // pour stocker les images sélectionnées
      videoDownloadLink: null, // Lien pour télécharger la vidéo générée
    };
  },
  computed: {
    ...mapGetters("sourate", ["sourates", "sourate"]),
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    ...mapGetters("bunny", ["portraitVideos", "landscapeVideos"]),
    ...mapState("user", ["user"]),
    computedMaxFin() {
      const maxPossible = this.numero + 10;
      const sourateMax =
        this.selectedSourate?.attributes?.Nombre || maxPossible;
      return Math.min(maxPossible, sourateMax);
    },
    selectedSourate() {
      return this.sourates.find(
        (sourate) => sourate.id === this.selectedSourateId
      );
    },
    ratioClass() {
      return {
        "ratio-9-16": this.selectedRatio === "9:16",
        "ratio-16-9": this.selectedRatio === "16:9",
      };
    },
  },
  methods: {
    ...mapActions("sourate", ["fetchSourates", "fetchSourate"]),
    ...mapActions("points", ["updateUserPoints"]),

    // Initialisation unique du contexte audio
    initAudioContext() {
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        this.gainNode = this.audioContext.createGain();
        this.gainNode.connect(this.audioContext.destination);
      } else if (this.audioContext.state === "suspended") {
        this.audioContext.resume();
      }
    },
    // Préchargement optimisé des audios pour l'enregistrement
    async preloadAudios() {
      const loadAudio = (audio) => {
        return new Promise((resolve) => {
          const audioElement = new Audio(`https://proxy.cors.sh/${audio.url}`);
          audioElement.crossOrigin = "anonymous";
          audioElement.preload = "auto";
          audioElement.onloadedmetadata = () => resolve(audioElement);
          audioElement.onerror = () => {
            console.error(`Erreur de chargement de l'audio: ${audio.url}`);
            resolve(null);
          };
        });
      };
      this.preloadedAudios = await Promise.all(this.audioList.map(loadAudio));
    },

    // Préchargement optimisé des audios pour la lecture
    async preloadPlaybackAudios() {
      const loadPlaybackAudio = (audio) => {
        return new Promise((resolve) => {
          const audioElement = new Audio(`https://proxy.cors.sh/${audio.url}`);
          audioElement.crossOrigin = "anonymous";
          audioElement.preload = "auto";
          audioElement.onloadedmetadata = () => resolve(audioElement);
          audioElement.onerror = () => {
            console.error(
              `Erreur de chargement de l'audio de lecture: ${audio.url}`
            );
            resolve(null);
          };
        });
      };
      this.preloadedPlaybackAudios = await Promise.all(
        this.audioList.map(loadPlaybackAudio)
      );
    },

    // Pré-rendu optimisé des images de texte
    async preloadTextImages() {
      const images = [];
      const { captureText } = this.$refs;
      if (!captureText) {
        console.error("Référence 'captureText' manquante.");
        return;
      }

      for (const [index, audio] of this.audioList.entries()) {
        this.prepareUpload = true;
        try {
          this.currentArText = audio.arabe;
          this.currentFrText = audio.fr;
          this.selectedVerseNumero = audio.numero;
          const selectedVerse = this.sizeVerses[index] || this.sizeVerses[0];
          this.arabicSize = selectedVerse.arabicSize;
          this.phoneticSize = selectedVerse.phoneticSize;

          const textCanvas = await this.$html2canvas(captureText, {
            useCORS: true,
            allowTaint: true,
            backgroundColor: null,
            scale: 8, // Haute résolution
          });

          const textImage = new Image();
          textImage.src = textCanvas.toDataURL("image/png");
          await textImage.decode().catch(() => {
            console.error(
              `Erreur de rendu de l'image de texte pour l'audio ${index + 1}`
            );
            return null;
          });

          images.push(textImage);
        } catch (error) {
          console.error(
            `Erreur de rendu de l'image de texte pour l'audio ${index + 1}:`,
            error
          );
          images.push(null);
        }
      }
      this.preloadedImages = images;
    },

    // Création optimisée des buffers audios
    async createAudioBuffers() {
      const decodeAudio = async (audio) => {
        const audioUrl = `https://proxy.cors.sh/${audio.url}`;
        try {
          const response = await fetch(audioUrl);
          if (!response.ok)
            throw new Error(
              `Failed to fetch audio at ${audioUrl}: ${response.status}`
            );
          const arrayBuffer = await response.arrayBuffer();
          return await this.audioContext.decodeAudioData(arrayBuffer);
        } catch (error) {
          console.error(`Error fetching or decoding audio ${audioUrl}:`, error);
          return null;
        }
      };
      this.audioBuffers = await Promise.all(this.audioList.map(decodeAudio));
    },

    // Timer de progression optimisé avec requestAnimationFrame
    startRecordingProgressTimer() {
      this.recordingProgress = 0;
      const startTime = performance.now();
      const totalDuration = this.totalAudioDuration * 1000; // en millisecondes

      const updateProgress = () => {
        const elapsed = performance.now() - startTime;
        if (elapsed >= totalDuration) {
          this.recordingProgress = 100;
        } else {
          this.recordingProgress = Math.floor((elapsed / totalDuration) * 100);
          requestAnimationFrame(updateProgress);
        }
      };
      requestAnimationFrame(updateProgress);
    },

    stopRecordingProgressTimer() {
      // Supprimé car nous utilisons requestAnimationFrame sans référence externe
      this.recordingProgress = 100;
    },

    // Méthode optimisée pour démarrer l'enregistrement
    async startRecording() {
      const hasEnoughPoints =
        Number(this.user.hassanate.points) >= Number(this.points);
      if (hasEnoughPoints || this.isSubscribed) {
        if (this.audioPlayer) {
          this.audioPlayer.pause();
          this.audioPlayer.removeEventListener("ended", this.handleAudioEnded);
        }

        const video = this.$refs.videoPlayer;
        const recordCanvas = this.$refs.recordCanvas;

        if (!video || video.readyState < 2) {
          console.error("startRecording: La vidéo n'est pas encore prête.");
          alert("La vidéo n'est pas encore prête pour l'enregistrement.");
          return;
        }

        video.play();

        try {
          this.initAudioContext();

          this.recordingDestination =
            this.audioContext.createMediaStreamDestination();

          // Préchargement parallèle optimisé des ressources
          await Promise.all([
            this.generateAudioList(),
            this.preloadAudios(),
            this.preloadTextImages(),
            this.createAudioBuffers(),
          ]);

          const ctx = recordCanvas.getContext("2d");
          ctx.clearRect(0, 0, recordCanvas.width, recordCanvas.height);

          // Définir les dimensions du canvas en fonction du ratio sélectionné
          recordCanvas.width = this.selectedRatio === "16:9" ? 1920 : 1080;
          recordCanvas.height = this.selectedRatio === "16:9" ? 1080 : 1920;

          await document.fonts.ready;

          // Fonction de dessin optimisée avec calcul des dimensions préalablement
          const draw = () => {
            if (!this.isRecording) return;

            ctx.clearRect(0, 0, recordCanvas.width, recordCanvas.height);
            ctx.drawImage(video, 0, 0, recordCanvas.width, recordCanvas.height);
            ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
            ctx.fillRect(0, 0, recordCanvas.width, recordCanvas.height);

            const currentImage = this.preloadedImages[this.currentAudioIndex];
            if (currentImage) {
              const imgWidth = recordCanvas.width;
              const imgHeight =
                (currentImage.height / currentImage.width) * imgWidth;
              ctx.drawImage(
                currentImage,
                0,
                recordCanvas.height - imgHeight,
                imgWidth,
                imgHeight
              );
            }

            requestAnimationFrame(draw);
          };

          const fps = 300;
          const canvasStream = recordCanvas.captureStream(fps);

          // Création du flux combiné vidéo + audio
          const combinedStream = new MediaStream([
            ...canvasStream.getVideoTracks(),
            ...this.recordingDestination.stream.getAudioTracks(),
          ]);

          // Fonction pour déterminer le mimeType supporté
          const determineMimeType = () => {
            const mimeTypes = [
              'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
              "video/webm; codecs=vp8,opus",
              "video/webm; codecs=vp9,opus",
              "video/webm; codecs=h264,opus",
            ];

            for (const type of mimeTypes) {
              if (MediaRecorder.isTypeSupported(type)) {
                return type;
              }
            }

            // Retourner un type par défaut si aucun n'est supporté
            return "";
          };

          const mimeType = determineMimeType();

          if (!mimeType) {
            console.error("Aucun mimeType supporté trouvé.");
            alert(
              "Aucun format vidéo compatible n'est supporté par votre navigateur."
            );
            return;
          }

          // Initialisation de RecorderRTC
          const options = {
            type: "video",
            mimeType: mimeType,
            disableLogs: false,
            timeSlice: 1000, // Facultatif : pour les données disponibles en continu
            videoBitsPerSecond: 2500000,
            audioBitsPerSecond: 128000,
            frameInterval: 8,
            canvas: {
              width: recordCanvas.width,
              height: recordCanvas.height,
            },
          };

          this.recorder = new RecordRTC(combinedStream, options);

          this.recorder.startRecording();
          this.isRecording = true;
          requestAnimationFrame(draw);

          this.startRecordingProgressTimer();

          await this.playSequentialAudios(this.recordingDestination);

          this.recorder.stopRecording(async () => {
            const blob = this.recorder.getBlob();

            if (blob.size === 0) {
              alert("L'enregistrement a échoué : le fichier vidéo est vide.");
              console.error("startRecording: Blob vide.");
              this.isRecording = false;
              this.stopRecordingProgressTimer();
              return;
            }

            if (!this.isSubscribed && hasEnoughPoints) {
              const newPoints =
                Number(this.user.hassanate.points) - Number(this.points);
              this.updateUserPoints(newPoints);
              this.user.hassanate.points = newPoints;
              // console.log(
              //   `Points déduits: ${this.points}, nouveaux points: ${newPoints}`
              // );
            }

            const date = new Date()
              .toISOString()
              .slice(0, 19)
              .replace(/[:-]/g, "");
            const extension = mimeType.includes("webm") ? "webm" : "mp4";
            const filename = `doonun_recording_${date}.${extension}`;

            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

            this.prepareUpload = false;
            this.isRecording = false;

            // Nettoyage du contexte audio
            if (this.audioContext && this.audioContext.state !== "closed") {
              this.audioContext.close().catch((error) => {
                console.error(
                  "Erreur lors de la fermeture de l'AudioContext:",
                  error
                );
              });
              this.audioContext = null;
            }

            this.stopRecordingProgressTimer();

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        } catch (error) {
          console.error(
            "startRecording: Erreur lors du démarrage de l'enregistrement:",
            error
          );
          alert(
            "Une erreur est survenue lors du démarrage de l'enregistrement."
          );
          this.isRecording = false;
          this.stopRecordingProgressTimer();
        }
      } else {
        if (!this.user.hassanate.points) {
          await this.updateUserPoints(0);
        }
        // console.log(
        //   "Vous n'avez pas suffisamment de points pour télécharger cette vidéo."
        // );
        this.centerDialogVisible = true;
      }
    },

    goToSubscription() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "tarifs" });
    },

    /**
     * Lecture séquentielle optimisée des audios.
     * @param {AudioNode} destination - La destination à laquelle connecter les audios.
     */
    async playSequentialAudios(destination) {
      for (let i = 0; i < this.audioBuffers.length; i++) {
        this.currentAudioIndex = i;
        const audioBuffer = this.audioBuffers[i];

        if (!audioBuffer) {
          console.warn(
            `playSequentialAudios: Buffer audio ${i + 1} est null, saut.`
          );
          continue;
        }

        this.currentArText = this.audioList[i].arabe;
        this.currentFrText = this.audioList[i].fr;
        this.selectedVerseNumero = this.audioList[i].numero;
        const selectedVerse = this.sizeVerses[i] || this.sizeVerses[0];
        this.arabicSize = selectedVerse.arabicSize;
        this.phoneticSize = selectedVerse.phoneticSize;

        const bufferSource = this.audioContext.createBufferSource();
        bufferSource.buffer = audioBuffer;
        bufferSource.connect(destination);

        await new Promise((resolve) => {
          bufferSource.onended = () => {
            this.updateImageAfterAudio();
            resolve();
          };
          bufferSource.start();
        });
      }
    },

    updateImageAfterAudio() {
      const currentImage = this.preloadedImages[this.currentAudioIndex];
      if (currentImage && this.$refs.imageDisplay) {
        this.$refs.imageDisplay.src = currentImage.src;
      }
    },

    stopRecording() {
      if (this.isRecording) {
        this.isRecording = false;

        // Annuler et arrêter l'enregistrement si nécessaire
        if (this.recorder) {
          // Arrêter l'enregistrement vidéo
          this.recorder.stopRecording(() => {
            const blob = this.recorder.getBlob();
            if (blob.size === 0) {
              alert("L'enregistrement a échoué : le fichier vidéo est vide.");
              console.error("stopRecording: Blob vide.");
              return;
            }

            // Réinitialiser l'état de préparation de l'upload
            this.prepareUpload = false;

            // Ne pas lancer de téléchargement du fichier
            // console.log("Enregistrement arrêté, mais pas de téléchargement.");

            // Réinitialisation des autres ressources associées
            this.resetRecordingState();
          });
        }

        // Réinitialisation de la vidéo et nettoyage du player
        const video = this.$refs.videoPlayer;
        if (video) {
          video.pause();
          video.currentTime = 0;
        }

        // Annuler la progression de l'enregistrement
        this.stopRecordingProgressTimer();
      }
    },
    resetRecordingState() {
      // Réinitialisation des états liés à l'enregistrement
      this.recordingProgress = 0;
      this.totalAudioDuration = 0;
      this.previousAudiosDuration = 0;
      this.audioList = [];
      this.audioDurations = [];
      this.preloadedAudios = [];
      this.preloadedPlaybackAudios = [];
      this.preloadedImages = [];
      this.audioBuffers = [];
      this.isRecording = false;

      // Nettoyer l'audio et vidéo
      if (this.audioPlayer) {
        this.audioPlayer.pause();
        this.audioPlayer.removeEventListener("ended", this.handleAudioEnded);
        this.audioPlayer = null;
      }

      if (this.proxiedAudioPlayer) {
        this.proxiedAudioPlayer.pause();
        this.proxiedAudioPlayer.removeEventListener(
          "timeupdate",
          this.updateAudioProgress
        );
        this.proxiedAudioPlayer = null;
      }

      // Réinitialisation de la vidéo (si présente)
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.currentTime = 0;
      }

      // Réinitialisation du contexte audio si nécessaire
      if (this.audioContext && this.audioContext.state !== "closed") {
        this.audioContext.close().catch((error) => {
          console.error(
            "Erreur lors de la fermeture de l'AudioContext:",
            error
          );
        });
        this.audioContext = null;
      }
    },
    handleSingerChange(newSinger) {
      this.selectedSinger = newSinger;
      this.fetchAndSetayahs(this.selectedSourateId);
      this.resetAndPreparePlayback();
    },

    constructAudioUrl(reciter, sourateNumero, versetNumero) {
      return `https://audio.doonun.com/sourates/ar/${reciter}/${sourateNumero}/${versetNumero}.mp3`;
    },

    selectVerse(verseNumero) {
      this.selectedVerseNumero = verseNumero;
      const selectedVerse = this.sizeVerses.find(
        (v) => v.numero === verseNumero
      );
      if (selectedVerse) {
        this.arabicSize = selectedVerse.arabicSize;
        this.phoneticSize = selectedVerse.phoneticSize;
      }
      const verse = this.verses.find((v) => v.numero === verseNumero);
      if (verse) {
        this.currentArText = verse.arabe;
        this.currentFrText = verse.fr;
      }
    },

    updateSizeVerses() {
      if (this.selectedVerseNumero !== null) {
        const selectedVerse = this.sizeVerses.find(
          (v) => v.numero === this.selectedVerseNumero
        );
        if (selectedVerse) {
          selectedVerse.arabicSize = this.arabicSize;
          selectedVerse.phoneticSize = this.phoneticSize;
        }
      }
    },

    getVersesInRange() {
      return this.verses.filter(
        (verse) => verse.numero >= this.numero && verse.numero <= this.numeroFin
      );
    },

    async fetchAndSetayahs(sourateId) {
      this.loading = true;
      try {
        await this.fetchSourate(sourateId, this.$i18n.locale);
        this.ayahs = this.sourate?.attributes?.ayahs?.data || [];
        this.currentVerseIndex = 0;
        await this.generateAudioList();
        const firstAudio = this.audioList[0];
        this.currentArText = firstAudio?.arabe || "";
        this.currentFrText = firstAudio?.fr || "";
        this.verses = this.ayahs.map((ayah) => ({
          numero: ayah.attributes.numero,
          arabe: ayah.attributes.arabe,
          fr: ayah.attributes.fr,
          selected: false,
        }));
      } catch (error) {
        console.error("Erreur lors de la récupération de la sourate :", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchVideos() {
      const orientation =
        this.selectedRatio === "9:16" ? "portrait" : "landscape";
      // console.log("Fetching videos for orientation:", orientation);
      await this.$store.dispatch(
        `bunny/load${
          orientation.charAt(0).toUpperCase() + orientation.slice(1)
        }Videos`
      );
    },

    async handleVideoClick(video) {
      const baseUrl = "https://vz-6191ec9e-917.b-cdn.net";
      const resolutions = [1920, 1280, 720, 480, 360];
      this.idVideo = video.id;
      for (const res of resolutions) {
        const url = `${baseUrl}/${video.id}/play_${res}p.mp4`;
        try {
          const response = await fetch(url, { method: "HEAD" });
          if (response.ok) {
            this.currentVideoUrl = url;
            return;
          }
        } catch (error) {
          // Silencieux car on continue même en cas d'erreur
        }
      }

      console.error("Aucune version disponible pour cette vidéo.");
      this.currentVideoUrl = null;
    },

    onSourateChange(newSourateId) {
      this.selectedSourateId = newSourateId;
      this.numero = 1;
      this.numeroFin = 1;
      this.isPlaying = false;
      this.fetchAndSetayahs(newSourateId);
      this.fetchVideos();
      this.$router.push({ params: { id: newSourateId, subId: this.numero } });
    },

    onVerseChange() {
      this.numeroFin = Math.min(
        Math.max(this.numeroFin, this.numero),
        this.computedMaxFin
      );
      this.$router.push({
        params: { id: this.selectedSourateId, subId: this.numero },
      });
      this.fetchAndSetayahs(this.selectedSourateId);
      this.resetAndPreparePlayback();
    },

    resetAndPreparePlayback() {
      // Réinitialisation des états pertinents
      this.recordingProgress = 0;
      this.stopRecordingProgressTimer();
      this.totalAudioDuration = 0;
      this.previousAudiosDuration = 0;
      this.audioList = [];
      this.audioDurations = [];
      this.currentArText = "";
      this.currentFrText = "";
      this.audioEnded = false;

      if (this.audioPlayer) {
        this.audioPlayer.pause();
        this.audioPlayer.removeEventListener("ended", this.handleAudioEnded);
        this.audioPlayer = null;
      }

      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.mediaRecorder = null;
        this.isRecording = false;
        this.stopRecordingProgressTimer();
      }

      if (this.proxiedAudioPlayer) {
        this.proxiedAudioPlayer.pause();
        this.proxiedAudioPlayer.removeEventListener(
          "timeupdate",
          this.updateAudioProgress
        );
        this.proxiedAudioPlayer = null;
      }

      this.preloadedAudios = [];
      this.preloadedPlaybackAudios = [];
      this.preloadedImages = [];
      this.audioBuffers = [];
    },

    scrollLeft() {
      const container = this.$refs.videosListRef;
      container.scrollBy({ left: -200, behavior: "smooth" });
    },

    scrollRight() {
      const container = this.$refs.videosListRef;
      container.scrollBy({ left: 200, behavior: "smooth" });
    },

    startScrollLeft() {
      const container = this.$refs.videosListRef;
      this.scrollInterval = setInterval(() => {
        container.scrollBy({ left: -200, behavior: "smooth" });
      }, 100);
    },

    startScrollRight() {
      const container = this.$refs.videosListRef;
      this.scrollInterval = setInterval(() => {
        container.scrollBy({ left: 200, behavior: "smooth" });
      }, 100);
    },

    stopScroll() {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    },

    togglePlayPause() {
      if (this.isPlaying) {
        this.pausePlayback();
      } else {
        this.initAudioContext(); // Assurez-vous que le contexte audio est initialisé ici
        this.startPlayback();
      }
    },
    async startPlayback() {
      if (!this.audioPlayer) {
        await this.prepareAudioPlayer();
      }
      this.isPlaying = true;
      if (this.currentVideoUrl) {
        this.$refs.videoPlayer.play().catch((error) => {
          console.error("Erreur lors de la lecture de la vidéo:", error);
        });
      }
      if (this.audioPlayer) {
        this.audioPlayer.play().catch((error) => {
          console.error("Erreur lors de la lecture de l'audio:", error);
        });
      }
    },
    pausePlayback() {
      this.isPlaying = false;
      if (this.currentVideoUrl) {
        this.$refs.videoPlayer.pause();
      }
      if (this.audioPlayer) {
        this.audioPlayer.pause();
      }
    },

    async prepareAudioPlayer() {
      if (!this.selectedSourateId || !this.selectedSinger) {
        alert("Veuillez sélectionner une Sourate et un récitant.");
        return;
      }

      await this.generateAudioList();

      if (this.audioList.length === 0) {
        console.error("La liste des audios est vide.");
        return;
      }

      await this.preloadPlaybackAudios();

      this.currentAudioIndex = 0;
      this.previousAudiosDuration = 0;
      this.audioEnded = false;
      this.recordingProgress = 0;

      const preloadedAudio =
        this.preloadedPlaybackAudios[this.currentAudioIndex];
      if (!preloadedAudio) {
        console.error("L'audio préchargé est introuvable.");
        return;
      }

      this.audioPlayer = preloadedAudio;
      this.audioPlayer.addEventListener("ended", this.handleAudioEnded);

      this.currentArText = this.audioList[this.currentAudioIndex].arabe;
      this.currentFrText = this.audioList[this.currentAudioIndex].fr;

      this.isPlaying = true;
      this.audioPlayer.play().catch((error) => {
        console.error("Erreur lors de la lecture de l'audio:", error);
      });

      if (this.currentVideoUrl) {
        this.$refs.videoPlayer.play().catch((error) => {
          console.error("Erreur lors de la lecture de la vidéo:", error);
        });
      }
    },

    setRatio(ratio) {
      if (this.selectedRatio !== ratio) {
        this.selectedRatio = ratio;
        this.currentVideoUrl = "";
        this.fetchVideos().then(() => {
          this.selectDefaultVideo();
        });
      }
    },

    selectDefaultVideo() {
      const videos =
        this.selectedRatio === "9:16"
          ? this.portraitVideos
          : this.landscapeVideos;
      if (videos && videos.length > 0) {
        this.handleVideoClick(videos[0]);
      } else {
        console.warn("Aucune vidéo disponible pour le ratio sélectionné.");
        this.currentVideoUrl = null;
      }
    },

    async generateAudioList() {
      const sourateNumero = this.selectedSourateId;
      const reciter = this.selectedSinger;
      const audios = this.verses
        .filter(
          (verse) =>
            verse.numero >= this.numero && verse.numero <= this.numeroFin
        )
        .map((verse) => ({
          url: this.constructAudioUrl(reciter, sourateNumero, verse.numero),
          arabe: verse.arabe,
          fr: verse.fr,
          numero: verse.numero,
        }));
      this.audioList = audios;

      if (audios.length > 0) {
        this.currentArText = audios[0].arabe;
        this.currentFrText = audios[0].fr;
      }

      await this.calculateAudioDurations();
    },

    getAudioDuration(url) {
      return new Promise((resolve) => {
        const audio = new Audio(url);
        audio.addEventListener("loadedmetadata", () => resolve(audio.duration));
        audio.addEventListener("error", () => {
          console.error(`Erreur de chargement de l'audio: ${url}`);
          resolve(0);
        });
      });
    },

    async calculateAudioDurations() {
      try {
        const durations = await Promise.all(
          this.audioList.map((audio) => this.getAudioDuration(audio.url))
        );
        this.audioDurations = durations;
        this.totalAudioDuration = durations.reduce((acc, dur) => acc + dur, 0);
        this.points = Math.floor(this.totalAudioDuration) * 17;
      } catch (error) {
        console.error("Erreur lors du calcul des durées des audios:", error);
        this.audioDurations = this.audioList.map(() => 0);
        this.totalAudioDuration = 0;
        this.points = 0;
      }
    },

    handleAudioEnded() {
      if (this.isRecording) return;

      this.previousAudiosDuration +=
        this.audioDurations[this.currentAudioIndex];
      this.currentAudioIndex += 1;

      if (this.currentAudioIndex < this.audioList.length) {
        const preloadedAudio =
          this.preloadedPlaybackAudios[this.currentAudioIndex];
        if (!preloadedAudio) {
          console.error("L'audio préchargé suivant est introuvable.");
          return;
        }

        this.audioPlayer = preloadedAudio;
        this.audioPlayer.addEventListener("ended", this.handleAudioEnded);

        this.currentArText = this.audioList[this.currentAudioIndex].arabe;
        this.currentFrText = this.audioList[this.currentAudioIndex].fr;

        const selectedVerse =
          this.sizeVerses[this.currentAudioIndex] || this.sizeVerses[0];
        this.selectedVerseNumero =
          this.audioList[this.currentAudioIndex].numero;
        this.arabicSize = selectedVerse.arabicSize;
        this.phoneticSize = selectedVerse.phoneticSize;

        this.audioPlayer.play().catch((error) => {
          console.error("Erreur lors de la lecture de l'audio:", error);
        });
      } else {
        this.audioEnded = true;

        if (!this.isRecording) {
          this.pausePlayback();
          if (this.audioPlayer) {
            this.audioPlayer.removeEventListener(
              "ended",
              this.handleAudioEnded
            );
            this.audioPlayer = null;
          }
          this.currentArText = "";
          this.currentFrText = "";
        }

        // Ne pas arrêter le mediaRecorder ici
      }
    },

    async seekAudio() {
      alert("La fonctionnalité de recherche n'est pas implémentée.");
    },

    onVideoEnded() {
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer) {
        videoPlayer.currentTime = 0;
        videoPlayer.play().catch((error) => {
          console.error("Erreur lors de la lecture de la vidéo:", error);
        });
      } else {
        console.warn("onVideoEnded: videoPlayer n'est pas disponible.");
      }
    },

    formatDuration(duration) {
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration % 60);
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
        2,
        "0"
      )}`;
    },

    handleSourateIdChange() {
      let sourateId = this.$route.params.id;
      if (this.$i18n.locale === "en") {
        sourateId = parseInt(sourateId, 10) + 114;
      }
      this.currentVerseIndex = 0;
      this.fetchAndSetayahs(sourateId.toString());
    },
  },
  async created() {
    await this.fetchVideos();
    this.selectDefaultVideo();

    let sourateId = this.$route.params.id;
    if (this.$i18n.locale === "en") {
      sourateId = parseInt(sourateId, 10) + 114;
    }
    await this.fetchAndSetayahs(sourateId.toString());
    await this.fetchSourates(this.$i18n.locale);

    const initialSourateId = parseInt(this.$route.params.id, 10) || null;
    this.selectedSourateId = initialSourateId;

    if (initialSourateId) {
      await this.fetchAndSetayahs(initialSourateId);
    }
  },
  watch: {
    "$i18n.locale"() {
      this.handleSourateIdChange();
    },
    "$route.params.id"(newId) {
      const parsedId = parseInt(newId, 10) || null;
      this.selectedSourateId = parsedId;
      if (parsedId) {
        this.fetchAndSetayahs(parsedId);
        this.resetAndPreparePlayback();
      }
      this.currentVideoUrl = "";
      this.isPlaying = false;

      this.totalAudioDuration = 0;
      this.previousAudiosDuration = 0;
      this.audioList = [];
      this.audioDurations = [];
      this.currentArText = "";
      this.currentFrText = "";

      if (this.audioPlayer) {
        this.audioPlayer.pause();
        this.audioPlayer.removeEventListener("ended", this.handleAudioEnded);
        this.audioPlayer = null;
      }

      const video = this.$refs.videoPlayer;
      if (video) {
        video.pause();
        video.currentTime = 0;
      } else {
        console.warn("watch: videoPlayer n'est pas disponible.");
      }

      if (this.proxiedAudioPlayer) {
        this.proxiedAudioPlayer.pause();
        this.proxiedAudioPlayer.removeEventListener(
          "timeupdate",
          this.updateAudioProgress
        );
        this.proxiedAudioPlayer = null;
      }

      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.mediaRecorder = null;
        this.isRecording = false;
        this.stopRecordingProgressTimer();
      }

      this.selectDefaultVideo();
    },
    "$route.params.subId"(newSubId) {
      const parsedSubId = parseInt(newSubId, 10) || 1;
      this.numero = parsedSubId;

      this.$router.push({
        params: { id: this.selectedSourateId, subId: this.numero },
      });

      this.numeroFin = Math.min(
        Math.max(this.numeroFin, this.numero),
        this.computedMaxFin
      );
      this.handleSourateIdChange();
      this.resetAndPreparePlayback();
    },
    numero(newNumero) {
      this.numeroFin = Math.min(
        Math.max(this.numeroFin, newNumero),
        this.computedMaxFin
      );
      this.updateSizeVerses();
      this.resetAndPreparePlayback();
    },
    numeroFin(newNumeroFin) {
      this.numeroFin = Math.min(
        Math.max(newNumeroFin, this.numero),
        this.computedMaxFin
      );
      this.resetAndPreparePlayback();
    },
    isRecording(newVal) {
      if (newVal) {
        if (this.audioPlayer) {
          this.originalVolume = this.audioPlayer.volume;
          this.audioPlayer.volume = 0;
        }
      } else {
        if (this.audioPlayer && this.originalVolume !== null) {
          this.audioPlayer.volume = this.originalVolume;
          this.originalVolume = null;
        }
      }
    },
    selectedRatio(newRatio, oldRatio) {
      if (newRatio !== oldRatio) {
        this.currentVideoUrl = "";
        this.fetchVideos().then(() => {
          this.selectDefaultVideo();
        });
      }
    },
  },
  beforeUnmount() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.mediaRecorder = null;
    }
    if (this.audioPlayer) {
      this.audioPlayer.removeEventListener("ended", this.handleAudioEnded);
      this.audioPlayer.pause();
      this.audioPlayer = null;
    }
    if (this.proxiedAudioPlayer) {
      this.proxiedAudioPlayer.removeEventListener(
        "timeupdate",
        this.updateAudioProgress
      );
      this.proxiedAudioPlayer.pause();
      this.proxiedAudioPlayer = null;
    }
    if (this.$refs.videoPlayer) {
      this.$refs.videoPlayer.pause();
    }

    if (this.audioContext && this.audioContext.state !== "closed") {
      this.audioContext.close().catch((error) => {
        console.error(
          "beforeUnmount: Erreur lors de la fermeture de l'AudioContext:",
          error
        );
      });
      this.audioContext = null;
    }

    if (this.preloadedPlaybackAudios.length > 0) {
      this.preloadedPlaybackAudios.forEach((audio) => {
        if (audio) {
          audio.pause();
          audio.src = "";
        }
      });
      this.preloadedPlaybackAudios = [];
    }

    if (this.preloadedAudios.length > 0) {
      this.preloadedAudios.forEach((audio) => {
        if (audio) {
          audio.pause();
          audio.src = "";
        }
      });
      this.preloadedAudios = [];
    }

    this.stopRecordingProgressTimer();
  },
};
</script>

<style scoped>
/* Container Principal */
.container {
  position: relative;
  display: block;
  margin: 70px auto 0 auto;
  padding-top: 70px;
  background: var(--card);
  border-radius: 6px;
  min-height: 100vh;
}

.ombre {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #000000d3;
  width: 100%;
  height: 100vh;
  scrollbar-width: none;
}

.ombre p {
  text-align: center;
  max-width: 400px;
  color: #fff700;
  font-weight: 700;
}

.box-editing {
  display: block;
  margin: auto auto;
  max-width: 1000px;
  height: 100%;
  padding: 0 30px 0 30px;
}

/* Grille Principale */
.box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

h3 {
  font-size: 16px;
}

/* Section Gauche */
.box .left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: var(--card);
  border-radius: 6px;
}

/* Récitant */
.recitateur {
  display: flex;
  justify-content: center;
}

/* Ratio */
.ratio {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ratio .buttons {
  display: flex;
  gap: 10px;
}

.ratio .ratio-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
  border-radius: 4px;
  border: 2px solid var(--body);
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.ratio .ratio-button.active {
  background-color: var(--button);
  color: white;
  border-color: var(--button);
}

.ratio .ratio-button:hover {
  background-color: #ccc5ff;
}

/* Sélection des Versets */
.select-verses {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

/* Box-Vidéos */
.box-videos {
  position: relative;
  width: 100%;
}

/* Liste des Vidéos */
.videos-list {
  display: flex;
  gap: 5px;
  width: 100%;
  max-height: 70px;
  min-height: 70px;
  overflow-x: auto;
  overflow-y: none;
  scrollbar-width: none;
  position: relative;
}

.videos-list .video {
  max-width: 80px;
  max-height: 60px;
  min-width: 80px;
  min-height: 60px;
  border-radius: 6px;
  cursor: pointer;
  flex-shrink: 0;
}

.videos-list .video img {
  object-fit: cover;
  max-width: 80px;
  max-height: 60px;
  min-width: 80px;
  min-height: 60px;
  border-radius: 6px;
}

/* Boutons de Navigation */
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 50%;
}

.nav-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.prev-button {
  left: 5px;
}

.next-button {
  right: 5px;
}

/* Options de Couleur et Taille */
.lists-size-color {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.changes-size {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.color {
  min-width: 30px;
  min-height: 30px;
  background: var(--card);
  border: 2px solid var(--color);
  border-radius: 50%;
  cursor: pointer;
}

/* Bouton Télécharger */
.dl {
  width: 100%;
  height: 40px;
  color: var(--colorBtn);
  background: var(--color);
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
}

.display {
  display: flex;
  align-items: center;
  gap: 10px;
}

.display .arabeTxt,
.display .frenchTxt,
.display .markTxt {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}

.verse-bar {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.verse-point {
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.verse-point:hover {
  background: var(--color) !important;
}

/* Section Droite */
.box .right {
  width: 100%;
  position: relative;
}

/* Lecteur Vidéo */
.video-canvas {
  position: relative;
  width: 90%;
  aspect-ratio: 9 / 16;
  background-color: black;
  overflow: hidden;
  border-radius: 6px;
}

.video-canvas.ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.video-canvas.ratio-9-16 {
  aspect-ratio: 9 / 16;
}

.video-canvas video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Overlay Noir Semi-Transparent */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  pointer-events: none;
}

/* Affichage du Texte du Verset */

.captureText {
  position: relative; /* Nécessaire pour les enfants en absolute */
  width: 100%;
  height: 100%; /* Assurez-vous que le parent de captureText a une hauteur définie */
}

.video-canvas.ratio-16-9 .watermark {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, -50%);
  color: white;
  text-align: end;
  z-index: 3;
  padding: 50px 15px 0 15px;
  font-size: 5px;
  opacity: 0.5;
  width: 100%;
}

.watermark {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 3;
  padding: 200px 60px 0 60px;
  font-size: 12px;
  opacity: 0.5;
}

.watermark span {
  font-weight: 900;
}

.verse-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 3;
  padding: 0 60px;
  width: 100%;
}

.verse-text .arabe {
  font-family: "uthmanic";
  font-size: 20px;
  margin-bottom: 10px;
}

.verse-text .francais {
  font-size: 12px;
}

.surah p {
  font-size: 10px;
}

.reciter p {
  margin-top: 0px;
  font-size: 10px;
  font-weight: 300;
  margin-bottom: 15px;
}

/* Contrôles Vidéo */
.controls {
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 2;
}

.control-button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.play-pause {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  background: #000000a1;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  z-index: 4;
  opacity: 0;
}

.fullscreen {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 30px 30px 0;
  z-index: 4;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}

.duration {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 30px 30px;
  z-index: 4;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.play-pause:hover {
  opacity: 1;
}

.progress-bar {
  flex-grow: 1;
  cursor: pointer;
}

/* Placeholder Vidéo */
.video-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--textSecondary);
  font-size: 16px;
  text-align: center;
}

.message-mobile {
  display: none;
}

@media (max-width: 1400px) {
  .container {
    margin-top: 0;
    border-radius: 0 0 6px 6px;
  }
}

/* Responsive Adjustments */
@media (max-width: 1000px) {
  .box-editing {
    padding: 60px 0px 0 0px;
  }
}
@media (max-width: 768px) {
  .box-editing {
    padding: 30px 0;
  }
  .box {
    gap: 20px;
  }
  .dl {
    display: none;
  }

  .message-mobile {
    display: block;
  }
  .box .right,
  .box .left {
    width: 100%;
    max-width: 90vw;
    margin: 0 auto;
  }
  .ratio .ratio-button {
    width: 60px;
    height: 28px;
    font-size: 12px;
  }

  .controls {
    flex-direction: column;
    gap: 5px;
  }

  .control-button {
    padding: 4px 8px;
    font-size: 14px;
  }
  .nav-button {
    display: none;
  }
  .verse-text .arabe {
    font-size: 20px;
  }

  .verse-text .francais {
    font-size: 16px;
  }

  .video-canvas {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .box {
    grid-template-columns: 1fr;
  }
  .box .right,
  .box .left {
    width: 100%;
    max-width: 85vw;
    margin: 0 auto;
  }
  .verse-text {
    padding: 0 40px;
  }
}

.progress-bar {
  flex-grow: 1;
  cursor: pointer;
}

/* Placeholder Vidéo */
.video-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--textSecondary);
  font-size: 16px;
  text-align: center;
}

/* Indicateur d'enregistrement */
.recording-indicator {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: red;
  font-weight: bold;
  z-index: 5;
}

.recording-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
