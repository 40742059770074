<template>
  <div class="start">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M4 6L4 18"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M8 12.0005L20 12.0005"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8C12 8 8.00001 10.946 8 12C7.99999 13.0541 12 16 12 16"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "start-icon",
};
</script>

<style scoped>
.start {
  display: flex;
  justify-content: center;
  align-items: center;
}

.start svg {
  width: 20px;
  height: 20px;
}

.start svg path {
  stroke: var(--link);
}
</style>
