import { createI18n } from "vue-i18n";

const messages = {
  en: {
    // Lien
    lang: "en",
    home: "Home",
    duaa: "Duuas",
    course: "Courses",
    pray: "Schedules",
    quran: "Quran",
    login: "Join",
    books: "Books",
    news: "News",
    timeline: "Chronology",
    pageSearch: "Search a verse",
    copyright: "All rights reserved.",

    //404

    titleOops: "Oops!",
    title404: "404 - Page Not Found",
    description404:
      "This might be due to a typo in the URL or a link that has been removed.",
    linkHome: "Return to Home Page",
    contactSuggestion:
      'If you believe this is an error, feel free to <a href="/contact">contact us</a>.',

    // Accueil

    titre: "Sow the seeds of knowledge and reap the fruits of faith",
    citation:
      "Knowledge is a treasure that cannot be stolen, and a sword that cannot be broken.",

    // Classement

    cta: "Start the adventure",
    titlerank: "Ranking",
    why: "Why Doonun ?",
    whycitation:
      "Doonun is currently the best platform for reading and memorizing the Koran, learning about Islam and tracking your progress.",
    withoutRank: `😳 you are outside the top 100!`,

    // Offres

    titletarif: "Choose the offer that suits you best",
    month: "Monthly",
    year: "Annual",
    free: "Free",
    student: "Student",
    life: "Lifetime",
    citationplan: "Enjoy Doonun's basic features for free",
    citationplanstuden:
      "The student offer gives you full access to the platform.",
    citationplanlife: "Pay in one go, and get Doonun for life.",
    priceplan: "0$",
    priceplanmonth: "4.99$ ",
    priceplanyear: "40.98$ ",
    priceplanlife: "147$ ",
    offerM: "Student offer/month",
    offerY: "Student offer/year",
    offerL: "Get Doonun in one go!",
    monthplan: "month",
    yearplan: "year",
    option1: "Unlock courses",
    option2: "Memorize the Koran",
    option3: "Track progress",
    option4: "Access to the Fiiyd social network",
    option5: "Access to the video platform",
    option6: "Access to the library",
    option7: "Articles reserved for subscribers",
    start: "Get Started",
    btnSub1: "Subscribe",
    btnSub2: "Take",
    titleAb: "Subscription",
    actif: "Active since",
    gereSub: "Manage my subscription",

    // Avis

    titleavis: "What they think about Doonun",

    // Faq

    faq: "Frequently asked questions",

    // Priere

    nextpray: "The next prayer",
    a: "at",

    // Cours
    titlepageC: "Courses",
    titlecours: "New courses",
    termine: "Completed",
    unlock: "Unlock",
    searchcours: "Search a course",
    nothingcourse: "No courses found",
    newCourse: "New",
    startCourse: "Start the course",
    resumeCourse: "Resume the course",
    restartCourse: "Redo the course",
    currentChap: "Chapter",
    pageCourse: "Course page",
    rebackContent: "Go back",
    bravoC: "Well done",
    youFinished: "You have completed the course",
    courageC: `Continue your learning and earn points to unlock other courses 😉.`,
    messagePopupC:
      "You do not have enough points to unlock this course, by taking out a subscription you will be able to unlock courses without limits.",
    allCourses: "View courses",

    // Duaas
    titlepageDu: "Summoning",
    searchduaas: "Search for an summoning",

    // Coran
    titlepage: "Quran",
    titlecoran: "Most read",
    vues: "views",
    searchcoran: "Search by number or name",
    searchTitleV: "Search in the Koran",
    searchverse: "Search a verse",
    resultSearchV: "Results",
    noFoundV: "No results found.",
    seekathir: "See Ibn Kathir",
    readcontxt: "Read more",
    closecontxt: "Close context",
    versekathir: "verse",
    ofkathir: "from the surah",
    backverse: "Return to verse",
    flag: "https://cp.doonun.com/uploads/united_kingdom_4c9fcf278f.png",
    langv: "English",
    ar: "Arabic",
    pho: "Phonetic",
    taf: "Tafseer",
    settingsourate: "Settings",
    memopuptitle: "Memorization",
    reveal: "was revealed",
    contain: "and contains",
    nbverse: "verses",
    resumeVerse: "Resume reading",
    lectureV: "Reading",
    traductionV: "Translation",
    step5Title: "Congratulations!",
    step5Message: "You seem to have memorized this verse. Allahumabarik",
    step5ImageAlt: "Robert Redford Nod GIF - Find & Share on GIPHY",
    step5Continue: "Continue reading",

    // Notes
    titleN: "My notes",

    // Auth
    or: "Or identify yourself with",
    usernameform: "Username",
    password: "Password",
    confirmpsw: "Confirm password",
    lost: "Forgot your password ?",
    loginbtn: "Login",
    registerbtn: "Register",
    connectctn: "Login",
    createctn: "Create an account",
    errors: {
      email_required: "Please enter your email.",
      password_required: "Please enter your password.",
      email_not_exist: "This email does not exist.",
      incorrect_credentials: "Incorrect email or password!",
    },
    // Livres
    titlebook: "Books",
    searchbooks: "Enter the book name",
    messageBook:
      "The library is reserved for subscribers! To benefit from it, please take out a subscription.",

    // Memorization
    memo: "To memorize",
    memoarabic: "Arabic",
    memophonetique: "Phonetic",
    step1info: "How many times do you want to repeat the audio of this verse?",
    listen: "Listen",
    pausememo: "Break",
    listenword: "Click on a word to listen to its pronunciation.",
    missingword: "Recite and complete the missing words in the verse !",
    unvealwords: "Unveil the words",
    movewords: "Click on the words to put the verse in the correct order.",

    // Profil
    points: "Points",
    surahP: "Surahs",
    coursesP: "Courses",
    rankP: "Rank",
    courseCompTitle: "Courses",
    surahsCompTitle: "Surahs",
    versesCompTitle: "Verses",
    booksCompTitle: "Books",
    duaasCompTitle: "Duaas",
    notesCompTitle: "Notes",
    testiComp: "My review",
    courseComp: "Courses",
    surahsComp: "Surahs",
    versesComp: "Verses",
    booksComp: "Books",
    duaasComp: "Duaas",
    notesComp: "Notes",
    settingsP: "Settings",
    editP: "Edit profile",
    addTestimonial: "Add a review",
    editTheme: "Change theme",
    mySub: "My subscription",
    disconnect: "Log out",
    gF: "Women",
    gH: "Men",
    gA: "Others",
    gAll: "All",
    sendReview: "Submit",
    updateReview: "Edit",
    holderReview:
      "What did you think of Doonun? Share your experience with users.",
    parametres: "Settings",
    resetQuranProgress: "Reset my Quran progress",
    resetFavorites: "Reset my favorite surahs list",
    resetCourses: "Reset my course progress",
    resetCache: "Clear cache",
    resetButton: "Reset",
    loading: "Loading",
    check: "Success",
    edit: "Edit",
    alertMessage:
      "👉 If you're logged in with Google, there's no need to fill all fields, only your username will be updated!",
    usernameLabel: "Username",
    usernameErrorShort: "The username must be at least 3 characters long!",
    usernameErrorLong: "The username must be less than 20 characters long!",
    usernameVoidError: "Please enter a username!",
    emailLabel: "Email",
    passwordLabel: "Password",
    passwordRequiredError: "Please enter your current password!",
    incorrectPasswordError: "The password is incorrect!",
    newPasswordLabel: "New password",
    newPasswordShortError: "The password is too short (minimum 8 characters)!",
    newPasswordWeakError:
      "Password is too weak, please strengthen it (A-z@_ etc.)!",
    confirmPasswordLabel: "Confirm password",
    passwordMismatchError: "The passwords do not match!",
    successMessageEdit: "Your information has been successfully updated!",

    saveButton: "Save",

    // Donation
    giveDonation: "Make a donation",

    // Priere

    hijriT: "Calendar",
    gregoT: "Gregorian",
    monthP: "Prays",
    lun: "Mon",
    mar: "Tue",
    mer: "Wed",
    jeu: "Thu",
    ven: "Fri",
    sam: "Sat",
    dim: "Sun",
    janM: "January",
    febM: "February",
    marM: "March",
    aprM: "April",
    mayM: "May",
    junM: "June",
    julM: "July",
    augM: "August",
    sepM: "September",
    octM: "October",
    novM: "November",
    decM: "December",
    searchCity: "Search your city",

    // Fiiyd

    feed: "News Feed",
    notif: "Notifications",
    myposts: "My Posts",
    inputPost: "What's new?",
    inputComment: "Post your reply",
    trends: "Trends",
    poster: "Post",
    comment: "Reply",
    delete: "Delete Post",
    sensible: "Sensitive Content",
    activeNotif: "Enable Notifications",
    activeComment: "Enable Comments",
    signal: "Report Post",
    likePost: "liked your post",
    likeComment: "liked your comment",
    commentPost: "commented on your post",
    commentComment: "replied to your comment",
    recent: "Recent",
    medias: "Media",
    likes: "Likes",
    comments: "Comments",
    messageDelete: "Deletion will be irreversible.",
    messageEscape: "Post deletion canceled.",
    deletePost: "Delete",
    escapeDelete: "Cancel",
    successDelete: "The post has been successfully deleted!",
    wrongDelete: "Deletion of post cancelled.",
    messageSensible:
      "marked this post as sensitive. Do you still want to display it?",
    displaySensible: "Display",
    addImagesFII: "Add an image link",
    addLinksFii: "Add a link",

    // Share
    share: "Share",
    copyLink: "Copy link",
    surahM1: "Discover the verse",
    surahM2: "from the surah",
    kathirM1: "Discover the tafsir of the verse",
    kathirM2: "from the surah",
    kathirM3: "by Ibn Kathir",
    DuaaShare: "Summoning :",

    // videomaker
    choiceSurah: "Surah choice",
    choiceVideo: "Background video",
    changeSize: "Text size",
    dlVideo: "Download video",
    watermark: "Watermark",
    arabicMaker: "Arabs",
    tradMaker: "Translation",
    genVideo: "Generating this video will cost you",
    makeBy: "Generated on",
    warningMake:
      "Please stay on this page until it finishes loading to avoid compromising the download. If you're using a browser other than Chrome, you'll need to convert your webm video to mp4.",
    cancelDl: "Cancel",
    warninMessageV:
      "You don't have enough points to download this video, but by taking out a subscription you'll be able to generate unlimited videos.",
    warningTitleM: "Warning",
    reciterMake: "Reciter",
    alertMobile:
      "For performance reasons, video generation is not yet available on a cell phone - you need a computer!",

    // CONTACT

    successMessage:
      "We thank you for getting in touch. We have received your request and we commit to respond as soon as possible. We will contact you shortly.",
    backToHome: "Back to home",
    contactFormTitle:
      '<span class="wave">👋</span>To get in touch,<br />please fill out the form.',
    labelName: "Your name",
    placeholderName: "Edward Snowden",
    labelEmail: "Your email",
    placeholderEmail: "thexample@gmail.com",
    labelInterest: "What you are interested in",
    selectSubject: "Select a subject",
    optionSubscription:
      "Subscription - For any subscription-related questions.",
    optionBugReport: "Report a bug - Help us improve Doonun by reporting bugs.",
    optionPartnership:
      "Partnership request - Explore partnership opportunities.",
    optionQuestion: "Question - Ask us your questions.",
    optionFeatureSuggestion: "Feature suggestion - Propose new ideas.",
    optionContribution: "Contribute to Doonun - Join our team of contributors.",
    labelMessage: "Message",
    placeholderMessage: "Your message",
    submitButtonC: "Just Send",

    // Doovie
    messageDoovie:
      "Doovie is reserved for subscribers! To benefit from it, please take out a subscription.",
    lookMovie: "Watch",
    lookDetails: "See more",
    newsMovies: "What's new",
    resumeMovies: "Resume",
    allMovies: "All",

    // HISTORY
    titleHi: "Chronology of the Muslim world",
    holderYear: "Year",
    holderWords: "Words",
    textY: "Year",
    moreD: "Learn more",
    linkTimeline: "timeline2_dbb3014c21",
    messageTimeline:
      "Explore the timeline of Islam, from AD 500 to today, by discovering its most significant events!",

    // Oublie
    titleO: "Forgot Password?",
    descriptionO:
      "Enter the email address associated with your account to reset your password. (Does not accept accounts created with Google.)",
    emailPlaceholderO: "Enter your email",
    successMessageO: "A reset link has been sent to this email.",
    emailErrorO: "Please enter an email address.",
    submitButtonO: "Submit",

    // Reset
    titleR: "Reset Password",
    headTitleR: "Reset - Doonun",
    newPasswordLabelR: "New Password",
    confirmPasswordLabelR: "Confirm Password",
    newPasswordPlaceholderR: "New Password",
    confirmPasswordPlaceholderR: "Confirm Password",
    passwordMismatchR: "Passwords do not match.",
    successMessageR: "Your password has been successfully reset.",
    errorMessageR: "An error occurred. Please try again.",
    submitButtonR: "Submit",

    // Footer

    titleF: "Start learning",
    sbB: " facilitates",
    sbC: " your",
    sbD: " understanding",
    sbE: " of Islam.",
    btnF: "Start classes",
    navF: "Navigation",
    menF: "Legal notices",
    cgd: "General conditions of use",
    pc: "Privacy policy",
    pCookies: "Cookies policy",
    nF: "Our networks",
    ctF: "Contact form",
  },
  fr: {
    lang: "fr",
    // Lien
    home: "Accueil",
    duaa: "Duuas",
    course: "Cours",
    pray: "Horaires",
    quran: "Coran",
    login: "Rejoindre",
    books: "Livres",
    news: "Actualités",
    timeline: "Chronologie",
    pageSearch: "Recherche",
    copyright: "Tous droits réservés.",

    //404

    titleOops: "Oops !",
    title404: "404 - Page non trouvée",
    description404:
      "Cela peut être dû à une erreur de frappe dans l'URL ou à un lien qui a été supprimé.",
    linkHome: "Retourner à la page d'accueil",
    contactSuggestion:
      "Si vous pensez qu'il s'agit d'une erreur, n'hésitez pas à nous <a href=\"/contact\">contacter</a>.",

    // Accueil
    titre:
      "Semez les graines de la connaissance et récoltez les fruits de la foi",
    citation:
      "La connaissance est un trésor qui ne peut être volé, et une épée qui ne peut être brisée.",
    cta: "Commencer l'aventure",
    // Classement
    titlerank: "Classement",
    why: "Pourquoi Doonun ?",
    whycitation:
      "Doonun est à ce jour la meilleure plateforme pour lire et mémoriser le coran, apprendre l'islam et suivre sa progression.",
    withoutRank: `😳 vous êtes en dehors du top 100!`,
    // Offres
    titletarif: "Choisissez l'offre qui vous convient",
    month: "Mensuelle",
    year: "Annuelle",
    free: "Gratuit",
    student: "Étudiant",
    life: "Une seule fois",
    citationplan: "Profitez des options basiques de Doonun gratuitement",
    citationplanstuden:
      "L'offre étudiant vous donne un accès complet à la plateforme.",
    citationplanlife: "Payez en une seule fois, et obtenez Doonun à vie.",
    priceplan: "0€ ",
    priceplanmonth: "4.99€ ",
    priceplanyear: "40.98€ ",
    priceplanlife: "147€ ",
    offerM: "Offre étudiant/mois",
    offerY: "Offre étudiant/année",
    offerL: "Obtenir Doonun en seule fois !",
    monthplan: "mois",
    yearplan: "année",
    option1: "Débloquer des cours",
    option2: "Mémoriser le Coran",
    option3: "Suivre sa progression",
    option4: "Accès au réseau social Fiiyd",
    option5: "Accès à la plateforme vidéo",
    option6: "Accès à la bibliothèque",
    option7: "Articles réservés aux abonnés",
    start: "Commencer",
    btnSub1: "S'abonner",
    btnSub2: "Prendre",
    titleAb: "Abonnement",
    actif: "Actif depuis le",
    gereSub: "Gérer mon abonnement",

    // Avis

    titleavis: "Ce qu'ils pensent de Doonun",

    // Faq

    faq: "Questions fréquemment posées",

    // Priere

    nextpray: "Prochaine prière",
    a: "à",

    // Cours
    titlepageC: "Courses",
    titlecours: "Nouveaux cours",
    termine: "Terminé",
    unlock: "Débloquer",
    searchcours: "Rechercher un cours",
    nothingcourse: "Aucun cours n'a été trouvé",
    newCourse: "Nouveau",
    startCourse: "Commencer le cours",
    resumeCourse: "Reprendre le cours",
    restartCourse: "Refaire le cours",
    currentChap: "Chapitre",
    pageCourse: "Page du cours",
    rebackContent: "Revenir en arrière",
    bravoC: "Bravo",
    youFinished: "Vous avez terminé le cours",
    courageC: `Continuez votre apprentissage et gagnez des points pour débloquer
          d'autres cours 😉.`,
    messagePopupC:
      "Vous n'avez pas assez de points pour débloquer ce cours, en prenant un abonnement vous pourrez débloquer des cours sans limite.",
    allCourses: "Voir les cours",

    //Duaas
    titlepageDu: "Invocations",
    searchduaas: "Rechercher une invocation",

    //Coran
    titlepage: "Coran",
    titlecoran: "Les plus lu",
    vues: "vues",
    searchcoran: "Rechercher par numéro ou nom",
    searchTitleV: "Rechercher dans le coran",
    searchverse: "Rechercher un verset",
    resultSearchV: "Résultats",
    noFoundV: "Aucun résultat trouvé.",
    seekathir: "Voir Ibn Kathir",
    readcontxt: "Lire la suite",
    closecontxt: "Fermer le contexte",
    versekathir: "verset",
    ofkathir: "de la sourate",
    backverse: "Revenir au verset",
    flag: "https://cp.doonun.com/uploads/france_73efc57867.png",
    langv: "Français",
    ar: "Arabe",
    pho: "Phonétique",
    taf: "Tafsir",
    settingsourate: "Paramètres",
    memopuptitle: "Mémorisation",
    reveal: "a été révélée à",
    contain: "et contient",
    nbverse: "versets",
    resumeVerse: "Reprendre la lecture",
    lectureV: "Lecture",
    traductionV: "Traduction",
    step5Title: "Bravo !",
    step5Message: "Vous semblez avoir mémorisé ce verset. Allahumabarik",
    step5ImageAlt: "Robert Redford Nod GIF - Find & Share on GIPHY",
    step5Continue: "Continuer la lecture",

    // Notes
    titleN: "Mes notes",

    // Auth
    or: "Ou identifiez-vous avec",
    password: "Mot de passe",
    confirmpsw: "Confirmer le mot de passe",
    lost: "Mot de passe oublié ?",
    loginbtn: "Se connecter",
    registerbtn: "S'inscrire",
    createctn: "Créer un compte",
    connectctn: "Se connecter",
    usernameform: "Nom d'utilisateur",
    errors: {
      email_required: "Veuillez entrer votre email.",
      password_required: "Veuillez entrer votre mot de passe.",
      email_not_exist: "Cet email n'existe pas.",
      incorrect_credentials: "Mot de passe ou Email incorrect !",
    },

    // Livres
    titlebook: "Livres",
    searchbooks: "Saisissez le nom du livre",
    messageBook:
      "La bibliothèque est reservée aux abonnés ! Pour en profitez, veuillez prendre un abonnement.",

    // Memorisation
    memo: "Mémoriser",
    memoarabic: "Arabe",
    memophonetique: "Phonétique",
    step1info: "Combien de fois voulez-vous répéter l'audio de ce verset ?",
    listen: "Ecouter",
    pausememo: "Pause",
    listenword: "Cliquez sur un mot pour écouter sa prononciation.",
    missingword: "Récitez et complétez les mots manquants du verset !",
    unvealwords: "Dévoiler les mots",
    movewords:
      "Cliquez sur les mots pour remettre le verset dans le bon ordre.",

    // Profil
    points: "Points",
    surahP: "Sourates",
    coursesP: "Cours",
    rankP: "Rang",
    courseCompTitle: "Mes cours",
    surahsCompTitle: "Mes sourates",
    versesCompTitle: "Mes versets",
    booksCompTitle: "Mes livres",
    duaasCompTitle: "Mes duaas",
    notesCompTitle: "Mes notes",
    courseComp: "Cours",
    surahsComp: "Sourates",
    versesComp: "Versets",
    booksComp: "Livres",
    duaasComp: "Duaas",
    notesComp: "Notes",
    testiComp: "Mon avis",
    settingsP: "Paramètres",
    editP: "Modifier le profil",
    addTestimonial: "Ajouter un avis",
    editTheme: "Changer de thème",
    mySub: "Mon abonnement",
    disconnect: "Se déconnecter",
    gF: "Femmes",
    gH: "Hommes",
    gA: "Autres",
    gAll: "Tous",
    sendReview: "Soumettre",
    updateReview: "Modifier",
    holderReview:
      "Qu'avez-vous pensé de Doonun ? Partagez votre expérience avec les utilisateurs.",
    parametres: "Paramètres",
    resetQuranProgress: "Réinitialiser ma progression du Coran",
    resetFavorites: "Réinitialiser la liste de mes sourates préférées",
    resetCourses: "Réinitialiser la progression de mes cours",
    resetCache: "Vider le cache",
    resetButton: "Réinitialiser",
    loading: "Chargement",
    check: "Succès",
    edit: "Éditer",
    alertMessage:
      "👉 Si vous êtes connecté avec Google, inutile de remplir tous les champs, seul votre nom d'utilisateur sera modifié !",
    usernameLabel: "Nom d'utilisateur",
    usernameErrorShort:
      "Le nom d'utilisateur doit faire minimum 3 caractères !",
    usernameErrorLong:
      "Le nom d'utilisateur doit faire moins de 20 caractères !",
    usernameVoidError: "Veuillez saisir un nom d'utilisateur !",
    emailLabel: "Email",
    passwordLabel: "Mot de passe",
    passwordRequiredError: "Veuillez saisir votre mot de passe actuel !",
    incorrectPasswordError: "Le mot de passe est incorrect !",
    newPasswordLabel: "Nouveau mot de passe",
    newPasswordShortError:
      "Le mot de passe est trop court (8 caractères minimum) !",
    newPasswordWeakError:
      "Mot de passe trop faible, veuillez le renforcer (A-z@_ etc.) !",
    confirmPasswordLabel: "Confirmez le mot de passe",
    passwordMismatchError: "Le mot de passe ne correspond pas !",
    successMessageEdit: "Vos informations ont bien été mise à jour !",
    saveButton: "Enregistrer",

    // Donation
    giveDonation: "Faire un don",

    // Priere

    hijriT: "Calendrier",
    gregoT: "Grégorien",
    monthP: "Prières",
    lun: "Lun",
    mar: "Mar",
    mer: "Mer",
    jeu: "Jeu",
    ven: "Ven",
    sam: "Sam",
    dim: "Dim",
    janM: "Janvier",
    febM: "Février",
    marM: "Mars",
    aprM: "Avril",
    mayM: "Mai",
    junM: "Juin",
    julM: "Juillet",
    augM: "Août",
    sepM: "Septembre",
    octM: "Octobre",
    novM: "Novembre",
    decM: "Décembre",
    searchCity: "Rechercher votre ville",

    // Fiiyd
    feed: "Fil d'actualités",
    notif: "Notifications",
    myposts: "Mes posts",
    inputPost: "Quoi de neuf ?",
    inputComment: "Postez votre réponse",
    trends: "Tendances",
    poster: "Poster",
    comment: "Répondre",
    delete: "Supprimer le post",
    sensible: "Contenu sensible",
    activeNotif: "Actver les notifications",
    activeComment: "Activer les commentaires",
    signal: "Signaler le post",
    likePost: "a aimé votre post",
    likeComment: "a aimé votre commentaire",
    commentPost: "a commenté votre post",
    commentComment: "a répondu a votre commentaire",
    recent: "Recent",
    medias: "Media",
    likes: "Likes",
    comments: "Comments",
    messageDelete: "La suppression sera irréversible.",
    messageEscape: "Suppression du post annulée.",
    deletePost: "Supprimer",
    escapeDelete: "Annuler",
    successDelete: "Le post a été supprimé avec succès !",
    wrongDelete: "Suppression du post annulée.",
    messageSensible:
      "a marqué ce post comme sensible. Voulez-vous quand même l'afficher ?",
    displaySensible: "Afficher",
    addImagesFII: "Ajouter un lien d'image",
    addLinksFii: "Ajoutez un lien",

    // Share
    share: "Partager",
    copyLink: "Copier le lien",
    surahM1: "Découvrez le verset",
    surahM2: "de la sourate",
    kathirM1: "Découvrez le tafsir du verset",
    kathirM2: "de la sourate",
    kathirM3: "par Ibn Kathir",
    DuaaShare: "Invocation :",

    // videomaker
    choiceSurah: "Choix de la sourate",
    choiceVideo: "Vidéo de fond",
    changeSize: "Taille du texte",
    dlVideo: "Télécharger la vidéo",
    filigran: "Filigrane",
    arabicMaker: "Arabes",
    tradMaker: "Traduction",
    genVideo: "Générer cette vidéo vous coûtera",
    makeBy: "Générée sur",
    warningMake:
      "Veuillez rester sur cette page jusqu'à la fin du chargement pour ne pas compromettre le téléchargement. Si vous utilisez un navigateur différent de chrome, il vous faudra convertir votre vidéo webm en mp4.",
    cancelDl: "Annuler",
    warninMessageV:
      "Vous n'avez pas assez de points pour télécharger cette vidéo, en prenant un abonnement vous pourrez générer des vidéos sans limite.",
    warningTitleM: "Attention",
    reciterMake: "Récitateur",
    alertMobile:
      "Pour des raisons de performance, la génération de vidéo n'est pas encore disponible sur un mobile, il vous faut un ordinateur !",

    // CONTACT

    successMessage:
      "Nous vous remercions pour votre prise de contact. Nous avons bien reçu votre demande et nous nous engageons à vous répondre dans les meilleurs délais. Nous vous contacterons très prochainement.",
    backToHome: "Revenir à l'accueil",
    contactFormTitle:
      '<span class="wave">👋</span>Pour prendre contact,<br />remplissez le formulaire.',
    labelName: "Votre nom",
    placeholderName: "Edward Snowden",
    labelEmail: "Votre email",
    placeholderEmail: "thexample@gmail.com",
    labelInterest: "Ce qui vous intéresse",
    selectSubject: "Sélectionnez un sujet",
    optionSubscription: "Abonnement - Pour toute question liée à l'abonnement.",
    optionBugReport:
      "Signaler un bug - Aidez-nous à améliorer Doonun en signalant des bugs.",
    optionPartnership:
      "Demande de partenariat - Explorez les opportunités de partenariat.",
    optionQuestion: "Question - Posez-nous vos questions.",
    optionFeatureSuggestion:
      "Suggestion de fonctionnalité - Proposez de nouvelles idées.",
    optionContribution:
      "Contribuer à Doonun - Rejoignez notre équipe de contributeurs.",
    labelMessage: "Message",
    placeholderMessage: "Votre message",
    submitButtonC: "Soumettre",

    // Doovie
    messageDoovie:
      "Doovie est reservée aux abonnés ! Pour en profitez, veuillez prendre un abonnement.",
    lookMovie: "Lecture",
    lookDetails: "Plus d'infos",
    newsMovies: "Nouveautés",
    resumeMovies: "Reprendre",
    allMovies: "Tous",

    // HISTORY
    titleHi: "Chronologie du monde musulman",
    holderYear: "Année",
    holderWords: "Mots",
    textY: "Année",
    moreD: "En savoir plus",
    linkTimeline: "timeline_c3f8d10ccf",
    messageTimeline:
      "Explorez la chronologie de l'islam, de l'an 500 jusqu'à nos jours, avec ses événements les plus marquants !",

    // Oublie
    titleO: "Mot de passe oublié ?",
    descriptionO:
      "Entrez l'adresse email associée à votre compte pour réinitialiser votre mot de passe. (N'accepte pas les comptes créés avec Google.)",
    emailPlaceholderO: "Saisissez votre email",
    successMessageO: "Un lien de réinitialisation a été envoyé à cet email.",
    emailErrorO: "Veuillez entrer une adresse email.",
    submitButtonO: "Soumettre",

    // Reset
    titleR: "Réinitialiser le mot de passe",
    headTitleR: "Réinitialiser - Doonun",
    newPasswordLabelR: "Nouveau mot de passe",
    confirmPasswordLabelR: "Confirmez le mot de passe",
    newPasswordPlaceholderR: "Nouveau mot de passe",
    confirmPasswordPlaceholderR: "Confirmez le mot de passe",
    passwordMismatchR: "Les mots de passe ne correspondent pas.",
    successMessageR: "Votre mot de passe a été réinitialisé avec succès.",
    errorMessageR: "Une erreur est survenue. Veuillez réessayer.",
    submitButtonR: "Soumettre",

    // Footer
    titleF: "Commencez à apprendre",
    sbB: " facilite",
    sbC: " votre",
    sbD: " compréhension",
    sbE: " de l'islam.",
    btnF: "Débutez les cours",
    navF: "Navigation",
    menF: "Mentions légales",
    cgd: "Conditions générales d'utilisations",
    pc: "Politique de confidentialité",
    pCookies: "Politique des cookies",
    nF: "Nos réseaux",
    ctF: "Formulaire de contact",
  },
};

const getUserLocale = () => {
  const userLang = navigator.language || navigator.userLanguage;
  return userLang.toLowerCase().split("-")[0];
};

const detectedLanguage = getUserLocale();

const i18n = createI18n({
  locale: detectedLanguage in messages ? detectedLanguage : "en",
  fallbackLocale: "en",
  messages,
});

export default i18n;
