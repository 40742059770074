<template>
  <Head>
    <title>Fiiyd - Réseau social musulman</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container">
    <div class="boites">
      <div class="content-with-sidebars">
        <div class="left">
          <div class="fiiyd-icon">
            <div class="icons">
              <ul>
                <li>
                  <router-link to="/fiiyd">
                    <HomeFii />
                    <span>{{ $t("feed") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/notifications">
                    <div class="bell">
                      <span v-if="fiiydsCount >= 1">{{ fiiydsCount }}</span>
                      <BellFii />
                    </div>
                    <span>{{ $t("notif") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/myposts">
                    <PostsFii />
                    <span>{{ $t("myposts") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="middle">
          <h1>Notifications</h1>
          <div class="size-box-v">
            <div class="fil">
              <div class="loading" v-if="isLoading">
                <Loading />
              </div>

              <div v-else class="lists-notifs">
                <div
                  v-for="(fiiyd, index) in fiiyds"
                  :key="index"
                  class="notification-card"
                >
                  <router-link
                    v-if="fiiyd.attributes.like && fiiyd.attributes.posts?.data"
                    :to="`/fiiyd/${fiiyd.attributes.posts.data.attributes.url}`"
                  >
                    <div class="notification">
                      <div class="event">
                        <span>
                          <BellFav />
                        </span>
                        <p>
                          <span class="name">{{
                            fiiyd.attributes.name_user
                          }}</span>
                          {{ $t("likePost") }}
                        </p>
                      </div>

                      <p class="content">
                        {{ fiiyd.attributes.posts.data.attributes.Content }}
                      </p>
                    </div>
                  </router-link>
                  <router-link
                    v-if="
                      fiiyd.attributes.like && fiiyd.attributes.comment?.data
                    "
                    :to="`/fiiyd/comment/${fiiyd.attributes.comment.data.attributes.url}`"
                  >
                    <div class="notification">
                      <div class="event">
                        <span>
                          <BellFav />
                        </span>
                        <p>
                          <span class="name">{{
                            fiiyd.attributes.name_user
                          }}</span>
                          {{ $t("likeComment") }}
                        </p>
                      </div>

                      <p class="content">
                        {{ fiiyd.attributes.comment.data.attributes.Content }}
                      </p>
                    </div>
                  </router-link>
                  <router-link
                    v-if="
                      fiiyd.attributes.commentaire &&
                      fiiyd.attributes.posts?.data
                    "
                    :to="`/fiiyd/${fiiyd.attributes.posts.data.attributes.url}`"
                  >
                    <div class="notification">
                      <div class="event">
                        <span>
                          <BellComment />
                        </span>
                        <p>
                          <span class="name">{{
                            fiiyd.attributes.name_user
                          }}</span>
                          {{ $t("commentPost") }}
                        </p>
                      </div>

                      <p class="content">
                        {{ fiiyd.attributes.posts.data.attributes.Content }}
                      </p>
                    </div>
                  </router-link>
                  <router-link
                    v-if="
                      fiiyd.attributes.commentaire &&
                      fiiyd.attributes.comment?.data
                    "
                    :to="`/fiiyd/comment/${fiiyd.attributes.comment.data.attributes.url}`"
                  >
                    <div class="notification">
                      <div class="event">
                        <span>
                          <BellComment />
                        </span>
                        <p>
                          <span class="name">{{
                            fiiyd.attributes.name_user
                          }}</span>
                          {{ $t("commentComment") }}
                        </p>
                      </div>

                      <p class="content">
                        {{ fiiyd.attributes.comment.data.attributes.Content }}
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <Trends />
        </div>
      </div>
    </div>
  </div>
  <div class="tabs">
    <TabsFiiyd />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import HomeFii from "../../components/icons/fiiyd/Home.vue";
import BellFii from "../../components/icons/fiiyd/Bell.vue";
import BellComment from "../../components/icons/fiiyd/BellComment.vue";
import BellFav from "../../components/icons/fiiyd/BellFav.vue";
import PostsFii from "../../components/icons/fiiyd/Posts.vue";
import Trends from "../../components/fiiyd/Trends.vue";
import TabsFiiyd from "../../components/fiiyd/TabsFiiyd.vue";
import { Head } from "@vueuse/head";

export default {
  name: "fiiyd-d",
  components: {
    Loading,
    HomeFii,
    BellFii,
    Trends,
    PostsFii,
    BellFav,
    BellComment,
    TabsFiiyd,
    Head,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState("fiiyd", ["fiiyds"]),
    fiiydsCount() {
      return this.$store.state.fiiyd.fiiyds.length;
    },
  },
  methods: {
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("fiiyd", ["fetchFiiyds", "markFiiydsAsRead"]),
  },
  async mounted() {
    await this.fetchFiiyds();
    await this.markFiiydsAsRead();
    this.isLoading = false;
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 1.5rem;
}
.boites {
  width: 100%;
  display: block;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  min-height: 100vh;
}

.loading {
  display: flex;
  justify-content: center;
}

.content-with-sidebars {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.content-with-sidebars .left {
  width: 20%;
}

.content-with-sidebars .middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(60% - 40px);
  border-right: 1px solid var(--card);
  border-left: 1px solid var(--card);
}

.content-with-sidebars .right {
  width: 20%;
}

.container {
  padding-bottom: 30px;
  min-height: 100vh;
}

.icons ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icons ul li {
  padding-bottom: 20px;
  padding-right: 10px;
}

.icons ul li a {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icons ul li a span {
  font-size: 20px;
  font-weight: 500;
}

.size-box-v {
  display: flex;
  justify-content: center;
  max-width: 550px;
  width: 100%;
}

.size-box-v .fil {
  width: 100%;
}

.post-input {
  padding: 0 20px;
}

.bell {
  position: relative;
  z-index: 1;
}

.bell span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  padding: 10px;
  background: var(--button);
  color: #fff;
  border-radius: 50%;
  z-index: 2;
  margin-right: -15px;
  margin-top: -14px;
  font-size: 10px !important;
  font-weight: 700;
}

.notification-card {
  border-bottom: 1px solid var(--card);
  padding: 15px 20px;
}
.notification p {
  margin: 0;
  font-weight: bold;
}

.event {
  display: flex;
  align-items: center;
  gap: 10px;
}

.event .name {
  font-weight: 600;
}

.event p {
  font-weight: 400;
  font-size: 14px;
}

.content {
  padding-left: 30px;
  font-weight: 400 !important;
  color: #71767b;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 14px;
}

.tabs {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  background: var(--card);
  width: 100%;
  z-index: 10;
}

@media (max-width: 998px) {
  .boites {
    margin-top: 100px;
  }

  .content-with-sidebars .left {
    width: calc(10% - 20px);
  }

  .content-with-sidebars .left span {
    display: none;
  }

  .content-with-sidebars .middle {
    width: calc(75% - 20px);
  }

  .content-with-sidebars .right {
    width: calc(15% - 20px);
    display: none;
  }
}

@media (max-width: 800px) {
  .boites {
    margin-top: 100px;
    padding: 0;
  }
  h1 {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .content-with-sidebars .left {
    width: 100%;
    display: none;
  }

  .content-with-sidebars .middle {
    width: 100%;
    border: none;
  }

  .content-with-sidebars .right {
    width: 100%;
    display: none;
  }

  .size-box-v .fil {
    padding: 0;
  }

  .post-input {
    padding: 0;
  }

  .notification-card {
    border-bottom: 1px solid var(--card);
    padding: 15px 0 15px 0;
  }

  .tabs {
    display: block;
  }
}
</style>
