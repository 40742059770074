<template>
  <div>
    <div class="links-container">
      <div class="liens">
        <h2 id="scroll-target" class="links">Ajouter vos liens</h2>
        <!-- <p class="alert-g">
          En intégrant le top 100, vos liens, s'afficheront à côté de votre
          pseudo dans la page
          <router-link to="/classement">classement</router-link>. Profitez de la
          visibilité de Doonun pour mettre en avant vos projets.
        </p> -->
        <form @submit.prevent="submitLinks" class="links-form">
          <div>
            <Twitter />
            <input
              v-model="twitterLink"
              type="url"
              placeholder="Votre lien Twitter"
            />
          </div>
          <div>
            <Instagram />
            <input
              v-model="instagramLink"
              type="url"
              placeholder="Votre lien Instagram"
            />
          </div>
          <div>
            <TikTok />
            <input
              v-model="tiktokLink"
              type="url"
              placeholder="Votre lien Tiktok"
            />
          </div>
          <div>
            <Planete />
            <input
              v-model="websiteLink"
              type="url"
              placeholder="Lien de votre site web"
            />
          </div>

          <p
            v-if="errorMessage"
            class="error-message"
            v-html="errorMessage"
          ></p>
          <p
            v-if="sucessMessage"
            class="success-message"
            v-html="sucessMessage"
          ></p>
          <div v-if="themeError" class="lnks-error">
            <p class="message-sub">
              {{ themeErrorMessage }}
              <router-link to="/tarifs">Cliquez-ici</router-link>
            </p>
          </div>
          <button type="submit">Enregistrer</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Twitter from "../icons/Twitter.vue";
import Instagram from "../icons/Instagram.vue";
import TikTok from "../icons/TikTok.vue";
import Planete from "../icons/Planete.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "user-links",
  components: {
    Twitter,
    Instagram,
    TikTok,
    Planete,
  },
  data() {
    return {
      twitterLink: "",
      instagramLink: "",
      tiktokLink: "",
      websiteLink: "",
      errorMessage: "",
      sucessMessage: "",
      themeError: false,
      themeErrorMessage: "",
    };
  },
  computed: {
    ...mapGetters("user", ["getUserLinks"]),
    ...mapGetters("sub", ["isSubscribed"]),
    hasSubscription() {
      return this.isSubscribed;
    },
  },
  methods: {
    ...mapActions("user", [
      "addUserLinks",
      "fetchUserLinks",
      "updateUserLinks",
    ]),
    validateLinks() {
      const errorMessages = [];
      let isValid = false;

      if (this.twitterLink) {
        if (!this.twitterLink.includes("twitter.com")) {
          errorMessages.push("Le lien Twitter doit contenir twitter.com*.");
        } else {
          isValid = true;
        }
      }

      if (this.instagramLink) {
        if (!this.instagramLink.includes("instagram.com")) {
          errorMessages.push("Le lien Instagram doit contenir instagram.com*.");
        } else {
          isValid = true;
        }
      }

      if (this.tiktokLink) {
        if (!this.tiktokLink.includes("tiktok.com")) {
          errorMessages.push("Le lien TikTok doit contenir 'tiktok.com*.");
        } else {
          isValid = true;
        }
      }

      if (this.websiteLink) {
        if (!this.websiteLink.startsWith("https://")) {
          errorMessages.push(
            "Le lien du site web doit commencer par https://*."
          );
        } else {
          isValid = true;
        }
      }

      if (errorMessages.length === 0 && !isValid) {
        errorMessages.push("Au moins un lien valide doit être fourni.");
      }

      this.errorMessage = errorMessages.join(" ");
      return isValid;
    },

    async submitLinks() {
      // if (!this.hasSubscription) {
      //   this.themeError = true;
      //   this.themeErrorMessage =
      //     "Cette fonctionnalité est reservée aux abonnés ! Veuillez souscrire à un abonnement, pour pouvoir ajouter vos liens.";
      //   setTimeout(() => {
      //     this.themeError = false;
      //     this.themeErrorMessage = "";
      //   }, 10000);
      //   return;
      // }

      // Vérifier la validité des liens et mettre à jour les champs non valides avec une valeur vide
      const errorMessages = [];
      let isValid = false;
      let hasChanged = false;

      if (this.twitterLink !== this.getUserLinks.twitterLink) {
        if (!this.twitterLink) {
          this.twitterLink = "";
          hasChanged = true;
        } else if (!this.twitterLink.includes("twitter.com")) {
          errorMessages.push("Le lien Twitter doit contenir twitter.com*.");
          this.twitterLink = "";
        } else {
          isValid = true;
        }
      }

      if (this.instagramLink !== this.getUserLinks.instagramLink) {
        if (!this.instagramLink) {
          this.instagramLink = "";
          hasChanged = true;
        } else if (!this.instagramLink.includes("instagram.com")) {
          errorMessages.push("Le lien Instagram doit contenir instagram.com*.");
          this.instagramLink = "";
        } else {
          isValid = true;
        }
      }

      if (this.tiktokLink !== this.getUserLinks.tiktokLink) {
        if (!this.tiktokLink) {
          this.tiktokLink = "";
          hasChanged = true;
        } else if (!this.tiktokLink.includes("tiktok.com")) {
          errorMessages.push("Le lien TikTok doit contenir 'tiktok.com*.");
          this.tiktokLink = "";
        } else {
          isValid = true;
        }
      }

      if (this.websiteLink !== this.getUserLinks.websiteLink) {
        if (!this.websiteLink) {
          this.websiteLink = "";
          hasChanged = true;
        } else if (!this.websiteLink.startsWith("https://")) {
          errorMessages.push(
            "Le lien du site web doit commencer par https://*."
          );
          this.websiteLink = "";
        } else {
          isValid = true;
        }
      }

      if (errorMessages.length === 0 && !isValid && !hasChanged) {
        errorMessages.push("Au moins un lien valide doit être fourni.");
      }

      this.errorMessage = errorMessages.join(" ");

      // Si les liens sont valides ou si un champ a été vidé, soumettre le formulaire
      if (!this.errorMessage) {
        const linksData = {
          twitterLink: this.twitterLink || null,
          instagramLink: this.instagramLink || null,
          tiktokLink: this.tiktokLink || null,
          websiteLink: this.websiteLink || null,
        };

        try {
          if (this.getUserLinks.id) {
            await this.updateUserLinks(linksData);
            this.sucessMessage = "Vos liens ont été modifiés !";
            console.log("Liens mis à jour avec succès!");
          } else {
            await this.addUserLinks(linksData);
            this.sucessMessage =
              "Vos liens ont bien été ajoutés ! Ils seront affichés dans la page classement.";
            console.log("Liens ajoutés avec succès!");
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour des liens:", error);
        }
      }
    },

    async fetchLinks() {
      try {
        await this.fetchUserLinks();
        this.twitterLink = this.getUserLinks.attributes.Twitter || "";
        this.instagramLink = this.getUserLinks.attributes.Instagram || "";
        this.tiktokLink = this.getUserLinks.attributes.Tiktok || "";
        this.websiteLink = this.getUserLinks.attributes.Lien || "";
      } catch (error) {
        console.error("Erreur lors de la récupération des liens:", error);
      }
    },
  },
  mounted() {
    this.fetchLinks();
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.links-container {
  display: block;
  max-width: 600px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

form input {
  background: transparent;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color);
  width: 100%;
  border: none;
}

form input:focus-visible {
  border: none;
  outline: none;
}

form div {
  display: flex;
  gap: 0px;
  background: var(--card);
  border-radius: 6px;
  padding: 10px;
}

form div span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  font-size: 20px;
}

form button {
  cursor: pointer;
  display: block;
  padding: 15px 20px;
  background: var(--text);
  color: var(--color);
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  transition: 0.2s ease;
}

form button:hover {
  background: var(--button);
  color: #fff;
}

.alert-g {
  background: #ffecd9;
  color: #000;
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.alert-g a {
  color: var(--button);
}

.lnks-error {
  background: #ffef89;
  border: 2px solid #ffd900;
  padding: 6px;
  border-radius: 10px;
}

.lnks-error p {
  color: #000;
}

.lnks-error p a {
  font-weight: 700;
  color: blue;
}

.error-message {
  padding: 10px;
  background: #ff7979;
  color: #000;
  border-radius: 6px;
  border: 2px solid #e34747;
  font-weight: 600;
  font-size: 14px;
}
.success-message {
  padding: 10px;
  background: #79ff9f;
  color: #000;
  border-radius: 6px;
  border: 2px solid #47e36b;
  font-weight: 600;
  font-size: 14px;
}

@media (max-width: 800px) {
  .liens {
    margin-top: 30px;
  }
}
</style>
