<template>
  <div>
    <div class="settings-container">
      <h2 id="scroll-target">{{ $t("parametres") }}</h2>
      <div class="list-delete">
        <div class="item-delete span coran">
          <p>{{ $t("resetQuranProgress") }}</p>
          <span
            v-if="!isResetting && !resetSuccess"
            @click="resetQuranProgress"
          >
            {{ $t("resetButton") }}
          </span>
          <div v-if="isResetting" class="load">
            <Loading />
          </div>
          <div v-if="resetSuccess" class="check">
            <Check />
          </div>
        </div>
        <div class="item-delete span">
          <p>{{ $t("resetFavorites") }}</p>
          <span
            v-if="!isResettingFavorites && !resetFavoritesSuccess"
            @click="resetFavoriteSourates"
          >
            {{ $t("resetButton") }}
          </span>
          <div v-if="isResettingFavorites" class="load">
            <Loading />
          </div>
          <div v-if="resetFavoritesSuccess" class="check">
            <Check />
          </div>
        </div>
        <div class="item-delete span">
          <p>{{ $t("resetCourses") }}</p>
          <span
            v-if="!isResettingCourses && !resetCoursesSuccess"
            @click="resetCourseProgress"
          >
            {{ $t("resetButton") }}
          </span>
          <div v-if="isResettingCourses" class="load">
            <Loading />
          </div>
          <div v-if="resetCoursesSuccess" class="check">
            <Check />
          </div>
        </div>
        <div class="item-delete span">
          <p>{{ $t("resetCache") }}</p>
          <span
            v-if="!isResettingLocalStorage && !resetLocalStorageSuccess"
            @click="resetLocalStorage"
          >
            {{ $t("resetButton") }}
          </span>
          <div v-if="isResettingLocalStorage" class="load">
            <Loading />
          </div>
          <div v-if="resetLocalStorageSuccess" class="check">
            <Check />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import Loading from "../navigation/Loading.vue";
import Check from "../icons/CheckAnimation.vue";
export default {
  name: "user-settings",
  components: {
    Loading,
    Check,
  },
  data() {
    return {
      isResetting: false,
      resetSuccess: false,
      isResettingFavorites: false,
      resetFavoritesSuccess: false,
      isResettingCourses: false,
      resetCoursesSuccess: false,
      isResettingLocalStorage: false,
      resetLocalStorageSuccess: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("points", ["updateUserStatSourates"]),
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("like", ["updateLike"]),
    async resetQuranProgress() {
      this.isResetting = true;
      this.resetSuccess = false;

      if (!this.user || !this.user.id) {
        console.error("Erreur: Utilisateur non identifié.");
        this.isResetting = false;
        return;
      }

      try {
        const userId = this.user.id;

        // Récupérer la progression du Coran pour cet utilisateur
        const response = await axios.get(
          `/versets-checks?filters[id_user][$eq]=${userId}`
        );

        const versetsChecks = response.data.data;
        let souratesChange = 0; // Initialiser souratesChange

        // Parcourir chaque versetCheck pour réinitialiser et vérifier le taux
        const updates = versetsChecks.map((versetCheck) => {
          if (versetCheck.attributes.taux === 100) {
            souratesChange += 1; // Incrémenter pour chaque taux de 100%
          }

          return axios.delete(`/versets-checks/${versetCheck.id}`);
        });

        await Promise.all(updates);

        if (souratesChange > 0) {
          // Si souratesChange est positif, soustraire ce nombre du total des sourates lues
          souratesChange = 0;
          await this.updateUserStatSourates(souratesChange);
        }

        this.resetSuccess = true;
        this.isResetting = false;
        this.user.hassanate.sourates = 0;
      } catch (error) {
        console.error(
          "Erreur lors de la réinitialisation de la progression du Coran:",
          error
        );
        this.isResetting = false;
      }
    },

    async resetFavoriteSourates() {
      this.isResettingFavorites = true; // Commence le chargement
      // console.log("Début de la réinitialisation des sourates favorites");

      if (!this.user || !this.user.id) {
        console.error("Erreur: Utilisateur non identifié.");
        this.isResettingFavorites = false; // Arrête le chargement si l'utilisateur n'est pas identifié
        return;
      }

      try {
        const userId = this.user.id;
        const response = await axios.get("/like-sourates", {
          params: {
            filters: {
              users_permissions_users: userId,
            },
          },
        });

        // Vérifiez le contenu de la réponse
        // console.log("Réponse de l'API:", response.data);

        if (
          !response.data ||
          !response.data.data ||
          response.data.data.length === 0
        ) {
          // console.log("Aucune donnée trouvée pour cet utilisateur.");
          this.isResettingFavorites = false;
          return;
        }

        // Vérifiez chaque entrée pour les informations de l'utilisateur
        for (const like of response.data.data) {
          // console.log("Traitement du like:", like);
          if (like.attributes && like.attributes.id_sourate !== undefined) {
            const newLikes = Number(like.attributes.likes);
            const idLike = Number(like.attributes.id_sourate);
            const likeBoolean = true;

            try {
              // Assurez-vous de passer l'identifiant correct
              await this.updateLike({ id: idLike, likeBoolean, newLikes });
              // console.log("Mise à jour réussie pour l'identifiant", idLike);
            } catch (error) {
              console.error("Erreur lors de la mise à jour des likes:", error);
            }
          } else {
            // console.log(
            //   `Données incorrectes pour le like: ${JSON.stringify(like)}`
            // );
          }
        }

        // console.log("Réinitialisation des sourates favorites réussie");
        localStorage.removeItem("sourateData"); // Supprime les données locales après réinitialisation
        this.resetFavoritesSuccess = true; // Indique le succès de la réinitialisation
      } catch (error) {
        console.error(
          "Erreur lors de la réinitialisation des sourates favorites:",
          error
        );
      } finally {
        this.isResettingFavorites = false; // Assurez-vous que le chargement s'arrête
      }
    },
    async resetCourseProgress() {
      this.isResettingCourses = true;
      this.resetCoursesSuccess = false;

      if (!this.user || !this.user.id) {
        console.error(
          "Erreur: Utilisateur non identifié pour la réinitialisation des cours."
        );
        this.isResettingCourses = false;
        return;
      }

      try {
        const userId = this.user.id;

        setTimeout(async () => {
          const response = await axios.get(
            `/suivis?filters[user_id][$eq]=${userId}`
          );

          const suivis = response.data.data;
          await Promise.all(
            suivis.map((suivi) => {
              const updatePayload = {
                data: {
                  id_chapitre: "1",
                  id_contenu: "0",
                  pourcentage: "0",
                  id_quiz: "0",
                  clicks: "0",
                  elements: "0",
                  quiz_active: false,
                  content_active: true,
                },
              };

              return axios.put(`/suivis/${suivi.id}`, updatePayload);
            })
          );

          // console.log(
          //   "La progression des cours a été réinitialisée avec succès."
          // );
          this.resetCoursesSuccess = true;
          this.isResettingCourses = false;
        }, 2000);
      } catch (error) {
        console.error(
          "Erreur lors de la réinitialisation de la progression des cours:",
          error
        );
        this.isResettingCourses = false;
      }
    },
    async resetLocalStorage() {
      this.isResettingLocalStorage = true;
      this.resetLocalStorageSuccess = false;

      setTimeout(() => {
        const keysToRemove = [
          "versetsSourate",
          "themeColors",
          "rappel",
          "vuex",
        ];
        keysToRemove.forEach((key) => localStorage.removeItem(key));

        this.isResettingLocalStorage = false;
        this.resetLocalStorageSuccess = true;
      }, 2000);
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.settings-container {
  display: block;
  max-width: 600px;
  margin: 0 auto;
}

.box-setting {
  margin-top: 100px;
}

@media (max-width: 800px) {
  .box-setting {
    margin-top: 30px;
  }
}

.list-delete {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 30px;
  background: var(--card);
  border-radius: 6px;
}

.item-delete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.item-delete p {
  font-size: 14px;
}

.span span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 3px;
  padding: 6px 20px;
  font-size: 14px;
  min-width: 120px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  background: #f56c6c;
}

.span span:hover {
  background: var(--color);
  color: var(--colorBtn) !important;
}

.delete-account span {
  outline: 1px solid #e23431;
  color: #e23431;
}

.delete-account span:hover {
  background: #e23431;
  color: #fff;
}

.check,
.load {
  max-width: 30px;
  max-height: 30px;
}
</style>
