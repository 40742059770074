<template>
  <div></div>
</template>
<script>
export default {
  name: "gregorian-calendar",
};
</script>

<style scoped></style>
