<template>
  <div class="box-prays-month">
    <div class="top">
      <div></div>
      <div>
        <Sun />
      </div>
      <div>
        <SunUp />
      </div>
      <div>
        <SunFull />
      </div>
      <div>
        <SunCloud />
      </div>
      <div>
        <SunDown />
      </div>
      <div>
        <SunNight />
      </div>
    </div>
    <!-- Utiliser 'day' comme clé pour s'assurer de l'unicité -->
    <div
      class="days-prays"
      v-for="(timing, day) in timings"
      :key="day"
      :class="{ today: day == today }"
    >
      <div>
        <p>{{ day }}</p>
      </div>
      <div>
        <p>{{ timing.Fajr }}</p>
      </div>
      <div>
        <p>{{ timing.Sunrise }}</p>
      </div>
      <div>
        <p>{{ timing.Dhuhr }}</p>
      </div>
      <div>
        <p>{{ timing.Asr }}</p>
      </div>
      <div>
        <p>{{ timing.Maghrib }}</p>
      </div>
      <div>
        <p>{{ timing.Isha }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Sun from "../icons/priere/Sun.vue";
import SunUp from "../icons/priere/SunUp.vue";
import SunDown from "../icons/priere/SunDown.vue";
import SunCloud from "../icons/priere/SunCloud.vue";
import SunFull from "../icons/priere/SunFull.vue";
import SunNight from "../icons/priere/SunNight.vue";

export default {
  name: "prays-months",
  components: {
    Sun,
    SunUp,
    SunDown,
    SunCloud,
    SunFull,
    SunNight,
  },
  props: {
    timings: Object,
  },
  data() {
    return {
      today: new Date().getDate(), // Obtenir le jour actuel du mois
    };
  },
};
</script>

<style scoped>
.box-prays-month {
  height: 100%;
}
.top {
  margin-top: 30px;
  margin-bottom: 10px;
}
.top,
.days-prays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.days-prays {
  /* margin-top: 5px; */
  /* background: var(--body); */
  border-bottom: 1px solid var(--body);
  padding: 10px;
  border-radius: 3px;
}

.today {
  background-color: var(--button) !important;
  color: #fff;
  font-weight: 600;
}

.top div,
.days-prays div {
  display: flex;
  align-items: center;
}

.days-prays div p {
  font-size: 12px;
}
</style>
