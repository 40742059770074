// store/modules/ranking.js
import axios from "axios";

const state = {
  topUsers: [],
  isLoading: false,
  error: null,
};

const getters = {
  topUsers: (state) => state.topUsers,
  isLoading: (state) => state.isLoading,
  error: (state) => state.error,
};

const mutations = {
  SET_TOP_USERS(state, users) {
    state.topUsers = users;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

const actions = {
  /**
   * Récupère les 100 utilisateurs ayant le plus de points.
   * @param {Object} context - Contexte Vuex.
   * @param {String} lang - Langue pour la localisation des données.
   */
  async fetchTopUsers({ commit }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const response = await axios.get("/hassanates", {
        params: {
          fields: [
            "id",
            "points",
            "id_user",
            "createdAt",
            "updatedAt",
            "publishedAt",
            "last_rank",
            "rang",
            "sourates",
            "cours",
          ],
          sort: ["points:desc"], // Trier par points décroissants
          pagination: {
            limit: 100, // Limiter à 100 utilisateurs
          },
          populate: {
            users_permissions_user: {
              fields: ["username"],
              populate: {
                avatar: {
                  fields: ["id", "Avatar"],
                  populate: {
                    Avatar: {
                      fields: ["formats"],
                    },
                  },
                },
              },
            },
          },
        },
      });

      const users = response.data.data;
      // console.log("users ranking: ", users);

      commit("SET_TOP_USERS", users);
    } catch (error) {
      commit("SET_ERROR", error.response ? error.response.data : error.message);
      console.error(
        "Erreur lors de la récupération des top utilisateurs :",
        error
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
