// store/modules/check.js
import axios from "axios";

const actions = {
  async updateOrCreateCheck(
    { rootGetters },
    { id, list_verset, sourate, iduser, taux, lang }
  ) {
    try {
      const userId = rootGetters["user/userId"];
      let response;

      if (id) {
        // Update existing check
        response = await axios.put(`/versets-checks/${id}`, {
          params: {
            fields: ["id", "list_verset"],
          },
          data: {
            id_user: iduser,
            sourate: sourate,
            id_sourate: String(sourate),
            taux: taux,
            list_verset: list_verset.join("|"),
            locale: lang,
          },
        });
      } else {
        // Create new check
        response = await axios.post(`/versets-checks`, {
          data: {
            users_permissions_user: userId,
            id_user: iduser,
            id_sourate: String(sourate),
            sourate: sourate,
            taux: taux,
            list_verset: list_verset.join("|"),
            locale: lang,
          },
        });
      }

      // console.log("check", response);
      return response.data; // Retourner les nouvelles données
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour ou création du check :",
        error
      );
      throw error;
    }
  },
};

export default {
  namespaced: true,
  actions,
};
