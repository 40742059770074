// store/modules/testimonials.js
import axios from "axios";

const state = {
  testimonials: [],
  testimonial: [],
  reviews: [],
  loading: false,
  error: null,
};

const getters = {
  // Retourne tous les témoignages
  allTestimonials: (state) => state.testimonials,
  myTestimonials: (state) => state.testimonial,

  // Indicateur de chargement
  isLoading: (state) => state.loading,

  // Retourne l'erreur actuelle
  getError: (state) => state.error,
};

const mutations = {
  // Définit les témoignages dans l'état
  SET_TESTIMONIALS(state, testimonials) {
    state.testimonials = testimonials;
  },
  SET_TESTIMONIAL(state, testimonial) {
    state.testimonial = testimonial;
  },

  ADD_REVIEW(state, review) {
    const user = review.attributes?.users_permissions_user?.data;
    state.reviews.push({
      IdReview: review.id,
      Avis: review.attributes.Avis,
      Note: review.attributes.Note,
      idUsername: user ? user.id : null,
    });
  },

  UPDATE_REVIEW(state, updatedReview) {
    state.testimonial = updatedReview;
  },
  REMOVE_REVIEW(state) {
    state.testimonial = null;
  },

  // Met à jour l'indicateur de chargement
  SET_LOADING(state, status) {
    state.loading = status;
  },

  // Définit l'erreur dans l'état
  SET_ERROR(state, error) {
    state.error = error;
  },
};

const actions = {
  // Action pour récupérer les témoignages depuis l'API
  async fetchTestimonials({ commit }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      const response = await axios.get("/testimionials", {
        params: {
          fields: ["Avis", "Note"],
          sort: ["publishedAt:desc"],
          populate: {
            users_permissions_user: {
              fields: ["username"],
              populate: {
                avatar: {
                  fields: ["id", "Avatar"],
                  populate: {
                    Avatar: {
                      fields: ["formats"],
                    },
                  },
                },
              },
            },
          },
        },
      });
      // Supposons que l'API renvoie un objet avec une propriété 'data' contenant un tableau de témoignages
      commit("SET_TESTIMONIALS", response.data.data);
    } catch (error) {
      commit(
        "SET_ERROR",
        error.message || "Erreur lors de la récupération des témoignages."
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchTestimonialById({ commit, rootGetters }) {
    const userId = rootGetters["user/userId"];
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const response = await axios.get("/testimionials", {
        params: {
          fields: ["id", "Avis", "Note"],
          filters: {
            users_permissions_user: {
              id: {
                $eq: userId,
              },
            },
          },
          populate: {
            users_permissions_user: {
              populate: {
                avatar: {
                  fields: ["id", "Avatar"],
                  populate: {
                    Avatar: {
                      fields: ["formats"],
                    },
                  },
                },
              },
            },
          },
        },
      });

      // console.log("review", response);

      commit("SET_TESTIMONIAL", response.data.data[0]);
    } catch (error) {
      commit(
        "SET_ERROR",
        error.message || "Erreur lors de la récupération du témoignage."
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async createReview({ commit }, reviewData) {
    try {
      const response = await axios.post(
        "/testimionials",
        { data: reviewData } // Wrapping postData in a data object
      );
      commit("ADD_REVIEW", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la création d'un review:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  async updateReview({ commit }, { id, data }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const response = await axios.put(`/testimionials/${id}`, { data });
      commit("UPDATE_REVIEW", response.data.data);
    } catch (error) {
      commit(
        "SET_ERROR",
        error.message || "Erreur lors de la mise à jour du témoignage."
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async deleteReview({ commit }, id) {
    // console.log("id review : ", id);

    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      await axios.delete(`/testimionials/${id}`);
      commit("REMOVE_REVIEW");
    } catch (error) {
      commit(
        "SET_ERROR",
        error.message || "Erreur lors de la suppression du témoignage."
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
