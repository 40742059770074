<template>
  <Head>
    <title>Classement - Doonun</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div
    class="ranking"
    :style="{
      backgroundImage: `url('${`https://cp.doonun.com${topUsers[0].attributes.users_permissions_user.data.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small.url}`}')`,
    }"
  ></div>
  <div class="top">
    <div class="second">
      <img
        :src="`https://cp.doonun.com${topUsers[1].attributes.users_permissions_user.data.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small.url}`"
        class="avatar"
        :alt="
          topUsers[1].attributes.users_permissions_user.data.attributes.username
        "
      />
      <p class="name">
        {{
          topUsers[1].attributes.users_permissions_user.data.attributes.username
        }}
      </p>
      <p class="points">{{ formatPoints(topUsers[1].attributes.points) }}</p>
      <div class="rank">
        <p>#2</p>
      </div>
    </div>
    <div class="first">
      <img
        :src="`https://cp.doonun.com${topUsers[0].attributes.users_permissions_user.data.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small.url}`"
        class="avatar"
        :alt="
          topUsers[0].attributes.users_permissions_user.data.attributes.username
        "
      />
      <p class="name">
        {{
          topUsers[0].attributes.users_permissions_user.data.attributes.username
        }}
      </p>
      <p class="points">{{ formatPoints(topUsers[0].attributes.points) }}</p>
      <div class="rank">
        <p>#1</p>
      </div>
    </div>
    <div class="third">
      <img
        :src="`https://cp.doonun.com${topUsers[2].attributes.users_permissions_user.data.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small.url}`"
        class="avatar"
        :alt="
          topUsers[2].attributes.users_permissions_user.data.attributes.username
        "
      />
      <p class="name">
        {{
          topUsers[2].attributes.users_permissions_user.data.attributes.username
        }}
      </p>
      <p class="points">{{ formatPoints(topUsers[2].attributes.points) }}</p>
      <div class="rank"><p>#3</p></div>
    </div>
  </div>
  <div class="container">
    <div class="lists">
      <div
        style="max-width: 600px"
        v-if="isAuthenticated && this.user.hassanate.rang > 100"
      >
        <el-alert
          :title="`🏆#${this.user.hassanate.rang} : ${$t('withoutRank')}`"
          type="success"
          effect="dark"
        />
      </div>
      <div class="card" v-for="user in topUsers.slice(3, 100)" :key="user.id">
        <div class="left">
          <p class="rank">{{ user?.attributes?.rang }}</p>
          <img
            :style="{
              outline:
                user?.attributes?.users_permissions_user?.data?.attributes
                  ?.username === this.user?.username
                  ? `3px solid #67C23A`
                  : 'none',
            }"
            :src="`https://cp.doonun.com${
              user?.attributes?.users_permissions_user?.data?.attributes?.avatar
                ?.data?.attributes?.Avatar?.data?.attributes?.formats?.small
                ?.url || '/uploads/small_plant_f9f00dabd7.png'
            }`"
            :alt="
              user?.attributes?.users_permissions_user?.data?.attributes
                ?.username
            "
            class="avatar"
          />
          <div class="infos">
            <p class="name">
              {{
                user?.attributes?.users_permissions_user?.data?.attributes
                  ?.username
              }}
            </p>
            <p class="points">{{ formatPoints(user.attributes?.points) }}</p>
          </div>
        </div>
        <div class="right">
          <el-icon
            class="itop"
            v-if="user?.attributes?.rang >= user?.attributes?.last_rank"
            ><CaretTop
          /></el-icon>
          <el-icon class="ibottom" v-else><CaretBottom /></el-icon>
        </div>
      </div>
    </div>
  </div>

  <Tabs />
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { Head } from "@vueuse/head";
import Tabs from "../components/global/Tabs.vue";
export default {
  name: "ranking-d",
  components: {
    Head,
    Tabs,
  },
  computed: {
    ...mapGetters("ranking", ["topUsers", "isLoading", "error"]),
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("ranking", ["fetchTopUsers"]),
    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return Number(points);
      }
    },
  },
  mounted() {
    this.fetchTopUsers();
  },
};
</script>

<style scoped>
.ranking {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  max-height: 40vh;
  background-size: cover;
  background-position: center;
  filter: blur(50px);
  opacity: 0.5;
  z-index: -1;
}
.top {
  padding-top: 100px;
  max-width: 1000px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0 auto;
  gap: 10px;
  z-index: 2 !important;
  opacity: 1 !important;
}

.top .second,
.top .first,
.top .third {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.top .rank {
  background: var(--body);
  display: flex;
  align-items: center;
  justify-content: center;
}

.top .avatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.top .name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -5px;
}

.top .points {
  display: inline;
  background: var(--card);
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 700;
}

.top .rank p {
  font-size: 20px;
  font-weight: 700;
}

.top .second .rank {
  width: 100px;
  height: 120px;
  border-radius: 10px 10px 0 0;
}

.top .first .rank {
  width: 100px;
  height: 160px;
  border-radius: 10px 10px 0 0;
}

.top .third .rank {
  width: 100px;
  height: 100px;
  border-radius: 10px 10px 0 0;
}

.container {
  position: relative;
  background: var(--card);
  border-radius: 6px;
  z-index: 3 !important;
}

.lists {
  position: relative;
  display: block;
  margin: -20px auto 0 auto;
  max-width: 600px;
  width: 100%;
  background: var(--card);
  border-radius: 10px 10px 0 0;
  padding: 20px;
  z-index: 3 !important;
}

.lists .card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px dotted var(--body);
}

.lists .card .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.lists .card .left .rank {
  min-width: 30px;
  font-weight: 700;
  font-size: 14px;
}

.lists .card img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  object-position: center;
  border-radius: 13px;
}

.lists .card .left .name {
  font-size: 14px;
  font-weight: 700;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lists .card .left .points {
  font-size: 12px;
  font-weight: 600;
}

.itop {
  color: #67c23a;
  font-size: 20px;
}

.ibottom {
  color: #f56c6c;
  font-size: 20px;
}

@media (max-width: 800px) {
  .lists {
    padding: 20px 0;
  }

  .lists .card {
    padding: 10px 0 10px 0;
  }
}
</style>
