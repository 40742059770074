<template>
  <Head>
    <!-- Titre de la page -->
    <title>Cours islamique - Doonun</title>

    <!-- Description Meta -->
    <meta
      name="description"
      content="Apprenez les sciences islamiques sur Doonun. Découvrez notre sélection de cours en ligne sur des sujets variés tels que la croyance, la jurisprudence, la spiritualité et bien plus encore. Inscrivez-vous dès maintenant et commencez votre voyage d'apprentissage islamique."
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Charset -->
    <meta charset="UTF-8" />

    <!-- Viewport pour la réactivité mobile -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <!-- Canonical URL -->
    <link rel="canonical" href="https://www.doonun.com/cours" />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.doonun.com/cours" />
    <meta property="og:title" content="Cours islamique - Doonun" />
    <meta
      property="og:description"
      content="Apprenez les sciences islamiques sur Doonun. Découvrez notre sélection de cours en ligne sur des sujets variés tels que la croyance, la jurisprudence, la spiritualité et bien plus encore. Inscrivez-vous dès maintenant et commencez votre voyage d'apprentissage islamique."
    />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/Cours_islamique_doonun.png"
    />

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.doonun.com/cours" />
    <meta name="twitter:title" content="Cours islamique - Doonun" />
    <meta
      name="twitter:description"
      content="Apprenez les sciences islamiques sur Doonun. Découvrez notre sélection de cours en ligne sur des sujets variés tels que la croyance, la jurisprudence, la spiritualité et bien plus encore. Inscrivez-vous dès maintenant et commencez votre voyage d'apprentissage islamique."
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/Cours_islamique_doonun.png"
    />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>

  <div class="container">
    <h1>{{ $t("titlepageC") }}</h1>
    <div class="search">
      <el-input
        v-model="input1"
        style="max-width: 640px; width: 100%"
        size="large"
        :placeholder="$t('searchcours')"
        :suffix-icon="Search"
        class="cours-search"
        @input="updateSearch"
      />
      <span class="filters" @click="toogleFilters()">
        <el-icon v-if="filtres === false"><Filter /></el-icon>
        <el-icon v-else><Close /></el-icon>
      </span>
    </div>
    <div class="filters-list" v-if="filtres">
      <div class="filtres">
        <el-tooltip content="Grille" raw-content>
          <div class="filtre view-toggle" @click="toggleView">
            <Grid v-if="viewMode === 'list'" />
            <List v-if="viewMode === 'grid'" />
          </div>
        </el-tooltip>
        <el-tooltip content="Points" raw-content>
          <div class="filtre points" @click="sortByPoints">
            <Points />
          </div>
        </el-tooltip>
        <el-tooltip content="Nouveautés" raw-content>
          <div class="filtre new" @click="sortByNew">
            <New />
          </div>
        </el-tooltip>
        <el-tooltip content="Défiltrer" raw-content>
          <div class="filtre all" @click="resetFilters">
            <el-icon><RefreshRight /></el-icon>
          </div>
        </el-tooltip>
      </div>
      <div class="selects">
        <el-select
          v-model="selectedLevel"
          placeholder="Niveau"
          style="width: 140px"
          @change="filterCourses"
        >
          <el-option
            v-for="level in levels"
            :key="level"
            :label="level"
            :value="level"
          />
        </el-select>
        <el-select
          v-model="selectedCategory"
          placeholder="Catégorie"
          style="width: 160px"
          @change="filterCourses"
        >
          <el-option
            v-for="category in categories"
            :key="category"
            :label="category"
            :value="category"
          />
        </el-select>
      </div>
    </div>
    <div class="loading" v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <div v-if="viewMode === 'list'">
        <div class="container-card" v-if="filteredCourses.length">
          <CardCourseList
            v-for="cours in filteredCourses"
            :key="cours?.id"
            :id="cours?.id"
            :titre="cours?.attributes?.titre"
            :points="cours?.attributes?.points"
            :gain="cours?.attributes?.gain"
            :level="cours?.attributes?.niveau"
            :url="cours?.attributes?.url"
            :category="cours?.attributes?.Category"
            :percent="
              cours?.attributes?.suivis?.data[0]?.attributes?.pourcentage
            "
            :publishedAt="cours?.attributes?.publishedAt"
            :cover="
              cours?.attributes?.Couverture?.data?.attributes?.formats.small.url
            "
            @unlock="unlock"
          />
        </div>
      </div>
      <div v-else>
        <div class="container-card" v-if="filteredCourses.length">
          <CardCourse
            v-for="cours in filteredCourses"
            :key="cours?.id"
            :id="cours?.id"
            :titre="cours?.attributes?.titre"
            :points="cours?.attributes?.points"
            :gain="cours?.attributes?.gain"
            :level="cours?.attributes?.niveau"
            :url="cours?.attributes?.url"
            :category="cours?.attributes?.Category"
            :percent="
              cours?.attributes?.suivis?.data[0]?.attributes?.pourcentage
            "
            :publishedAt="cours?.attributes?.publishedAt"
            :cover="
              cours?.attributes?.Couverture?.data?.attributes?.formats.small.url
            "
            @unlock="unlock"
          />
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardCourse from "../../components/cours/CardCourse.vue";
import CardCourseList from "../../components/cours/CardCourseList.vue";
import Loading from "../../components/navigation/Loading.vue";
import Points from "../../components/icons/cours/Points.vue";
import Grid from "../../components/icons/cours/Grid.vue";
import List from "../../components/icons/cours/List.vue";
import New from "../../components/icons/cours/New.vue";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "cours-doonun",
  components: {
    CardCourse,
    CardCourseList,
    Loading,
    Points,
    Grid,
    List,
    New,
    Head,
    Tabs,
  },
  data() {
    return {
      loading: true,
      viewMode: "grid",
      input1: "",
      selectedLevel: null,
      selectedCategory: null,
      sortOrderPoints: null,
      sortOrderDate: null,
      filtres: false,
      categories: [
        "Al-Qur'an",
        "Hadith",
        "Fiqh",
        "Usul al-Fiqh",
        "Aqidah",
        "Lugha",
        "Akhlaq",
        "Tarikh",
        "Conseil",
        "Question",
      ],
      levels: ["Debutant", "Intermediaire", "Avance"],
    };
  },
  computed: {
    ...mapGetters("cours", ["courses"]),
    ...mapGetters("login", ["isAuthenticated"]),

    filteredCourses() {
      const searchTerm = this.input1.toLowerCase();
      let filtered = this.courses.filter((cours) => {
        const matchesSearch = cours?.attributes?.titre
          .toLowerCase()
          .includes(searchTerm);
        const matchesLevel = this.selectedLevel
          ? cours?.attributes?.niveau === this.selectedLevel
          : true;
        const matchesCategory = this.selectedCategory
          ? cours?.attributes?.Category === this.selectedCategory
          : true;
        return matchesSearch && matchesLevel && matchesCategory;
      });

      // Sort by points
      if (this.sortOrderPoints !== null) {
        filtered.sort((b, a) => {
          return (
            (b?.attributes?.points - a?.attributes?.points) *
            this.sortOrderPoints
          );
        });
      }

      // Sort by published date
      if (this.sortOrderDate !== null) {
        filtered.sort((a, b) => {
          return (
            (new Date(b?.attributes?.publishedAt) -
              new Date(a?.attributes?.publishedAt)) *
            this.sortOrderDate
          );
        });
      }

      return filtered;
    },
  },
  methods: {
    ...mapActions("cours", ["fetchCourses"]),
    async listCourses() {
      this.loading = true;
      try {
        await this.fetchCourses(this.$i18n.locale);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async unlock() {
      try {
        await this.fetchCourses(this.$i18n.locale);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    },
    updateSearch(value) {
      this.input1 = value;
      this.filterCourses();
    },
    filterCourses() {
      this.filteredCourses; // Trigger computed property to reevaluate
    },
    toggleView() {
      this.viewMode = this.viewMode === "grid" ? "list" : "grid";
    },
    toogleFilters() {
      this.filtres = !this.filtres;
    },
    sortByPoints() {
      this.sortOrderPoints = this.sortOrderPoints === 1 ? -1 : 1; // Toggle between ascending and descending
      this.sortOrderDate = null; // Reset date sort order
    },
    sortByNew() {
      this.sortOrderDate = this.sortOrderDate === 1 ? -1 : 1; // Toggle between newest first and oldest first
      this.sortOrderPoints = null; // Reset points sort order
    },
    resetFilters() {
      this.input1 = "";
      this.selectedLevel = null;
      this.selectedCategory = null;
      this.sortOrderPoints = null;
      this.sortOrderDate = null;
    },
  },
  async created() {
    await this.$store.dispatch("user/fetchUser");
    this.listCourses();
  },
  watch: {
    "$i18n.locale"() {
      this.listCourses();
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  min-height: 100vh;
  z-index: 2;
}

h1 {
  font-size: clamp(20px, 8vw, 30px);
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  text-align: center;
  margin-top: 20px;
}

.container-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  align-items: stretch;
  justify-content: left;
  gap: 15px;
  margin-top: 20px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
}

.search {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: var(--card);
  border-radius: 6px;
  cursor: pointer;
}

.filters-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -10px;
  min-height: 50px;
  width: 100%;
  border-radius: 6px;
  gap: 10px;
  /* background: var(--card); */
  /* padding: 6px 10px; */
}

.filters-list .filtres {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.filters-list .filtre {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.selects {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  min-height: 40px;
  border-radius: 6px;
}
</style>
