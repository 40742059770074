<template>
  <div class="box-verset">
    <!-- <div class="box-inner"> -->
    <p class="arabe" :class="vActive">
      <span v-for="(verset, index) in verses" :key="index" :id="index + 1">
        {{ verset?.attributes?.arabe
        }}<span class="number">{{
          convertToArabic(verset?.attributes?.numero)
        }}</span>
      </span>
    </p>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "full-verse",
  props: {
    verses: Object,
    vActive: String,
  },
  methods: {
    convertToArabic(number) {
      const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      return number
        .toString()
        .split("")
        .map((digit) => arabicNumerals[parseInt(digit)])
        .join("");
    },
  },
};
</script>

<style scoped>
.box-verset {
  display: block;
  margin: 10px auto 80px auto;
  max-width: 800px;
}

.arabe {
  gap: 10px;
  direction: rtl;
  font-family: "uthmanic";
  font-size: clamp(20px, 8vw, 33px);
  /* text-align-last: right; */
  text-align: center;
  word-spacing: 0px;
  overflow-wrap: break-word; /* Remplace word-wrap par overflow-wrap */
  /* text-wrap: balance; */ /* Commenté, optionnel */
  line-height: 1.8em;
}

.number {
  margin: 0 5px 0 10px;
}

.reading-verse {
  color: #71d5e3 !important;
  transition: 0.3s ease;
}
</style>
