<template>
  <div class="fullscreen">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M11.9902 8.98595V3.31543"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9902 20.6705V15"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.6703 12.0001L15 11.9482"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.00036 12.0001L3.33008 11.9482"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 5.99998C15 5.99998 12.7905 3.00001 12 3C11.2094 2.99999 9 6 9 6"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99998 15C5.99998 15 3.00001 12.7905 3 12C2.99999 11.2094 6 9 6 9"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 9C18 9 21 11.2095 21 12C21 12.7906 18 15 18 15"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 18C9 18 11.2095 21 12 21C12.7906 21 15 18 15 18"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "fullscreen-icon",
};
</script>

<style scoped>
.fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen svg {
  width: 20px;
  height: 20px;
}

.fullscreen svg path {
  stroke: var(--link);
}
</style>
