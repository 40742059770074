// Store Vuex
import axios from "axios";

const state = {
  articles: null,
  articles4: null,
  article: null,
  selectedArticle: null,
  categories: null,
  currentCategory: null,
  articlesByCategory: null,
  topArticles: null,
};

const getters = {
  articlesList: (state) => state.articles,
  articles4List: (state) => state.articles4,
  articleLast: (state) => state.article,
  articleDetails: (state) => state.selectedArticle,
  categoriesList: (state) => state.categories,
  currentCategory: (state) => state.currentCategory,
  articlesByCategoryList: (state) => state.articlesByCategory,
  topArticlesList: (state) => state.topArticles,
};

const mutations = {
  SET_ARTICLES(state, articles) {
    state.articles = articles;
  },
  SET_ARTICLES_4(state, articles4) {
    state.articles4 = articles4;
  },
  SET_ARTICLE(state, article) {
    state.article = article;
  },
  SET_SELECTED_ARTICLE(state, article) {
    state.selectedArticle = article;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_CURRENT_CATEGORY(state, category) {
    state.currentCategory = category;
  },
  SET_ARTICLES_BY_CATEGORY(state, articles) {
    state.articlesByCategory = articles;
  },
  SET_TOP_ARTICLES(state, articles) {
    state.topArticles = articles;
  },
  UPDATE_ARTICLE_VIEWS(state, { articleId, newViews }) {
    const article = state.articles.find((a) => a.id === articleId);
    if (article) {
      article.attributes.vues = newViews;
    }
    if (state.selectedArticle && state.selectedArticle.id === articleId) {
      state.selectedArticle.attributes.vues = newViews;
    }
  },
};

const actions = {
  async fetchArticles({ commit }, lang) {
    try {
      const response = await axios.get("/articles", {
        params: {
          locale: lang,
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url", "alternativeText"],
            },
          },
        },
      });
      commit("SET_ARTICLES", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des articles:", error);
    }
  },
  async fetchLatestArticle({ commit }, lang) {
    try {
      const response = await axios.get("/articles", {
        params: {
          locale: lang,
          sort: ["publishedAt:desc"], // Trier par date de publication décroissante
          pagination: {
            limit: 1, // Limiter à 1 article
          },
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url", "alternativeText"],
            },
          },
        },
      });

      // console.log("last : ", response.data.data);

      const latestArticle = response.data.data[0];
      commit("SET_ARTICLE", latestArticle);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du dernier article:",
        error
      );
    }
  },
  async fetchLates4tArticles({ commit }, lang) {
    try {
      const response = await axios.get("/articles", {
        params: {
          locale: lang,
          sort: ["publishedAt:desc"], // Trier par date de publication décroissante
          pagination: {
            limit: 5, // Limiter à 1 article
          },
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url", "alternativeText"],
            },
          },
        },
      });

      // console.log("last : ", response.data.data);

      const latest4Articles = response.data.data[0];
      commit("SET_ARTICLES_4", latest4Articles);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du dernier article:",
        error
      );
    }
  },

  async fetchArticleBySlug({ commit, dispatch }, { slug, lang }) {
    try {
      const response = await axios.get(`/articles?populate=*`, {
        params: {
          locale: lang,
          filters: {
            url: {
              $eq: slug,
            },
          },
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url", "alternativeText"],
            },
          },
        },
      });
      const article = response.data.data[0];
      commit("SET_SELECTED_ARTICLE", article);

      // Vérifiez et mettez à jour les vues
      const articleId =
        article.id !== undefined ? article.id : article.attributes.id;
      const currentViews = article.attributes.vues || 0;

      if (articleId !== undefined) {
        // console.log("ID de l'article :", articleId);
        dispatch("updateArticleViews", { articleId, currentViews });
      } else {
        console.error("ID de l'article est undefined");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération de l'article par slug:",
        error
      );
      commit("SET_SELECTED_ARTICLE", null);
    }
  },
  async fetchCategories({ commit }, lang) {
    try {
      const response = await axios.get("/categories", {
        params: {
          locale: lang,
          fields: ["id", "titre", "url"],
        },
      });
      commit("SET_CATEGORIES", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  },
  async fetchArticlesByCategory({ commit }, { categoryUrl, lang }) {
    try {
      const response = await axios.get(`/categories?populate=*`, {
        params: {
          locale: lang,
          filters: {
            url: {
              $eq: categoryUrl,
            },
          },
          populate: {
            articles: {
              fields: ["id", "titre", "url", "text", "Meta", "publishedAt"],
              populate: {
                Cover: {
                  fields: ["*"],
                },
                categories: {
                  fields: ["*"],
                },
              },
            },
          },
        },
      });
      // console.log("Réponse catégories :", response.data.data);
      commit("SET_ARTICLES_BY_CATEGORY", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des articles par catégorie:",
        error
      );
    }
  },
  async updateArticleViews({ commit }, { articleId, currentViews }) {
    // console.log("ID de l'article à mettre à jour :", articleId);
    // console.log("Nombre de vues actuel :", currentViews);
    try {
      await axios.put(`/articles/${articleId}`, {
        data: {
          vues: Number(currentViews) + 1, // Incrémenter les vues directement
        },
      });
      // Mettre à jour l'état local
      commit("UPDATE_ARTICLE_VIEWS", { articleId, newViews: currentViews + 1 });
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des vues de l'article:",
        error
      );
    }
  },
  async fetchTopArticles({ commit }, lang) {
    try {
      const response = await axios.get("/articles", {
        params: {
          locale: lang,
          sort: ["vues:desc"], // Trier par vues décroissantes
          pagination: {
            limit: 5, // Limiter à 5 articles
          },
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url", "alternativeText"],
            },
          },
        },
      });
      // console.log("Articles les plus lus :", response.data.data); // Ajouté pour vérification
      commit("SET_TOP_ARTICLES", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des articles les plus lus:",
        error
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
