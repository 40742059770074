import axios from "axios";

// Configuration API BunnyCDN
const API_KEY = "26f420c6-fd12-4294-813ceb181f9b-ac46-4a2c";
const LIBRARY_ID = "314059";
const BASE_URL = "https://video.bunnycdn.com/library/" + LIBRARY_ID;

const bunnyAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    AccessKey: API_KEY,
    accept: "application/json",
  },
});

// État du module Vuex
const state = {
  collections: null,
  latestCollections: null,
  latest4Collections: null,
  categories: null,
  videos: [],
  portraitVideos: [],
  landscapeVideos: [],
};

// Mutations
const mutations = {
  SET_COLLECTIONS(state, collections) {
    state.collections = collections;
  },
  SET_LATEST_COLLECTIONS(state, collections) {
    state.latestCollections = collections;
  },
  SET_LATEST_4_COLLECTIONS(state, collections) {
    state.latest4Collections = collections;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_VIDEOS(state, videos) {
    // Mutation pour les vidéos
    state.videos = videos;
  },
  SET_PORTRAIT_VIDEOS(state, videos) {
    state.portraitVideos = videos; // Mutation pour les vidéos de portrait
  },
  SET_LANDSCAPE_VIDEOS(state, videos) {
    state.landscapeVideos = videos; // Mutation pour les vidéos de paysage
  },
};

// Actions
const actions = {
  async loadCollections({ commit }, lang) {
    try {
      const response = await axios.get("/doovies", {
        params: {
          locale: lang,
          fields: ["titre", "collection", "desc", "voix", "cc", "note"],
          populate: {
            locale: lang,
            doovie_categories: {
              fields: ["titre"],
              locale: lang,
            },
            cover: { fields: ["formats"] },
          },
        },
      });

      commit("SET_COLLECTIONS", response.data.data);
      commit("SET_LATEST_COLLECTIONS", response.data.data.slice(-1));
      commit(
        "SET_LATEST_4_COLLECTIONS",
        response.data.data.slice(-5).reverse()
      );
      // console.log("Collections récupérées :", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des collections:", error);
    }
  },

  async getCategories({ commit }, lang) {
    try {
      const response = await axios.get("/doovie-categories", {
        params: {
          locale: lang,
          fields: ["titre"],
        },
      });
      commit("SET_CATEGORIES", response.data.data);
      // console.log("categories: ", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  },

  // Nouvelle action pour charger les vidéos d'une collection spécifique
  async loadVideosByCollection({ commit }, collectionId) {
    const url = `/videos?page=1&itemsPerPage=100&collection=${collectionId}&orderBy=title`;
    try {
      const response = await bunnyAPI.get(url);
      commit("SET_VIDEOS", response.data.items);
      console.log(
        `Vidéos pour la collection ${collectionId}:`,
        response.data.items
      );
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des vidéos de la collection:",
        error
      );
    }
  },

  // Nouvelle action pour charger les vidéos de la collection portrait
  async loadPortraitVideos({ commit }) {
    const collectionId = "4dd0ba69-7a12-47f5-a315-5422bf92aa0a"; // ID de la collection portrait
    const url = `/videos?page=1&itemsPerPage=300&collection=${collectionId}&orderBy=title`;
    try {
      const response = await bunnyAPI.get(url);
      const videos = response.data.items.map((video) => ({
        id: video.guid,
        title: video.title,
        image: `https://vz-6191ec9e-917.b-cdn.net/${video.guid}/thumbnail.jpg`,
      }));
      commit("SET_PORTRAIT_VIDEOS", videos);
      // console.log("Vidéos portrait récupérées :", videos);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des vidéos portrait:",
        error
      );
    }
  },

  // Nouvelle action pour charger les vidéos de la collection landscape
  async loadLandscapeVideos({ commit }) {
    const collectionId = "9c051bea-0eb9-4e17-ae94-b609e4ca8a65"; // ID de la collection landscape
    const url = `/videos?page=1&itemsPerPage=300&collection=${collectionId}&orderBy=title`;
    try {
      const response = await bunnyAPI.get(url);
      const videos = response.data.items.map((video) => ({
        id: video.guid,
        title: video.title,
        image: `https://vz-6191ec9e-917.b-cdn.net/${video.guid}/thumbnail.jpg`,
      }));
      commit("SET_LANDSCAPE_VIDEOS", videos);
      // console.log("Vidéos landscape récupérées :", videos);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des vidéos landscape:",
        error
      );
    }
  },
};

// Getters
const getters = {
  collections: (state) => state.collections,
  categories: (state) => state.categories,
  latestCollections: (state) => state.latestCollections,
  latest4Collections: (state) => state.latest4Collections,
  videos: (state) => state.videos,
  portraitVideos: (state) => state.portraitVideos,
  landscapeVideos: (state) => state.landscapeVideos,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
