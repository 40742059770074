<template>
  <div class="courses">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M12 15L12 16.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.99992 15C3.14924 17.9325 3.30564 19.4716 4.39804 20.4447C5.58266 21.5 7.43047 21.5 11.1261 21.5H12.8738C16.5694 21.5 18.4172 21.5 19.6018 20.4447C20.6942 19.4716 20.8506 17.9325 20.9999 15"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.84718 10.4431C4.54648 13.6744 8.3792 15 12 15C15.6208 15 19.4535 13.6744 21.1528 10.4431C21.964 8.90056 21.3498 6 19.352 6H4.648C2.65023 6 2.03603 8.90056 2.84718 10.4431Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M15.9999 6L15.9116 5.69094C15.4716 4.15089 15.2516 3.38087 14.7278 2.94043C14.204 2.5 13.5083 2.5 12.1168 2.5H11.8829C10.4915 2.5 9.79575 2.5 9.27198 2.94043C8.7482 3.38087 8.52819 4.15089 8.08818 5.69094L7.99988 6"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "courses-icon",
};
</script>

<style scoped>
.courses {
  display: flex;
  justify-content: center;
  align-items: center;
}

.courses svg {
  width: 22px;
  height: 22px;
}

.courses svg path {
  stroke: var(--color);
}

.components:hover .courses svg path {
  stroke-width: 1.5;
  stroke: var(--button);
}
</style>
