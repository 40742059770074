<template>
  <div class="valide-c">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="432.75" height="438.131" viewBox="0 0 432.75 438.131">
              <g id="vegan" transform="translate(-39.271 475.23)">
                  <path id="Tracé_22556" data-name="Tracé 22556" d="M454.3-474.9c-4.9,1-12.5,6-25.3,16.5-24.2,20-50.1,32.7-103.8,50.8-39.2,13.2-58.4,21.4-76.9,32.7-66.4,40.6-87.6,109.1-55.8,180.4,4.1,9.1,3.4,13.3-4.7,27.5-3.5,6.3-9.8,17.8-13.8,25.5-10.6,20.3-17.9,32.5-19.5,32.5-.7,0-7.1-8.4-14.2-18.7-17.4-25.5-26.2-36.4-42.2-52.4C79.4-198.8,67.8-206,56.4-206c-7.8,0-14.8,4.9-16.9,11.8-1.3,4.4,2.9,10.4,13.4,19.1,5.5,4.5,16.4,14.8,24.3,23,23.6,24.4,39.1,45.6,49.4,67.8,12.3,26.6,20.9,42.7,23.6,44.7a19.925,19.925,0,0,0,8,2.4c4.4.3,5.9,0,8.8-2,4.5-3,6.6-7.5,13.3-28.3,3-9.4,8-22.6,11.1-29.4,10-22.3,28.6-54.5,33.7-58.3,2.5-1.9,2.9-1.9,10.5-.4,25.9,5.2,40.5,5.8,57.2,2,16.5-3.7,26.4-7.3,45.7-16.9,28.6-14.1,51.6-30.5,71-50.6,25.5-26.3,40.3-53,49.9-90,6.7-25.7,8.2-39.9,9.1-85.4.4-18.7,1.2-36.1,2.1-41.9,2.8-19.8,1.5-30.5-4.2-34.3A17.149,17.149,0,0,0,454.3-474.9ZM436.4-388c0,29.5-1.1,40.8-6,62.9-6.9,30.8-22.4,60.5-42.7,81.7-16.8,17.6-33.3,29.3-61,43.3-27.8,14-49,20-66.8,18.7-9.9-.6-13.9-1.9-13.9-4.1,0-1.7,34.7-44.9,44.5-55.4,3.3-3.5,13.9-13.3,23.5-21.6,20.5-17.9,42.4-39.8,44-44a16.347,16.347,0,0,0-.9-13.9c-2.3-3.7-9.1-7.6-13.2-7.6-3,0-13.3,4.8-19.7,9.2-11,7.6-60.6,55.6-93.6,90.8-13.2,14.1-12.3,13.9-17.2,3.5-3.9-8.3-5.4-16.1-5.4-27.6,0-37.9,18.1-68.6,55-93.3,17.5-11.8,29.9-17.2,71-30.9,30.3-10.1,48.2-17.3,78-31,12-5.6,19.6-8.5,21.5-8.4l3,.2Z" fill="#4ade80"/>
              </g>
          </svg> -->
    <svg
      id="GUIDE_TO_DELETE"
      enable-background="new 0 0 128 128"
      height="512"
      viewBox="0 0 128 128"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m124 64c0 5.12-6.29 9.34-7.55 14.06-1.3 4.88 1.99 11.68-.48 15.95-2.51 4.34-10.06 4.86-13.58 8.38s-4.04 11.07-8.38 13.58c-4.27 2.47-11.07-.82-15.95.48-4.72 1.26-8.94 7.55-14.06 7.55s-9.34-6.29-14.06-7.55c-4.88-1.3-11.68 1.99-15.95-.48-4.34-2.51-4.86-10.06-8.38-13.58s-11.07-4.04-13.58-8.38c-2.47-4.27.82-11.07-.48-15.95-1.26-4.72-7.55-8.94-7.55-14.06s6.29-9.34 7.55-14.06c1.3-4.88-1.99-11.68.48-15.95 2.51-4.34 10.06-4.86 13.58-8.38s4.04-11.07 8.38-13.58c4.27-2.47 11.07.82 15.95-.48 4.72-1.26 8.94-7.55 14.06-7.55s9.34 6.29 14.06 7.55c4.88 1.3 11.68-1.99 15.95.48 4.34 2.51 4.86 10.06 8.38 13.58s11.07 4.04 13.58 8.38c2.47 4.27-.82 11.07.48 15.95 1.26 4.72 7.55 8.94 7.55 14.06z"
          fill="#00D566"
        />
        <path
          d="m81.34 46.12-22.84 22.84-11.84-11.83c-2.57-2.57-6.74-2.57-9.31 0-2.57 2.57-2.57 6.74 0 9.31l16.61 16.61c2.5 2.5 6.56 2.5 9.06 0l27.62-27.62c2.57-2.57 2.57-6.74 0-9.31-2.57-2.57-6.73-2.57-9.3 0z"
          fill="#fff"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "check-d",
};
</script>

<style>
.valide-c {
  display: flex;
  align-items: center;
  width: 100%;
}

.valide-c svg {
  width: 20px;
  height: 20px;
}
</style>
