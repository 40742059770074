<template>
  <Head>
    <!-- Titre de la page -->
    <title>Duaas pour chaque situation - Doonun</title>

    <!-- Description Meta -->
    <meta
      name="description"
      content="Découvrez l'importance des invocations islamiques dans votre vie quotidienne avec notre sélection de Douas sur Doonun. Trouvez la citadelle du musulman dans la protection et la guidance offertes par les invocations. Apprenez la signification et la prononciation correcte de chaque Doua en arabe et en français. Renforcez votre foi et votre connexion avec Allah en intégrant ces invocations importantes dans vos prières quotidiennes. Explorez notre sélection dès maintenant pour trouver les invocations les plus pertinentes pour vos besoins."
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Charset -->
    <meta charset="UTF-8" />

    <!-- Viewport pour la réactivité mobile -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <!-- Canonical URL -->
    <link
      rel="canonical"
      href="https://www.doonun.com/duaas-pour-chaque-situation"
    />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta
      property="og:url"
      content="https://www.doonun.com/duaas-pour-chaque-situation"
    />
    <meta property="og:title" content="Duaas pour chaque situation - Doonun" />
    <meta
      property="og:description"
      content="Découvrez l'importance des invocations islamiques dans votre vie quotidienne avec notre sélection de Douas sur Doonun. Trouvez la citadelle du musulman dans la protection et la guidance offertes par les invocations. Apprenez la signification et la prononciation correcte de chaque Doua en arabe et en français. Renforcez votre foi et votre connexion avec Allah en intégrant ces invocations importantes dans vos prières quotidiennes. Explorez notre sélection dès maintenant pour trouver les invocations les plus pertinentes pour vos besoins."
    />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/duaas_73117ce2be.png"
    />

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:url"
      content="https://www.doonun.com/duaas-pour-chaque-situation"
    />
    <meta name="twitter:title" content="Duaas pour chaque situation - Doonun" />
    <meta
      name="twitter:description"
      content="Découvrez l'importance des invocations islamiques dans votre vie quotidienne avec notre sélection de Douas sur Doonun. Trouvez la citadelle du musulman dans la protection et la guidance offertes par les invocations. Apprenez la signification et la prononciation correcte de chaque Doua en arabe et en français. Renforcez votre foi et votre connexion avec Allah en intégrant ces invocations importantes dans vos prières quotidiennes. Explorez notre sélection dès maintenant pour trouver les invocations les plus pertinentes pour vos besoins."
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/duaas_73117ce2be.png"
    />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>

  <div class="container">
    <h1>{{ $t("titlepageDu") }}</h1>
    <div class="search">
      <el-input
        v-model="input1"
        style="max-width: 640px; width: 100%"
        size="large"
        :placeholder="$t('searchduaas')"
        :suffix-icon="Search"
        class="duaas-search"
      />
    </div>
    <div class="loading" v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <div class="container-duaas">
        <div
          class="item"
          v-for="category in filteredCategories"
          :key="category?.id"
        >
          <router-link :to="`/duaas/${category?.attributes?.slug}`">
            <div class="icon">
              <img
                :src="`https://cp.doonun.com/${category?.attributes?.icon?.data?.attributes?.url}`"
                alt=""
              />
            </div>
            <h3>{{ category?.attributes?.name }}</h3>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Search } from "@element-plus/icons-vue";
import Loading from "../../components/navigation/Loading.vue";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "duaas-doonun",
  components: {
    Loading,
    Head,
    Tabs,
  },
  data() {
    return {
      loading: true,
      input1: "",
    };
  },
  setup() {
    return {
      Search,
    };
  },
  computed: {
    ...mapGetters("duaas", ["categoriesList"]),
    filteredCategories() {
      const query = this.input1.toLowerCase();
      return this.categoriesList.filter((category) =>
        category?.attributes?.name.toLowerCase().includes(query)
      );
    },
  },
  methods: {
    ...mapActions("duaas", ["fetchCategories"]),
    async listDuaas() {
      this.loading = true;
      try {
        await this.fetchCategories(this.$i18n.locale);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    this.listDuaas();
  },
  watch: {
    "$i18n.locale"() {
      this.fetchCategories(this.$i18n.locale);
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  z-index: 2;
}

h1 {
  font-size: clamp(20px, 8vw, 30px);
  text-align: center;
  margin-bottom: 20px;
}

.container-duaas {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: stretch;
  justify-content: left;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

h3 {
  font-size: 14px;
  font-weight: 500;
}

.item {
  display: flex;
  align-items: center;
  background: var(--card);
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  transition: 0.2s ease-in;
}

.item:hover {
  outline: 1px solid var(--button);
}

.item a {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.item .icon img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
}
</style>
