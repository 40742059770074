import { themes } from "./themes";

export function applyTheme(index) {
  const theme = themes[index];
  if (theme) {
    Object.keys(theme).forEach((variable) => {
      if (variable !== "name") {
        const cssVariable = getCssVariableName(variable);
        document.documentElement.style.setProperty(
          cssVariable,
          theme[variable]
        );
      }
    });
  }
}

function getCssVariableName(variable) {
  // Gestion des variables spécifiques
  const specialVariables = [
    "colorBtn",
    "bgPoints",
    "borderV",
    "bgV",
    "optionsBackground",
  ];
  if (specialVariables.includes(variable)) {
    return `--${variable}`; // Laisse les variables spécifiques comme elles sont
  }

  // Autres variables, transformation en kebab-case
  return `--${camelToKebab(variable)}`;
}

function camelToKebab(str) {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}
