<template>
  <Head>
    <title>{{ cleanTitle(currentVideo.title) }} - Doonun</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="bg">
    <div class="container">
      <!-- Player Artplayer intégré -->
      <div v-if="currentVideo">
        <div ref="artRef" class="artplayer-container"></div>
      </div>

      <div v-else>
        <p>Chargement de la vidéo...</p>
      </div>

      <!-- Liste des autres vidéos -->
      <div class="video-list" v-if="videos.length > 1">
        <div class="video-thumbnails">
          <div
            v-for="(video, index) in sortedVideos"
            :key="video.guid"
            :class="['video-thumbnail', { active: videoIndex === index }]"
            @click="selectVideo(index + 1)"
          >
            <img :src="getThumbnailUrl(video.guid)" alt="" />
            <p>{{ index + 1 }}</p>
            <div class="view">
              <openEye />
              <span>{{ video.views }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import Artplayer from "artplayer";
import artplayerPluginMultipleSubtitles from "artplayer-plugin-multiple-subtitles";
import { mapGetters, mapActions } from "vuex";
import openEye from "../../components/icons/openEye.vue";
import { Head } from "@vueuse/head";
import Tabs from "../../components/global/Tabs.vue";

export default {
  name: "dooviePlay",
  components: {
    openEye,
    Head,
    Tabs,
  },
  data() {
    return {
      collectionId: this.$route.params.slug, // ID de la collection
      videoIndex: this.$route.params.subslug
        ? this.$route.params.subslug - 1
        : 0, // Index ajusté, commence à 0 pour la première vidéo
      isVideoPlaying: false,
      currentTime: 0,
      intervalId: null,
      instance: null,
      selectedResolution: "720p", // Résolution par défaut
      showSubtitleMenu: false, // Contrôle l'affichage du menu des sous-titres
    };
  },
  props: {
    option: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters("bunny", ["videos"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    // Trie les vidéos par le numéro d'épisode extrait du titre
    sortedVideos() {
      return this.videos.slice().sort((a, b) => {
        const numA = this.extractEpisodeNumber(a.title);
        const numB = this.extractEpisodeNumber(b.title);
        return numA - numB;
      });
    },

    currentVideo() {
      // Sélectionne la vidéo en cours à partir de l'index ajusté
      return this.sortedVideos.length > 0
        ? this.sortedVideos[this.videoIndex]
        : null;
    },
    availableResolutionsArray() {
      return this.currentVideo
        ? this.currentVideo.availableResolutions.split(",")
        : [];
    },
    videoUrl() {
      // Génère l'URL directe de la vidéo avec la résolution sélectionnée
      return this.currentVideo
        ? `https://vz-6191ec9e-917.b-cdn.net/${this.currentVideo.guid}/play_${this.selectedResolution}.mp4`
        : null;
    },
    captionsArray() {
      return this.currentVideo && this.currentVideo.captions
        ? this.currentVideo.captions.map((caption) => ({
            name: caption.label || "Sous-titre", // Nom affiché pour le sous-titre
            url: `https://vz-6191ec9e-917.b-cdn.net/${this.currentVideo.guid}/captions/${caption.srclang}.vtt`, // URL du fichier de sous-titres
            type: caption.type || "vtt", // Type de sous-titre, par défaut "vtt"
            encoding: caption.encoding || "utf-8", // Encodage, par défaut "utf-8"
            escape: caption.escape !== undefined ? caption.escape : true, // Échapper les balises HTML, par défaut true
            style: caption.style || {
              fontSize: "2.5vw",
              lineHeight: "normal",
              fontWeight: "bold",
              color: "#ffffff",
              textShadow: "0px 0px 7px #000000",
              fontFamily:
                "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif",
              paddingBottom: "3vh",
            },
          }))
        : [];
    },
    highlightsArray() {
      return this.currentVideo && this.currentVideo.moments
        ? this.currentVideo.moments.map((moment) => ({
            time: moment.timestamp,
            text: moment.label,
          }))
        : [];
    },
  },
  methods: {
    ...mapActions("bunny", ["loadVideosByCollection"]),
    // Génère l'URL de la miniature pour chaque vidéo
    getThumbnailUrl(videoGuid) {
      return `https://vz-6191ec9e-917.b-cdn.net/${videoGuid}/thumbnail.jpg`;
    },

    // Méthode pour changer la vidéo en fonction de l'index
    selectVideo(index) {
      this.videoIndex = index - 1; // Ajuste l'index pour que le premier soit 1
      this.$router.push({
        params: { slug: this.collectionId, subslug: index },
      }); // Met à jour l'URL avec l'index ajusté
    },

    // Extrait le premier numéro trouvé dans le titre
    extractEpisodeNumber(title) {
      const match = title.match(/(\d+)/); // Recherche le premier nombre dans le titre
      return match ? parseInt(match[1], 10) : 0; // Renvoie le numéro ou 0 si non trouvé
    },

    // Enlève l'extension ".mp4" du titre
    cleanTitle(title) {
      return title.endsWith(".mp4") ? title.slice(0, -4) : title;
    },

    receiveMessage(event) {
      const allowedOrigin = "https://iframe.mediadelivery.net";
      if (event.origin !== allowedOrigin) return;

      // const data = event.data;
      // Gestion des événements reçus de l'iframe si nécessaire
      // Actuellement non utilisé car l'iframe est supprimé
    },

    checkAndUpdateSubSlug() {
      const currentEpisode = this.currentVideo.episode;
      const currentSubSlug = parseInt(this.$route.params.subslug, 10) || 1;

      if (currentSubSlug !== currentEpisode) {
        this.$router.replace({
          params: { slug: this.collectionId, subslug: currentEpisode },
        });
      }
    },

    initializeArtplayer() {
      if (this.instance) {
        this.instance.destroy();
        this.instance = null;
      }

      if (this.videoUrl) {
        console.log("Initialisation d'Artplayer avec l'URL :", this.videoUrl);
        this.instance = new Artplayer({
          container: this.$refs.artRef,
          url: this.videoUrl,
          autoOrientation: true,
          autoplay: true,
          fullscreenWeb: true,
          fullscreen: true,
          // pip: true,
          setting: true,
          subtitle:
            this.captionsArray.find((caption) => caption.name === "fr") || {},

          plugins: [
            artplayerPluginMultipleSubtitles({
              subtitles: this.captionsArray.map((caption) => ({
                name: caption.name,
                url: caption.url,
              })),
            }),
          ],
          playbackRate: true,
          hotkey: true,
          playsInline: true,
          autoSize: true,
          settings: [
            {
              width: 200,
              html: "Subtitle",
              tooltip: "Afficher/Masquer",
              icon: `<i class="fa-solid fa-closed-captioning" style="color: #FFD43B;"></i>`,
              selector: [
                {
                  html: `<i class="fa-solid fa-closed-captioning"></i>`,
                  tooltip: "",
                  switch: true,
                  onSwitch: function (item) {
                    item.tooltip = item.switch ? "Masquer" : "Afficher";
                    this.subtitle.show = !item.switch;
                    return !item.switch;
                  },
                },
                // Ajout des sous-titres disponibles sans l'option "Double"
                ...this.captionsArray.map((caption) => ({
                  html: caption.name,
                  name: caption.name,
                })),
              ],
              onSelect: function (item) {
                // Afficher un sous-titre spécifique
                this.plugins.multipleSubtitles.tracks([item.name]);
                return item.html;
              },
            },
          ],
          controls: [
            {
              position: "right",
              html: `<i class="fa-solid fa-layer-group"></i>`,
              tooltip: "Episodes",
              style: {
                color: "white",
                fontSize: "20px",
              },
              click: function (...args) {
                console.info("click", args);
              },
            },
          ],
          highlight: this.highlightsArray,
          theme: "#ffad00",
          // quality: [
          //   {
          //     default: true,
          //     html: "SD 480P",
          //     url: "/assets/sample/video.mp4",
          //   },
          //   {
          //     html: "HD 720P",
          //     url: "/assets/sample/video.mp4",
          //   },
          // ],
          ...this.option,
        });

        // Gestion des événements Artplayer
        this.instance.on("ready", () => {
          console.log("Artplayer est prêt");
        });

        this.instance.on("play", () => {
          this.isVideoPlaying = true;
        });

        this.instance.on("pause", () => {
          this.isVideoPlaying = false;
        });

        this.instance.on("ended", () => {
          this.isVideoPlaying = false;
        });

        this.instance.on("timeupdate", () => {
          this.currentTime = this.instance.currentTime;
        });
      } else {
        console.error("URL de la vidéo non définie.");
      }
    },

    updateVideoUrl() {
      if (this.instance && this.instance.switch) {
        console.log("Changement de l'URL de la vidéo vers :", this.videoUrl);
        this.instance.switch(this.videoUrl);
      } else {
        this.initializeArtplayer();
      }
    },

    changeSubtitle(subtitle) {
      if (this.instance) {
        this.instance.subtitle.destroy(); // Détruire le sous-titre actuel
        if (subtitle) {
          this.instance.subtitle.load(subtitle.url, subtitle.type);
        }
      }
      this.showSubtitleMenu = false; // Fermer le menu après la sélection
    },
  },
  watch: {
    videoUrl(newUrl) {
      if (this.instance && this.instance.switch) {
        console.log("Changement de l'URL de la vidéo via watcher :", newUrl);
        this.instance.switch(this.videoUrl);
      } else {
        this.initializeArtplayer();
      }
    },
    currentVideo() {
      this.checkAndUpdateSubSlug();
      // Réinitialiser la résolution sélectionnée si nécessaire
      // this.selectedResolution = this.availableResolutionsArray.includes("720p")
      //   ? "720p"
      //   : this.availableResolutionsArray[0] || "480p";

      // Réinitialiser les sous-titres
      this.initializeArtplayer();
    },
  },
  created() {
    if (!this.isSubscribed) {
      // Redirige vers /doovie si non abonné
      this.$router.push("/doovie");
      return;
    }

    this.loadVideosByCollection(this.collectionId);
  },
  mounted() {
    this.initializeArtplayer();
    window.addEventListener("message", this.receiveMessage);
  },
  beforeUnmount() {
    if (this.instance && this.instance.destroy) {
      this.instance.destroy(false);
    }
    window.removeEventListener("message", this.receiveMessage);
  },
};
</script>

<style scoped>
.artplayer-container {
  width: 100%;
  height: 100vh;
}

.bg {
  margin-top: 20px;
  padding: 60px 0;
  background-color: #000;
  min-height: 100vh;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.box-player {
  position: relative;
}

.before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 20px;
  background: rgb(0, 0, 0);
  width: 100%;
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.00043767507002800965) 0%,
    rgba(0, 0, 0, 0.8687850140056023) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.00043767507002800965) 0%,
    rgba(0, 0, 0, 0.8687850140056023) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.00043767507002800965) 0%,
    rgba(0, 0, 0, 0.8687850140056023) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0;
  transition: 0.2s ease-in;
}

.before h3 {
  color: #fff;
}

.box-player:hover .before {
  opacity: 1;
}

.video-list {
  margin-top: 20px;
}

.video-thumbnails {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 10px;
}

.video-thumbnail {
  position: relative;
  cursor: pointer;
  background: var(--button);
  z-index: 1;
}

.video-thumbnail::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2px;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    328deg,
    rgba(0, 0, 0, 0.00043767507002800965) 0%,
    rgba(0, 0, 0, 0.4318102240896359) 100%
  );
  background: -webkit-linear-gradient(
    328deg,
    rgba(0, 0, 0, 0.00043767507002800965) 0%,
    rgba(0, 0, 0, 0.4318102240896359) 100%
  );
  background: linear-gradient(
    328deg,
    rgba(0, 0, 0, 0.00043767507002800965) 0%,
    rgba(0, 0, 0, 0.4318102240896359) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.video-thumbnail.active {
  border: 3px solid var(--button);
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  margin-bottom: 0;
}

.video-thumbnail p {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 22px;
  z-index: 3;
  color: #fff;
  font-weight: 600;
  padding-top: 5px;
  padding-left: 10px;
}

.view {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  gap: 3px;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  color: #fff;
  z-index: 4;
}

.before select {
  padding: 5px;
  font-size: 14px;
}
</style>
