<template>
  <div class="hass">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.9375rem"
      height="0.9375rem"
      fill="none"
      viewBox="0 0 22 30"
      class="sc-chSlKD bBarkW"
    >
      <path
        fill="#000"
        d="m21.731 14.683-14 15a1 1 0 0 1-1.711-.875l1.832-9.167L.65 16.936a1 1 0 0 1-.375-1.625l14-15a1 1 0 0 1 1.71.875l-1.837 9.177 7.204 2.7a1 1 0 0 1 .375 1.62h.005Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "hass-icon",
};
</script>

<style>
.hass {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hass svg {
  width: 13px;
  height: 13px;
}

.hass svg path {
  fill: var(--link);
}

.lock-btn:hover .hass svg path {
  fill: #fff;
}
</style>
