// store/modules/ayahs.js
import axios from "axios";

const state = {
  ayahs: [],
  loading: false,
  error: null,
};

const getters = {
  // Retourne tous les versets
  allAyahs: (state) => state.ayahs,

  // Indicateur de chargement
  isLoading: (state) => state.loading,

  // Retourne l'erreur actuelle
  getError: (state) => state.error,
};

const mutations = {
  // Définit les versets dans l'état
  SET_AYAHS(state, ayahs) {
    state.ayahs = ayahs;
  },

  // Ajoute des versets au state existant
  ADD_AYAHS(state, ayahs) {
    state.ayahs = [...state.ayahs, ...ayahs];
  },

  // Met à jour l'indicateur de chargement
  SET_LOADING(state, status) {
    state.loading = status;
  },

  // Définit l'erreur dans l'état
  SET_ERROR(state, error) {
    state.error = error;
  },
};

const actions = {
  // Action optimisée pour récupérer les ayahs avec chargement progressif
  async fetchAyahs({ commit }, lang) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    const baseParams = {
      locale: lang,
      fields: ["id", "fr", "phonetique", "arabe", "numero"],
      populate: {
        sourate: {
          fields: ["Numero", "Phonetique", "Titre"],
        },
      },
      pagination: {
        page: 1,
        pageSize: 200,
      },
    };

    try {
      // 1. Récupérer la première page
      const initialResponse = await axios.get("/ayahs", { params: baseParams });
      const { data: initialData, meta } = initialResponse.data;

      commit("SET_AYAHS", initialData); // Afficher immédiatement la première page

      const totalPages = meta.pagination.pageCount;

      // 2. Charger les autres pages en arrière-plan
      if (totalPages > 1) {
        const promises = [];

        for (let page = 2; page <= totalPages; page++) {
          const params = {
            ...baseParams,
            pagination: {
              page: page,
              pageSize: 200,
            },
          };
          // Lancer les requêtes sans attendre
          const promise = axios
            .get("/ayahs", { params })
            .then((response) => {
              commit("ADD_AYAHS", response.data.data); // Ajouter les versets au state
            })
            .catch((error) => {
              // Gérer les erreurs pour chaque page individuellement
              console.error(
                `Erreur lors de la récupération de la page ${page}:`,
                error
              );
            });

          promises.push(promise);
        }

        // Optionnel : Vous pouvez suivre la progression des requêtes si nécessaire
        // await Promise.all(promises);
      }
    } catch (error) {
      commit(
        "SET_ERROR",
        error.message || "Erreur lors de la récupération des ayahs."
      );
      console.error("Erreur lors de la récupération des Ayahs:", error);
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
