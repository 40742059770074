<template>
  <Navbar />
  <div class="main">
    <router-view> </router-view>
    <Up />
  </div>
  <Footer />
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions, mapState } from "vuex";
import Navbar from "./components/navigation/Navbar.vue";
import Footer from "./components/navigation/Footer.vue";
import Up from "./components/navigation/Up.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Up,
  },
  data() {
    return {
      userEmail: "",
      loading: {},
    };
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    ...mapState("user", ["user"]),
    hasSubscription() {
      return this.isSubscribed;
    },
  },
  async created() {
    try {
      await this.fetchUser();
    } catch {
      console.log("error info user");
    }
  },
  async mounted() {
    await this.fetchUserEmail();
    if (this.userEmail) {
      await this.checkSubscriptionStatus();
    }
    this.checkUserStatus();
  },
  methods: {
    ...mapActions("subscribe", ["checkSubscriptionStatus"]),
    ...mapActions("user", ["fetchUser"]),

    async fetchUserEmail() {
      if (this.user) {
        try {
          this.userEmail = this.user.email;
          // Mettre à jour l'e-mail de l'utilisateur dans le store
          this.$store.commit("subscribe/SET_USER_EMAIL", this.userEmail);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des informations de l'utilisateur:",
            error
          );
        }
      }
    },

    handleStripeCheckout(event) {
      const button = event.currentTarget;
      const planId = button.getAttribute("data-id");
      const userEmail = button.getAttribute("data-email");
      this.loading[planId] = true;

      axios
        .get(
          `https://cp.doonun.com/strapi-stripe/getRedirectUrl/${planId}/${userEmail}`
        )
        .then((response) => {
          if (response.data && response.data.url) {
            window.location.href = response.data.url;
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la redirection vers Stripe:", error);
          this.loading[planId] = false;
        });
    },

    async checkUserStatus() {
      // Si l'utilisateur est authentifié
      if (this.isAuthenticated) {
        // Vérifier l'abonnement avant de continuer
        await this.checkSubscriptionStatus(); // Appel de l'action pour vérifier l'abonnement

        // Si l'utilisateur est abonné, ne rien faire
        if (this.isSubscribed) {
          return;
        } else {
          // Si l'utilisateur n'est pas abonné, on vérifie l'état de l'offre gratuite
          const freeOfferData = localStorage.getItem("freeOfferSelected");
          if (freeOfferData !== null) {
            const { expiration } = JSON.parse(freeOfferData); // Extraire la date d'expiration
            const currentTime = new Date().getTime();
            if (currentTime > expiration) {
              // Si l'offre gratuite a expiré, supprimer et rediriger vers /tarifs
              localStorage.removeItem("freeOfferSelected");
              this.$router.push("/tarifs");
            }
          } else {
            // Si 'freeOfferSelected' n'existe pas, rediriger vers /tarifs
            this.$router.push("/tarifs");
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
