<template>
  <div class="classement">
    <div class="top">
      <div class="img-c">
        <img
          src="https://cp.doonun.com/uploads/trophy_1389170_fadfb481cb.png"
          alt=""
        />
        <h3 class="title">Classement</h3>
      </div>
      <router-link to="/classement">
        <Arrow />
      </router-link>
    </div>
    <diV
      class="rank-item"
      v-for="(user, index) in topUsers.slice(0, 5)"
      :key="index"
    >
      <diV class="l">
        <img
          :src="`https://cp.doonun.com${
            user?.attributes?.users_permissions_user?.data?.attributes?.avatar
              ?.data?.attributes?.Avatar?.data?.attributes?.formats?.small
              ?.url || '/uploads/small_plant_f9f00dabd7.png'
          }`"
          :alt="
            user?.attributes?.users_permissions_user?.data?.attributes?.username
          "
          class="avatar"
        />
        <p class="username">
          {{
            user?.attributes?.users_permissions_user?.data?.attributes?.username
          }}
        </p>
      </diV>
      <div class="end">
        <p class="points">
          {{ formatPoints(user.attributes?.points) }} <Hass />
        </p>
        <div class="num">
          <p>#{{ user?.attributes?.rang }}</p>
        </div>
      </div>
    </diV>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Hass from "../icons/Hass.vue";
import Arrow from "../icons/Arrow.vue";
export default {
  name: "ranking-list",
  components: { Arrow, Hass },
  computed: {
    ...mapGetters("ranking", ["topUsers", "isLoading", "error"]),
  },
  methods: {
    ...mapActions("ranking", ["fetchTopUsers"]),
    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return Number(points);
      }
    },
  },
  mounted() {
    this.fetchTopUsers();
  },
};
</script>

<style scoped>
.classement {
  background: var(--card);
  width: 100%;
  padding: 20px;
  border-radius: 6px;
}

.classement .top {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.classement p {
  font-size: 16px;
}

.classement .top .img-c {
  display: flex;
  align-items: center;
  gap: 10px;
}

.classement .top img {
  width: 40px;
  height: 40px;
}

.classement .top .title {
  font-size: 18px;
  font-weight: 800;
}

.classement .rank-item {
  margin-top: 10px;
  border-top: 1px solid var(--body);
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.classement .rank-item .l {
  display: flex;
  align-items: center;
  gap: 10px;
}

.classement .rank-item .end {
  display: flex;
  align-items: center;
  gap: 10px;
}

.classement .rank-item .end .points {
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 600;
  font-size: 14px;
  list-style: 0;
}

.classement .rank-item .end .num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  padding: 15px;
  background: var(--body);
  border-radius: 8px;
}
.classement .rank-item .end .num p {
  font-weight: 600;
  font-size: 14px;
  color: var(--text);
}

.classement .rank-item img {
  width: 40px;
  height: 40px;
  border-radius: 15px;
}

.classement .rank-item .username {
  font-weight: 500;
  font-size: 14px;
  word-wrap: break-word;
}
</style>
