<template>
  <Head>
    <title>Fiiyd - Réseau social musulman</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container">
    <div class="boites">
      <div class="content-with-sidebars">
        <div class="left">
          <div class="fiiyd-icon">
            <div class="icons">
              <ul>
                <li>
                  <router-link to="/fiiyd">
                    <HomeFii />
                    <span>{{ $t("feed") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/notifications">
                    <div class="bell">
                      <span v-if="fiiydsCount >= 1">{{ fiiydsCount }}</span>
                      <BellFii />
                    </div>
                    <span>{{ $t("notif") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/myposts">
                    <PostsFii />
                    <span>{{ $t("myposts") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="middle">
          <div class="size-box-v">
            <div class="fil">
              <div class="loading" v-if="isLoading">
                <Loading />
              </div>

              <div v-else>
                <div class="lists-posts">
                  <div class="box-posts" v-if="post">
                    <CardFiiydPost
                      :key="post?.IdPost"
                      :username="post?.username"
                      :avatar="post?.avatar"
                      :time="post?.time"
                      :content="post?.Content"
                      :images="post?.images"
                      :liens="post?.liens"
                      :vues="post?.vues"
                      :likes="post?.like"
                      :url="post?.url"
                      :comments="post?.totalComments"
                      :favoris="filteredFav"
                      :IdPost="post?.IdPost"
                      :idUsername="post?.idUsername"
                      :enable_comment="post?.enable_comment"
                      :enable_notif="post?.enable_notif"
                      :content_sensitive="post?.content_sensitive"
                      @addFavorite="addFavorite"
                    />
                  </div>
                </div>
                <div class="post-comment">
                  <InputComments
                    v-if="post?.enable_comment"
                    :postId="post?.IdPost"
                    :notif="post?.enable_notif"
                    :postUser="post?.idUsername"
                    @comment-added="fetchPost"
                  />
                  <div
                    v-else
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 5px;
                    "
                  >
                    <el-icon><Warning /></el-icon>
                    <p style="text-align: center">
                      <span style="font-weight: 500">{{ post.username }}</span>
                      a fermer les commentaires
                    </p>
                  </div>
                </div>
                <div class="box-comments">
                  <CardFiiydComment
                    v-for="comment in post?.comments"
                    :key="comment?.IdComment"
                    :username="comment?.username"
                    :time="comment?.time"
                    :content="comment?.Content"
                    :images="comment?.images"
                    :avatar="comment?.avatar"
                    :liens="comment?.liens"
                    :vues="comment?.vues"
                    :likes="comment?.like"
                    :sensible="comment?.content_sensitive"
                    :enable_comment="comment?.enable_comment"
                    :enable_notif="comment?.enable_notif"
                    :favoris="getFilteredFavForComment(comment)"
                    :comments="comment?.answers"
                    :url="comment?.url"
                    :IdComment="comment?.IdComment"
                    @comment-deleted="handleCommentDeleted"
                    @addFavorite="addFavorite"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <Trends />
        </div>
      </div>
      <!-- <Head>
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head> -->
    </div>
  </div>
  <div class="tabs">
    <TabsFiiyd />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import InputComments from "../../components/fiiyd/InputComments.vue";
import HomeFii from "../../components/icons/fiiyd/Home.vue";
import BellFii from "../../components/icons/fiiyd/Bell.vue";
import PostsFii from "../../components/icons/fiiyd/Posts.vue";
import CardFiiydPost from "../../components/fiiyd/CardFiidPost.vue";
import CardFiiydComment from "../../components/fiiyd/CardFiiComment.vue";
import Trends from "../../components/fiiyd/Trends.vue";
import TabsFiiyd from "../../components/fiiyd/TabsFiiyd.vue";
import { Head } from "@vueuse/head";

export default {
  name: "fiiyd-url",
  components: {
    Loading,
    Head,
    Trends,
    InputComments,
    HomeFii,
    BellFii,
    PostsFii,
    CardFiiydPost,
    CardFiiydComment,
    TabsFiiyd,
  },
  data() {
    return {
      isLoading: true,
      slug: this.$route.params.slug,
    };
  },
  computed: {
    ...mapState({
      post: (state) => state.fiiyd.fetchedPost,
      user: (state) => state.user.user,
    }),
    fiiydsCount() {
      return this.$store.state.fiiyd.fiiyds.length; // Accès direct à la longueur
    },
    filteredFav() {
      if (this.post && Array.isArray(this.post.fav)) {
        const userFav = this.post.fav.find(
          (favItem) => favItem.id === this.user.id
        );
        return userFav ? userFav.id : null;
      }
      return null;
    },
  },
  methods: {
    ...mapActions("fiiyd", ["fetchPostByUrl"]),
    ...mapActions("user", ["fetchUser"]),

    // Méthode pour filtrer fav des posts
    getFilteredFav(post) {
      if (post && Array.isArray(post.fav)) {
        const userFav = post.fav.find((favItem) => favItem.id === this.user.id);
        return userFav ? userFav.id : null;
      }
      return null;
    },

    // Méthode pour filtrer fav des commentaires
    getFilteredFavForComment(comment) {
      if (comment && Array.isArray(comment.fav)) {
        const userFav = comment.fav.find(
          (favItem) => favItem.id === this.user.id
        );
        // console.log(
        //   `Comment ID: ${comment.IdComment}, User Fav: ${
        //     userFav ? userFav.id : null
        //   }`
        // );
        return userFav ? userFav.id : null;
      }
      // console.log(
      //   `Comment ID: ${comment.IdComment}, Fav is not an array or undefined`
      // );
      return null;
    },

    async fetchPost() {
      await this.fetchPostByUrl(this.slug);
      this.isLoading = false;
      // this.logComments();
    },
    logComments() {
      if (this.post && Array.isArray(this.post.comments)) {
        console.log("posts:", this.post);
        this.post.comments.forEach((comment) => {
          console.log("Comment Time:", comment.time); // Vérifiez que comment.time est défini
        });
      } else {
        console.log("No comments or post is undefined");
      }
    },

    async addFavorite() {
      try {
        await this.fetchPost();
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    },

    handleCommentDeleted(deletedId) {
      this.post.comments = this.post.comments.filter(
        (comment) => comment.IdComment !== deletedId
      );
    },
  },
  async mounted() {
    try {
      await this.fetchPost();
      // console.log("post :", this.post);
    } catch (error) {
      console.error("Erreur lors de la récupération du post:", error);
    }
  },
  created() {
    this.fetchPost();
  },
};
</script>

<style scoped>
.boites {
  width: 100%;
  display: block;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  min-height: 100vh;
}

.content-with-sidebars {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.content-with-sidebars .left {
  width: 20%;
}

.content-with-sidebars .middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(60% - 40px);
  border-right: 1px solid var(--card);
  border-left: 1px solid var(--card);
}

.content-with-sidebars .right {
  width: 20%;
}

.container {
  padding-bottom: 30px;
  .container {
    min-height: 100vh;
  }
}

.icons ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icons ul li {
  padding-bottom: 20px;
  padding-right: 10px;
}

.icons ul li a {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icons ul li a span {
  font-size: 20px;
  font-weight: 500;
}

.size-box-v {
  display: flex;
  justify-content: center;
  max-width: 550px;
  width: 100%;
}

.size-box-v .fil {
  width: 100%;
}

.post-comment {
  padding: 10px 20px;
  border-bottom: 1px solid var(--card);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bell {
  position: relative;
  z-index: 1;
}

.bell span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  padding: 10px;
  background: var(--button);
  color: #fff;
  border-radius: 50%;
  z-index: 2;
  margin-right: -15px;
  margin-top: -14px;
  font-size: 10px !important;
  font-weight: 700;
}

.tabs {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  background: var(--card);
  width: 100%;
  z-index: 10;
}

@media (max-width: 998px) {
  .boites {
    margin-top: 100px;
  }

  .content-with-sidebars .left {
    width: calc(10% - 20px);
  }

  .content-with-sidebars .left span {
    display: none;
  }

  .content-with-sidebars .middle {
    width: calc(75% - 20px);
  }

  .content-with-sidebars .right {
    width: calc(15% - 20px);
    display: none;
  }
}

@media (max-width: 800px) {
  .boites {
    margin-top: 100px;
    padding: 0;
  }
  .content-with-sidebars .left {
    width: 100%;
    display: none;
  }

  .content-with-sidebars .middle {
    width: 100%;
    border: none;
  }

  .content-with-sidebars .right {
    width: 100%;
    display: none;
  }

  .size-box-v .fil {
    padding: 0;
  }

  .post-input {
    padding: 0;
  }

  .post-comment {
    padding: 10px 0;
  }

  .tabs {
    display: block;
  }
}
</style>
