// store/modules/like.js
import axios from "axios";

const state = {
  likes: [],
};

const getters = {
  likes: (state) => state.likes,
};

const mutations = {
  SET_LIKES(state, likes) {
    state.likes = likes;
  },
};

const actions = {
  async fetchLikes({ commit, rootGetters }) {
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get("/like-sourates", {
        params: {
          fields: ["id", "id_sourate"],
          filters: {
            users_permissions_users: userId,
          },
        },
      });

      // console.log("likes : ", response.data);
      commit("SET_LIKES", response.data);
    } catch (error) {
      console.error(error);
    }
  },

  async updateLike({ rootGetters }, { id, liked, like }) {
    try {
      const userId = rootGetters["user/userId"];
      const endpoint = `/like-sourates/${id}?populate=*`;
      const method = "put";

      const getResponse = await axios.get(endpoint);

      if (
        getResponse.data &&
        getResponse.data.data &&
        getResponse.data.data.attributes &&
        getResponse.data.data.attributes.users_permissions_users &&
        getResponse.data.data.attributes.users_permissions_users.data
      ) {
        let currentUsers =
          getResponse.data.data.attributes.users_permissions_users.data.map(
            (user) => user.id
          );
        // console.log("user_permission : ", currentUsers);

        let data = {
          likes: like,
          users_permissions_users: {},
        };

        if (liked && !currentUsers.includes(userId)) {
          data.likes = like + 1;
          data.users_permissions_users.connect = [{ id: userId }];
        } else if (!liked && currentUsers.includes(userId)) {
          data.likes = like - 1;
          data.users_permissions_users.disconnect = [{ id: userId }];
        }

        await axios({
          method: method,
          url: endpoint,
          data: { data },
        });

        // console.log("Réponse de la mise à jour du like :", response.data);
      } else {
        throw new Error(
          "users_permissions_users non trouvé dans la réponse de l'API"
        );
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du like :", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
