<template>
  <router-link :to="courseUrl">
    <div class="box-card" ref="cardContainer">
      <div class="img-box">
        <div class="new" v-if="isNewCourse"></div>
        <img :src="`https://cp.doonun.com${cover}`" alt="" />
      </div>
      <div class="right">
        <div class="top-info">
          <p v-if="!percent" class="pts">{{ points }} pts</p>
          <p v-else class="pts gain">+{{ gain }} pts</p>
          <p class="category">{{ category }}</p>
          <p class="level">{{ level }}</p>
        </div>
        <div class="bottom">
          <div class="titre">
            <h3>{{ titre }}</h3>
          </div>
          <div class="lock" v-if="!percent">
            <button
              class="lock-btn"
              type="primary"
              color="#0c0020"
              @click.prevent="unlockCours()"
              v-if="!loading"
            >
              <Hass />
            </button>
            <Loading v-else />
          </div>
          <div class="d-progress pr" v-else>
            <el-progress
              type="circle"
              :percentage="Number(percent)"
              :width="45"
              :stroke-width="4"
              :color="customColors"
            />
          </div>
        </div>
      </div>
    </div>
  </router-link>
  <el-dialog
    v-model="centerDialogVisible"
    :title="$t('warningTitleM')"
    width="500"
    align-center
  >
    <span>{{ $t("messagePopupC") }}</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Ok</el-button>
        <el-button type="primary" @click="goToSubscription">
          {{ $t("btnSub1") }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import confetti from "../../assets/js/confetti";
import Hass from "../icons/Hass.vue";
import Loading from "../navigation/Loading.vue";
import { ref } from "vue";

export default {
  name: "card-course-list",
  components: {
    Hass,
    Loading,
  },
  props: {
    id: Number,
    titre: String,
    url: String,
    percent: String,
    cover: String,
    points: String,
    gain: String,
    level: String,
    category: String,
    publishedAt: String,
  },
  data() {
    return {
      userPoints: 0,
      loading: false,
      centerDialogVisible: false,
    };
  },
  setup() {
    const customColors = [
      { color: "#FEC733", percentage: 20 },
      { color: "#FC68EB", percentage: 40 },
      { color: "#F88055", percentage: 60 },
      { color: "#0738FD", percentage: 80 },
      { color: "#2BC971", percentage: 100 },
    ];
    const format = (percentage) =>
      percentage === 100 ? "100%" : `${percentage}%`;
    const percentage = ref(50);
    return { percentage, format, customColors };
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    ...mapState("user", ["user"]),
    courseUrl() {
      if (this.isAuthenticated) {
        return this.percent ? `/cours/${this.url}` : "";
      } else {
        return "/connexion";
      }
    },
    isNewCourse() {
      const publishedDate = new Date(this.publishedAt);
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 4);
      return publishedDate > currentDate;
    },
  },
  methods: {
    ...mapActions("points", ["updateUserPoints"]),
    ...mapActions("cours", ["createCourseProgress"]),

    ...mapActions("user", ["fetchUser"]),
    triggerConfetti() {
      const cardRect = this.$refs.cardContainer.getBoundingClientRect();
      confetti({
        particleCount: 150,
        spread: 60,
        origin: {
          x: (cardRect.left + cardRect.right) / 2 / window.innerWidth,
          y: (cardRect.top + cardRect.bottom) / 2 / window.innerHeight,
        },
      });
    },
    async unlockCours() {
      this.loading = true;
      try {
        if (
          Number(this.user.hassanate.points) >= Number(this.points) ||
          this.isSubscribed
        ) {
          if (!this.isSubscribed) {
            let newPoints =
              Number(this.user.hassanate.points) - Number(this.points);
            await this.updateUserPoints(newPoints);
            this.user.hassanate.points = newPoints;
          }
          await this.createCourseProgress({ url: this.url, idCourse: this.id });
          this.triggerConfetti();
          this.$emit("unlock");
        } else {
          this.centerDialogVisible = true;
          if (!this.user.hassanate.points) {
            await this.updateUserPoints(0);
            // console.log("hassanate crée");
          }
          // console.log("points : ", this.user.hassanate.points);
          // console.log(
          //   "Vous n'avez pas suffisamment de points pour débloquer ce cours."
          // );
        }
      } catch (error) {
        console.error("Erreur lors du déblocage du cours :", error);
      } finally {
        this.loading = false; // Fin du chargement
      }
    },
    goToSubscription() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "tarifs" });
    },
  },
  async created() {
    await this.fetchUser();
  },
};
</script>

<style scoped>
.box-card {
  position: relative;
  display: flex;
  min-width: 240px;
  height: 100%;
  transition: 0.2s ease;
  border-radius: 6px;
  gap: 10px;
  background: var(--card);
}

/* Keyframes pour l'animation de pulsation */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Classe .new avec animation */
.new {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  background: #ff2f40;
  border-radius: 50%;

  /* Ajout de l'animation de pulsation */
  animation: pulse 2s infinite;

  /* Transition douce */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.box-card .img-box {
  position: relative;
  max-width: 60px;
  min-height: 60px;
  height: 100%;
}

.img-box img {
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 8px 0 0 8px;
  object-fit: cover;
  object-position: center;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.top-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 0;
}

.top-info p {
  font-size: 12px;
  color: #656c7c;
}

.group {
  display: flex;
  align-items: center;
  gap: 3px;
}

.group span {
  font-size: 12px;
  color: #656c7c;
}

.pts {
  color: #fbc33b !important;
  font-weight: 500;
  padding: 5px 8px;
  border-radius: 4px;
  background: #fff9eb;
  font-size: 10px !important;
}

.gain {
  background: #f0f9eb !important;
  color: #9bd77d !important;
}

.titre {
  display: flex;
  align-items: start;
  min-height: 20px;
  width: 100%;
  height: 100%;
}

h3 {
  font-size: 13px;
  font-weight: 400;
}

.bottom {
  display: flex;
  padding-bottom: 10px;
  align-items: end;
  justify-content: space-around;
  gap: 10px;
  height: 100%;
  width: 100%;
}

.lock {
  display: flex;
  align-items: end;
  justify-content: end;
  padding-right: 10px;
}

.lock-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: var(--card);
  color: var(--color);
  padding: 8px 10px 8px 10px;
  border-radius: 4px;
  border: 1px solid var(--color);
  font-size: 12px;
  gap: 3px;
}

.lock-btn:hover {
  background: var(--button);
  border: 1px solid var(--button);
  color: #fff;
}

.d-progress {
  display: flex;
  padding-right: 10px;
  height: auto !important;
  align-items: end;
}

.pr svg {
  width: 50px !important;
  height: 50px !important;
}
</style>
