<template>
  <Head>
    <!-- Titre de la page -->
    <title>Rechercher dans le Coran - Doonun</title>

    <!-- Description Meta -->
    <meta
      name="description"
      content="Trouvez facilement les versets et les sourates que vous cherchez dans le Coran avec notre moteur de recherche avancé. Recherchez dans le Coran en utilisant des mots-clés, des numéros de sourate ou de verset, ou même des phrases complètes. Notre moteur de recherche puissant et précis vous aidera à trouver exactement ce que vous cherchez en un rien de temps. Découvrez également des fonctionnalités de filtrage avancées pour affiner votre recherche et trouver les résultats les plus pertinents. Essayez notre moteur de recherche dès maintenant pour explorer le Coran comme jamais auparavant."
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Charset -->
    <meta charset="UTF-8" />

    <!-- Viewport pour la réactivité mobile -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <!-- Canonical URL -->
    <link rel="canonical" href="https://www.doonun.com/versets" />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.doonun.com/versets" />
    <meta property="og:title" content="Rechercher dans le Coran - Doonun" />
    <meta
      property="og:description"
      content="Trouvez rapidement les versets et sourates du Coran avec notre moteur de recherche avancé sur Doonun. Utilisez des mots-clés, des numéros de sourate ou de verset, et même des phrases complètes. Affinez votre recherche avec nos filtres avancés pour des résultats précis. Explorez le Coran comme jamais auparavant en essayant notre moteur de recherche dès maintenant."
    />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/versets_b4704a977d.png"
    />

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.doonun.com/versets" />
    <meta name="twitter:title" content="Rechercher dans le Coran - Doonun" />
    <meta
      name="twitter:description"
      content="Trouvez rapidement les versets et sourates du Coran avec notre moteur de recherche avancé sur Doonun. Utilisez des mots-clés, des numéros de sourate ou de verset, et même des phrases complètes. Affinez votre recherche avec nos filtres avancés pour des résultats précis. Explorez le Coran comme jamais auparavant en essayant notre moteur de recherche dès maintenant."
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/versets_b4704a977d.png"
    />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />
  </Head>

  <div class="container">
    <div class="box-verses">
      <div class="left"></div>
      <div class="middle">
        <div>
          <h1>{{ $t("searchTitleV") }}</h1>
          <p class="resultats" v-if="filteredVerse.length > 0">
            {{ filteredVerse.length }} {{ $t("resultSearchV") }}
          </p>
          <div class="search">
            <el-input
              v-model="recherche"
              @input="fetchFilteredVerse"
              style="max-width: 620px; width: 100%"
              size="large"
              :placeholder="$t('searchverse')"
              :suffix-icon="Search"
            />
          </div>
          <div class="pag">
            <el-pagination
              :pager-count="5"
              layout="prev, pager, next"
              v-model:current-page="currentPage"
              :page-size="itemsPerPage"
              :total="filteredVerse.length"
            />
          </div>
          <div class="list-result-v">
            <div v-if="isLoading && filteredVerse.length <= 0" class="loading">
              <Loading />
            </div>
            <div v-if="!isLoading && filteredVerse.length === 0">
              <p style="text-align: center">{{ $t("noFoundV") }}</p>
            </div>

            <versetCard
              v-else
              v-for="verse in paginatedVerseList"
              :key="verse.id"
              :numero="verse.attributes.numero"
              :phonetique="verse.attributes.sourate.data.attributes.Phonetique"
              :sourate="verse.attributes.sourate.data.attributes.Numero"
              :trad="verse.attributes.sourate.data.attributes.Titre"
              :french="verse.attributes.fr"
              :phonetic="verse.attributes.phonetique"
              :arabe="verse.attributes.arabe"
              :search-term="recherche"
            />
          </div>
        </div>

        <div class="pag">
          <el-pagination
            :pager-count="5"
            layout="prev, pager, next"
            v-model:current-page="currentPage"
            :page-size="itemsPerPage"
            :total="filteredVerse.length"
          />
        </div>

        <div v-if="error" class="error">
          <p>{{ error }}</p>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import versetCard from "../../components/coran/versetCard.vue";
import Loading from "../../components/navigation/Loading.vue";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "VersetD",
  components: { versetCard, Loading, Head, Tabs },
  data() {
    return {
      recherche: "",
      currentPage: 1,
      itemsPerPage: 15,
    };
  },
  computed: {
    ...mapGetters("versets", ["allAyahs", "isLoading", "getError"]),
    filteredVerse() {
      if (!this.recherche) {
        return this.allAyahs;
      }
      const searchTerm = this.recherche.toLowerCase();
      return this.allAyahs.filter((verse) => {
        const fr = verse.attributes.fr || "";
        const phonetic = verse.attributes.phonetique || "";
        const arabe = verse.attributes.arabe || "";
        const phonetique =
          verse.attributes.sourate?.data?.attributes?.Phonetique || "";
        const numero = verse.attributes.numero
          ? verse.attributes.numero.toString()
          : "";
        const sourateNumero = verse.attributes.sourate?.data?.attributes?.Numero
          ? verse.attributes.sourate.data.attributes.Numero.toString()
          : "";

        return (
          fr.toLowerCase().includes(searchTerm) ||
          phonetic.toLowerCase().includes(searchTerm) ||
          arabe.includes(searchTerm) ||
          phonetique.toLowerCase().includes(searchTerm) ||
          numero.includes(searchTerm) ||
          sourateNumero.includes(searchTerm)
        );
      });
    },

    paginatedVerseList() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredVerse.slice(start, end);
    },

    error() {
      return this.getError;
    },
  },
  methods: {
    ...mapActions("versets", ["fetchAyahs"]),
    fetchFilteredVerse() {
      this.currentPage = 1;
    },

    loadAyahs() {
      this.fetchAyahs(this.$i18n.locale);
    },
  },
  watch: {
    // currentPage(newPage) {
    //   console.log(`Page actuelle mise à jour: ${newPage}`);
    // },
    "$i18n.locale"() {
      this.loadAyahs();
    },
  },
  created() {
    this.loadAyahs();
  },
};
</script>

<style scoped>
.container {
  margin-top: 100px;
  min-height: 100vh;
}

.box-verses {
  padding: 100px;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background: var(--card);
  border-radius: 6px;
}

.box-verses h1 {
  font-weight: 700;
  line-height: 1.5rem;
  /* margin-top: 30px; */
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
}

.search {
  margin-bottom: 20px;
}

.resultats {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.left,
.right {
  flex: 1;
}

.middle {
  width: 700px;
  max-width: 100%;
  padding: 0 20px;
}

.pag {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.loading {
  display: flex;
  justify-content: center;
}

@media (max-width: 760px) {
  .last-item img {
    width: 100%;
    max-width: 100px;
    border-radius: 3px;
  }

  .container {
    margin-top: 0;
    border-radius: 0;
    background: var(--card);
  }

  .box-verses {
    padding: 30px 0 0 0;
  }
}

@media (max-width: 1024px) {
  .left,
  .right {
    flex: 100%;
  }

  .left {
    order: 2;
  }

  .middle {
    order: 1;
    flex: 100%;
    padding: 0;
    border: none;
  }

  .right {
    order: 3;
  }
}
</style>
