<template>
  <div class="card">
    <div>
      <div class="top">
        <img :src="`https://cp.doonun.com${img}`" :alt="username" />
        <p>{{ username }}</p>
      </div>
      <p class="avis">
        {{ avis }}
      </p>
    </div>
    <div class="note">
      <el-rate disabled v-model="value" clearable></el-rate>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "card-avis-doonun",
  props: {
    username: String,
    img: {
      type: String,
    },
    note: Number,
    avis: String,
  },
  setup(props) {
    const value = ref(props.note);
    return {
      value,
    };
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  min-width: 315px;
  width: 100%;
  height: 100%;
  /* min-height: 300px; */
  background: var(--card);
  border-radius: 8px;
}

.card .top {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.card .top img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  background: var(--body);
  border-radius: 50%;
}

.card .avis {
  padding-top: 10px;
  font-size: 14px;
  line-height: 1.7;
}

.card .note {
  display: flex;
  justify-content: end;
}

.card .note p span {
  font-size: 20px;
  font-weight: 600;
  color: #efbb01;
}
</style>
