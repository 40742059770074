<template>
  <div class="success">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M5 14L8.5 17.5L19 6.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "success-icon",
};
</script>

<style scoped>
.success {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  background: #f0f9eb;
}

.success svg {
  width: 25px;
  height: 25px;
}

.success svg path {
  stroke: #67c23a;
}
</style>
