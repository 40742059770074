<template>
  <div class="quote-top">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="409.56"
      height="297.765"
      viewBox="0 0 409.56 297.765"
    >
      <g id="_11398399" data-name="11398399" transform="translate(0)">
        <path
          id="Tracé_32"
          data-name="Tracé 32"
          d="M218.4-176.8v-69.5h-54v-3c0-9.4,8-36.1,15.1-50.1,12.9-25.8,33.6-47.3,60.1-62.4l3.5-2-8.4-20.6L226.3-405l-7.2,1.3c-76,13.2-140,71.5-160.2,145.9-6.8,24.8-6.7,24.3-7.2,90.3l-.4,60.2H218.4Z"
          transform="translate(166.46 405)"
          fill="#0c0020"
        />
        <path
          id="Tracé_33"
          data-name="Tracé 33"
          d="M436.623-107.969s.53-32.365.53-70.265v-68.8h-27.5c-23.5,0-27.5-.2-27.5-1.5,0-4.1,4.3-22.6,7.4-31.8,11.3-33.9,33.1-61.2,62.7-78.7,4.9-3,8.8-5.8,8.6-6.4-.3-.6-4-9.8-8.3-20.3-9-22.3-7.1-20.8-22.6-17.3a207.516,207.516,0,0,0-84.8,40.7c-9.8,7.9-27,25.8-34.3,35.5a207.827,207.827,0,0,0-31.1,59.8c-9.4,28.6-10.8,43.4-10.4,109.5l.3,49,83.1.3C398.453-108.133,436.623-107.969,436.623-107.969Z"
          transform="translate(-269.293 405.733)"
          fill="#0c0020"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "quote-top-icon",
};
</script>

<style scoped>
.quote-top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote-top svg {
  width: 16px;
  height: 16px;
}

.quote-top svg path {
  fill: var(--color);
}
</style>
