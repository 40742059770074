<template>
  <div v-if="showPopup" class="gif-popup">
    <div class="gif-popup-content">
      <div class="top">
        <el-icon v-if="selectedCategory || searchQuery" @click="resetCategory"
          ><ArrowLeftBold
        /></el-icon>
        <input
          type="search"
          v-model="searchQuery"
          @input="searchGifs"
          placeholder="Search GIFs..."
        />
        <el-icon @click="closePopup"><CloseBold /></el-icon>
      </div>

      <div v-if="!selectedCategory && !searchQuery" class="categories-grid">
        <div
          v-for="category in categories"
          :key="category.searchterm"
          class="category-item"
          @click="selectCategory(category)"
        >
          <img :src="category.image" alt="Category Image" />
          <p>{{ category.name }}</p>
        </div>
      </div>
      <div v-else class="gif-grid" @scroll="loadMoreGifs">
        <img
          v-for="gif in gifs"
          :key="gif.id"
          :src="gif.media_formats.tinygif.url"
          @click="selectGif(gif.media_formats.tinygif.url)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
      gifs: [],
      categories: [],
      searchQuery: "",
      selectedCategory: null,
      nextPos: 0,
      totalCount: 0,
    };
  },
  methods: {
    async searchGifs() {
      if (this.searchQuery.length > 2) {
        this.selectedCategory = null; // Reset selected category when searching
        this.nextPos = 0; // Reset pagination
        this.gifs = []; // Clear current GIFs
        await this.loadMoreGifs();
      }
    },
    async loadCategories() {
      try {
        const response = await fetch(
          `https://tenor.googleapis.com/v2/categories?key=AIzaSyBNqzP-1x0pRx6VQeppnPpU-3bhqvgJuho&client_key=my_project&locale=${this.$i18n.locale}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.categories = data.tags;
      } catch (error) {
        console.error("Erreur lors de la récupération des catégories:", error);
      }
    },
    async selectCategory(category) {
      this.selectedCategory = category;
      this.searchQuery = ""; // Clear search query
      this.nextPos = 0; // Reset pagination
      this.gifs = []; // Clear current GIFs
      await this.loadMoreGifs();
    },
    async loadMoreGifs() {
      if (this.nextPos === null) return;
      const query = this.selectedCategory
        ? this.selectedCategory.searchterm
        : this.searchQuery;
      try {
        const response = await fetch(
          `https://tenor.googleapis.com/v2/search?q=${query}&key=AIzaSyBNqzP-1x0pRx6VQeppnPpU-3bhqvgJuho&client_key=my_project&limit=20&pos=${this.nextPos}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.gifs = [...this.gifs, ...data.results];
        this.nextPos = data.next;
      } catch (error) {
        console.error("Erreur lors de la récupération des GIFs:", error);
      }
    },
    selectGif(gifUrl) {
      this.$emit("gifSelected", gifUrl);
      this.closePopup();
    },
    closePopup() {
      this.showPopup = false;
      this.resetCategory();
      this.searchQuery = "";
      this.gifs = [];
    },
    openPopup() {
      this.showPopup = true;
      this.loadCategories();
    },
    resetCategory() {
      this.selectedCategory = null;
      this.searchQuery = "";
      this.gifs = [];
    },
  },
};
</script>

<style scoped>
.gif-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 999;
}

.gif-popup-content {
  background: var(--card);
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.top .el-icon {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  background: var(--body);
  cursor: pointer;
  border-radius: 50%;
}

.categories-grid {
  max-height: 400px;
  overflow-x: auto;
  scrollbar-width: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  margin-top: 20px;
}

.category-item {
  position: relative;
  cursor: pointer;
  width: 100%;
  text-align: center;
  flex-grow: 1;
  margin-bottom: -6px;
}

.category-item p {
  display: inline;
  position: absolute;
  bottom: 0;
  left: 10px;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  font-size: 20px;
  background: var(--truncated);
}

.category-item img {
  object-fit: cover;
  width: 100%;
  height: 150px;
}

.gif-grid {
  max-height: 400px;
  overflow-x: auto;
  scrollbar-width: none;
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin-top: 20px;
}

.gif-grid img {
  object-fit: cover;
  max-width: 117px;
  height: 117px;
  cursor: pointer;
}

input[type="search"] {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--card);
  background: var(--body);
  border-radius: 5px;
}
</style>
