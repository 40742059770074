<!-- Movie.vue -->
<template>
  <router-link :to="movieUrl" class="item" @click="addToResume">
    <div class="movie-card" @click="showSubscriptionDialog()">
      <div class="info" @click.prevent="handleClick">
        <el-icon><InfoFilled /></el-icon>
      </div>
      <img :src="`https://cp.doonun.com${image}`" :alt="title" />
      <div class="movie-info">
        <h3>{{ title }}</h3>
      </div>
    </div>
  </router-link>
  <PopupMovie
    :isOpen="isOpen"
    :title="title"
    :description="description"
    :image="image"
    :lien="lien"
    :lang="lang"
    :note="note"
    :cc="cc"
    :categories="categories"
    @update:isOpen="isOpen = $event"
  />
  <el-dialog
    v-model="centerDialogVisible"
    :title="`${$t('warningTitleM')}`"
    width="500"
    align-center
  >
    <span>{{ $t("messageDoovie") }}</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Ok</el-button>
        <el-button type="primary" @click="goToSubscription">
          {{ $t("btnSub1") }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import PopupMovie from "./Popup.vue";
import { mapGetters } from "vuex";
export default {
  name: "MovieCard",
  components: {
    PopupMovie,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: "",
    },
    lien: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      default: "",
    },
    note: Number,
    cc: {
      type: String,
      default: "",
    },
    episode: {
      type: Number,
      default: 1,
    },
    timer: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      centerDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    movieUrl() {
      if (this.isAuthenticated) {
        if (this.isSubscribed) {
          return `/doovie/${this.lien}/${this.episode}`;
        } else {
          return "";
        }
      } else {
        return "/connexion";
      }
    },
  },
  methods: {
    togglePopup() {
      this.isOpen = !this.isOpen;
    },
    addToResume() {
      const movie = {
        id: this.id,
        title: this.title,
        image: this.image,
        lien: this.lien,
        episode: this.episode,
        timer: this.timer,
        categories: this.categories,
        description: this.description,
        lang: this.lang,
        cc: this.cc,
      };

      // Récupérer les movies sauvegardés ou initialiser un tableau vide
      let resumeMovies = JSON.parse(localStorage.getItem("resumeMovies")) || [];

      // Optionnel : Assurer l'unicité basée sur 'lien'
      resumeMovies = resumeMovies.filter((m) => m.lien !== this.lien);

      // Ajouter le nouveau movie
      resumeMovies.push(movie);

      // Limiter le tableau à 5 éléments
      if (resumeMovies.length > 5) {
        resumeMovies.shift(); // Supprime le premier élément (le plus ancien)
      }

      // Sauvegarder le tableau mis à jour dans localStorage
      localStorage.setItem("resumeMovies", JSON.stringify(resumeMovies));
    },
    handleClick() {
      this.togglePopup();
    },
    showSubscriptionDialog() {
      if (!this.isSubscribed) {
        this.centerDialogVisible = true;
      }
    },
    goToSubscription() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "tarifs" });
    },
  },
};
</script>

<style scoped>
@media (max-width: 1400px) {
  .movie-card {
    aspect-ratio: 16 / 9;
  }

  img {
    aspect-ratio: 16 / 9;
  }
}
.movie-card {
  position: relative;
  overflow: hidden;
}

.info {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  background: #000;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  cursor: pointer;
}

.info i {
  color: #fff;
  font-size: 16px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  margin-bottom: 0;
}

.movie-info {
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  transition: bottom 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.movie-card:hover .movie-info {
  bottom: 0;
}

h3 {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: white;
  font-weight: 500;
  max-width: 70%;
}

.movie-info p {
  display: none;
}

@media (max-width: 700px) {
  .movie-card {
    aspect-ratio: auto;
    height: 190px;
    border-radius: 6px;
  }

  img {
    object-position: center;
    aspect-ratio: auto;
    height: 190px;
    border-radius: 6px;
  }
  .movie-info {
    display: none;
  }
}
</style>
