<template>
  <div class="trends-fii">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <path
        d="M7 2V22"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M17 2V22"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M22 7L2 7"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M22 17L2 17"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "trends-fii",
};
</script>

<style s>
.trends-fii {
  display: flex;
  align-items: center;
}

.trends-fii svg {
  width: 23px;
  height: 23px;
}

.router-link-exact-active .trends-fii svg path {
  stroke: var(--button) !important;
}

.trends-fii svg path {
  stroke: var(--color) !important;
}
</style>
