// store/index.js
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import login from "./modules/login";
import register from "./modules/register";
import user from "./modules/user";
import google from "./modules/google";
import sourate from "./modules/sourate";
import avatar from "./modules/avatar";
import like from "./modules/like";
import resume from "./modules/resume";
import check from "./modules/check";
import livres from "./modules/livres";
import cours from "./modules/cours";
import subscribe from "./modules/subscribe";
import points from "./modules/points";
import duaas from "./modules/duaas";
import notes from "./modules/notes";
import fiiyd from "./modules/fiiyd";
import bunny from "./modules/bunny";
import articles from "./modules/articles";
import ranking from "./modules/ranking";
import testimonials from "./modules/testimonials";
import faq from "./modules/faq";
import kathirs from "./modules/kathirs";
import versets from "./modules/versets";

export default createStore({
  modules: {
    login,
    register,
    user,
    google,
    articles,
    sourate,
    avatar,
    like,
    resume,
    check,
    livres,
    cours,
    subscribe,
    points,
    duaas,
    notes,
    fiiyd,
    bunny,
    ranking,
    testimonials,
    faq,
    kathirs,
    versets,
  },
  plugins: [
    createPersistedState({
      paths: [
        "login",
        "register",
        "user",
        "articles",
        // "google",
        "sourate",
        "avatar",
        "like",
        "resume",
        "check",
        "livres",
        "cours",
        "subscribe",
        "points",
        "duaas",
        "notes",
        "ranking",
        "testimonials",
        "faq",
        "kathirs",
        // "versets",
        // "fiiyd",
        // Pas de 'bunny' ici pour l'exclure de la persistance
      ],
    }),
  ],
});
