<template>
  <div class="emoji-icon-fii">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color="#000000"
      fill="none"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 14C8.91212 15.2144 10.3643 16 12 16C13.6357 16 15.0879 15.2144 16 14"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 8C15 8 14 9 14 10C14.75 9 16.25 9 17 10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00897 9H8"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 16L14.2348 17.3203C14.644 17.9703 15.5502 18.1929 16.2589 17.8177C16.9676 17.4424 17.2104 16.6113 16.8012 15.9614L16 15"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "emoji-fii",
};
</script>

<style s>
.emoji-icon-fii {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: 0.2s ease;
  cursor: pointer;
  margin-bottom: -10px;
}

.emoji-icon-fii:hover {
  background: var(--bgPoints);
}

.emoji-icon-fii svg {
  width: 20px;
  height: 20px;
  color: var(--color);
}

.emoji-icon-fii:hover svg {
  color: var(--button) !important;
}

.emoji-icon-fii:hover svg path {
  stroke: var(--button) !important;
}

.emoji-icon-fii svg path {
  stroke: var(--color) !important;
}
</style>
