<template>
  <router-link to="/versets">
    <div class="search">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        color="#000000"
        fill="none"
      >
        <path
          d="M17.5 17.5L22 22"
          stroke="currentColor"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z"
          stroke="currentColor"
          stroke-width="1"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "search-icon",
};
</script>

<style>
.search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search svg {
  width: 18px;
  height: 18px;
}

.search svg path {
  stroke: var(--link);
}
</style>
