<template>
  <div class="home-icon-fii">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="#000000"
      fill="none"
    >
      <path
        d="M12 17H12.009"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 8.5V13.5C20 17.2712 20 19.1569 18.8284 20.3284C17.6569 21.5 15.7712 21.5 12 21.5C8.22876 21.5 6.34315 21.5 5.17157 20.3284C4 19.1569 4 17.2712 4 13.5V8.5"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M22 10.5L17.6569 6.33548C14.9902 3.77849 13.6569 2.5 12 2.5C10.3431 2.5 9.00981 3.77849 6.34315 6.33548L2 10.5"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "home-fii",
};
</script>

<style s>
.home-icon-fii {
  display: flex;
  align-items: center;
}

.home-icon-fii svg {
  width: 23px;
  height: 23px;
}

.router-link-exact-active .home-icon-fii svg path {
  stroke: var(--button) !important;
}

.home-icon-fii svg path {
  stroke: var(--color) !important;
}
</style>
