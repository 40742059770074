<template>
  <div class="options">
    <img
      v-if="!showSelect"
      :src="getSelectedLanguage().img"
      alt=""
      @click="toggleSelect"
      style="width: 20px; height: 20px; cursor: pointer"
    />
    <el-select
      v-else
      v-model="selectedLanguage"
      placeholder="Sélectionner"
      style="width: 60px"
      @change="changeLanguage"
    >
      <el-option
        v-for="language in languages"
        :key="language.value"
        :label="language.label"
        :value="language.value"
      >
        <template #default>
          <div class="lang">
            <img
              :src="language.img"
              alt=""
              style="width: 20px; height: 20px; margin-right: 8px"
            />
            <span>{{ language.name }}</span>
          </div>
        </template>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { ref } from "vue";

const getUserLocale = () => {
  const userLang = navigator.language || navigator.userLanguage;
  return userLang.toLowerCase().split("-")[0];
};

export default {
  name: "lang-doonun",
  setup() {
    const selectedLanguage = ref(getUserLocale());
    const showSelect = ref(false);

    const languages = [
      {
        value: "fr",
        name: "France",
        img: "https://cdn-icons-png.flaticon.com/512/197/197560.png", // France flag icon
      },
      {
        value: "en",
        name: "Royaume-Uni",
        img: "https://cdn-icons-png.flaticon.com/512/197/197374.png", // UK flag icon
      },
      // Uncomment other languages if needed
      // {
      //   value: "usa",
      //   name: "États-Unis",
      //   img: "https://cdn-icons-png.flaticon.com/512/197/197484.png",
      // },
      // {
      //   value: "it",
      //   name: "Italie",
      //   img: "https://cdn-icons-png.flaticon.com/512/197/197626.png",
      // },
      // {
      //   value: "de",
      //   name: "Allemagne",
      //   img: "https://cdn-icons-png.flaticon.com/512/197/197571.png",
      // },
    ];

    const getSelectedLanguage = () => {
      return languages.find((lang) => lang.value === selectedLanguage.value);
    };

    const toggleSelect = () => {
      showSelect.value = !showSelect.value;
    };

    return {
      selectedLanguage,
      languages,
      getSelectedLanguage,
      showSelect,
      toggleSelect,
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.toggleSelect();
    },
  },
};
</script>

<style scoped>
.options {
  display: flex;
  align-items: center;
}

.lang {
  display: flex;
  align-items: center;
}
</style>
