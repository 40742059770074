<template>
  <Head>
    <!-- Titre de la page -->
    <title>
      {{
        currentCategory.value ? currentCategory.value.name : "Duaas - Doonun"
      }}
    </title>

    <!-- Description Meta -->
    <meta
      name="description"
      :content="
        currentCategory.value
          ? `${currentCategory.value.name} : Liste de toutes les invocations, Duaas importantes à connaître.`
          : 'Liste de toutes les invocations, Duaas importantes à connaître sur Doonun.'
      "
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Canonical URL -->
    <link
      rel="canonical"
      :href="`https://www.doonun.com/duaas/${
        currentCategory.value ? currentCategory.value.slug : ''
      }`"
    />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta
      property="og:url"
      :content="`https://www.doonun.com/duaas/${
        currentCategory.value ? currentCategory.value.slug : ''
      }`"
    />
    <meta
      property="og:title"
      :content="
        currentCategory.value ? currentCategory.value.name : 'Duaas - Doonun'
      "
    />
    <meta
      property="og:description"
      :content="
        currentCategory.value
          ? `${currentCategory.value.name} : Liste de toutes les invocations, Duaas importantes à connaître.`
          : 'Liste de toutes les invocations, Duaas importantes à connaître sur Doonun.'
      "
    />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/duaas_73117ce2be.png"
    />

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:url"
      :content="`https://www.doonun.com/duaas/${
        currentCategory.value ? currentCategory.value.slug : ''
      }`"
    />
    <meta
      name="twitter:title"
      :content="
        currentCategory.value ? currentCategory.value.name : 'Duaas - Doonun'
      "
    />
    <meta
      name="twitter:description"
      :content="
        currentCategory.value
          ? `${currentCategory.value.name} : Liste de toutes les invocations, Duaas importantes à connaître.`
          : 'Liste de toutes les invocations, Duaas importantes à connaître sur Doonun.'
      "
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/duaas_73117ce2be.png"
    />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>
  <div>
    <div class="loading" v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <div class="container">
        <!-- Afficher l'image de la catégorie si l'URL existe -->
        <img
          v-if="getIconUrl(currentCategory)"
          class="cat-img"
          :src="getIconUrl(currentCategory)"
          alt=""
        />
        <h1>
          {{ currentCategory?.attributes?.name || "Category Name Unavailable" }}
        </h1>
        <div class="lists-title">
          <div
            class="box"
            v-for="current in currentCategory?.attributes?.situations?.data ||
            []"
            :key="current.id"
          >
            <router-link
              :to="`/duaas/${currentCategory?.attributes?.slug || ''}/${
                current?.attributes?.slug || ''
              }`"
            >
              <div class="item">
                <h2>{{ current?.attributes?.name || "Title Unavailable" }}</h2>
                <Right />
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="getIconUrl(currentCategory)"
        class="img-bg-dua"
        :style="{
          backgroundImage: `url('${getIconUrl(currentCategory)}')`,
        }"
      ></div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import Right from "../../components/icons/Right.vue";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "duaas-slug",
  components: {
    Loading,
    Right,
    Head,
    Tabs,
  },
  data() {
    return {
      loading: true,
      slug: this.$route.params.slug,
    };
  },
  computed: {
    ...mapGetters("duaas", ["currentCategory"]),
  },
  methods: {
    ...mapActions("duaas", ["fetchCategoryBySlug"]),
    async listTitle() {
      this.loading = true;
      try {
        await this.fetchCategoryBySlug({
          slug: this.slug,
          lang: this.$i18n.locale,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    getIconUrl(category) {
      // Vérifier si l'icône existe et accéder au premier élément du tableau
      const url = category?.attributes?.icon?.data?.attributes?.url;
      return url ? `https://cp.doonun.com${url}` : "path/to/default/image.png";
    },
  },
  async created() {
    this.listTitle();
  },
  watch: {
    "$i18n.locale"() {
      this.fetchCategoryBySlug({
        slug: this.slug,
        lang: this.$i18n.locale,
      });
    },
  },
};
</script>

<style scoped>
.cat-img {
  max-width: 50px;
  max-height: 50px;
  object-fit: contain;
  display: block;
  margin: 100px auto 0 auto;
}

.containter {
}

h1 {
  text-align: center;
  margin-top: 10px;
}

.lists-title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 30px auto 0 auto;
  max-width: 600px;
  min-height: 100vh;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 15px;
  gap: 15px;
  background: var(--card);
  border-radius: 6px;
  min-height: 40px;
  transition: 0.2s ease-in;
}

.item:hover {
  outline: 1px solid var(--button);
  padding: 10px 15px 10px 15px;
}

.item h2 {
  font-size: 16px;
  font-weight: 500;
}

.img-bg-dua {
  position: absolute;
  margin-top: 40px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 400px;
  background-size: cover;
  background-position: center;
  filter: blur(50px);
  opacity: 0.5;
  z-index: -1 !important;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
}
</style>
