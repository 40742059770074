<template>
  <div class="box-options-coran">
    <div class="options">
      <div class="left">
        <router-link v-if="previousSourate" :to="`/sourate/${previousSourate}`">
          <Left />
        </router-link>
      </div>
      <div class="middle">
        <div class="plays">
          <div class="plays-ar run" @click="$emit('playAllAudio', 'ar')">
            <Play v-if="!playAllAr" />
            <Pause v-else />
            <img src="https://cp.doonun.com/uploads/ar_d5a49e3344.png" alt="" />
          </div>
          <div class="plays-fr run" @click="$emit('playAllAudio', $t('lang'))">
            <Play v-if="!playAllFr" />
            <Pause v-else />
            <img :src="$t('flag')" alt="" />
          </div>
          <div class="plays-ar-fr run" @click="$emit('playAllAudio', 'ar-fr')">
            <Play v-if="!playAllArFr" />
            <Pause v-else />
            <img src="https://cp.doonun.com/uploads/ar_d5a49e3344.png" alt="" />
            <img :src="$t('flag')" alt="" />
          </div>
        </div>
        <div class="popup" @click="dialogTableVisible = true">
          <Points />
        </div>
        <div class="texts">
          <div class="lang txt">
            <el-checkbox
              v-model="options.lang"
              :label="$t('langv')"
              size="large"
              @change="updateOptions"
            />
          </div>
          <div class="arabic txt">
            <el-checkbox
              v-model="options.arabic"
              :label="$t('ar')"
              size="large"
              @change="updateOptions"
            />
          </div>
          <div class="phonetic txt">
            <el-checkbox
              v-model="options.phonetic"
              :label="$t('pho')"
              size="large"
              @change="updateOptions"
            />
          </div>
          <div class="tafsir txt">
            <el-checkbox
              v-model="options.tafsir"
              :label="$t('taf')"
              size="large"
              @change="updateOptions"
            />
          </div>
          <div class="tajweed txt">
            <el-checkbox
              v-model="options.tajweed"
              label="Tajweed"
              size="large"
              @change="updateOptions"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <router-link v-if="nextSourate" :to="`/sourate/${nextSourate}`">
          <Right />
        </router-link>
      </div>
    </div>
  </div>

  <div class="modal-options">
    <el-dialog
      v-model="dialogTableVisible"
      :title="$t('settingsourate')"
      class="modal-sourate"
      :append-to-body="true"
    >
      <div class="texts">
        <div class="lang txt">
          <el-checkbox
            v-model="options.lang"
            :label="$t('langv')"
            size="large"
            @change="updateOptions"
          />
        </div>
        <div class="arabic txt">
          <el-checkbox
            v-model="options.arabic"
            :label="$t('ar')"
            size="large"
            @change="updateOptions"
          />
        </div>
        <div class="phonetic txt">
          <el-checkbox
            v-model="options.phonetic"
            :label="$t('pho')"
            size="large"
            @change="updateOptions"
          />
        </div>
        <div class="tafsir txt">
          <el-checkbox
            v-model="options.tafsir"
            :label="$t('taf')"
            size="large"
            @change="updateOptions"
          />
        </div>
        <div class="tajweed txt">
          <el-checkbox
            v-model="options.tajweed"
            label="Tajweed"
            size="large"
            @change="updateOptions"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Left from "../icons/Left.vue";
import Right from "../icons/Right.vue";
import Play from "../icons/Play.vue";
import Pause from "../icons/Pause.vue";
import Points from "../icons/Points.vue";

import { ref, watch } from "vue";

export default {
  name: "options-coran",
  emits: ["playAllAudio"],
  components: { Left, Right, Play, Points, Pause },
  props: {
    initialOptions: {
      type: Object,
      default: () => ({
        lang: true,
        arabic: true,
        phonetic: true,
        tafsir: false,
        tajweed: false,
      }),
    },
    playAllFr: Boolean,
    playAllAr: Boolean,
    playAllArFr: Boolean,
  },
  setup(props, { emit }) {
    const dialogTableVisible = ref(false);
    const options = ref({ ...props.initialOptions });

    const updateOptions = () => {
      emit("update:initialOptions", { ...options.value });
    };

    watch(
      () => options.value.tajweed,
      (newVal) => {
        if (newVal) {
          options.value.arabic = false;
        }
      }
    );

    watch(
      () => options.value.arabic,
      (newVal) => {
        if (newVal) {
          options.value.tajweed = false;
        }
      }
    );

    watch(options, updateOptions, { deep: true });

    return {
      options,
      updateOptions,
      dialogTableVisible,
    };
  },
  computed: {
    previousSourate() {
      if (this.$route.params.id > 1) {
        return parseInt(this.$route.params.id) - 1;
      }
      return null;
    },

    nextSourate() {
      if (this.$route.params.id < 114) {
        return parseInt(this.$route.params.id) + 1;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.box-options-coran {
  display: flex;
  justify-content: center;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  max-width: 1000px;
  min-height: 80px;
  padding: 0 70px;
}

.options .left,
.options .right {
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
}

.options a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  background: var(--card);
  border-radius: 6px;
  transition: 0.2s ease;
}

.options a:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.options .middle {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex: 1;
}

.options .middle::-webkit-scrollbar {
  display: none;
}

.popup {
  display: none;
}

.modal-sourate .texts {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.plays {
  display: flex;
  align-items: center;
  gap: 5px;
}

.plays .run {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 5px 6px;
  background: var(--card);
  border: 1px solid var(--card);
  border-radius: 6px;
  transition: 0.2s ease;
}

.plays .run:hover {
  border: 1px solid var(--button);
}

.plays div img {
  width: 15px;
  height: 15px;
}

.middle .texts {
  display: flex;
  align-items: center;
  gap: 10px;
}

.middle .texts .txt {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 5px 8px;
  background: var(--card);
  border-radius: 6px;
  height: 32px;
  border: 1px solid var(--card);
  transition: 0.2s ease;
}
.middle .texts .txt:hover {
  border: 1px solid var(--button);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 #150b6e90;
    rotate: 20deg;
  }

  50% {
    rotate: -20deg;
  }

  75% {
    box-shadow: 0 0 0 10px #150b6e60;
  }

  100% {
    box-shadow: 0 0 0 13px #150b6e30;
    rotate: 0;
  }
}

@media (max-width: 1140px) {
  .popup {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 32px;
    border-radius: 6px;
    background: var(--card);
    cursor: pointer;
    border: 1px solid var(--card);
    transition: 0.2s ease;
  }
  .popup:hover {
    border: 1px solid var(--button);
    transition: 0.2s ease;
  }
  .middle .texts {
    display: none;
  }
}

@media (max-width: 900px) {
  .options {
    padding: 10px 0 5vh 0;
    width: 100%;
    gap: 5px;
  }
}

@media (min-width: 360px) {
  .middle {
    gap: 8px !important;
  }

  .middle .plays {
    /* gap: 10px !important; */
  }

  .middle .texts {
    gap: 5px !important;
  }
}
</style>
